<template>
  <div v-if="loading" class="flex items-center justify-center h-full">
    <loading-spinner diameter="64px" />
  </div>

  <alert-badge
    v-else-if="error.title"
    isDanger
    :title="error.title"
    :body="error.body"
    @dismissed="
      () => {
        error.title = '';
        error.body = '';
      }
    "
  />

  <div v-else>
    <div class="flex flex-row">
      <div class="flex flex-col">
        <action-button :title="t('print')" faIcon="print" @click="print" />
        <action-button
          :title="$t('views.sales.newSale')"
          isSuccess
          @click="$router.push($Route.SALES_NEW_SALE)"
        >
          <custom-icon>
            <i-new-sale />
          </custom-icon>
        </action-button>
      </div>

      <CustomerAccountStatementA4
        v-if="$ctx.getAccountStatementSize() == 'a4'"
        :orders="orders"
        :transactions="transactions"
        :customer="customer"
        :totalAmount="totalAmount"
        :totalAmountDinero="totalAmountDinero"
        :balance="balance"
        :balance-dinero="balanceDinero"
        :total-paid="totalPaid"
        :total-paid-dinero="totalPaidDinero"
        :printed-on="printedOn"
      ></CustomerAccountStatementA4>

      <CustomerAccountStatementSmall
        v-if="$ctx.getAccountStatementSize() == 'receipt'"
        :orders="orders"
        :transactions="transactions"
        :customer="customer"
        :totalAmount="totalAmount"
        :totalAmountDinero="totalAmountDinero"
        :balance="balance"
        :balance-dinero="balanceDinero"
        :total-paid="totalPaid"
        :total-paid-dinero="totalPaidDinero"
        :printed-on="printedOn"
      ></CustomerAccountStatementSmall>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { Order } from "@/models/sales/Order";
import { parseFormat } from "@/utils/date";
import { useI18n } from "vue-i18n";
import { formatDinero, formatMoney } from "@/utils/money";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import AlertBadge from "@/components/AlertBadge.vue";
import { Transaction } from "@/models/accounting/Transaction";
import ActionButton from "@/components/ActionButton.vue";
import INewSale from "@/components/icons/sales/INewSale.vue";
import CustomIcon from "@/components/CustomIcon.vue";
import Customer from "@/models/customer/Customer";
import CustomerAccountStatement from "@/models/customer/AccountStatement";
import Dinero, { Currency } from "dinero.js";
import CustomerAccountStatementA4 from "./CustomerAccountStatementA4.vue";
import CustomerAccountStatementSmall from "./CustomerAccountStatementSmall.vue";

export default defineComponent({
  components: {
    CustomerAccountStatementA4,
    CustomerAccountStatementSmall,
    LoadingSpinner,
    AlertBadge,
    ActionButton,
    CustomIcon,
    INewSale
  },

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          print: "Print Receipt"
        },
        ar: {
          print: "اطبع الفاتورة"
        }
      }
    });

    return { t };
  },
  data() {
    return {
      orders: [] as Array<Order>,
      transactions: [] as Array<Transaction>,
      customer: new Customer(),
      loading: true,
      totalAmount: 0,
      totalAmountDinero: Dinero(),
      balance: 0,
      balanceDinero: Dinero(),
      totalPaid: 0,
      totalPaidDinero: Dinero(),
      printedOn: "",
      error: {
        title: "",
        body: ""
      }
    };
  },
  async beforeMount() {
    try {
      const res = await this.$http.get<CustomerAccountStatement>(
        `${CustomerAccountStatement.ENDPOINT}?customer_id=${this.$route.params["customer_id"]}`
      );

      const accountStatement = CustomerAccountStatement.from(res);
      this.orders = accountStatement.orders;
      this.transactions = accountStatement.transactions;
      this.customer = accountStatement.customer!;
      this.printedOn = accountStatement.printedOn!;

      this.orders.forEach(o => {
        this.balance += o.invoice.totalBalanceAmount!;
        this.totalAmount += o.invoice.totalDueAmount!;
      });

      this.balanceDinero = Dinero({
        amount: this.balance,
        currency: this.$ctx.currency as Currency,
        precision: this.$ctx.currencyPrecision
      });

      this.totalAmountDinero = Dinero({
        amount: this.totalAmount,
        currency: this.$ctx.currency as Currency,
        precision: this.$ctx.currencyPrecision
      });

      this.transactions.forEach(t => {
        this.totalPaid += t.amountAmount!;
      });

      this.totalPaidDinero = Dinero({
        amount: this.totalPaid,
        currency: this.$ctx.currency as Currency,
        precision: this.$ctx.currencyPrecision
      });
    } catch (error) {
      console.log(error);
      this.error.title = error.title;
      this.error.body = error.body;
    } finally {
      this.loading = false;
    }
  },
  methods: {
    parseFormat,
    formatDinero,
    formatMoney,
    print() {
      window.print();
    }
  }
});
</script>
<style></style>