<template>
  <div v-if="loading" class="flex items-center justify-center h-full">
    <loading-spinner diameter="64px" />
  </div>

  <alert-badge
    v-else-if="error.title"
    isDanger
    :title="error.title"
    :body="error.body"
    @dismissed="
      () => {
        error.title = ''
        error.body = ''
      }
    "
  />

  <div v-else>
    <div class="w-full mx-8 my-2">
      {{ t('mainOperations') }}
    </div>
    <div class="flex flex-col flex-wrap content-center m-3 md:flex-row lg:justify-start">
      <action-button
        v-if="registerIsOpen"
        :title="$t('views.sales.newSale')"
        isSuccess
        @click="$router.push($Route.SALES_NEW_SALE)"
      >
        <custom-icon>
          <i-new-sale />
        </custom-icon>
      </action-button>
      <action-button
        v-if="registerIsOpen"
        :title="$t('views.sales.orders.returnOrder')"
        isDanger
        @click="$router.push($Route.SALES_ORDERS_RETURN_ORDERS)"
      >
        <custom-icon>
          <i-return-sale />
        </custom-icon>
      </action-button>
      <action-button
        :title="$t('views.sales.orders.orders')"
        faIcon="receipt"
        @click="$router.push($Route.SALES_ORDERS)"
      >
      </action-button>
      <action-button
        v-if="registerIsOpen"
        :title="$t('views.sales.transactions.transactions')"
        faIcon="exchange-alt"
        @click="$router.push($Route.SALES_TRANSACTIONS)"
      >
      </action-button>
    </div>

    <div class="w-full mx-8 my-2">
      {{ t('registerOperations') }}
    </div>
    <div class="flex flex-col flex-wrap content-center m-3 md:flex-row lg:justify-start">
      <action-button
        v-if="!registerIsOpen"
        :title="$t('views.sales.registers.openRegister')"
        faIcon="cash-register"
        @click="$router.push($Route.SALES_REGISTERS_OPEN_REGISTER)"
      >
      </action-button>

      <action-button
        v-else
        :title="$t('views.sales.registers.closeRegister')"
        faIcon="cash-register"
        @click="$router.push($Route.SALES_REGISTERS_CLOSE_REGISTER)"
      >
      </action-button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import ActionButton from '@/components/ActionButton.vue'
import CustomIcon from '@/components/CustomIcon.vue'
import INewSale from '@/components/icons/sales/INewSale.vue'
import IReturnSale from '@/components/icons/sales/IReturnSale.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import AlertBadge from '@/components/AlertBadge.vue'

import { Register, RegisterLog } from '@/models/company/Register'

export default defineComponent({
  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          mainOperations: 'Main Operations',
          registerOperations: 'Register Operations'
        },
        ar: {
          mainOperations: 'العمليات الاساسية',
          registerOperations: 'عمليات الخزينة'
        }
      }
    })

    return {
      t
    }
  },
  components: {
    ActionButton,
    CustomIcon,
    INewSale,
    IReturnSale,
    LoadingSpinner,
    AlertBadge
  },

  data() {
    return {
      error: {
        title: '',
        body: ''
      },
      loading: false
    }
  },

  computed: {
    registerIsOpen() {
      return this.$ctx.currentRegister.status === 'open'
    }
  },

  async beforeMount() {
    const loadRegisterStatus = async () => {
      try {
        this.loading = true
        const registerLog = await this.$http.get<RegisterLog>(
          `${Register.ENDPOINT}/status/${this.$ctx.currentRegister.id}`
        )

        this.$ctx.currentRegister.status = RegisterLog.from(registerLog).action
      } catch (error) {
        this.error.title = error.title
        this.error.body = error.body
      } finally {
        this.loading = false
      }
    }
    // check if the register is open or not
    loadRegisterStatus()
  },
  methods: {
    canWithReason(): boolean {
      return this.$can('read', 'magazine')
    }
  }
})
</script>

<style></style>
