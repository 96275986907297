import { Transaction } from '../accounting/Transaction'
import VendorBill from './VendorBill'
import Vendor from './Vendor'

export default class VendorAccountStatement {
  static from(json: Record<string, unknown> | VendorAccountStatement) {
    const accountStatement = Object.assign(new VendorAccountStatement(), json)
    if (accountStatement.vendor)
      accountStatement.vendor = Vendor.from(accountStatement.vendor)
    if (accountStatement.vendorBills)
      accountStatement.vendorBills = accountStatement.vendorBills.map((vendorBill) => VendorBill.from(vendorBill))
    if (accountStatement.transactions)
      accountStatement.transactions = accountStatement.transactions.map((transaction) =>
        Transaction.from(transaction)
      )
    return accountStatement
  }
  static ENDPOINT = '/reports/vendor/account-statement'

  vendor?: Vendor
  vendorBills: Array<VendorBill> = []
  transactions: Array<Transaction> = []
  printedOn?: string
}
