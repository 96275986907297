<template>
  <div class="m-3" style="height: 90vh">
    <div class="grid grid-cols-12 items-center content-center gap-2">
      <el-select class="input-label col-span-12 md:col-span-2" v-model="location" :placeholder="t('selectLocation')">
        <el-option
          v-for="(loc, i) in locations"
          :key="i"
          :label="$ctx.getDir() === 'rtl' ? loc.arName : loc.enName"
          :value="loc.id"
        >
        </el-option>
      </el-select>
      <div class="flex col-span-12 md:col-span-2">
        <el-select class="input-label" v-model="shortcut" :placeholder="t('shortcuts')">
          <el-option :label="t('lowInventory')" value="low"></el-option>
          <el-option :label="t('highInventory')" value="high"></el-option>
          <el-option :label="t('negativeInventory')" value="negative"></el-option>
        </el-select>
      </div>
      <div class="flex col-span-12 md:col-span-2">
        <el-input v-model="lowestAmount" placeholder="low" />
        <el-input v-model="highestAmount" placeholder="high" />
      </div>
      <div class="col-span-12 md:col-span-2">
        <horizontal-button :title="t('generateReport')" @click="updateRowData()" />
      </div>
    </div>

    <v-chart :option="option" autoresize />


    <ag-grid-vue
      class="ag-theme-alpine"
      :columnDefs="columnDefs"
      :rowData="rowData"
      rowSelection="multiple"
      :enable-rtl="$ctx.getDir() === 'rtl'"
      style="height: 85vh"
      :pagination="true"
      :paginationAutoPageSize="true"
      :defaultColDef="defaultColDef"
      :onGridReady="onGridReady"
    >
    </ag-grid-vue>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { ref, provide } from "vue";

// components
import { AgGridVue } from 'ag-grid-vue3'
import { ColDef, GridApi, GridOptions } from 'ag-grid-community'
import HorizontalButton from '@/components/HorizontalButton.vue'
import Location from '@/models/company/Location'

import { useI18n } from 'vue-i18n'
import { getPath } from '../sales/utils'

class InventoryQuantity {
  static from(json: Record<string, unknown> | InventoryQuantity) {
    return Object.assign(new InventoryQuantity(), json)
  }

  static ENDPOINT = 'reports/inventory-quantity'

  low?: number
  high?: number
  arLocationName?: string
  enLocationName?: string
  locationID = ''
  items: ItemLocationQuantity[] = []
}

interface ItemLocationQuantity {
  itemName: string
  itemID: string
  quantity: number
}

export default defineComponent({
  name: 'quantity-report',

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          highInventory: 'High Inventory',
          lowInventory: 'Low Inventory',
          negativeInventory: 'Negative Inventory',
          itemName: 'Item Name',
          quantity: 'Quantity',
          location: 'Location',
          generateReport: 'Generate Report',
          selectLocation: 'Select Location'
        },
        ar: {
          highInventory: 'High Inventory',
          lowInventory: 'Low Inventory',
          negativeInventory: 'Negative Inventory',
          itemName: 'اسم المادة',
          quantity: 'الكمية',
          location: 'الموقع',
          generateReport: 'إنشاء تقرير',
          selectLocation: 'اختر الموقع'
        }
      }
    })

    use([
      CanvasRenderer,
      PieChart,
      TitleComponent,
      TooltipComponent,
      LegendComponent
    ]);

    provide(THEME_KEY, "light");


const option = ref({
  title: {
    text: "Traffic Sources",
    left: "center"
  },
  tooltip: {
    trigger: "item",
    formatter: "{a} <br/>{b} : {c} ({d}%)"
  },
  legend: {
    orient: "vertical",
    left: "left",
    data: ["Direct", "Email", "Ad Networks", "Video Ads", "Search Engines"]
  },
  series: [
    {
      name: "Traffic Sources",
      type: "pie",
      radius: "55%",
      center: ["50%", "60%"],
      data: [
        { value: 335, name: "Direct" },
        { value: 310, name: "Email" },
        { value: 234, name: "Ad Networks" },
        { value: 135, name: "Video Ads" },
        { value: 1548, name: "Search Engines" }
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)"
        }
      }
    }
  ]
});

    return { t, option }
  },

  data() {
    const columnDefs: ColDef[] = []
    const rowData: ItemLocationQuantity[] = []
    const gridOptions: GridOptions = {}
    const defaultColDef: ColDef = {}
    let highestAmount: number | undefined
    let lowestAmount: number | undefined
    const shortcut = ''
    return {
      shortcut,
      highestAmount,
      lowestAmount,
      locations: new Array<Location>(),
      location: '',
      columnDefs,
      rowData,
      gridOptions,
      defaultColDef,
      gridApi: undefined as GridApi | undefined
    }
  },

  components: {
    AgGridVue,
    HorizontalButton,
    VChart
  },

  watch: {
    shortcut() {
      if (this.shortcut === 'low') {
        this.lowestAmount = 0
        this.highestAmount = 10
      }
      if (this.shortcut === 'high') {
        this.lowestAmount = 50
        this.highestAmount = undefined
      }
      if (this.shortcut === 'negative') {
        this.lowestAmount = undefined
        this.highestAmount = -1
      }
    }
  },

  async beforeMount() {
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
      minWidth: 150
    }

    this.columnDefs = [
      {
        headerName: this.t('itemName'),
        field: 'itemName',
        checkboxSelection: true,
        headerCheckboxSelection: true
      },
      {
        headerName: this.t('quantity'),
        field: 'quantity',
        cellStyle: { textAlign: 'center' }
      }
    ]

    const locationsResp = await this.$http.get<Location[]>(Location.ENDPOINT)
    this.locations = locationsResp.map((cat) => Location.from(cat))
  },

  mounted() {
  },

  methods: {
    async updateRowData() {
      this.gridApi?.showLoadingOverlay()
      try {
        const res = await this.$http.get<InventoryQuantity>(
          `${getPath(InventoryQuantity.ENDPOINT, this.location)}&low=${this.lowestAmount ? this.lowestAmount : ''}&high=${this.highestAmount ? this.highestAmount : ''}`
        )

        const report = InventoryQuantity.from(res)

        this.rowData = report.items
      } catch (error: any) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        })
      }

      this.gridApi?.hideOverlay()
    },

    async onGridReady(params: any) {
      this.gridApi = params.api
      this.gridApi?.sizeColumnsToFit()
      // await this.updateRowData()
    },
  }
})
</script>

<style></style>
