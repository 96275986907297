interface PermissionRoute {
  route: string
  permissions: Permission[]
}

interface Permission {
  id: string
  externalID: string
  createdAt: Date
  updatedAt: Date
  deletedAt: null
  object: string
  action: Action
  checked: boolean
}

enum Action {
  Create = 'create',
  Delete = 'delete',
  Read = 'read',
  Update = 'update'
}

export default class PermissionGroup {
  static ENDPOINT = '/settings/permissions'

  static from(json: Record<string, unknown> | PermissionGroup) {
    return Object.assign(new PermissionGroup(), json)
  }

  group?: string
  permission_routes?: PermissionRoute[]
}
