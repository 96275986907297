<template>
  <div class="m-3" style="height: 90vh">
    <div class="grid grid-cols-2 gap-2">
      <div class="col-span-12 md:col-span-2 chart-card" style="height: 400px">
        <v-chart :option="option" autoresize />
      </div>
    </div>

    <ag-grid-vue
      class="ag-theme-alpine mt-4 md:mt-0"
      :columnDefs="columnDefs"
      :rowData="rowData"
      rowSelection="multiple"
      :enable-rtl="$ctx.getDir() === 'rtl'"
      style="height: 85vh"
      :pagination="true"
      :paginationAutoPageSize="true"
      :defaultColDef="defaultColDef"
      :onGridReady="onGridReady"
    >
    </ag-grid-vue>
  </div>
</template>

<script setup lang="ts">
import { ref, onBeforeMount, provide } from 'vue'
import { useI18n } from 'vue-i18n'
import { ColDef, GridApi } from 'ag-grid-community'
import { AgGridVue } from 'ag-grid-vue3'
import { useRoute, useRouter } from 'vue-router'
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { PieChart, BarChart } from 'echarts/charts'
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
  DataZoomComponent
} from 'echarts/components'
import VChart, { THEME_KEY } from 'vue-echarts'

import { useHTTP } from '@/plugins/http'
import { useAlertModal } from '@/plugins/alert-modal/alert-modal'
import { formatMoney } from '@/utils/money'

// i18n
const { t } = useI18n({
  messages: {
    en: {
      name: 'Name',
      quantity: 'Quantity',
      negativeStockInventory: 'Negative Stock Inventory'
    },
    ar: {
      name: 'أسم المنتج',
      quantity: 'الكمية',
      negativeStockInventory: 'المخزون السلبي'
    }
  }
})

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  BarChart,
  GridComponent,
  ToolboxComponent,
  DataZoomComponent
])

provide(THEME_KEY, 'light')

const option = ref({
  title: {
    text: t('negativeStockInventory'),
    left: 'left'
  },
  toolbox: {
    feature: {
      saveAsImage: {}
    }
  },
  tooltip: {
    trigger: 'item',
  },
  
  xAxis: {
    type: 'category',
    data: [],
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      formatter: (val: any) => -val
    }
  },
  dataZoom: [
    {
      id: 'dataZoomX',
      type: 'slider',
      xAxisIndex: 0,
      filterMode: 'empty',
      start: 0,
      end: 50,
      handleSize: 0,
    },
    {
      type: 'inside',
      id: 'insideX',
      xAxisIndex: 0,
      start: 0,
      end: 50,
      zoomOnMouseWheel: false,
      moveOnMouseMove: true,
      moveOnMouseWheel: true
    }
  ],
  grid: {
    containLabel: true,
    bottom: 80,
    left: 0,
  },
  series: [
    {
      name: t('negativeStockInventory'),
      type: 'bar',
      barWidth: 50,
      label: {
        show: true,
        position: 'inside',
        formatter: (params: any) => -params.data
      },
      data: [],
      z: 1000
    }
  ]
})

// Data
const columnDefs = ref<ColDef[]>([])
const rowData = ref<any[]>([])
const defaultColDef = ref<ColDef>({})
const searchTerm = ref('')
const selectedValue = ref('')
const gridApi = ref<GridApi | undefined>(undefined)

const http = useHTTP()
const route = useRoute()
const router = useRouter()
const alertModal = useAlertModal()

onBeforeMount(async () => {
  defaultColDef.value = {
    sortable: true,
    filter: true,
    resizable: true,
    minWidth: 150
  }

  columnDefs.value = [
    {
      headerName: t('name'),
      field: 'name',
      checkboxSelection: true,
      headerCheckboxSelection: true
    },
    {
      headerName: t('quantity'),
      field: 'quantity',
      cellStyle: { direction: 'ltr !important', textAlign: 'center' }
    }
  ]
})

// Methods
async function updateRowData() {
  gridApi.value?.showLoadingOverlay()

  try {
    const data: any = await http.get<any[]>('/reports/inventory/negative-stock-items')
    rowData.value = data

    option.value.series[0].data = data.map((i: any) => i.quantity * -1)
    option.value.xAxis.data = data.map((i: any) => i.name)
  } catch (error) {
    alertModal.showDanger({
      title: error.title,
      body: error.body
    })
  }

  gridApi.value?.hideOverlay()
}

async function onGridReady(params: any) {
  gridApi.value = params.api
  gridApi.value?.sizeColumnsToFit()
  await updateRowData()
}
</script>
