<template>
  <div v-if="loading" class="flex items-center justify-center h-full">
    <loading-spinner diameter="64px" />
  </div>

  <alert-badge
    v-else-if="error.title"
    isDanger
    :title="error.title"
    :body="error.body"
    @dismissed="
      () => {
        error.title = ''
        error.body = ''
      }
    "
  />

  <div v-else>
    <div class="flex flex-row">
      <div class="flex flex-col">
        <action-button :title="t('print')" faIcon="print" @click="print" />
        <action-button
          :title="$t('views.sales.newSale')"
          isSuccess
          @click="$router.push($Route.SALES_NEW_SALE)"
        >
          <custom-icon>
            <i-new-sale />
          </custom-icon>
        </action-button>
      </div>

      <VendorAccountStatementA4
        v-if="$ctx.getAccountStatementSize() == 'a4'"
        :vendorBills="vendorBills"
        :transactions="transactions"
        :vendor="vendor"
        :totalAmount="totalAmount"
        :totalAmountDinero="totalAmountDinero"
        :balance="balance"
        :balance-dinero="balanceDinero"
        :total-paid="totalPaid"
        :total-paid-dinero="totalPaidDinero"
        :printed-on="printedOn"
      ></VendorAccountStatementA4>

      <VendorAccountStatementSmall
        v-if="$ctx.getAccountStatementSize() == 'receipt'"
        :vendorBills="vendorBills"
        :transactions="transactions"
        :vendor="vendor"
        :totalAmount="totalAmount"
        :totalAmountDinero="totalAmountDinero"
        :balance="balance"
        :balance-dinero="balanceDinero"
        :total-paid="totalPaid"
        :total-paid-dinero="totalPaidDinero"
        :printed-on="printedOn"
      ></VendorAccountStatementSmall>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { parseFormat } from '@/utils/date'
import { useI18n } from 'vue-i18n'
import { formatDinero, formatMoney } from '@/utils/money'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import AlertBadge from '@/components/AlertBadge.vue'
import { Transaction } from '@/models/accounting/Transaction'
import ActionButton from '@/components/ActionButton.vue'
import INewSale from '@/components/icons/sales/INewSale.vue'
import CustomIcon from '@/components/CustomIcon.vue'
import VendorAccountStatement from '@/models/vendor/AccountStatement'
import Dinero, { Currency } from 'dinero.js'
import VendorAccountStatementA4 from './VendorAccountStatementA4.vue'
import VendorAccountStatementSmall from './VendorAccountStatementSmall.vue'
import VendorBill from '@/models/vendor/VendorBill'
import Vendor from '@/models/vendor/Vendor'

export default defineComponent({
  components: {
    VendorAccountStatementA4,
    VendorAccountStatementSmall,
    LoadingSpinner,
    AlertBadge,
    ActionButton,
    CustomIcon,
    INewSale
  },

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          print: 'Print Receipt'
        },
        ar: {
          print: 'اطبع الفاتورة'
        }
      }
    })

    return { t }
  },
  data() {
    return {
      vendorBills: [] as Array<VendorBill>,
      transactions: [] as Array<Transaction>,
      vendor: new Vendor(),
      loading: true,
      totalAmount: 0,
      totalAmountDinero: Dinero(),
      balance: 0,
      balanceDinero: Dinero(),
      totalPaid: 0,
      totalPaidDinero: Dinero(),
      printedOn: '',
      error: {
        title: '',
        body: ''
      }
    }
  },
  async beforeMount() {
    try {
      const res = await this.$http.get<VendorAccountStatement>(
        `${VendorAccountStatement.ENDPOINT}?vendor_id=${this.$route.params['vendor_id']}`
      )

      const accountStatement = VendorAccountStatement.from(res)
      this.vendorBills = accountStatement.vendorBills
      this.transactions = accountStatement.transactions
      this.vendor = accountStatement.vendor!
      this.printedOn = accountStatement.printedOn!

      this.vendorBills.forEach((o) => {
        this.balance += o.totalBalanceAmount!
        this.totalAmount += o.totalDueAmount!
      })

      this.balanceDinero = Dinero({
        amount: this.balance,
        currency: this.$ctx.currency as Currency,
        precision: this.$ctx.currencyPrecision
      })

      this.totalAmountDinero = Dinero({
        amount: this.totalAmount,
        currency: this.$ctx.currency as Currency,
        precision: this.$ctx.currencyPrecision
      })

      this.transactions.forEach((t) => {
        this.totalPaid += t.amountAmount!
      })

      this.totalPaidDinero = Dinero({
        amount: this.totalPaid,
        currency: this.$ctx.currency as Currency,
        precision: this.$ctx.currencyPrecision
      })
    } catch (error) {
      console.log(error)
      this.error.title = error.title
      this.error.body = error.body
    } finally {
      this.loading = false
    }
  },
  methods: {
    parseFormat,
    formatDinero,
    formatMoney,
    print() {
      window.print()
    }
  }
})
</script>
<style></style>
