<template>
  <div class="w-full flex justify-end">
    <button class="text-black hover:text-blue-600 font-bold" @click="btnClickedHandler('id_card')">
      <span class="fa fa-credit-card"></span>
    </button>
    <button
      class="text-black mx-2 hover:text-blue-600 font-bold"
      @click="btnClickedHandler('print')"
    >
      <span class="fa fa-print"></span>
    </button>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  methods: {
    btnClickedHandler(action: string) {
      /* @ts-ignore */
      this.params.onPrintBtnClicked(action, this.params.data)
    }
  }
})
</script>
