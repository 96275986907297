<template>
  <div class="m-3" style="height: 90vh">
    <div class="grid grid-cols-2 gap-2">
      <div class="col-span-12 md:col-span-1 chart-card" style="height: 400px">
        <v-chart :option="option1" autoresize />
      </div>
      <div class="col-span-12 md:col-span-1 chart-card" style="height: 400px">
        <v-chart :option="option2" autoresize />
      </div>
    </div>

    <ag-grid-vue
      class="ag-theme-alpine mt-4 md:mt-0"
      :columnDefs="columnDefs"
      :rowData="rowData"
      rowSelection="multiple"
      :enable-rtl="$ctx.getDir() === 'rtl'"
      style="height: 85vh"
      :pagination="true"
      :paginationAutoPageSize="true"
      :defaultColDef="defaultColDef"
      :onGridReady="onGridReady"
    >
    </ag-grid-vue>
  </div>
</template>

<script setup lang="ts">
import { ref, onBeforeMount, provide } from 'vue'
import { useI18n } from 'vue-i18n'
import { ColDef, GridApi } from 'ag-grid-community'
import { AgGridVue } from 'ag-grid-vue3'
import { useRoute, useRouter } from 'vue-router'
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { PieChart, BarChart } from 'echarts/charts'
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
  DataZoomComponent
} from 'echarts/components'
import VChart, { THEME_KEY } from 'vue-echarts'

import { useHTTP } from '@/plugins/http'
import { useAlertModal } from '@/plugins/alert-modal/alert-modal'
import { formatMoney } from '@/utils/money'

// i18n
const { t } = useI18n({
  messages: {
    en: {
      username: 'Username',
      name: 'Name',
      salary: 'Salary',
      topSalaries: 'Top Salaries'
    },
    ar: {
      username: 'أسم المستخدم',
      name: 'أسم الموظف',
      salary: 'ألراتب',
      topSalaries: 'أعلى الرواتب'
    }
  }
})

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  BarChart,
  GridComponent,
  ToolboxComponent,
  DataZoomComponent
])

provide(THEME_KEY, 'light')

const option1 = ref({
  title: {
    text: t('topSalaries'),
    left: 'left'
  },
  grid: {
    top: 80,
  },
  toolbox: {
    feature: {
      saveAsImage: {}
    }
  },
  tooltip: {
    trigger: 'item',
    formatter: ({ seriesName, name, value, percent }: any) => {
      const salary = value ? formatMoney(value, 'IQD') : ''
      return `${name}</br>${salary} (${percent}%)`
    }
  },
  legend: {
    top: 30,
    orient: 'vertical',
    left: 'left',
    formatter: (data: any, x: any) => {
      return `${data}`
    }
  },
  series: [
    {
      name: t('topSalaries'),
      type: 'pie',
      radius: '55%',
      center: ['50%', '60%'],
      data: [] as any[]
    }
  ]
})

const option2 = ref({
  title: {
    text: t('topSalaries'),
    left: 'left'
  },
  toolbox: {
    feature: {
      saveAsImage: {}
    }
  },
  tooltip: {
    trigger: 'item',
    formatter: ({ seriesName, name, value }: any) => {
      const salary = value ? formatMoney(value, 'IQD') : ''
      return `${name}</br>${salary}`
    }
  },
  xAxis: {
    type: 'category',
    data: []
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      formatter: (val: any) => val ? formatMoney(val, 'IQD') : ''
    }
  },
  dataZoom: [
    {
      id: 'dataZoomX',
      type: 'slider',
      xAxisIndex: 0,
      filterMode: 'empty',
      start: 0,
      end: 50,
      handleSize: 0,
    },
    {
      type: 'inside',
      id: 'insideX',
      xAxisIndex: 0,
      start: 0,
      end: 50,
      zoomOnMouseWheel: false,
      moveOnMouseMove: true,
      moveOnMouseWheel: true
    }
  ],
  grid: {
    containLabel: true,
    bottom: 80,
    left: 0,
  },
  series: [
    {
      name: t('topSalaries'),
      type: 'bar',
      barWidth: 50,
      label: {
        rotate: 90,
        show: true,
        position: 'inside',
        formatter: (val: any) => {
          return val.data ? formatMoney(val.data, 'IQD') : ''
        }
      },
      data: [],
    }
  ]
})

// Data
const columnDefs = ref<ColDef[]>([])
const rowData = ref<any[]>([])
const defaultColDef = ref<ColDef>({})
const searchTerm = ref('')
const selectedValue = ref('')
const gridApi = ref<GridApi | undefined>(undefined)

const http = useHTTP()
const route = useRoute()
const router = useRouter()
const alertModal = useAlertModal()

onBeforeMount(async () => {
  defaultColDef.value = {
    sortable: true,
    filter: true,
    resizable: true,
    minWidth: 150
  }

  columnDefs.value = [
    {
      headerName: t('username'),
      field: 'username',
      checkboxSelection: true,
      headerCheckboxSelection: true
    },
    {
      headerName: t('name'),
      valueFormatter: ({ data }) => {
        if (!data) return ''
        return `${data.first_name || ''} ${data.last_name || ''}`
      }
    },
    {
      headerName: t('salary'),
      field: 'salary',
      cellStyle: { direction: 'ltr !important', textAlign: 'center' },
      valueFormatter: ({ data }) => {
        if (!data) return ''
        return data.salary != undefined ? formatMoney(data.salary, 'IQD') : ''
      }
    }
  ]
})

// Methods
async function updateRowData() {
  gridApi.value?.showLoadingOverlay()

  try {
    const data = await http.get<any[]>('/reports/hr/top-salaries')
    rowData.value = data
    const chartData: any = []
    rowData.value.forEach((i: any) => {
      chartData.push({
        value: i.salary,
        name: `${i.first_name || ''} ${i.last_name || ''}`
      })
    })
    option1.value.series[0].data = chartData
    option2.value.series[0].data = chartData.map((i: any) => i.value)
    option2.value.xAxis.data = chartData.map((i: any) => i.name)
  } catch (error) {
    alertModal.showDanger({
      title: error.title,
      body: error.body
    })
  }

  gridApi.value?.hideOverlay()
}

async function onGridReady(params: any) {
  gridApi.value = params.api
  gridApi.value?.sizeColumnsToFit()
  await updateRowData()
}
</script>
