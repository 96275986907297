<template>
  <div v-if="loading" class="flex h-full justify-center items-center">
    <loading-spinner diameter="64px" />
  </div>

  <alert-badge
    v-else-if="error.title"
    isDanger
    :title="error.title"
    :body="error.body"
    @dismissed="
      () => {
        error.title = ''
        error.body = ''
      }
    "
  />

  <vendor-form
    v-else
    :initVendor="initVendor"
    :successMsg="$t('actions.saveChangesSuccess')"
    :submitAction="update"
  />
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, ref } from 'vue'

import { useHTTP } from '@/plugins/http'

import Vendor from '@/models/vendor/Vendor'

// components
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import VendorForm from '@/views/vendors/VendorForm.vue'
import AlertBadge from '@/components/AlertBadge.vue'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  name: 'vendor',
  components: {
    LoadingSpinner,
    VendorForm,
    AlertBadge
  },
  setup() {
    const initVendor = ref(new Vendor())
    const error = ref({ title: '', body: '' })
    const http = useHTTP()
    const loading = ref(true)
    const route = useRoute()
    const router = useRouter()


    onBeforeMount(async () => {
      const url = `${Vendor.ENDPOINT}/${route.params.id}`

      try {
        const resp = await http.get<Vendor>(url)
        initVendor.value = Vendor.from(resp)
      } catch (errorResp) {
        error.value.title = errorResp.title
        error.value.body = errorResp.body
      }

      loading.value = false
    })

    const update = async (vendor: Vendor) => {
      try {
        const url = `${Vendor.ENDPOINT}/${vendor.id}`
        const resp = await http.put<Vendor>(url, vendor)
        initVendor.value = Vendor.from(resp)
        router.back();
      } catch (error) {
        return error
      }
    }

    return {
      initVendor,
      error,
      loading,
      update
    }
  }
})
</script>
