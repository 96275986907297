<template>
  <div v-if="!isEdit" class="flex items-center justify-around">
    <span :class="{ 'text-red-600': transaction.receiptType == 'return' }">
      {{ formatDinero(transaction.amountDinero) }}
    </span>
    <div v-if="enabled">
      <button class="text-black hover:text-blue-600 font-bold" @click="edit()">
        <span class="fa fa-edit"></span>
      </button>
    </div>
  </div>

  <div v-if="isEdit" class="flex justify-between">
    <money-input
      :currency="$ctx.currency"
      v-model="transaction.amountAmount"
      @dinero-created="(dinero) => (transaction.amountDinero = dinero)"
      @keyup-enter="update"
      ref="moneyInput"
    />
    <button class="mx-1 hover:text-green-400 text-green-600 font-bold" @click="update">
      <span class="fa fa-check"></span>
    </button>
    <button class="mx-1 hover:text-red-400 text-red-600 font-bold" @click="isEdit = false">
      <span class="fa fa-ban"></span>
    </button>
  </div>
</template>

<script lang="ts">
import { Transaction } from '@/models/accounting/Transaction'
import { defineComponent } from 'vue'
import MoneyInput from '@/components/form/MoneyInput.vue'
import { formatDinero } from '@/utils/money'

export default defineComponent({
  name: 'payment-edit',
  components: {
    MoneyInput
  },
  data() {
    return {
      transaction: new Transaction(),
      isEdit: false,
    }
  },
  beforeMount() {
    this.transaction = Object.assign({}, this.$props.data)
  },
  props: {
    data: {
      required: true,
      type: Transaction
    },
    enabled: {
      required: false,
      type: Boolean,
      default: false,
    }
  },
  methods: {
    formatDinero,
    edit() {
      this.isEdit = true
      this.$nextTick(() => {
        /* @ts-ignore */
        this.$refs['moneyInput'].focus()
      })
    },
    async update() {
      try {
        const url = `${Transaction.ENDPOINT}/${this.transaction.id}`
        const resp = await this.$http.put<Transaction>(url, this.transaction)
        this.transaction = Transaction.from(resp)
        this.isEdit = false
        this.$emit('updated')
      } catch (error) {
        return error
      }
    }
  }
})
</script>

<style scoped></style>
