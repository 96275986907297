import UUIDBase from '../UUIDBase'
import { Register } from "@/models/company/Register";

export class Machine extends UUIDBase {
  static ENDPOINT = '/company/machines'

  name = ''
  machineGUID = ''

  register: Register = new Register();
  locationID: string = ""

  isVirtual = false;

  arName = ''
  enName = ''

  static from(json: Record<string, unknown> | Machine): Machine {
    const m = Object.assign(new Machine(), json)
    m.register = Register.from(m.register)
    return m;
  }
}
