import UUIDBase from '../UUIDBase'
import { useI18nInstance, localizeFieldName } from '@/plugins/i18n'

const { t } = useI18nInstance().global

export const categoryI18nMessages = {
  en: {
    name: 'Category Name',
    parentCategory: 'Belongs to Category'
  },
  ar: {
    name: 'اسم المجموعة',
    parentCategory: 'تابعة الى المجموعة'
  }
}

export default class Category extends UUIDBase {
  static ENDPOINT = '/inventory/itemcategories'

  name = ''
  parentID = ''
  parentCategory?: Category

  constructor(name?: string) {
    super()
    this.name = name ?? this.name
  }

  static from(json: Record<string, unknown> | Category) {
    const c = Object.assign(new Category(), json)

    if (json.parentCategory) {
      // recursive
      c.parentCategory = Category.from(json.parentCategory as Record<string, unknown>)
    }

    return c
  }

  validateName() {
    if (this.name.length === 0) {
      return t('validation.required', {
        field: localizeFieldName('name', categoryI18nMessages)
      })
    } else if (this.name.length > 255) {
      return t('validation.maxLength', {
        field: localizeFieldName('name', categoryI18nMessages)
      })
    }
  }
}
