import UUIDBase from '../UUIDBase'
import { useI18nInstance, localizeFieldName } from '@/plugins/i18n'

const { t } = useI18nInstance().global

export const tagI18nMessages = {
  en: {
    name: 'Tag Name'
  },
  ar: {
    name: 'اسم الوسم'
  }
}

export default class Tag extends UUIDBase {
  static ENDPOINT = '/inventory/itemtags'

  name = ''

  constructor(name?: string) {
    super()
    this.name = name ?? this.name
  }

  static from(json: Record<string, unknown> | Tag) {
    return Object.assign(new Tag(), json)
  }

  validateName() {
    if (this.name.length === 0) {
      return t('validation.required', {
        field: localizeFieldName('name', tagI18nMessages)
      })
    } else if (this.name.length > 255) {
      return t('validation.maxLength', {
        field: localizeFieldName('name', tagI18nMessages)
      })
    }
  }
}
