<template>
  <custom-price-form
    :initCustomPrice="initCustomPrice"
    :successMsg="t('customPriceCreated')"
    :submitAction="submit"
    enableClearForm
    clearFormOnSubmit
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import CustomPriceForm from '@/views/inventory/customPrices/CustomPriceForm.vue'

import CustomPrice from '@/models/inventory/CustomPrice'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'new-custom-price',
  components: {
    CustomPriceForm
  },
  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          customPriceCreated: 'Custom price type was created successfully'
        },
        ar: {
          customPriceCreated: 'تم انشاء نوع السعر بنجاح'
        }
      }
    })

    return { t }
  },
  data() {
    return {
      initCustomPrice: new CustomPrice()
    }
  },

  methods: {
    async submit(customPrice: CustomPrice) {
      try {
        await this.$http.post(CustomPrice.ENDPOINT, customPrice)
        await this.$router.push(this.$Route.INVENTORY_ITEM_CUSTOM_PRICE_TYPES)
      } catch (error) {
        return error
      }
    }
  }
})
</script>

<style></style>
