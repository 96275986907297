<template>
  <div>
    <el-form
      @submit.prevent="validateSubmit('billForm')"
      novalidate
      :model="pay"
      :rules="rules"
      label-position="top"
      ref="payForm"
      class="flex flex-col items-center"
    >
      <div class="input-section">
        <div class="flex flex-col md:flex-row justify-end">
          <div class="md:w-3/12 m-2">
            <horizontal-button
              :title="$t('actions.saveChanges')"
              isSuccess
              faIcon="save"
              @click.prevent="validateSubmit('payForm')"
            />
          </div>

          <div class="md:w-3/12 m-2" v-if="enableClearForm">
            <horizontal-button
              :title="$t('actions.clearButton')"
              isDanger
              faIcon="trash"
              @click.prevent="clearForm('payForm')"
            />
          </div>
        </div>
      </div>

      <!-- @submit errors -->
      <alert-badge
        isDanger
        :title="error.title"
        :body="error.body"
        @dismissed="
          () => {
            error.title = ''
            error.body = ''
          }
        "
      />

      <div class="input-section">
        <h1>{{ t('sections.newPay') }}</h1>
      </div>

      <!-- employee name -->
      <div class="input-section">
        <el-form-item :label="t('employeeName')" prop="payToAccountID" class="input-label">
          <el-select
            id="payToAccountID"
            v-model="pay.payToAccountID"
            :trigger-on-focus="false"
            value-key="name"
            class="w-full"
            filterable
            remote
            :remote-method="searchExpensesAccounts"
          >
            <el-option
              v-for="option in userAccounts"
              :key="option.id"
              :label="$ctx.locale === 'ar' ? option.arName : option.enName"
              :value="option.id"
              class="bg-blue-100"
            ></el-option>
            <el-option
              v-for="option in expenseAccounts"
              :key="option.id"
              :label="$ctx.locale === 'ar' ? option.arName : option.enName"
              :value="option.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>

      <!-- employee type -->
      <div class="input-section">
        <el-form-item :label="t('employeeType')" prop="employeeType" class="input-label">
          <el-select v-model="pay.employeeType" filterable clearable class="w-full" required>
            <el-option :label="t('employee')" value="employee">
              <span :class="{ 'float-right': $ctx.getDir() == 'rtl' }">{{t("employee")}} </span>
            </el-option>
            <el-option :label="t('freelancer')" value="freelance">
              <span :class="{ 'float-right': $ctx.getDir() == 'rtl' }"> {{t("freelancer")}} </span>
            </el-option>
            <el-option :label="t('contractor')" value="contractor">
              <span :class="{ 'float-right': $ctx.getDir() == 'rtl' }"> {{t("contractor")}} </span>
            </el-option>
          </el-select>
        </el-form-item>
      </div>

      <!-- pay amount -->
      <div class="input-section">
        <el-form-item :label="t('amount')" prop="amount" class="input-label">
          <money-input
            id="amount"
            :currency="$ctx.currency"
            v-model="pay.totalAmount"
            ref="amountRef"
          />
        </el-form-item>
      </div>

      <div class="input-section">
        <el-form-item :label="t('paidFrom')" prop="payFromAccountID" class="input-label">
          <el-select
            id="credit-account"
            v-model="pay.payFromAccountID"
            :trigger-on-focus="false"
            value-key="name"
            class="w-full"
            filterable
            remote
            :remote-method="searchAccounts"
          >
            <el-option
              :key="registerAccount.id"
              :label="$ctx.locale === 'ar' ? registerAccount.arName : registerAccount.enName"
              :value="registerAccount.id"
            >
              <div class="w-full bg-green-100">
                {{$ctx.locale === 'ar' ? registerAccount.arName : registerAccount.enName}}
              </div>
            </el-option>

            <el-option
              :key="safeAccount.id"
              :label="$ctx.locale === 'ar' ? safeAccount.arName : safeAccount.enName"
              :value="safeAccount.id"
            >
              <div class="w-full bg-green-100">
                {{$ctx.locale === 'ar' ? safeAccount.arName : safeAccount.enName}}
              </div>
            </el-option>

            <el-option
              v-for="option in accounts"
              :key="option.id"
              :label="$ctx.locale === 'ar' ? option.arName : option.enName"
              :value="option.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>

      <!-- date period -->
      <div class="input-section">
        <el-form-item class="input-label" prop="period" :label="t('period')">
          <el-date-picker
            v-model="pay.period"
            type="daterange"
            :range-separator="t('to')"
            :start-placeholder="t('startPeriod')"
            :end-placeholder="t('endPeriod')"
          >
          </el-date-picker>
        </el-form-item>
      </div>

      <!-- pay date -->
      <div class="input-section">
        <el-form-item :label="t('date')" prop="payDate" class="input-label">
          <el-date-picker id="date" v-model="pay.payDate" type="date" :placeholder="t('date')">
          </el-date-picker>
        </el-form-item>
      </div>



      <!-- pay type -->
      <div class="input-section">
        <el-form-item :label="t('payType')" prop="payType" class="input-label">
          <el-select v-model="pay.payType" filterable clearable class="w-full" required>
            <el-option :label="t('salary')" value="Salary">
              <span :class="{ 'float-right': $ctx.getDir() == 'rtl' }"> {{t("salary")}} </span>
            </el-option>
            <el-option :label="t('commission')" value="commission">
              <span :class="{ 'float-right': $ctx.getDir() == 'rtl' }"> {{t("commission")}} </span>
            </el-option>
            <el-option :label="t('bonus')" value="bonus">
              <span :class="{ 'float-right': $ctx.getDir() == 'rtl' }"> {{t("bonus")}} </span>
            </el-option>
            <el-option :label="t('fees')" value="fees">
              <span :class="{ 'float-right': $ctx.getDir() == 'rtl' }"> {{t("fees")}} </span>
            </el-option>
          </el-select>
        </el-form-item>
      </div>


      <!-- notes -->
      <div class="input-section">
        <el-form-item :label="t('notes')">
          <el-input v-model="pay.notes" :rows="4" type="textarea" />
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
// models
import { Pay, payI18nMessages } from './Hr.vue'
// components
import HorizontalButton from '@/components/HorizontalButton.vue'
import AlertBadge from '@/components/AlertBadge.vue'
import { ElForm } from 'element-plus'
import MoneyInput from '@/components/form/MoneyInput.vue'
import { useI18n } from 'vue-i18n'
import { Account } from '@/models/accounting/Account'
import User from '@/models/user/User'

export default defineComponent({
  name: 'new-pay-form',
  setup() {
    const messages = {
      en: {
        ...payI18nMessages.en,
        payType: 'Pay Type',
        payDate: 'Pay Date',
        payee: 'Payee',
        notes: 'Notes',
        to: 'To',
        sections: {
          newPay: 'New Pay'
        }
      },
      ar: {
        ...payI18nMessages.ar,
        payType: 'نوع الدفع',
        payDate: 'تاريخ الدفع',
        payee: 'المستفيد',
        notes: 'الملاحظات',
        to: 'الى',
        sections: {
          newPay: 'عملية دفع جديدة'
        }
      }
    }
    const { t } = useI18n({ messages, useScope: 'global' })
    const rules = {
      payDate: [{ required: true, message: t('validation.required') }],
      totalAmount: [{ required: true, message: t('validation.required') }],
      payToAccountID: [{ required: true, message: t('validation.required') }],
      employeeType: [{ required: true, message: t('validation.required') }],
      payType: [{ required: true, message: t('validation.required') }],
      period: [{ required: true, message: t('validation.required') }]
    }
    return { t, rules }
  },
  components: {
    HorizontalButton,
    AlertBadge,
    MoneyInput
  },
  props: {
    initPay: {
      type: Pay,
      required: true
    },
    successMsg: {
      type: String,
      required: true
    },
    submitAction: {
      type: Function,
      required: true
    },
    enableClearForm: {
      type: Boolean,
      default: false
    },
    clearFormOnSubmit: {
      type: Boolean,
      default: false
    }
  },
  // I have the choice to use v-model but would make this code
  // incredibly complicated!
  // I chose to alias/copy props, even though it might be against vue style guide
  // the parent hence passes an initial copy of the item and variants and DOES NOT
  // expect those inital copies to be synchronized!
  data() {
    const accounts : Account[] = [];
    const expenseAccounts : Account[] = [];
    const registerAccount: Account = new Account();
    const safeAccount: Account = new Account();
    const userAccounts: Account[] = [];

    return {
      pay: this.initPay,
      error: { title: '', body: '' },
      accounts,
      registerAccount,
      safeAccount,
      expenseAccounts,
      userAccounts
    }
  },
  async beforeMount() {
    try {
      this.registerAccount = Account.from(await this.$http.get<Account>(`${Account.ENDPOINT}/${this.$ctx.currentRegister.accountID}`))
      this.safeAccount = Account.from(await this.$http.get<Account>(`${Account.ENDPOINT}/${this.$ctx.currentLocation.safeAccountID}`))
      const users = await this.$http.get<User[]>("/user/users")
      for (const u of users) {
        if (u.username !== "admin") {
          const userAccount = await this.$http.get<Account[]>(`/accounting/accounts/${u.payrollAccountID}`);
          this.userAccounts.push(userAccount)
        }
      }
    } catch (error) {
      this.error.title = error?.title;
      this.error.body = error?.body;
    }
  },
  methods: {
    async searchAccounts(query: string) {
      if (!query) {
        return
      }

      try {
        const url = `${Account.ENDPOINT}?q=${query}`
        this.accounts = await this.$http.get<Account[]>(url)
      } catch (error) {
        this.$alertModal.showDanger({ title: error.title })
        this.accounts = []
      }
    },

    async searchExpensesAccounts(query: string) {
      if (!query) {
        return
      }

      try {
        const url = `${Account.ENDPOINT}?account-category=expenses&q=${query}`
        this.expenseAccounts = await this.$http.get<Account[]>(url)
      } catch (error) {
        this.$alertModal.showDanger({ title: error.title })
        this.expenseAccounts = []
      }
    },

    clearForm(formName: string) {
      const form = this.$refs[formName] as typeof ElForm
      this.pay = new Pay()
      this.$globalEmitter.emit('money-input-reset')
      form.resetFields()
    },
    async validateSubmit(formName: string) {
      // attach the amount values
      this.pay.currency = this.$ctx.currency
      this.pay.precision = this.$ctx.currencyPrecision

      this.pay.periodStartDate = this.pay.period[0]
      this.pay.periodEndDate = this.pay.period[1]
      this.pay.transaction = null

      const form = this.$refs[formName] as typeof ElForm
      form?.validate(async (valid: boolean) => {
        if (!valid) {
          document.getElementById('main')?.scrollTo({ top: 0 })
          return false
        }
        const resp = await this.submitAction(this.pay)
        if (resp) {
          this.error.title = resp?.title
          this.error.body = resp?.body
          document.getElementById('main')?.scrollTo({ top: 0 })
          return false
        } else {
          if (this.clearFormOnSubmit) {
            this.clearForm(formName)
          }
          this.$alertModal.showSuccess({ title: this.successMsg, body: '' })
        }
        return true
      })
    }
  }
})
</script>

<style scoped>
h1 {
  @apply text-lg py-3 font-bold;
}
h2 {
  @apply py-3 font-bold;
}
h3 {
  @apply py-3 font-bold;
}
.input-group {
  @apply flex flex-col justify-between w-full items-end;
}
.input-group-row {
  @apply flex justify-between w-full;
}
.input-group-row > label {
  @apply w-6/12;
}
.input-section {
  @apply rounded w-full justify-center;
}
@screen lg {
  .input-section {
    @apply w-7/12;
  }
}
@screen md {
  .input-section {
    @apply flex-row;
  }
  .input-group {
    @apply flex-row;
  }
  .input-group > .input-label {
    @apply w-6/12;
  }
}
.remove-button {
  @apply text-lg;
  color: var(--danger-text-color);
  align-self: center;
  justify-self: center;
  cursor: pointer;
  margin: 0.5rem;
}
.remove-button:hover {
  color: var(--danger-hover-bg-color);
  align-self: center;
  justify-self: center;
}
.fully-paid-button {
  height: 40px;
}
hr.solid {
  border-top: 1px solid var(--tertiary-bg-color);
}
.hide {
  display: none;
}
.input:hover + .hide {
  display: flex;
  justify-content: center;
}
.danger {
  color: var(--danger-text-color);
  background: var(--danger-bg-color);
}
.success {
  color: var(--success-text-color);
  background: var(--success-bg-color);
}
td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  text-align: center;
}
tr:nth-child(even) {
  background-color: #f3f3f3;
}
</style>
