<template>
  <div>
    <!-- important information like time and account infor -->
    <div class="header">
      <div class="flex items-center">
        <div class="icon" @click="$router.push($Route.ACCOUNTING_REGISTERS_REGISTER.replace(':id', $ctx.currentRegister.id))">
          <font-awesome-icon icon="cash-register" />
        </div>
        <div class="mx-2 font-bold">
          <template v-if="locale == 'en'">{{ location?.enName }}</template>
          <template v-if="locale == 'ar'">{{ location?.arName }}</template>
        </div>
      </div>

      <div dir="ltr">
        {{ now.toLocaleDateString('en-GB') + ' ' + now.toLocaleTimeString('en-US') }}
      </div>

      <div class="flex items-center">
        <div class="mx-2 font-bold">
          {{ user ? `${user.firstName} ${user.lastName}` : '' }}
        </div>
        <div class="icon">
          <el-dropdown trigger="click">
            <div class="icon el-dropdown-link">
              <font-awesome-icon icon="user-circle" />
            </div>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click.prevent="$router.push($Route.HR_EMPLOYEE_SCHEDULE)">{{
                  $t('views.hr.viewSchedule')
                }}</el-dropdown-item>
                <el-dropdown-item @click.prevent="$router.push($Route.HR)">{{
                  $t('views.hr.clockInOut')
                }}</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <!-- <font-awesome-icon icon="user-circle" /> -->
        </div>
      </div>
    </div>

    <div class="grid grid-cols-1 lg:grid-cols-2 content-center">
      <!-- shortcuts -->
      <div class="m-3 col-span-1">
        <div class="title">
          {{ t('shortcuts') }}
        </div>
        <div class="flex flex-col flex-wrap content-center md:flex-row">
          <div v-for="element in list" :key="element">
            <action-button
              v-if="element.icon === 'icon-examination'"
              class="pt-2 pb-4"
              :title="$t(element.title)"
              @click="$router.push(element.route)"
              :isSuccess="element.isSuccess"
            >
              <span class="lg:text-5xl">
                <i :class="element.icon" />
              </span>
            </action-button>
            <action-button
              v-else
              class="pt-2 pb-4"
              :title="$t(element.title)"
              @click="$router.push(element.route)"
              :faIcon="element.icon"
              :faPrefix="element.iconPrefix"
              :isSuccess="element.isSuccess"
            ></action-button>
          </div>
        </div>
      </div>

      <div class="m-3">
        <div class="title">
          {{ t('reports') }}
        </div>
        <div class="justify-center">
          <div class="bg-white w-full" v-if="salesSummary.summaryByNumberOfSales" dir="ltr">
            <column-chart
              :xAxis="salesSummary.getChartCategories()"
              :chartData="data"
              :chartTitle="$t('views.reports.salesSummary.salesSummaryNoSales.title')"
              :colors="['#27ae60', '#F44336']"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import ActionButton from '@/components/ActionButton.vue'
import ColumnChart from '@/components/charts/ColumnChart.vue'
import { SalesSummary } from './reports/sales/SalesSummary.vue'
import { useHTTP } from '@/plugins/http'
import dayjs from 'dayjs'

export default defineComponent({
  setup() {
    const { t, locale } = useI18n({
      messages: {
        en: {
          shortcuts: 'Shortcuts',
          reports: 'Important Reports'
        },
        ar: {
          shortcuts: 'مختصراتي',
          reports: 'التقارير المهمة'
        }
      }
    })

    const user = ref()
    const location = ref()
    const http = useHTTP()
    const loading = ref(true)

    return { t, user, location, http, loading, locale }
  },

  components: {
    ActionButton,
    ColumnChart
  },
  data() {
    return {
      now: new Date(),
      text: '',
      salesSummary: new SalesSummary(),
      list: [
        {
          title: 'views.sales.newSale',
          route: this.$Route.SALES_NEW_SALE,
          id: 0,
          icon: 'new-sale',
          iconPrefix: 'fac',
          isSuccess: true
        },
        {
          title: 'views.inventory.items.items',
          route: this.$Route.INVENTORY_ITEMS,
          id: 1,
          icon: 'eye'
        },
        {
          title: 'views.inventory.items.newSimpleItem',
          route: this.$Route.INVENTORY_ITEMS_NEW_SIMPLE_ITEM,
          id: 2,
          icon: 'plus'
        },
        {
          title: 'views.examinations.newExamination',
          route: this.$Route.EXAMINATIONS_NEW_EXAMINATION,
          icon: 'icon-examination',
          id: 3
        },
        {
          title: 'views.inventory.inventoryReceivings.newInventoryReceiving',
          route: this.$Route.INVENTORY_INVENTORY_RECEIVINGS_NEW_INVENTORY_RECEIVING,
          id: 4,
          icon: 'arrow-down'
        },
        {
          title: 'views.sales.orders.orders',
          route: this.$Route.SALES_ORDERS,
          id: 5,
          icon: 'receipt'
        },
        {
          title: 'views.customers.customers',
          route: this.$Route.CUSTOMERS,
          icon: 'users',
          id: 6
        },
        {
          title: 'views.customers.newCustomer',
          route: this.$Route.CUSTOMERS_NEW_CUSTOMER,
          id: 7,
          icon: 'user-plus'
        }
      ],
      data: null
    }
  },

  async beforeMount() {
    const salesSummaryResp = await this.$http.get<SalesSummary>(
      `${SalesSummary.ENDPOINT}?from_date=${dayjs().startOf('day')}&to_date=${dayjs().endOf('day')}`
    )

    this.salesSummary = SalesSummary.from(salesSummaryResp)
    this.data = this.salesSummary.getSummaryByNumberOfSalesChartData(
                  this.$t('views.reports.salesSummary.salesSummaryNoSales.noOfSales'),
                  this.$t('views.reports.salesSummary.salesSummaryNoSales.noOfReturns')
                )
  },

  mounted() { 
    setInterval(() => (this.now = new Date()), 1000)
    this.user = JSON.parse(localStorage.getItem('user') || 'null')
    this.http
      .get(`/company/locations/${this.$ctx.currentLocation.id}`)
      .then((data) => {
        this.location = data as Location
        this.$ctx.setLocation(this.location)
      })
      .catch((e) => {
        this.$router.replace('login')
      })
  }
})
</script>

<style scoped>
.header {
  @apply flex p-4 m-3 rounded shadow-sm items-center justify-between;
  background: var(--secondary-bg-color);
  color: var(--secondary-text-color);
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.icon {
  @apply cursor-pointer text-2xl rounded-full h-12 w-12 flex items-center justify-center;
  background: var(--body-bg-color);
  color: var(--secondary-text-color);
}

.icon:hover {
  background: var(--primary-hover-bg-color);
  color: var(--primary-hover-text-color);
}

.title {
  @apply col-span-1 p-2 rounded shadow-sm text-center text-lg w-full;
  background: var(--secondary-bg-color);
  color: var(--secondary-text-color);
}

@screen lg {
  .title {
    @apply col-span-3;
  }
}
</style>
