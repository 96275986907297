<template>
  <tag-form
    :initTag="initTag"
    :successMsg="t('created')"
    :submitAction="submit"
    enableClearForm
    clearFormOnSubmit
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import TagForm from '@/views/inventory/tags/TagForm.vue'

import Tag from '@/models/inventory/Tag'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'new-tag',
  components: {
    TagForm
  },

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          created: 'Tag was created successfully'
        },
        ar: {
          created: 'تم انشاء الوسم بنجاح'
        }
      }
    })

    return { t }
  },

  data() {
    return {
      initTag: new Tag()
    }
  },

  methods: {
    async submit(tag: Tag) {
      try {
        await this.$http.post(Tag.ENDPOINT, tag)
        await this.$router.push(this.$Route.INVENTORY_ITEM_TAGS)
      } catch (error) {
        return error
      }
    }
  }
})
</script>

<style></style>
