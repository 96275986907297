<template>
  <new-pay-form
    :initPay="initPay"
    :successMsg="t('payWasCreated')"
    :submitAction="submit"
    :enableClearForm="true"
    :clearFormOnSubmit="true"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import NewPayForm from './NewPayForm.vue'

// models
import { Pay } from './Hr.vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: { NewPayForm },
  name: 'new-pay',

  setup() {
    const { t } = useI18n({
      messages: {
        en: { payWasCreated: 'Pay was recorded successfully' },
        ar: { payWasCreated: 'تم تسجيل عملية الدفع بنجاح' }
      }
    })
    return { t }
  },

  data() {
    return {
      initPay: new Pay()
    }
  },

  methods: {
    async submit(pay: Pay) {
      try {
        await this.$http.post<Pay>("/accounting/hr", pay);
        this.$router.back()
      } catch (error) {
        return error;
      }
    }
  }
})
</script>
