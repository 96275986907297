<template>
  <div
    class="action-button"
    :class="{
      'action-button-success': isSuccess,
      'action-button-danger': isDanger,
      'action-button-warning': isWarning
    }"
  >
    <div class="icon">
      <div v-if="faIcon" class="lg:text-5xl">
        <font-awesome-icon :icon="[faPrefix, faIcon]" />
      </div>
      <div v-else>
        <slot id="custom-icon"></slot>
      </div>
    </div>
    <div class="m-4">{{ title }}</div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'actionButton',
  props: {
    id: String,
    title: String,
    faIcon: String,
    faPrefix: {
      required: false,
      type: String,
      default: 'fas'
    },
    isDanger: Boolean,
    isSuccess: Boolean,
    isWarning: Boolean
  }
})
</script>

<style scoped>
.action-button {
  background: var(--secondary-bg-color);
  color: var(--secondary-text-color);
  @apply w-full flex justify-between m-3 rounded-lg shadow-sm text-center cursor-pointer;
}

.action-button:hover {
  background: var(--secondary-hover-bg-color);
  color: var(--secondary-hover-text-color);
}

.action-button-success {
  background: var(--success-bg-color);
  color: var(--success-text-color);
}

.action-button-success:hover {
  background: var(--success-hover-bg-color);
  color: var(--success-hover-text-color);
}

.action-button-danger {
  background: var(--danger-bg-color);
  color: var(--danger-text-color);
}

.action-button-danger:hover {
  background: var(--danger-hover-bg-color);
  color: var(--danger-hover-text-color);
}

.action-button-warning {
  background: var(--warning-bg-color);
  color: var(--warning-text-color);
}

.action-button-warning:hover {
  background: var(--warning-hover-bg-color);
  color: var(--warning-hover-text-color);
}

.icon {
  @apply m-4 flex flex-col justify-center h-full;
}

@screen lg {
  .icon {
    height: 50%;
  }

  .action-button {
    @apply block w-40 h-40;
  }
}
</style>
