<template>
  <div class="m-3" style="height: 90vh">
    <div class="grid grid-cols-12 items-center content-center gap-2">
      <div class="col-span-12 md:col-span-1 items-center flex context-center justify-center cursor-pointer hover:bg-blue-500 hover:text-white h-1/2" :class="enableTreeView ? 'bg-blue-300' : 'white'" @click="enableTreeView = !enableTreeView">
        <font-awesome-icon icon="sitemap"  />
      </div>

      <el-select
        class="input-label col-span-12 md:col-span-2"
        v-model="selectedValue"
        @change="applyToSelected"
      >
        <template v-if="$can('read', '/accounting/accounts')">
          <el-option
            key="export"
            :label="$t('views.common.listView.selectedRows.export')"
            value="export"
          >
          </el-option>
        </template>
        <!-- <template v-if="$can('delete', '/accounting/accounts')">
          <el-option
            key="delete"
            :label="$t('views.common.listView.selectedRows.delete')"
            value="delete"
          >
          </el-option>
        </template> -->
      </el-select>

      <el-input
        :placeholder="$t('views.common.listView.searchRows')"
        class="w-full col-span-12 md:col-span-7"
        :prefix-icon="Search"
        v-model="searchTerm"
      />

      <div class="w-full col-span-12 md:col-span-2">
        <template v-if="$can('create', '/accounting/accounts')">
          <horizontal-button
            :title="$t('views.accounting.accounts.newAccount')"
            @click.prevent="$router.push($Route.ACCOUNTING_ACCOUNTS_NEW_ACCOUNT)"
          />
        </template>
      </div>
    </div>

    <ag-grid-vue
      v-if="!enableTreeView"
      class="ag-theme-alpine mt-4 md:mt-0"
      :columnDefs="columnDefs"
      :rowData="rowData"
      rowSelection="multiple"
      :enable-rtl="$ctx.getDir() === 'rtl'"
      style="height: 85vh"
      :pagination="true"
      :paginationAutoPageSize="true"
      :defaultColDef="defaultColDef"
      @cell-double-clicked="onCellDoubleClicked"
      :onGridReady="onGridReady"
    >
    </ag-grid-vue>
    <div id="tree"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import ApexTree from 'apextree'

// components
import { AgGridVue } from 'ag-grid-vue3'
import { ColDef, GridApi, GridOptions } from 'ag-grid-community'
import HorizontalButton from '@/components/HorizontalButton.vue'

import { Account, accountI18nMessages } from '@/models/accounting/Account'
import { useI18n } from 'vue-i18n'
import { parseFormat } from '@/utils/date'
import { Search } from '@element-plus/icons-vue'
import {
  generateAccountsTree,
  ReversedAccount
} from "@/views/accounting/accounts/accountstransformer";

export default defineComponent({
  name: 'accounts',

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          ...accountI18nMessages.en,
          name: 'Name',
          description: 'Description'
        },
        ar: {
          ...accountI18nMessages.ar,
          name: 'الاسم',
          description: 'الوصف'
        }
      }
    })

    return {
      t,
      Search
    }
  },

  data() {
    const columnDefs: ColDef[] = []
    const rowData: Account[] = []
    const gridOptions: GridOptions = {}
    const defaultColDef: ColDef = {}
    const treeView: ReversedAccount = new ReversedAccount("", new Account(), "")
    const enableTreeView: boolean = false

    return {
      columnDefs,
      rowData,
      gridOptions,
      defaultColDef,
      treeView,
      enableTreeView,

      searchTerm: '',
      selectedValue: '',
      gridApi: undefined as GridApi | undefined
    }
  },

  components: {
    AgGridVue,
    HorizontalButton
  },

  beforeMount() {
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
      minWidth: 150
    }

    this.columnDefs = [
      {
        headerName: this.t('code'),
        field: 'code',
        checkboxSelection: true,
        headerCheckboxSelection: true
      },
      {
        headerName: this.$t('models.common.createdAt'),
        field: 'createdAt',
        cellStyle: { direction: 'ltr' },
        valueFormatter: (params) => {
          return parseFormat(params.value)
        }
      },
      {
        headerName: this.t('name'),
        field: this.$ctx.locale === 'en' ? 'enName' : 'arName'
      },
      {
        headerName: this.t('description'),
        field: this.$ctx.locale === 'en' ? 'enDescription' : 'arDescription'
      },
      {
        headerName: this.t('parentAccount'),
        field: this.$ctx.locale === 'en' ? 'parentAccount.enName' : 'parentAccount.arName'
      }
    ]
  },

  mounted() {
    this.gridApi?.sizeColumnsToFit()
  },

  watch: {
    searchTerm() {
      this.filterItems()
    },
    enableTreeView() {
      if (this.enableTreeView) {
        const options = {
          width: 1350,
          height: 700,
          nodeWidth: 120,
          nodeHeight: 80,
          childrenSpacing: 100,
          siblingSpacing: 30,
          direction: 'top',
          canvasStyle: 'border: 1px solid black; background: #f6f6f6;',
        };

        const tree = new ApexTree(document.getElementById('tree'), options);
        const graph = tree.render(generateAccountsTree(this.rowData, this.$ctx.getDir() === "rtl" ? "ar" : "en"));
      }
    }
  },

  methods: {
    async updateRowData() {
      this.gridApi?.showLoadingOverlay()

      try {
        const data = await this.$http.get<Account[]>(Account.ENDPOINT)
        this.rowData = data.map((account) => Account.from(account))
        const x = generateAccountsTree(this.rowData, "en")
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        })
      }

      this.gridApi?.hideOverlay()
    },

    async onGridReady(params: any) {
      this.gridApi = params.api
      await this.updateRowData()
    },

    async delete(accounts: Account[]) {
      if (confirm(this.$t('views.common.listView.selectedRows.deleteConfirm'))) {
        try {
          await this.$http.delete(Account.ENDPOINT, { data: accounts })
          await this.updateRowData()
          this.$alertModal.showSuccess({
            title: this.$t('views.common.listView.selectedRows.deleteSuccess')
          })
        } catch (error) {
          this.$alertModal.showDanger({
            title: error.title,
            body: error.body
          })
        }
      }
    },

    async applyToSelected() {
      if (this.selectedValue === 'delete' || this.selectedValue === 'export') {
        const selected = this.gridApi?.getSelectedRows()

        if (selected?.length) {
          if (this.selectedValue === 'delete') {
            // send request to archive
            await this.delete(selected)
          } else {
            this.gridApi?.exportDataAsCsv({ onlySelected: true })
            this.$alertModal.showSuccess({
              title: this.$t('views.common.listView.selectedRows.exportSuccess')
            })
          }

          // deselect
          selected.length = 0
        }
      }

      this.selectedValue = ''
    },

    filterItems() {
      this.gridApi?.setQuickFilter(this.searchTerm)
    },
    onCellDoubleClicked(event: any) {
      this.$router.push(this.$Route.ACCOUNTING_ACCOUNTS_ACCOUNT.replace(':id', event.data.id))
    }
  }
})
</script>

<style></style>
