<template>
  <div class="m-3" style="height: 90vh">
    <div class="grid grid-cols-12 items-center content-center gap-2">
      <el-select
        class="input-label col-span-12 md:col-span-3"
        v-model="location"
        @change="onFilterChange"
        :placeholder="t('select')"
      >
        <el-option
          v-for="(loc, i) in locations"
          :key="i"
          :label="$ctx.locale === 'en' ? loc.enName : loc.arName"
          :value="loc.id"
        >
        </el-option>
      </el-select>

      <el-date-picker
        class="col-span-12 md:col-span-1"
        v-model="period"
        type="daterange"
        range-separator="to"
        :start-placeholder="t('startDate')"
        :end-placeholder="t('endDate')"
        @change="onFilterChange"
      >
      </el-date-picker>
    </div>

    <div class="grid grid-cols-2 gap-2">
      <div class="col-span-12 md:col-span-2 chart-card" style="height: 400px">
        <v-chart :option="option" autoresize />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onBeforeMount, provide } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { PieChart, BarChart } from 'echarts/charts'
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
  DataZoomComponent
} from 'echarts/components'
import VChart, { THEME_KEY } from 'vue-echarts'

import { useHTTP } from '@/plugins/http'
import { useAlertModal } from '@/plugins/alert-modal/alert-modal'
import { formatMoney } from '@/utils/money'
import dayjs from 'dayjs'
import Location from '@/models/company/Location'

// i18n
const { t } = useI18n({
  messages: {
    en: {
      topReps: 'Top Reps'
    },
    ar: {
      topReps: 'اعلى الممثلين',
    }
  }
})

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  BarChart,
  GridComponent,
  ToolboxComponent,
  DataZoomComponent
])

provide(THEME_KEY, 'light')

const rowData = ref<any[]>([])
const location = ref(null)
const locations = ref<Location[]>([])
const period = ref<Date[]>([]) // the default period is from the first day of the month to the current day

const option = ref({
  title: {
    text: t('topReps'),
    left: 'left'
  },
  grid: {
    top: 80,
  },
  toolbox: {
    feature: {
      saveAsImage: {}
    }
  },
  tooltip: {
    trigger: 'item',
    formatter: ({ seriesName, name, value, percent }: any) => {
      const salary = value ? formatMoney(value, 'IQD') : ''
      return `${name}</br>${salary} (${percent}%)`
    }
  },
  legend: {
    top: 30,
    orient: 'vertical',
    left: 'left',
    formatter: (data: any, x: any) => {
      return `${data}`
    }
  },
  series: [
    {
      name: t('topReps'),
      type: 'pie',
      radius: '55%',
      center: ['50%', '60%'],
      data: [] as any[]
    }
  ]
})

const http = useHTTP()
const route = useRoute()
const router = useRouter()
const alertModal = useAlertModal()

onBeforeMount(async () => {
  load()
})

// Methods
async function load() {
  const urlParams = new URLSearchParams()
  urlParams.set('location', location.value || '')
  if (period.value && period.value.length == 2) {
    urlParams.set('from_date', dayjs(period.value[0]).format('YYYY-MM-DD'))
    urlParams.set('to_date', dayjs(period.value[1]).format('YYYY-MM-DD'))
  }

  try {
    const data: any = await http.get<any[]>('/reports/sales/top-reps?' + urlParams.toString())
    rowData.value = data

    const chartData: any = []
    rowData.value.forEach((i: any) => {
      chartData.push({
        value: i.total,
        name: `${i.first_name || ''} ${i.last_name || ''}`
      })
    })

    option.value.series[0].data = chartData
  } catch (error) {
    alertModal.showDanger({
      title: error.title,
      body: error.body
    })
  }
}


async function onFilterChange() {
  await load()
}
</script>
