<template>
  <div class="chart-card">
    <div class="chart-title">
      <h3>{{ chartTitle }}</h3>
    </div>
    <apexchart
      :series="chartData"
      type="bar"
      :height="height || '400'"
      :options="chartOptions"
    ></apexchart>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { pdfCustomIcon } from './PdfCustomIcon'

export default defineComponent({
  setup(props) {
    const chartOptions = {
      chart: {
        type: 'bar',
        height: props.height || '400',
        toolbar: {
          tools: {
            customIcons: [pdfCustomIcon]
          }
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '30%',
          endingShape: 'rounded'
        }
      },
      dataLabels: {
        enabled: props.showLables
      },
      legend: {
        show: props.showLegend
      },
      stroke: {
        show: true,
        width: 5,
        colors: ['transparent']
      },
      xaxis: {
        categories: props.xAxis
      },
      fill: {
        opacity: 1,
        colors: props.colors
      },
      colors: props.colors
    }
    return { chartOptions }
  },

  props: {
    chartData: {
      required: true,
      type: Object as () => ApexAxisChartSeries
    },
    chartTitle: String,
    xAxis: {
      type: Object as () => string[]
    },
    height: Number,
    colors: {
      type: Object as () => string[]
    },
    showLables: {
      type: Boolean,
      default: true
    },
    showLegend: {
      type: Boolean,
      default: true
    }
  }
})
</script>

<style scoped>
.chart-card {
  width: 100%;
  background-color: white;
  padding: 1rem;
  border-radius: 5px;
  @apply mt-5;
}

.chart-title {
  text-align: center;
}
</style>
