<template>
  <div
    class="flex flex-col items-center mx-4 mt-3 px-2"
    id="small-receipt"
    :class="{
      'dir-ltr': $ctx.receiptLanguage == 'en',
      'dir-rtl': $ctx.receiptLanguage == 'ar'
    }"
  >
    <div class="flex flex-col items-center w-full" style="height: 100vh">
      <!-- header info -->
      <div id="small-receipt-header" class="flex flex-col w-full">
        <div class="flex mt-6 justify-center w-full">
          <img src="@/assets/images/logo.png" width="250" alt="" />
        </div>
      </div>

      <div id="small-receipt-body" class="w-full">
        <!-- title -->
        <div class="flex flex-col w-full mt-2 items-center">
          <h2 class="bg-gray-200 rounded py-1 px-2 font-bold mt-3 capitalize">
            {{
              $ctx.receiptLanguage == 'en'
                ? 'Account Statement'
                : t('accountStatement', $ctx.receiptLanguage)
            }}
          </h2>
        </div>
        <div class="flex w-full justify-around mt-5 px-5">
          <div class="flex-grow">
            <table class="w-full text-sm header-table">
              <tr>
                <td>
                  <p>
                    {{
                      $ctx.receiptLanguage == 'en'
                        ? 'Printed On'
                        : t('printedOn', $ctx.receiptLanguage)
                    }}
                  </p>
                </td>
                <td>
                  <p class="font-bold mx-5">
                    {{ parseFormat(printedOn) }}
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    {{ $ctx.receiptLanguage == 'en' ? 'Name' : t('name', $ctx.receiptLanguage) }}
                  </p>
                </td>
                <td>
                  <p class="font-bold mx-5">
                    {{ `${vendor.name} ${vendor.contactFirstName ? '-': ''} ${vendor.contactDisplayName}` }}
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    {{
                      $ctx.receiptLanguage == 'en'
                        ? 'Total Orders'
                        : t('totalOrders', $ctx.receiptLanguage)
                    }}
                  </p>
                </td>
                <td>
                  <p class="font-bold mx-5">
                    {{ vendorBills.length }}
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    {{
                      $ctx.receiptLanguage == 'en'
                        ? 'Total Amount'
                        : t('totalAmount', $ctx.receiptLanguage)
                    }}
                  </p>
                </td>
                <td>
                  <p class="font-bold mx-5">
                    {{ formatDinero(totalAmountDinero) }}
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    {{
                      $ctx.receiptLanguage == 'en'
                        ? 'Balance'
                        : t('balancePrint', $ctx.receiptLanguage)
                    }}
                  </p>
                </td>
                <td>
                  <p class="font-bold mx-5">
                    {{ formatDinero(balanceDinero) }}
                  </p>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="flex flex-col w-full items-center mt-5">
          <hr class="border-1 w-11/12" style="border-color: #585954" />
        </div>
        <div class="w-full px-5 mt-1">
          <h2 class="account-statement-label p-0">
            {{
              $ctx.receiptLanguage == 'en' ? 'Orders List' : t('ordersList', $ctx.receiptLanguage)
            }}
          </h2>
          <div class="w-full text-sm">
            <table class="w-full small-account-statement-table">
              <thead>
                <tr
                  :class="{
                    'text-right': $ctx.receiptLanguage == 'ar',
                    'text-left': $ctx.receiptLanguage == 'en'
                  }"
                >
                  <th>
                    {{
                      $ctx.receiptLanguage == 'en'
                        ? 'Receipt #'
                        : t('receiptNumberPrint', $ctx.receiptLanguage)
                    }}
                  </th>
                  <th>
                    {{
                      $ctx.receiptLanguage == 'en'
                        ? 'Date & Time'
                        : t('dateAndTime', $ctx.receiptLanguage)
                    }}
                  </th>
                  <th>
                    {{
                      $ctx.receiptLanguage == 'en'
                        ? 'Total Amount'
                        : t('totalAmount', $ctx.receiptLanguage)
                    }}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(vb, i) in vendorBills" :key="i" class="text-sm">
                  <td class="text-sm">
                    <div>{{ vb.externalID }}</div>
                  </td>

                  <td>{{ parseFormat(vb.createdAt) }}</td>
                  <td>{{ formatDinero(vb.totalDueDinero) }}</td>
                </tr>
              </tbody>
            </table>
            <div class="grid">
              <h2 class="place-self-end bg-gray-200 rounded py-1 px-2 font-bold mt-3 capitalize">
                <span>{{
                  $ctx.receiptLanguage == 'en' ? 'Total' : t('totalPrint', $ctx.receiptLanguage)
                }}</span
                ><span class="mx-2">{{ formatDinero(totalAmountDinero) }}</span>
              </h2>
            </div>
          </div>
        </div>
        <div class="w-full px-5 mt-4">
          <h2 class="account-statement-label p-0">
            {{
              $ctx.receiptLanguage == 'en'
                ? 'Payments History'
                : t('paymentsHistory', $ctx.receiptLanguage)
            }}
          </h2>
          <div class="w-full text-sm">
            <table class="w-full small-account-statement-table">
              <thead>
                <tr
                  :class="{
                    'text-right': $ctx.receiptLanguage == 'ar',
                    'text-left': $ctx.receiptLanguage == 'en'
                  }"
                >
                  <th>
                    {{
                      $ctx.receiptLanguage == 'en'
                        ? 'Payment #'
                        : t('paymentID', $ctx.receiptLanguage)
                    }}
                  </th>
                  <th>
                    {{
                      $ctx.receiptLanguage == 'en'
                        ? 'Date & Time'
                        : t('dateAndTime', $ctx.receiptLanguage)
                    }}
                  </th>
                  <th>
                    {{
                      $ctx.receiptLanguage == 'en'
                        ? 'Receipt #'
                        : t('receiptNumberPrint', $ctx.receiptLanguage)
                    }}
                  </th>
                  <th>
                    {{
                      $ctx.receiptLanguage == 'en'
                        ? 'Total Amount'
                        : t('totalAmount', $ctx.receiptLanguage)
                    }}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(t, i) in transactions" :key="i" class="text-sm">
                  <td class="text-sm">
                    <div>{{ t.externalID }}</div>
                  </td>

                  <td>{{ parseFormat(t.createdAt) }}</td>
                  <td>
                    {{ t.vendorBill?.externalID }}
                  </td>
                  <td>{{ formatDinero(t.amountDinero) }}</td>
                </tr>
              </tbody>
            </table>
            <div class="grid">
              <h2 class="place-self-end bg-gray-200 rounded py-1 px-2 font-bold mt-3 capitalize">
                <span>{{
                  $ctx.receiptLanguage == 'en' ? 'Total' : t('totalPrint', $ctx.receiptLanguage)
                }}</span
                ><span class="mx-2">{{ formatDinero(totalPaidDinero) }}</span>
              </h2>
            </div>
          </div>
        </div>
      </div>

      <!-- <div
          id="small-receipt-footer"
          class="flex justify-center self-center w-full mt-auto"
        >
          <div
            class="flex items-center justify-between pt-2 px-4 mt-2 mb-4"
            style="background: #EFEFEF; bottom: 0px; width: 95%;"
          >
            <p><img src="@/assets/images/print_logo.png" width="80" /></p>
            <p class="thecky-site">thecky.com</p>
          </div>
        </div> -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

import { i18nOrderMessages, Order } from '@/models/sales/Order'
import { parseFormat } from '@/utils/date'
import { useI18n } from 'vue-i18n'
import { formatDinero, formatMoney } from '@/utils/money'
import { Transaction } from '@/models/accounting/Transaction'
import Dinero from 'dinero.js'
import VendorBill from '@/models/vendor/VendorBill'
import Vendor from '@/models/vendor/Vendor'

export default defineComponent({
  components: {},
  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          ...i18nOrderMessages.en,
          print: 'Print Receipt'
        },
        ar: {
          ...i18nOrderMessages.ar,
          print: 'اطبع الفاتورة',
          accountStatement: 'كشف الحساب',
          name: 'الاسم',
          totalOrders: 'عدد الطلبات',
          totalAmount: 'المبلغ الكلي',
          balancePrint: 'المتبقي',
          printedOn: 'طبع بتاريخ',
          ordersList: 'قائمة الطلبات',
          receiptNumberPrint: 'رقم الفاتورة',
          dateAndTime: 'التاريخ والوقت',
          typePrint: 'النوع',
          totalPrint: 'المجموع',
          direct: 'مباشر',
          credit: 'آجل',
          layaway: 'عربون',
          paymentsHistory: 'تاريخ المدفوعات',
          paymentID: 'رقم الحركة'
        }
      }
    })

    return { t }
  },
  props: {
    vendorBills: {
      type: Array as PropType<Array<VendorBill>>,
      required: true
    },
    transactions: {
      type: Array as PropType<Array<Transaction>>,
      required: true
    },
    vendor: {
      type: Vendor,
      required: true
    },
    totalAmount: {
      type: Number,
      required: true
    },
    totalAmountDinero: {
      type: Dinero,
      required: true
    },
    balance: {
      type: Number,
      required: true
    },
    balanceDinero: {
      type: Dinero,
      required: true
    },
    totalPaid: {
      type: Number,
      required: true
    },
    totalPaidDinero: {
      type: Dinero,
      required: true
    },
    printedOn: {
      type: String,
      required: true
    }
  },
  created() {
    // Add print CSS when component is created
    this.addPrintCSS()
  },
  beforeUnmount() {
    // Remove print CSS when component is about to be destroyed
    this.removePrintCSS()
  },
  methods: {
    parseFormat,
    formatDinero,
    formatMoney,
    print() {
      window.print()
    },
    addPrintCSS() {
      // Create a style element with a unique id
      const style = document.createElement('style')
      style.setAttribute('id', 'print-css')
      style.textContent = `
            @media print {
              @page:first {
                size: A4;
                margin-left: 0;
                margin-right: 0;
                margin-top: 0px;
              }
      
              @page {
                size: A4;
                margin-left: 0;
                margin-right: 0;
                margin-top: 40px;
                margin-bottom: 30px;
              }
            }`
      // Append the style to the document head
      document.head.appendChild(style)
    },
    removePrintCSS() {
      // Find and remove the style element
      const style = document.querySelector('#print-css')
      if (style && style.parentNode) {
        style.parentNode.removeChild(style)
      }
    }
  }
})
</script>
<style scoped>
.thecky-site {
  color: #000;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 3px;
}

#small-receipt {
  width: 100mm;
  overflow: scroll;
  background-color: #fff;
}
.small-account-statement-table table {
  table-layout: fixed;
  /* width: 200px; */
  border-collapse: collapse;
}

.small-account-statement-table tr:not(:last-child) {
  border-bottom: 1pt solid #bbbbbb;
}

.small-account-statement-table td {
  padding-top: 4px;
  padding-bottom: 2px;
  border-bottom: 1pt solid #bbbbbb;
}

.small-account-statement-table th,
td {
  font-size: 12px;
  width: auto;
  overflow: hidden;
}

.small-account-statement-table td:nth-child(1) {
  width: auto;
  min-width: 60px;
  overflow: hidden;
}

.small-account-statement-table td:nth-child(2) {
  width: auto;
  min-width: 130px;
  overflow: hidden;
}

.small-account-statement-table td:nth-child(3) {
  width: auto;
  min-width: 55px;
  overflow: hidden;
}

.small-account-statement-table td:nth-child(4) {
  width: auto;
  min-width: auto;
  overflow: hidden;
}

.header-table td:nth-child(1) {
  width: 120px;
}

.header-table td:nth-child(2) {
  width: 300px;
}

#small-receipt-header {
  margin-top: 10px;
}

#small-receipt-body {
  margin-top: 10px;
}

@media print {
  body * {
    page-break-after: avoid;
    visibility: hidden;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  #small-receipt,
  #small-receipt * {
    visibility: visible;
  }

  #small-receipt {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: visible;
    margin: 0cm;
  }

  #small-receipt-header {
    margin-top: 0;
  }

  #small-receipt-body {
    position: relative;
  }

  p,
  div {
    page-break-inside: avoid;
  }
}
</style>
