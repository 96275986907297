import UUIDBase from "@/models/UUIDBase";
import { Account, AccountCategory } from "@/models/accounting/Account";


export class ReversedAccount extends UUIDBase {

  name = ''
  originalAccount: Account;

  children: ReversedAccount[] = []

  constructor(id: string, originalAccount: Account, name: string) {
    super();
    this.id = id;
    this.originalAccount = originalAccount;
    this.name = name;
  }
}

export function generateAccountsTree(accounts: Account[], lang: string): ReversedAccount {
  const reversedAccounts = reverseAccountRelationship(accounts, lang);

  const assets = new ReversedAccount("assets", new Account(), lang === "ar" ? "الممتلكات" : "Assets");
  const liabilities = new ReversedAccount("liabilities", new Account(), lang === "ar" ? "الديون" : "Liabilities");
  const equity = new ReversedAccount("equity", new Account(), lang === "ar" ? "حقوق الملكية" : "Equity");
  const revenue = new ReversedAccount("revenue", new Account(), lang === "ar" ? "الوارد" : "Revenue");
  const expenses = new ReversedAccount("expenses", new Account(), lang === "ar" ? "المصاريف" : "Expenses");

  reversedAccounts.forEach(ra => {
    switch (ra.originalAccount.accountCategory) {
      case AccountCategory.Assets:
        assets.children.push(ra)
        break
      case AccountCategory.Liabilities:
        liabilities.children.push(ra)
        break
      case AccountCategory.Equity:
        equity.children.push(ra)
        break
      case AccountCategory.Revenue:
        revenue.children.push(ra)
        break
      case AccountCategory.Expenses:
        expenses.children.push(ra)
        break;
    }
  })

  const tree = new ReversedAccount("accounting", new Account(), lang === "ar" ? "المحاسبة" : "Accounting")
  tree.children = [assets, liabilities, equity, revenue, expenses]
  return tree;
}

function reverseAccountRelationship(accounts: Account[], lang: string) {
  const mainAccounts = accounts
    .filter((a) => a.parentAccountID == undefined)
    .map((a) => new ReversedAccount(a.id || "", a, lang === "ar" ? a.arName : a.enName));

  const childrenOf = new Map<string, ReversedAccount[]>()
  accounts
    .filter(a => a.parentAccountID != "")
    .forEach(a => {
      const children = childrenOf.get(a.parentAccountID)
      if (children) {
        children.push(new ReversedAccount(a.id || "", a, lang === "ar" ? a.arName : a.enName))
        childrenOf.set(a.parentAccountID, children)
      } else {
        childrenOf.set(a.parentAccountID, [new ReversedAccount(a.id || "", a, lang === "ar" ? a.arName : a.enName)])
      }
    })

  return mainAccounts.map(m => assignChildren(m, childrenOf))
}

function assignChildren(reversedAccount: ReversedAccount, childrenOf: Map<string, ReversedAccount[]>): ReversedAccount {
  const children = childrenOf.get(reversedAccount.id || "")

  if (children && children.length > 0) {
    reversedAccount.children = children.map(a => assignChildren(a, childrenOf))
  }

  return reversedAccount
}