<template>
  <div class="m-3" style="height: 90vh" :dir="$ctx.getDir()">
    <div class="grid grid-cols-12 items-center content-center gap-2">
      <template v-if="$can('read', '/sales/orders')">
        <el-select
          class="input-label col-span-12 md:col-span-2"
          v-model="selectedValue"
          @change="applyToSelected"
        >
          <el-option
            key="export"
            :label="$t('views.common.listView.selectedRows.export')"
            value="export"
          >
          </el-option>
        </el-select>
      </template>

      <el-input
        :placeholder="t('searchReceipt')"
        class="w-full col-span-12 md:col-span-8"
        :prefix-icon="Search"
        v-model="searchTerm"
        ref="searchBar"
      />
      <div class="w-full col-span-12 md:col-span-2">
        <template v-if="true">
          <horizontal-button
            :title="$t('actions.newReturn')"
            @click.prevent="$router.push($Route.SALES_ORDERS_NEW_RETURN_ORDER)"
          />
        </template>
      </div>
    </div>

    <ag-grid-vue
      class="ag-theme-alpine mt-4 md:mt-0"
      :columnDefs="columnDefs"
      :rowData="rowData"
      rowSelection="multiple"
      :enable-rtl="$ctx.getDir() === 'rtl'"
      style="height: 85vh"
      :pagination="true"
      :paginationAutoPageSize="true"
      :defaultColDef="defaultColDef"
      @cell-double-clicked="onCellDoubleClicked"
      :onGridReady="onGridReady"
      rowModelType="infinite"
      :maxConcurrentDatasourceRequests="1"
      :inifiniteInitialRowCount="1000"
    >
    </ag-grid-vue>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

// components
import { AgGridVue } from 'ag-grid-vue3'
import {
  ColDef,
  GridApi,
  GridOptions,
  IDatasource,
  IGetRowsParams,
  ValueFormatterParams
} from 'ag-grid-community'

import { i18nOrderMessages, Order, OrderChange } from '@/models/sales/Order'
import { useI18n } from 'vue-i18n'
import { logError } from '@/utils/log'
import { formatPostgresDate, parseFormat } from '@/utils/date'
import { formatMoney } from '@/utils/money'
import HorizontalButton from '@/components/HorizontalButton.vue'
import { Search } from '@element-plus/icons-vue'

export default defineComponent({
  name: 'return-orders',

  setup() {
    const { t } = useI18n({
      messages: {
        en: { ...i18nOrderMessages.en, searchReceipt: 'Enter receipt number' },
        ar: { ...i18nOrderMessages.ar, searchReceipt: 'ادخل رقم الفاتورة' }
      }
    })

    return {
      t,
      Search
    }
  },

  data() {
    const columnDefs: ColDef[] = []
    const rowData: OrderChange[] = []
    const gridOptions: GridOptions = {}
    const defaultColDef: ColDef = {}
    const datasource: IDatasource = {} as IDatasource

    return {
      dateRange: '',
      columnDefs,
      rowData,
      gridOptions,
      defaultColDef,
      datasource,
      searchTerm: '',
      selectedValue: '',
      gridApi: undefined as GridApi | undefined
    }
  },

  components: {
    AgGridVue,
    HorizontalButton
  },

  beforeMount() {
    this.defaultColDef = {
      resizable: true,
      minWidth: 50
    }

    this.columnDefs = [
      {
        headerName: this.t('orderNumber'),
        field: 'externalID',
        checkboxSelection: true
      },
      {
        headerName: this.$t('models.common.date'),
        field: 'createdAt',
        cellStyle: { direction: 'ltr' },
        valueGetter: (params) => {
          const order = params.node?.data as Order
          if (!order) return ''
          if (order.createdAt) {
            const createdAt = new Date(order.createdAt)
            return formatPostgresDate(String(createdAt), 'DD/MM/YYYY')
          }
          return ''
        }
      },
      {
        headerName: this.$t('models.common.time'),
        field: 'createdAt',
        cellStyle: { direction: 'ltr' },
        valueGetter: (params) => {
          const order = params.node?.data as Order
          if (!order) return ''
          if (order.createdAt) {
            const createdAt = new Date(order.createdAt)
            return formatPostgresDate(String(createdAt), 'hh:mm A')
          }
          return ''
        }
      },
      {
        headerName: this.t('total'),
        field: 'totalChangeAmount',
        valueFormatter: ({ data }) => {
          const orderChange = data as OrderChange
          if (!orderChange) return ''
          return orderChange.totalChangeAmount != undefined
            ? formatMoney(orderChange.totalChangeAmount, orderChange.currency)
            : ''
        }
      },
      {
        headerName: this.t('user'),
        field: 'user.fullName',
        cellStyle: { textAlign: 'center' }
      }
    ]
  },

  mounted() {
    this.gridApi?.sizeColumnsToFit()
  },

  watch: {
    searchTerm() {
      this.filterItems()
    }
  },

  methods: {
    async updateRowData(receiptNumber: string) {
      let rowCount = { count: 0 }
      try {
        // get row counts
        rowCount = await this.$http.get<typeof rowCount>('/helpers/count?model=orders')
      } catch (error) {
        logError(`failed to retrieve orders count: ${error}`)
      }

      const get = this.$http.get
      const alertModal = this.$alertModal
      // const pageSize = this.gridOptions.paginationPageSize || 20

      this.datasource = {
        async getRows(params: IGetRowsParams) {
          // const page = Math.floor(params.startRow / pageSize) + 1

          try {
            const data = await get<OrderChange[]>(
              `/sales/returns`
            )
            const returns = data.map((ret) => OrderChange.from(ret))
            // const returns = orders.flatMap((o) =>
            //   o.orderChanges.filter((oc) => oc.changeType === 'return')
            // )

            // if the receipt number was entered
            if (receiptNumber) params.successCallback(returns, returns.length)
            else params.successCallback(returns, returns.length)
          } catch (error) {
            if (error?.code === 404) {
              params.successCallback([], 0)
            } else {
              alertModal.showDanger({ title: error.title, body: error.body })
              params.failCallback()
            }
          }
        }
      } as IDatasource

      this.gridApi?.setDatasource(this.datasource)
    },

    async onGridReady(params: any) {
      this.gridApi = params.api
      this.gridOptions.cacheBlockSize = this.gridOptions.paginationPageSize
      await this.updateRowData('')
    },

    async applyToSelected() {
      if (this.selectedValue === 'delete' || this.selectedValue === 'export') {
        const selected = this.gridApi?.getSelectedRows()

        if (selected?.length) {
          if (this.selectedValue === 'delete') {
            // send request to archive
          } else {
            this.gridApi?.exportDataAsCsv({ onlySelected: true })
            this.$alertModal.showSuccess({
              title: this.$t('views.common.listView.selectedRows.exportSuccess')
            })
          }

          // deselect
          selected.length = 0
        }
      }

      this.selectedValue = ''
    },

    filterItems() {
      this.updateRowData(this.searchTerm)
    },
    onCellDoubleClicked(event: any) {
      this.$router.push(
        this.$Route.SALES_ORDERS_RETURN_ORDER_RECEIPT.replace(
          ':id',
          event.data.orderID || ''
        ).replace(':orderChangeID', event.data.id || '')
      )
    }
  }
})
</script>

<style></style>
