<template>
  <div>
    <div v-if="loading" class="flex items-center justify-center h-full">
      <loading-spinner diameter="64px" />
    </div>

    <alert-badge
      v-else-if="error.title"
      isDanger
      :title="error.title"
      :body="error.body"
      @dismissed="
        () => {
          error.title = ''
          error.body = ''
        }
      "
    />

    <div class="m-3">
      <div class="flex flex-col items-center content-center gap-2 md:flex-row">
        <el-select class="input-label" v-model="location">
          <el-option
            v-for="(loc, i) in locations"
            :key="i"
            :label="$ctx.locale === 'en' ? loc.enName : loc.arName"
            :value="loc.id"
          >
          </el-option>
        </el-select>
        <el-select class="input-label" v-model="selectedShortcut">
          <el-option :label="t('thisMonth')" value="this-month"> </el-option>
          <el-option :label="t('lastMonth')" value="last-month"> </el-option>
          <el-option :label="t('thisQuarter')" value="this-quarter"> </el-option>
        </el-select>

        <el-date-picker
          v-model="period"
          type="daterange"
          range-separator="to"
          :start-placeholder="t('startDate')"
          :end-placeholder="t('endDate')"
        >
        </el-date-picker>

        <div class="w-1/4">
          <horizontal-button :title="t('generateReport')" @click="generateReport()" />
        </div>
      </div>

      <div v-if="Object.keys(profitAndLoss).length">
        <column-chart
          :xAxis="getChartInfo(profitAndLoss).xAxis"
          :chartData="getChartInfo(profitAndLoss).chartData"
          :ChartTitle="'title'"
          :height="600"
          :colors="getChartInfo(profitAndLoss).colors"
          :showLegend="false"
        />
        <el-divider />
        <div class="income-statement pb-18">
          <h1 class="text-xl">{{ t('incomeStatement') }}</h1>
          <div class="justify-evenly items-center">
            <table class="mt-8 w-1/2">
              <tbody>
                <tr>
                  <td class="w-3/4">{{ t('totalRevenueAmount') }}</td>
                  <td>
                    {{
                      formatMoney(
                        profitAndLoss.totalRevenueAmount || 0,
                        profitAndLoss.currency || 'IQD'
                      )
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="w-3/4">{{ t('costOfGoodsSoldAmount') }}</td>
                  <td>
                    {{
                      formatMoney(
                        profitAndLoss.costOfGoodsSoldAmount || 0,
                        profitAndLoss.currency || 'IQD'
                      )
                    }}
                  </td>
                </tr>
                <tr class="border-t-2 border-black">
                  <td class="font-bold w-3/4">{{ t('grossProfitAmount') }}</td>
                  <td class="font-bold">
                    {{
                      formatMoney(
                        profitAndLoss.grossProfitAmount || 0,
                        profitAndLoss.currency || 'IQD'
                      )
                    }}
                  </td>
                </tr>
              </tbody>
            </table>

            <el-collapse accordion class="mt-8 w-1/2">
              <el-collapse-item name="1">
                <template #title>
                  <div class="w-3/4 h-full border-r p-2 font-bold text-16 flex items-center">
                    {{ t('totalExpensesAmount') }}
                  </div>
                  <div class="h-full border-1 flex-1 p-2 font-bold text-16 flex items-center">
                    {{
                      formatMoney(
                        profitAndLoss.totalExpensesAmount || 0,
                        profitAndLoss.currency || 'IQD'
                      )
                    }}
                  </div>
                </template>
                <table class="w-full flex-1">
                  <tbody>
                    <tr v-for="(expense, i) in profitAndLoss.expenses" :key="i">
                      <td class="w-3/4 table-cell">
                        {{ expense.expenseName }}
                      </td>
                      <td class="table-cell">
                        {{ formatMoney(expense.amount || 0, profitAndLoss.currency || 'IQD') }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </el-collapse-item>
            </el-collapse>

            <table class="mt-8 w-1/2">
              <tbody>
                <tr>
                  <td class="w-3/4">{{ t('taxesAmount') }}</td>
                  <td>
                    {{
                      formatMoney(profitAndLoss.taxesAmount || 0, profitAndLoss.currency || 'IQD')
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="w-3/4">{{ t('interestAmount') }}</td>
                  <td>
                    {{
                      formatMoney(
                        profitAndLoss.interestAmount || 0,
                        profitAndLoss.currency || 'IQD'
                      )
                    }}
                  </td>
                </tr>
                <tr class="border-t-2 border-black">
                  <td class="font-bold w-3/4">
                    {{ t('netProfitOrLossAmount') }}
                  </td>
                  <td class="font-bold">
                    {{
                      formatMoney(
                        profitAndLoss.netProfitOrLossAmount || 0,
                        profitAndLoss.currency || 'IQD'
                      )
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div v-else class="flex flex-col justify-center items-center h-5/6 w-full">
        <img src="@/assets/emptyFolder.png" alt="empty folder" width="250" />
        <h1 class="uppercase">{{ $t('views.reports.emptyState') }}</h1>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import AlertBadge from '@/components/AlertBadge.vue'
import Location from '@/models/company/Location'
import { useI18n } from 'vue-i18n'
import dayjs from 'dayjs'
import HorizontalButton from '@/components/HorizontalButton.vue'
import ColumnChart from '@/components/charts/ColumnChart.vue'
import { formatMoney } from '@/utils/money'
import { Currency } from 'dinero.js'

class ProfitAndLoss {
  static from(json: Record<string, unknown> | ProfitAndLoss) {
    return Object.assign(new ProfitAndLoss(), json)
  }

  static ENDPOINT = '/reports/accounting/profit-and-loss'

  locationID?: string
  locationName?: string
  period?: string
  fromDate?: Date
  toDate?: Date
  currency?: Currency
  precision = 0
  totalRevenueAmount?: number
  costOfGoodsSoldAmount?: number
  grossProfitAmount?: number
  totalExpensesAmount?: number
  expenses?: { expenseName?: string; amount?: number }[]
  hrAmount?: number
  operatingProfitAmount?: number
  taxesAmount?: number
  interestAmount?: number
  netProfitOrLossAmount?: number
}

export default defineComponent({
  components: {
    LoadingSpinner,
    AlertBadge,
    HorizontalButton,
    ColumnChart
  },

  data() {
    const profitAndLoss: ProfitAndLoss = {} as ProfitAndLoss
    const selectedShortcut: 'last-month' | 'this-month' | 'this-quarter' = 'this-month'
    const selectedExpense: {
      expenseName?: string
      amount?: number
    } = profitAndLoss.expenses ? profitAndLoss.expenses[0] : {}
    const messages = {
      en: {
        startDate: 'Start Date',
        endDate: 'End Date',
        thisMonth: 'This Month',
        lastMonth: 'Last Month',
        thisQuarter: 'This Quarter',
        generateReport: 'Generate Report',
        totalRevenueAmount: 'Total Revenue',
        costOfGoodsSoldAmount: 'CoGS',
        grossProfitAmount: 'Gross Profit',
        totalExpensesAmount: 'Total Expenses',
        hrAmount: 'HR',
        operatingProfitAmount: 'Operating Profit',
        netProfitOrLossAmount: 'Net Profit/Loss',
        taxesAmount: 'Taxes',
        interestAmount: 'Interest',
        incomeStatement: 'Income Statement',
        expense: 'Expense'
      },
      ar: {
        startDate: 'Start Date',
        endDate: 'End Date',
        thisMonth: 'This Month',
        lastMonth: 'Last Month',
        thisQuarter: 'This Quarter',
        generateReport: 'Generate Report',
        totalRevenueAmount: 'Total Revenue',
        costOfGoodsSoldAmount: 'CoGS',
        grossProfitAmount: 'Gross Profit',
        totalExpensesAmount: 'Total Expenses',
        hrAmount: 'HR',
        operatingProfitAmount: 'Operating Profit',
        netProfitOrLossAmount: 'Net Profit/Loss',
        taxesAmount: 'Taxes',
        interestAmount: 'Interest',
        incomeStatement: 'Income Statement',
        expense: 'Expense'
      }
    }

    const { t } = useI18n({
      messages
    })

    return {
      profitAndLoss,
      selectedShortcut,
      selectedExpense,
      error: {
        title: '',
        body: ''
      },
      loading: false,
      locations: new Array<Location>(),
      location: '',
      period: [dayjs().startOf('month').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')], // the default period is from the first day of the month to the current day
      t
    }
  },

  async beforeMount() {
    const locationRes = await this.$http.get<Location[]>(Location.ENDPOINT)
    this.locations = locationRes.map((cat) => Location.from(cat))
  },

  methods: {
    formatMoney,
    async updateRowData() {
      try {
        const res = await this.$http.get<ProfitAndLoss>(
          `${ProfitAndLoss.ENDPOINT}?location_id=${this.location}&from_date=${this.period[0]}&to_date=${this.period[1]}`
        )
        this.profitAndLoss = ProfitAndLoss.from(res)
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        })
      }
    },

    isLosing(amount: number) {
      return amount < 0
    },

    getBarColor(field: string) {
      if (field === 'totalRevenueAmount') return '#1e88e5'
      if (
        field === 'costOfGoodsSoldAmount' ||
        field === 'totalExpensesAmount' ||
        field === 'costOfGoodsSoldAmount' ||
        field === 'taxesAmount'
      )
        return '#f44336'
      if (
        field === 'grossProfitAmount' ||
        field === 'operatingProfitAmount' ||
        field === 'netProfitOrLossAmount'
      ) {
        return this.isLosing(this.profitAndLoss[field]!) ? '#f44336' : '#4caf50'
      }
      return '#000'
    },

    getChartInfo(profitAndLoss: any): {
      chartData: ApexAxisChartSeries
      xAxis: Array<string>
      colors: Array<string>
    } {
      const theWantedFields = [
        'totalRevenueAmount',
        'costOfGoodsSoldAmount',
        'grossProfitAmount',
        'totalExpensesAmount',
        'taxesAmount',
        'operatingProfitAmount',
        'netProfitOrLossAmount'
      ]
      const colors = Array<string>()
      return {
        chartData: theWantedFields.map((field) => {
          colors.push(this.getBarColor(field))
          return {
            name: this.t(field),
            data: [profitAndLoss[field]]
          }
        }),
        xAxis: theWantedFields.map((field) => this.t(field)),
        colors
      }
    },

    generateReport() {
      this.updateRowData()
    }
  }
})
</script>

<style>
.el-date-editor .el-range-separator {
  width: auto !important;
}

.income-statement {
  background-color: white;
  padding-block: 2rem;
  padding-inline: 1rem;
}

.table-cell {
  font-size: 16px;
}

.el-collapse-item__content {
  border: 0 !important;
  border-bottom: 0;
  padding-bottom: 0;
}

.el-collapse-item__wrap {
  border: 0 !important;
  border-bottom: 0;
}

.el-collapse {
  border-top: 1px solid #e4e7ed;
  border-inline: 1px solid #e4e7ed;
}

.text-16 {
  font-size: 16px;
}
</style>
