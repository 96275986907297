<template>
  <div v-if="title && !dismissed" class="w-full flex justify-center">
    <div
      class="w-5/6 md:w-4/6 p-2 rounded"
      :class="{
        normal: isNormal,
        danger: isDanger,
        success: isSuccess,
        warning: isWarning
      }"
    >
      <div class="flex justify-between items-center">
        <h1>{{ title }}</h1>
        <font-awesome-icon icon="times" class="mx-2 cursor-pointer" @click="dismiss" />
      </div>
      <p style="white-space: pre-line">{{ body }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'alert-badge',

  props: {
    title: {
      type: String,
      required: true
    },
    body: {
      type: String,
      required: false
    },
    isDanger: {
      type: Boolean,
      default: false
    },
    isSuccess: {
      type: Boolean,
      default: false
    },
    isWarning: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isNormal: !(this.isWarning || this.isDanger || this.isSuccess),
      dismissed: false
    }
  },
  emits: ['dismissed'],
  methods: {
    dismiss() {
      this.dismissed = true
      this.$emit('dismissed')
    }
  },

  watch: {
    title() {
      // make sure the badge appears if title changes
      this.dismissed = false
    }
  }
})
</script>

<style scoped>
.normal {
  color: var(--secondary-text-color);
  background: var(--secondary-bg-color);
}

.danger {
  color: var(--danger-text-color);
  background: var(--danger-bg-color);
}

.success {
  color: var(--success-text-color);
  background: var(--success-bg-color);
}

.warning {
  color: var(--warning-text-color);
  background: var(--warning-bg-color);
}
</style>
