import { RouteRecordRaw } from 'vue-router'
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'
import Inventory from '@/views/inventory/Inventory.vue'
import Sales from '@/views/sales/Sales.vue'
import Customers from '@/views/customers/Customers.vue'
import HumanResources from '@/views/hr/HumanResources.vue'
import Accounting from '@/views/accounting/Accounting.vue'
import Settings from '@/views/settings/Settings.vue'
import License from '@/views/license/License.vue'
import Vendors from '@/views/vendors/Vendors.vue'
import AccountingVendors from '@/views/accounting/vendors/Vendors.vue'
import Examination from '@/views/examinations/Examinations.vue'
import OpenRegister from '@/views/sales/registers/OpenRegister.vue'
import NewCheckout from '@/views/sales/NewCheckout.vue'
import Orders from '@/views/sales/Orders.vue'
import Transactions from '@/views/sales/Transactions.vue'
import AccountingTransactions from '@/views/accounting/transactions/Transactions.vue'
import Order from '@/views/sales/Order.vue'
import SaleReceipt from '@/views/sales/new-receipt/SaleReceipt.vue'
import ReturnOrders from '@/views/sales/ReturnOrders.vue'
import ReturnOrder from '@/views/sales/ReturnOrder.vue'
import ReturnReceipt from '@/views/sales/new-receipt/ReturnReceipt.vue'
import OrderEdit from '@/views/sales/OrderEdit.vue'
import OrderPayment from '@/views/sales/OrderPayment.vue'
import CloseRegister from '@/views/sales/registers/CloseRegister.vue'
import Items from '@/views/inventory/items/Items.vue'
import SimpleItem from '@/views/inventory/items/SimpleItem.vue'
import ItemView from '@/views/inventory/items/ItemView.vue'
import NewSimpleItem from '@/views/inventory/items/NewSimpleItem.vue'
import CustomPrices from '@/views/inventory/customPrices/CustomPrices.vue'
import CustomPrice from '@/views/inventory/customPrices/CustomPrice.vue'
import NewCustomPrice from '@/views/inventory/customPrices/NewCustomPrice.vue'
import Brands from '@/views/inventory/brands/Brands.vue'
import Brand from '@/views/inventory/brands/Brand.vue'
import NewBrand from '@/views/inventory/brands/NewBrand.vue'
import Categories from '@/views/inventory/categories/Categories.vue'
import Category from '@/views/inventory/categories/Category.vue'
import NewCategory from '@/views/inventory/categories/NewCategory.vue'
import Tags from '@/views/inventory/tags/Tags.vue'
import Tag from '@/views/inventory/tags/Tag.vue'
import NewTag from '@/views/inventory/tags/NewTag.vue'
import InventoryReceivings from '@/views/inventory/inventoryReceivings/InventoryReceivings.vue'
import InventoryReceiving from '@/views/inventory/inventoryReceivings/InventoryReceiving.vue'
import NewInventoryReceiving from '@/views/inventory/inventoryReceivings/NewInventoryReceiving.vue'
import InventoryAdjustments from '@/views/inventory/inventoryAdjustments/InventoryAdjustments.vue'
import InventoryAdjustment from '@/views/inventory/inventoryAdjustments/InventoryAdjustment.vue'
import NewInventoryAdjustment from '@/views/inventory/inventoryAdjustments/NewInventoryAdjustment.vue'
import InventoryTransfers from '@/views/inventory/inventoryTransfers/InventoryTransfers.vue'
import InventoryTransfer from '@/views/inventory/inventoryTransfers/InventoryTransfer.vue'
import NewInventoryTransfer from '@/views/inventory/inventoryTransfers/NewInventoryTransfer.vue'
import ReceiveInventoryTransfer from '@/views/inventory/inventoryTransfers/ReceiveInventoryTransfer.vue'
import PrintBarcode from '@/views/inventory/barcode/PrintBarcode.vue'
import Customer from '@/views/customers/Customer.vue'
import CustomerView from '@/views/customers/CustomerView.vue'
import NewCustomer from '@/views/customers/NewCustomer.vue'
import NewExamination from '@/views/examinations/NewExamination.vue'
import ExaminationView from '@/views/examinations/ExaminationView.vue'
import CustomerAccountStatement from '@/views/customers/CustomerAccountStatement.vue'
import VendorAccountStatement from '@/views/vendors/VendorAccountStatement.vue'
import ExaminationReceipt from '@/views/examinations/ExaminationReceipt.vue'
import ExaminationIDPrint from '@/views/examinations/ExaminationIDPrint.vue'
import Vendor from '@/views/vendors/Vendor.vue'
import VendorView from '@/views/vendors/VendorView.vue'
import NewVendor from '@/views/vendors/NewVendor.vue'
import Employees from '@/views/hr/employees/Employees.vue'
import Employee from '@/views/hr/employees/Employee.vue'
import NewEmployee from '@/views/hr/employees/NewEmployee.vue'
import ViewEmployeeSchedule from '@/views/hr/employeeSchedule/ViewEmployeeSchedule.vue'
import NewEmployeeSchedule from '@/views/hr/employeeSchedule/NewEmployeeSchedule.vue'
import Accounts from '@/views/accounting/accounts/Accounts.vue'
import Account from '@/views/accounting/accounts/Account.vue'
import NewAccount from '@/views/accounting/accounts/NewAccount.vue'
import Transaction from '@/views/accounting/transactions/Transaction.vue'
import NewTransaction from '@/views/accounting/transactions/NewTransaction.vue'
import CustomerInvoicesByCustomer from '@/views/accounting/customers/CustomerInvoicesByCustomer.vue'
import Registers from '@/views/accounting/registers/Registers.vue'
import Register from '@/views/accounting/registers/Register.vue'
import Hr from '@/views/accounting/hr/Hr.vue'
import NewBill from '@/views/accounting/vendors/NewBill.vue'
import VendorBillPayment from '@/views/accounting/vendors/VendorBillPayment.vue'
import RegisterTransaction from '@/views/accounting/registers/RegisterTransaction.vue'
import NewPay from '@/views/accounting/hr/NewPay.vue'
import NewExpense from '@/views/accounting/company/NewExpense.vue'
import Reports from '@/views/reports/Reports.vue'
import SalesByCategory from '@/views/reports/sales/SalesByCategory.vue'
import TopSoldItems from '@/views/reports/v2/sales/TopSoldItems.vue'
import SalesByItem from '@/views/reports/sales/SalesByItem.vue'
import TopCustomers from '@/views/reports/customers/TopCustomers.vue'
import LeastSoldItems from '@/views/reports/sales/LeastSoldItems.vue'
import TopVendors from '@/views/reports/vendors/TopVendors.vue'
import InventoryValue from '@/views/reports/inventory/InventoryValue.vue'
import InventoryValueDetails from '@/views/reports/inventory/InventoryValueDetails.vue'
import SalesSummary from '@/views/reports/sales/SalesSummary.vue'
import NewRegisterClosing from '@/views/reports/sales/RegisterClosing/NewRegisterClosing.vue'
import SalesComparison from '@/views/reports/sales/SalesComparison.vue'
import VendorsBalances from '@/views/reports/vendors/VendorsBalances.vue'
import CustomersBalances from '@/views/reports/accounting/CustomersBalances.vue'
import SalesHeatmap from '@/views/reports/sales/SalesHeatmap.vue'
import QuantityReport from '@/views/reports/inventory/QuantityReport.vue'
import ProfitAndLoss from '@/views/reports/accounting/ProfitAndLoss.vue'
import NewReturnOrder from '@/views/sales/NewReturnOrder.vue'
import TransactionReceipt from '@/views/sales/new-receipt/TransactionReceipt.vue'
import RegisterClosingReport from "@/views/sales/registers/RegisterClosingReport.vue";
import EmployeesTopSalary from '@/views/reports/v2/hr/EmployeesTopSalary.vue'
import InventoryQuickFacts from '@/views/reports/v2/inventory/InventoryQuickFacts.vue'
import NegativeStockInventory from '@/views/reports/v2/inventory/NegativeStockInventory.vue'
import LowStockInventory from '@/views/reports/v2/inventory/LowStockInventory.vue'
import CustomerInvoices from '@/views/accounting/customers/CustomerInvoices.vue'
import Expenses from '@/views/accounting/company/Expenses.vue'
import WeeklySales from '@/views/reports/v2/sales/WeeklySales.vue'
import MonthlySales from '@/views/reports/v2/sales/MonthlySales.vue'
import TotalReturns from '@/views/reports/v2/sales/TotalReturns.vue'
import TotalSales from '@/views/reports/v2/sales/TotalSales.vue'
import TopReps from '@/views/reports/v2/sales/TopReps.vue'
import VendorBillView from '@/views/accounting/vendors/VendorBillView.vue'

export enum Route {
  HOME = '/',

  LOGIN = '/login',

  SALES = '/sales',
  SALES_NEW_SALE = '/sales/new-sale',
  SALES_ORDERS = '/sales/orders',
  SALES_ORDERS_ORDER = '/sales/orders/order/:id',
  SALES_ORDERS_ORDER_RECEIPT = '/sales/orders/order/:id/receipt',
  SALES_ORDERS_RETURN_ORDERS = '/sales/orders/return-orders',
  SALES_ORDERS_NEW_RETURN_ORDER = '/sales/orders/new-return-order',
  SALES_ORDERS_RETURN_ORDER = '/sales/orders/return-orders/:orderID',
  SALES_ORDERS_RETURN_ORDER_RECEIPT = '/sales/orders/return-orders/:id/receipt/:orderChangeID',
  SALES_ORDERS_ORDER_EDIT = '/sales/orders/order/:id/edit',
  SALES_ORDERS_ORDER_PAYMENT = '/sales/orders/order/:id/order-payment',
  SALES_ORDERS_ORDER_PAYMENT_RECEIPT = '/sales/orders/order-payment/:id/receipt',
  SALES_REGISTERS_OPEN_REGISTER = '/sales/registers/open-register',
  SALES_REGISTERS_CLOSE_REGISTER = '/sales/registers/close-register',
  SALES_REGISTERS_CLOSE_REGISTER_REPORT = '/sales/registers/close-register/report/:id',
  SALES_TRANSACTIONS = '/sales/transactions',
  SALES_TRANSACTIONS_RECEIPT = "/sales/transactions/:transactionID/receipt",

  INVENTORY = '/inventory',
  INVENTORY_ITEMS = '/inventory/items',
  INVENTORY_ITEMS_SIMPLE_ITEM = '/inventory/items/simple-item/:id',
  INVENTORY_ITEMS_ITEM_VIEW = '/inventory/items/item/view/:id',
  INVENTORY_ITEMS_NEW_SIMPLE_ITEM = '/inventory/items/new-simple-item',
  INVENTORY_ITEM_BRANDS = '/inventory/item-brands',
  INVENTORY_ITEM_BRANDS_BRAND = '/inventory/item-brands/brand/:id',
  INVENTORY_ITEM_BRANDS_NEW_BRAND = '/inventory/item-brands/new-brand',
  INVENTORY_ITEM_CUSTOM_PRICE_TYPES = '/inventory/custom-price-types',
  INVENTORY_ITEM_CUSTOM_PRICE_TYPE = '/inventory/custom-price-types/type/:id',
  INVENTORY_ITEM_NEW_CUSTOM_PRICE_TYPE = '/inventory/custom-price-types/new-type',
  INVENTORY_ITEM_CATEGORIES = '/inventory/item-categories',
  INVENTORY_ITEM_CATEGORIES_CATEGORY = '/inventory/item-categories/category/:id',
  INVENTORY_ITEM_CATEGORIES_NEW_CATEGORY = '/inventory/item-categories/new-category',
  INVENTORY_ITEM_TAGS = '/inventory/item-tags',
  INVENTORY_ITEM_TAGS_TAG = '/inventory/item-tags/tag/:id',
  INVENTORY_ITEM_TAGS_NEW_TAG = '/inventory/item-tags/new-tag',
  INVENTORY_INVENTORY_RECEIVINGS = '/inventory/inventory-receivings',
  INVENTORY_INVENTORY_RECEIVINGS_INVENTORY_RECEIVING = '/inventory/inventory-receivings/inventory-receiving/:id',
  INVENTORY_INVENTORY_RECEIVINGS_NEW_INVENTORY_RECEIVING = '/inventory/inventory-receivings/new-inventory-receiving',
  INVENTORY_INVENTORY_ADJUSTMENTS = '/inventory/inventory-adjustments',
  INVENTORY_INVENTORY_ADJUSTMENTS_INVENTORY_ADJUSTMENT = '/inventory/inventory-adjustments/inventory-adjustment/:id',
  INVENTORY_INVENTORY_ADJUSTMENTS_NEW_INVENTORY_ADJUSTMENT = '/inventory/inventory-adjustments/new-inventory-adjustment',
  INVENTORY_INVENTORY_TRANSFERS = '/inventory/inventory-transfers',
  INVENTORY_INVENTORY_TRANSFERS_INVENTORY_TRANSFER = '/inventory/inventory-transfers/inventory-transfer/:id',
  INVENTORY_INVENTORY_TRANSFERS_NEW_INVENTORY_TRANSFER = '/inventory/inventory-transfers/new-inventory-transfer',
  INVENTORY_INVENTORY_TRANSFERS_RECEIVE_INVENTORY_TRANSFER = '/inventory/inventory-transfers/receive-inventory-transfer',

  INVENTORY_PRINT_BARCODE = '/inventory/print-barcode',

  CUSTOMERS = '/customers',
  CUSTOMERS_CUSTOMER = '/customers/customer/:id',
  CUSTOMERS_CUSTOMER_VIEW = '/customers/customer/view/:id',
  CUSTOMERS_NEW_CUSTOMER = '/customers/new-customer',
  CUSTOMERS_NEW_EXAMINATION = '/customers/:customer_id/new-examination',
  CUSTOMERS_ACCOUNT_STATEMENT = '/customers/:customer_id/account-statement',

  EXAMINATIONS = '/examinations',
  EXAMINATIONS_EXAMINATION_VIEW = '/examinations/examination/view/:examination_id',
  EXAMINATIONS_NEW_EXAMINATION = '/examinations/new-examination',
  EXAMINATIONS_EXAMINATION_RECEIPT = '/examinations/examination/:examination_id/receipt',
  EXAMINATIONS_EXAMINATION_ID_PRINT = '/examinations/examination/:examination_id/id-print',

  VENDORS = '/vendors',
  VENDORS_VENDOR = '/vendors/vendor/:id',
  VENDORS_VENDOR_VIEW = '/vendors/vendor/view:id',
  VENDORS_NEW_VENDOR = '/vendors/new-vendor',
  VENDORS_ACCOUNT_STATEMENT = '/vendors/:vendor_id/account-statement',

  HR = '/hr',
  HR_EMPLOYEES = '/hr/employees',
  HR_EMPLOYEES_EMPLOYEE = '/hr/employees/employee/:id',
  HR_EMPLOYEES_NEW_EMPLOYEE = '/hr/employees/new-employee',
  HR_EMPLOYEE_SCHEDULE = '/hr/employee-schedules',
  HR_EMPLOYEE_SCHEDULES_NEW_EMPLOYEE_SCHEDULE = '/hr/employee-schedules/new-employee-schedule',

  ACCOUNTING = '/accounting',
  ACCOUNTING_ACCOUNTS = '/accounting/accounts',
  ACCOUNTING_ACCOUNTS_ACCOUNT = '/accounting/accounts/account/:id',
  ACCOUNTING_ACCOUNTS_NEW_ACCOUNT = '/accounting/accounts/new-account',
  ACCOUNTING_TRANSACTIONS = '/accounting/transactions',
  ACCOUNTING_TRANSACTIONS_TRANSACTION = '/accounting/transactions/transaction/:id',
  ACCOUNTING_TRANSACTIONS_NEW_TRANSACTION = '/accounting/transactions/new-transaction',
  ACCOUNTING_CUSTOMERS = '/accounting/customers',
  ACCOUNTING_CUSTOMER_INVOICES = '/accounting/customers/customer-invoices/:id',
  ACCOUNTING_VENDORS = '/accounting/vendors',
  ACCOUNTING_VENDORS_NEW_BILL = '/accounting/vendors/new-bill',
  ACCOUNTING_VENDORS_BILL_VIEW = '/accounting/vendors/:id',
  ACCOUNTING_VENDORS_BILL_PAYMENT = '/accounting/vendors/:id/vendor-bill-payment',
  ACCOUNTING_REGISTERS = '/accounting/registers',
  ACCOUNTING_REGISTERS_REGISTER = '/accounting/registers/register/:id',
  ACCOUNTING_REGISTERS_REGISTER_TRANSACTION = '/accounting/registers/transactions/register/:id',
  ACCOUNTING_HR = '/accounting/hr',
  ACCOUNTING_HR_NEW_PAY = '/accounting/hr/new-pay',
  ACCOUNTING_COMPANY = '/accounting/company',
  ACCOUNTING_COMPANY_NEW_EXPENSE = '/accounting/company/new-expense',

  REPORTS = '/reports',
  REPORTS_SALES_SALES_BY_CATEGORY = '/reports/sales/sales-by-category',
  REPORTS_SALES_SALES_BY_ITEM = '/reports/sales/sales-by-item',
  REPORTS_SALES_TOP_SOLD_ITEMS = '/reports/sales/top-sold-items',
  REPORTS_SALES_TOTAL_SALES = '/reports/sales/total-sales',
  REPORTS_SALES_TOTAL_RETURNS = '/reports/sales/total-returns',
  REPORTS_SALES_WEEKLY_SALES = '/reports/sales/weekly-sales',
  REPORTS_SALES_MONTHLY_SALES = '/reports/sales/monthly-sales',
  REPORTS_SALES_TOP_REPS = '/reports/sales/top-reps',
  REPORTS_SALES_LEAST_SOLD_ITEMS = '/reports/sales/least-sold-items',
  REPORTS_SALES_SALES_SUMMARY = '/reports/sales/sales-summary',
  REPORTS_SALES_SALES_NEW_REGISTER_CLOSING = '/reports/sales/new-register-closing',
  REPORTS_SALES_SALES_REGISTER_CLOSING_REPORT = '/reports/sales/register-closing-report',
  REPORTS_SALES_END_OF_DAY = '/reports/sales/end-of-day',
  REPORTS_SALES_SALES_COMPARISON = '/reports/sales/sales-comparison',
  REPORTS_SALES_SALES_HEATMAP = '/reports/sales/sales-heatmap',
  REPORTS_CUSTOMERS_TOP_CUSTOMERS = '/reports/customers/top-customers',
  REPORTS_VENDORS_TOP_VENDORS = '/reports/vendors/top-vendors',
  REPORTS_INVENTORY_QUANTITY = '/reports/inventory/quantity',
  REPORTS_INVENTORY_INVENTORY_VALUE = '/reports/inventory/inventory-value',
  REPORTS_INVENTORY_INVENTORY_VALUE_DETAILS = '/reports/inventory/inventory-value-details',
  REPORTS_ACCOUNTING_PROFIT_AND_LOSS = '/reports/accounting/profit-and-loss',
  REPORTS_VENDORS_VENDORS_BALANCES = '/reports/vendors/vendors-balances',
  REPORTS_ACCOUNTING_CUSTOMERS_BALANCES = '/reports/accounting/customers-balances',


  REPORTS_INVENTORY_QUICK_FACTS = '/reports/inventory/quick-facts',
  REPORTS_INVENTORY_LOW_STOCK = '/reports/inventory/low-stock-inventory',
  REPORTS_INVENTORY_NEGATIVE_STOCK = '/reports/inventory/negative-stock-inventory',
  REPORTS_HR_TOP_SALARIES = '/reports/hr/top-salaries',

  SETTINGS = '/settings',
  LICENSE = '/license'
}

export const routes: Array<RouteRecordRaw> = [
  {
    path: Route.HOME,
    name: 'home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: Route.LOGIN,
    name: 'login',
    component: Login,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: Route.SALES,
    name: 'sales',
    component: Sales,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.SALES_NEW_SALE,
    name: 'new-sale',
    component: NewCheckout,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.SALES_ORDERS,
    name: 'orders',
    component: Orders,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.SALES_TRANSACTIONS,
    name: 'sales_transactions',
    component: Transactions,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.SALES_ORDERS_ORDER,
    name: 'order',
    component: Order,
    meta: {
      requiresAuth: true
    }
  },
  // need to verify this
  {
    path: Route.SALES_ORDERS_ORDER_RECEIPT,
    name: 'order-receipt',
    component: SaleReceipt,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.SALES_TRANSACTIONS_RECEIPT,
    name: "transaction-receipt",
    component: TransactionReceipt,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.SALES_ORDERS_RETURN_ORDERS,
    name: 'return-orders',
    component: ReturnOrders,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.SALES_ORDERS_NEW_RETURN_ORDER,
    name: 'new-return-order',
    component: NewReturnOrder,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.SALES_ORDERS_RETURN_ORDER,
    name: 'return-order',
    component: ReturnOrder,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.SALES_ORDERS_RETURN_ORDER_RECEIPT,
    name: 'return-order-receipt',
    component: ReturnReceipt,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.SALES_ORDERS_ORDER_EDIT,
    name: 'order-edit',
    component: OrderEdit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.SALES_ORDERS_ORDER_PAYMENT,
    name: 'order-payment',
    component: OrderPayment,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.SALES_REGISTERS_OPEN_REGISTER,
    name: 'open-register',
    component: OpenRegister,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.SALES_REGISTERS_CLOSE_REGISTER,
    name: 'close-register',
    component: CloseRegister,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.SALES_REGISTERS_CLOSE_REGISTER_REPORT,
    name: 'register-closing-receipt',
    component: RegisterClosingReport,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY,
    name: 'inventory',
    component: Inventory,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_ITEMS,
    name: 'inventory-items',
    component: Items,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_ITEMS_SIMPLE_ITEM,
    name: 'simple-item',
    component: SimpleItem,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_ITEMS_ITEM_VIEW,
    name: 'item-view',
    component: ItemView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_ITEMS_NEW_SIMPLE_ITEM,
    name: 'new-simple-item',
    component: NewSimpleItem,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_ITEM_CUSTOM_PRICE_TYPES,
    name: 'item-custom-price-types',
    component: CustomPrices,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_ITEM_CUSTOM_PRICE_TYPE,
    name: 'custom-price',
    component: CustomPrice,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_ITEM_NEW_CUSTOM_PRICE_TYPE,
    name: 'new-custom-price',
    component: NewCustomPrice,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_ITEM_BRANDS,
    name: 'item-brands',
    component: Brands,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_ITEM_BRANDS_BRAND,
    name: 'brand',
    component: Brand,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_ITEM_BRANDS_NEW_BRAND,
    name: 'new-brand',
    component: NewBrand,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_ITEM_CATEGORIES,
    name: 'categories',
    component: Categories,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_ITEM_CATEGORIES_CATEGORY,
    name: 'category',
    component: Category,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_ITEM_CATEGORIES_NEW_CATEGORY,
    name: 'new-category',
    component: NewCategory,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_ITEM_TAGS,
    name: 'tags',
    component: Tags,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_ITEM_TAGS_TAG,
    name: 'tag',
    component: Tag,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_ITEM_TAGS_NEW_TAG,
    name: 'new-tag',
    component: NewTag,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_INVENTORY_RECEIVINGS,
    name: 'inventory-receivings',
    component: InventoryReceivings,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_INVENTORY_RECEIVINGS_INVENTORY_RECEIVING,
    name: 'inventory-receiving',
    component: InventoryReceiving,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_INVENTORY_RECEIVINGS_NEW_INVENTORY_RECEIVING,
    name: 'new-inventory-receiving',
    component: NewInventoryReceiving,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_INVENTORY_ADJUSTMENTS,
    name: 'inventory-adjustments',
    component: InventoryAdjustments,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_INVENTORY_ADJUSTMENTS_INVENTORY_ADJUSTMENT,
    name: 'inventory-adjustment',
    component: InventoryAdjustment,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_INVENTORY_ADJUSTMENTS_NEW_INVENTORY_ADJUSTMENT,
    name: 'new-inventory-adjustment',
    component: NewInventoryAdjustment,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_INVENTORY_TRANSFERS,
    name: 'inventory-transfers',
    component: InventoryTransfers,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_INVENTORY_TRANSFERS_INVENTORY_TRANSFER,
    name: 'inventory-transfer',
    component: InventoryTransfer,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_INVENTORY_TRANSFERS_NEW_INVENTORY_TRANSFER,
    name: 'new-inventory-transfer',
    component: NewInventoryTransfer,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_INVENTORY_TRANSFERS_RECEIVE_INVENTORY_TRANSFER,
    name: 'receive-inventory-transfer',
    component: ReceiveInventoryTransfer,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_PRINT_BARCODE,
    name: 'print-barcode',
    component: PrintBarcode,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: Route.CUSTOMERS,
    name: 'customers',
    component: Customers
  },
  {
    path: Route.CUSTOMERS_CUSTOMER,
    name: 'customer',
    component: Customer,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.CUSTOMERS_CUSTOMER_VIEW,
    name: 'customer-view',
    component: CustomerView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.CUSTOMERS_NEW_CUSTOMER,
    name: 'new-customer',
    component: NewCustomer,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.CUSTOMERS_NEW_EXAMINATION,
    name: 'new-examination',
    component: NewExamination,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.EXAMINATIONS,
    name: 'examinations',
    component: Examination
  },
  {
    path: Route.EXAMINATIONS_EXAMINATION_VIEW,
    name: 'examination',
    component: ExaminationView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.EXAMINATIONS_NEW_EXAMINATION,
    name: 'new-examination',
    component: NewExamination,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.CUSTOMERS_ACCOUNT_STATEMENT,
    name: 'account-statement',
    component: CustomerAccountStatement,
    meta: {
      requiresAuth: true
    }
  },  

  {
    path: Route.EXAMINATIONS_EXAMINATION_RECEIPT,
    name: 'examination-receipt',
    component: ExaminationReceipt,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: Route.EXAMINATIONS_EXAMINATION_ID_PRINT,
    name: 'examination-id-print',
    component: ExaminationIDPrint,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: Route.VENDORS,
    name: 'vendors',
    component: Vendors
  },

  {
    path: Route.VENDORS_VENDOR,
    name: 'vendor',
    component: Vendor,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.VENDORS_VENDOR_VIEW,
    name: 'vendor-view',
    component: VendorView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.VENDORS_NEW_VENDOR,
    name: 'new-vendor',
    component: NewVendor,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: Route.VENDORS_ACCOUNT_STATEMENT,
    name: 'vendor-account-statement',
    component: VendorAccountStatement,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: Route.HR,
    name: 'hr',
    component: HumanResources,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.HR_EMPLOYEES,
    name: 'employees',
    component: Employees,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.HR_EMPLOYEES_EMPLOYEE,
    name: 'employee',
    component: Employee,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.HR_EMPLOYEES_NEW_EMPLOYEE,
    name: 'new-employee',
    component: NewEmployee,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.HR_EMPLOYEE_SCHEDULE,
    name: 'employee-schedules',
    component: ViewEmployeeSchedule,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.HR_EMPLOYEE_SCHEDULES_NEW_EMPLOYEE_SCHEDULE,
    name: 'new-employee-schedule',
    component: NewEmployeeSchedule,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: Route.ACCOUNTING,
    name: 'accounting',
    component: Accounting,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.ACCOUNTING_ACCOUNTS,
    name: 'accounts',
    component: Accounts,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.ACCOUNTING_ACCOUNTS_ACCOUNT,
    name: 'account',
    component: Account,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.ACCOUNTING_ACCOUNTS_NEW_ACCOUNT,
    name: 'new-account',
    component: NewAccount,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.ACCOUNTING_TRANSACTIONS,
    name: 'transactions',
    component: AccountingTransactions,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.ACCOUNTING_TRANSACTIONS_TRANSACTION,
    name: 'transaction',
    component: Transaction,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.ACCOUNTING_TRANSACTIONS_NEW_TRANSACTION,
    name: 'new-transaction',
    component: NewTransaction,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.ACCOUNTING_CUSTOMERS,
    name: 'accounting-customers',
    component: CustomerInvoices,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.ACCOUNTING_CUSTOMER_INVOICES,
    name: 'customer-invoices',
    component: CustomerInvoicesByCustomer,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.ACCOUNTING_VENDORS,
    name: 'accounting-vendors',
    component: AccountingVendors,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.ACCOUNTING_REGISTERS,
    name: 'accounting-registers',
    component: Registers,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.ACCOUNTING_REGISTERS_REGISTER,
    name: 'accounting-register',
    component: Register,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.ACCOUNTING_HR,
    name: 'accounting-hr',
    component: Hr,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.ACCOUNTING_COMPANY,
    name: 'expenses',
    component: Expenses,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.ACCOUNTING_VENDORS_NEW_BILL,
    name: 'new-bill',
    component: NewBill,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.ACCOUNTING_VENDORS_BILL_VIEW,
    name: 'vendorBill',
    component: VendorBillView,
    meta: {
      requiresAuth: true
    }
  },  
  {
    path: Route.ACCOUNTING_VENDORS_BILL_PAYMENT,
    name: 'bill-payment',
    component: VendorBillPayment,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.ACCOUNTING_REGISTERS_REGISTER_TRANSACTION,
    name: 'register-transaction',
    component: RegisterTransaction,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.ACCOUNTING_HR_NEW_PAY,
    name: 'hr-newpay',
    component: NewPay,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.ACCOUNTING_COMPANY_NEW_EXPENSE,
    name: 'new-expense',
    component: NewExpense,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: Route.REPORTS,
    name: 'reports',
    component: Reports,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.REPORTS_SALES_SALES_BY_CATEGORY,
    name: 'sales-by-category',
    component: SalesByCategory,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.REPORTS_SALES_TOP_SOLD_ITEMS,
    name: 'top-sold-items',
    component: TopSoldItems,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.REPORTS_SALES_TOTAL_SALES,
    name: 'total-sales',
    component: TotalSales,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.REPORTS_SALES_TOTAL_RETURNS,
    name: 'total-returns',
    component: TotalReturns,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.REPORTS_SALES_WEEKLY_SALES,
    name: 'weekly-sales',
    component: WeeklySales,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.REPORTS_SALES_MONTHLY_SALES,
    name: 'monthly-sales',
    component: MonthlySales,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.REPORTS_SALES_TOP_REPS,
    name: 'top-reps',
    component: TopReps,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.REPORTS_SALES_SALES_BY_ITEM,
    name: 'sales-by-item',
    component: SalesByItem,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.REPORTS_CUSTOMERS_TOP_CUSTOMERS,
    name: 'top-customers',
    component: TopCustomers,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.REPORTS_SALES_LEAST_SOLD_ITEMS,
    name: 'least-sold-items',
    component: LeastSoldItems,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.REPORTS_VENDORS_TOP_VENDORS,
    name: 'top-vendors',
    component: TopVendors,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.REPORTS_INVENTORY_INVENTORY_VALUE,
    name: 'inventory-value',
    component: InventoryValue,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.REPORTS_INVENTORY_INVENTORY_VALUE_DETAILS,
    name: 'inventory-value-details',
    component: InventoryValueDetails,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.REPORTS_SALES_SALES_SUMMARY,
    name: 'sales-summary',
    component: SalesSummary,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.REPORTS_SALES_SALES_NEW_REGISTER_CLOSING,
    name: 'new-register-closing',
    component: NewRegisterClosing,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.REPORTS_SALES_SALES_COMPARISON,
    name: 'sales-comparison',
    component: SalesComparison,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.REPORTS_VENDORS_VENDORS_BALANCES,
    name: 'vendors-balances',
    component: VendorsBalances,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.REPORTS_ACCOUNTING_CUSTOMERS_BALANCES,
    name: 'customers-balances',
    component: CustomersBalances,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.REPORTS_SALES_SALES_HEATMAP,
    name: 'sales-heatmap',
    component: SalesHeatmap,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.REPORTS_INVENTORY_QUANTITY,
    name: 'quantity-report',
    component: QuantityReport,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.REPORTS_ACCOUNTING_PROFIT_AND_LOSS,
    name: 'profit-and-loss-report',
    component: ProfitAndLoss,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.SETTINGS,
    name: 'settings',
    component: Settings,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.LICENSE,
    name: 'license',
    component: License,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.REPORTS_HR_TOP_SALARIES,
    name: 'top-salary-report',
    component: EmployeesTopSalary,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.REPORTS_INVENTORY_QUICK_FACTS,
    name: 'inventory-quick-facts',
    component: InventoryQuickFacts,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.REPORTS_INVENTORY_LOW_STOCK,
    name: 'low-stock-inventory',
    component: LowStockInventory,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.REPORTS_INVENTORY_NEGATIVE_STOCK,
    name: 'negative-stock-inventory',
    component: NegativeStockInventory,
    meta: {
      requiresAuth: true
    }
  },
]
