<!-- credits to https://github.com/sdras/vue-sample-svg-icons -->

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    :aria-labelledby="name"
    role="presentation"
    :style="{ color: color }"
  >
    <title :id="name" lang="en">{{ name }} icon</title>
    <g>
      <slot />
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    name: {
      type: String,
      default: 'icon'
    },
    color: {
      type: String,
      default: 'currentColor'
    }
  }
})
</script>

<style scoped>
svg {
  display: inline-block;
  vertical-align: center;
  margin-bottom: -2px;
  height: 1.3rem;
  width: 1.3rem;
}

@screen lg {
  svg {
    height: 4rem;
    width: 4rem;
  }
}
</style>
