<template>
  <el-button class="w-full btn" plain @click="onClicked">{{ linkTitle }}</el-button>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { RouteLocationRaw } from 'vue-router'

export default defineComponent({
  methods: {
    onClicked() {
      this.$router.push(this.path)
    }
  },
  props: {
    linkTitle: String,
    path: {
      required: true,
      type: String
    }
  }
})
</script>

<style scoped>
.btn {
  margin-block: 0.75rem;
  border: none;
}
.btn:hover {
  background: var(--secondary-hover-bg-color);
  color: var(--secondary-hover-text-color);
}
</style>
