<template>
  <div v-if="loading" class="flex items-center justify-center h-full">
    <loading-spinner diameter="64px" />
  </div>

  <alert-badge
    v-else-if="error.title"
    isDanger
    :title="error.title"
    :body="error.body"
    @dismissed="
      () => {
        error.title = ''
        error.body = ''
      }
    "
  />

  <div class="m-3" style="height: 90vh">
    <div class="flex flex-col items-center content-center gap-2 md:flex-row">
      <el-select class="input-label" v-model="location">
        <el-option
          v-for="(location, i) in locations"
          :key="i"
          :label="$ctx.locale === 'ar' ? location.arName : location.enName"
          :value="location.id"
        >
        </el-option>
      </el-select>

      <el-date-picker
        v-model="period"
        type="daterange"
        :start-placeholder="$t('views.reports.agGridToolbar.fromDate')"
        :end-placeholder="$t('views.reports.agGridToolbar.toDate')"
      >
      </el-date-picker>
    </div>

    <ag-grid-vue
      class="ag-theme-alpine"
      :columnDefs="columnDefs"
      :rowData="rowData"
      rowSelection="multiple"
      :enable-rtl="$ctx.getDir() === 'rtl'"
      style="height: 85vh"
      :pagination="true"
      :paginationAutoPageSize="true"
      :defaultColDef="defaultColDef"
      :onGridReady="onGridReady"
    >
    </ag-grid-vue>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import AlertBadge from '@/components/AlertBadge.vue'
import Location from '@/models/company/Location'
import { ColDef, GridApi, GridOptions } from 'ag-grid-community'
import { AgGridVue } from 'ag-grid-vue3'
import { useI18n } from 'vue-i18n'
import { Dinero as DineroType } from 'dinero.js'
import { formatDinero } from '@/utils/money'
import Vendor, { vendorI18nMessages } from '@/models/vendor/Vendor'

interface RowData {
  vendor: Vendor
  totalPaidAmount: number
  totalPaidCurrency: 'IQD' | 'USD'
  totalPaidPrecision: number
  totalPaidDinero: DineroType
}

export default defineComponent({
  components: {
    LoadingSpinner,
    AlertBadge,
    AgGridVue
  },

  data() {
    const columnDefs: ColDef[] = []
    const rowData: RowData[] = []
    const gridOptions: GridOptions = {}
    const defaultColDef: ColDef = {}
    const messages = {
      en: {
        ...vendorI18nMessages.en,
        totalPaidAmount: 'Total Paid Amount'
      },
      ar: {
        ...vendorI18nMessages.ar,
        totalPaidAmount: 'مجموع المبلغ المدفوع'
      }
    }

    const { t } = useI18n({
      messages
    })
    return {
      columnDefs,
      rowData,
      gridOptions,
      defaultColDef,
      error: {
        title: '',
        body: ''
      },
      loading: false,
      locations: new Array<Location>(),
      location: new Location(),
      gridApi: undefined as GridApi | undefined,
      period: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), Date()], // the default period is from the first day of the month to the current day
      t
    }
  },

  async beforeMount() {
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
      minWidth: 100,
      cellStyle: { textAlign: 'center' },
      flex: 1
    }
    this.columnDefs = [
      {
        headerName: this.t('name'),
        field: 'vendor.name',
        checkboxSelection: true,
        autoHeight: true,
        cellStyle: { textAlign: 'start' },
        headerCheckboxSelection: true,
        minWidth: 500
      },
      {
        headerName: this.t('totalPaidAmount'),
        field: 'totalPaidAmount',
        type: 'numericColumn',
        filter: 'agNumberColumnFilter',
        valueFormatter: (params) => {
          const data = params.data as RowData
          return formatDinero(data.totalPaidDinero)
        },
        cellStyle: { textAlign: 'center' }
      }
    ]
    const locationRes = await this.$http.get<Location[]>(Location.ENDPOINT)
    this.locations = locationRes.map((cat) => Location.from(cat))
  },

  watch: {
    period: {
      handler: function () {
        this.updateRowData()
      }
    },
    location: {
      handler: function () {
        this.updateRowData()
      }
    }
  },

  methods: {
    async updateRowData() {
      this.gridApi?.showLoadingOverlay()
      //here should formulate the path for the new request depending on the new date and location
      //for now I'm just hitting the vendors endpoint
      //this function will be called whenever the location or the period is changed
      try {
        // const data = await this.$http.get<Vendor[]>(Vendor.ENDPOINT);

        const data = await this.$http.get<RowData[]>('/reports/vendor/top-vendors')
        //here just generating mock data
        this.rowData = data.map((row) => {
          row.vendor = Vendor.from(row.vendor)
          return row
        })
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        })
      }

      this.gridApi?.hideOverlay()
    },

    async onGridReady(params: any) {
      this.gridApi = params.api
      this.gridApi?.sizeColumnsToFit()
      await this.updateRowData()
    },
  }
})
</script>

<style>
.el-date-editor .el-range-separator {
  width: auto !important;
}
</style>
