import dayjs from 'dayjs/esm/index.js'

export function parseFormat(date: string | Date | undefined): string {
  if (!date) return ''
  const parsed = new Date(date)
  if (isNaN(parsed.valueOf())) return ''
  return `${parsed.toLocaleDateString('en-GB')} ${parsed.toLocaleTimeString('en-US')}`
}

export function formatPostgresDate(
  postgresDateString: string,
  outputFormat = 'YYYY-MM-DD hh:mm:ss A'
) {
  // Parse the PostgreSQL date string
  const parsedDate = dayjs(postgresDateString)

  // Format the date with the specified output format
  const formattedDate = parsedDate.format(outputFormat)

  return formattedDate
}

export function formatAge(postgresDateString: string): number {
  // Parse the PostgreSQL date string
  const birthdate = dayjs(postgresDateString)
  const currentDate = dayjs()

  // Calculate the difference in years
  const age = currentDate.diff(birthdate, 'year')

  return age
}
