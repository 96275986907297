<template>
  <div
    class="flex flex-col items-center mx-4 mt-3 px-2"
    id="a4-receipt"
    :class="{
      'dir-ltr': $ctx.receiptLanguage == 'en',
      'dir-rtl': $ctx.receiptLanguage == 'ar'
    }"
  >
    <div class="flex flex-col items-center w-full" style="height: 100vh;">
      <!-- header info -->
      <div id="a4-receipt-header" class="flex flex-col w-full">
        <div class="flex mt-6 justify-between w-full">
          <div class="mx-8 mt-5 pb-2 text-sm self-end">
            <p style="font-size: 12px;">
              مركزنا الوحيد: العراق - بغداد - شارع 62
            </p>
            <p>07707106888 - 07806470404</p>
            <p>info@almaaedy.iq</p>
            <p>almaaedy.com.iq</p>
          </div>

          <div class="mx-8">
            <img src="@/assets/images/logo.png" width="200" alt="" />
          </div>
        </div>

        <div class="flex flex-col w-full items-center">
          <hr class="border-2 w-11/12" style="border-color: #585954" />
          <hr class="border-2 w-7/12 mt-0.5" style="border-color: #f7e2b6" />
        </div>
      </div>

      <div id="a4-receipt-body" class="w-full">
        <!-- title -->
        <div class="flex flex-col w-full mt-2 items-center">
          <h2 class="bg-gray-200 rounded py-1 px-2 font-bold mt-3 capitalize">
            {{ receiptTranslate("Sales Receipt", "receipt.salesReceipt") }}
          </h2>
        </div>

        <!-- order type info and barcode -->
        <div class="flex w-full justify-around">
          <div>
            <table class="w-full text-sm">
              <tr>
                <td>
                  <p>{{ receiptTranslate("Type", "receipt.type") }}</p>
                </td>
                <td>
                  <p class="font-bold mx-5">
                    <span v-if="order.invoice.paymentType == 'direct'">{{
                      receiptTranslate("Direct", "receipt.direct")
                    }}</span>
                    <span v-if="order.invoice.paymentType == 'credit_sale'">{{
                      receiptTranslate("Credit", "receipt.credit")
                    }}</span>
                    <span v-if="order.invoice.paymentType == 'layaway'">{{
                      receiptTranslate("Layaway", "receipt.layaway")
                    }}</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    {{ receiptTranslate("Customer", "receipt.customer") }}
                  </p>
                </td>
                <td>
                  <p class="font-bold mx-5">
                    {{
                      order.invoice.customer?.contactDisplayName
                        ? `${order.invoice.customer?.contactDisplayName} ${
                            order.invoice.customer?.businessName
                              ? " - " + order.invoice.customer?.businessName
                              : ""
                          }`
                        : receiptTranslate(
                            "Cash Customer",
                            "receipt.cashCustomer"
                          )
                    }}
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    {{ receiptTranslate("Location", "receipt.location") }}
                  </p>
                </td>
                <td>
                  <p v-if="$ctx.receiptLanguage == 'en'" class="font-bold mx-5">
                    {{ order.location?.enName || "" }}
                  </p>
                  <p v-if="$ctx.receiptLanguage == 'ar'" class="font-bold mx-5">
                    {{ order.location?.arName || "" }}
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    {{ receiptTranslate("Order No", "receipt.orderNo") }}
                  </p>
                </td>
                <td>
                  <p class="font-bold mx-5">{{ order.externalID }}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    {{ receiptTranslate("Cashier", "receipt.cashier") }}
                  </p>
                </td>
                <td>
                  <p class="font-bold mx-5">{{ order.user?.fullName }}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    {{ receiptTranslate("Date & Time", "receipt.dateAndTime") }}
                  </p>
                </td>
                <td>
                  <p class="font-bold mx-5" dir="ltr">
                    {{ parseFormat(order.createdAt) }}
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    {{ receiptTranslate("Order Notes", "receipt.orderNotes") }}
                  </p>
                </td>
                <td>
                  <p class="font-bold mx-5" dir="ltr">
                    {{ order.invoice.notes }}
                  </p>
                </td>
              </tr>
            </table>
          </div>

          <div class="flex flex-col items-center">
            <div>
              <svg id="barcode" class="mt-3"></svg>
              <div class="flex items-center justify-center">
                <span class="font-bold mx-5" style="font-size: 20px;">{{
                  barcodeText
                }}</span>
              </div>
            </div>
            <div
              v-if="order?.invoice.pouchID"
              class="flex flex-col w-full mt-2 items-center"
            >
              <h2 class="bg-gray-200 rounded py-1 px-2 flex">
                <span>{{ receiptTranslate("Pouch #", "receipt.pouch") }}</span>
                <span class="flex justify-end custom-bold mx-2">
                  {{ order?.invoice.pouchID }}
                </span>
              </h2>
            </div>
          </div>
        </div>

        <!-- items -->
        <div class="w-full px-5 my-2 text-sm">
          <table class="receipt-table w-full">
            <thead>
              <tr class="text-sm">
                <th>
                  {{ receiptTranslate("Item Name", "receipt.itemName") }}
                </th>
                <th>{{ receiptTranslate("Qty", "receipt.qty") }}</th>
                <th>{{ receiptTranslate("Price", "receipt.price") }}</th>
                <th>
                  {{ receiptTranslate("Final Price", "receipt.finalPrice") }}
                </th>
              </tr>
            </thead>

            <tbody v-if="order.orderItems">
              <tr
                v-for="(oi, i) in order.orderItems"
                :key="i"
                class="text-sm"
                :class="{ 'receipt-row-border': !oi.item.isSerialized }"
              >
                <td class="text-sm reciept-cell">
                  <div>{{ oi.item.name }}</div>
                  <data v-if="oi.item.isSerialized" class="flex gap-2 flex-wrap">
                    <small v-for="ois in oi.orderItemSerials" class="bg-gray-200 rounded-md px-1">{{ ois.itemSerial?.number }}</small>
                  </data>
                  <small>{{ oi.notes }}</small>
                </td>

                <td>{{ oi.quantity }}</td>
                <td>
                  {{ formatDinero(oi.itemSalePriceDinero) }}
                </td>
                <td>{{ formatDinero(oi.itemTotal) }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- payment info -->
        <div class="text-sm flex flex-col w-full items-end">
          <div class="flex-col w-5/12 mr-10">
            <div class="px-4 w-full mt-2">
              <table
                class="payment-table"
                :class="{ 'ar-receipt': $ctx.receiptLanguage == 'ar' }"
              >
                <tr>
                  <td>
                    <p class="p-0">
                      {{
                        receiptTranslate(
                          "Total Before Discounts",
                          "receipt.totalBeforeDiscounts"
                        )
                      }}
                    </p>
                  </td>
                  <td>
                    <p class="text-right">
                      {{
                        formatDinero(order.invoice?.totalBeforeDiscountsDinero)
                      }}
                    </p>
                  </td>
                </tr>

                <tr>
                  <td>
                    <p class="p-0">
                      {{
                        receiptTranslate(
                          "Total Discount",
                          "receipt.totalDiscount"
                        )
                      }}
                    </p>
                  </td>
                  <td>
                    <p class="text-right">
                      {{ formatDinero(order.invoice?.totalDiscountDinero) }}
                    </p>
                  </td>
                </tr>

                <tr>
                  <td>
                    <p class="p-0">
                      {{ receiptTranslate("Total Due", "receipt.totalDue") }}
                    </p>
                  </td>
                  <td>
                    <p class="text-right">
                      {{ formatDinero(order.invoice?.totalDueDinero) }}
                    </p>
                  </td>
                </tr>

                <tr>
                  <td>
                    <p class="p-0">
                      {{
                        receiptTranslate(
                          "Total Items Sold",
                          "receipt.totalItemsSold"
                        )
                      }}
                    </p>
                  </td>
                  <td>
                    <p class="text-right">
                      {{ calculateTotalItemsSold() }}
                    </p>
                  </td>
                </tr>
              </table>
            </div>

            <div class="w-full p-2"></div>

            <div class="px-4 w-full mt-2" v-if="transactions.length">
              <div style="color: #6e6e6e; padding-left: 2px">
                <h4 class="text-lg">
                  {{
                    receiptTranslate("Payments Info", "receipt.paymentsInfo")
                  }}
                </h4>
              </div>

              <div
                v-for="customerPayment in transactions"
                :key="customerPayment.id"
              >
                <table
                  class="payment-table"
                  :class="{ 'ar-receipt': $ctx.receiptLanguage == 'ar' }"
                >
                  <tr>
                    <td>
                      <p class="p-0">
                        {{
                          receiptTranslate("Payment ID", "receipt.paymentID")
                        }}: {{ customerPayment.externalID }}
                      </p>
                    </td>
                    <td>
                      <p class="text-right">
                        {{ parseFormat(customerPayment.createdAt) }}
                      </p>
                    </td>
                  </tr>
                </table>

                <table
                  class="payment-table"
                  :class="{ 'ar-receipt': $ctx.receiptLanguage == 'ar' }"
                >
                  <tr v-if="customerPayment.receiptType != 'return'">
                    <td>
                      {{
                        receiptTranslate(
                          "Payment Method",
                          "receipt.paymentMethod"
                        )
                      }}
                    </td>
                    <td>
                      <p class="text-right">
                        {{
                          customerPayment.paymentMethod === "debit-card"
                            ? "E-Payment"
                            : "Cash"
                        }}
                      </p>
                    </td>
                  </tr>

                  <tr
                    v-if="
                      customerPayment.receiptType != 'return' &&
                        customerPayment.paymentMethod === 'cash'
                    "
                  >
                    <td>{{ receiptTranslate("Cash", "receipt.cash") }}</td>
                    <td>
                      <p class="text-right">
                        {{
                          formatDinero(customerPayment.paidBeforeChangeDinero)
                        }}
                      </p>
                    </td>
                  </tr>

                  <tr
                    v-if="customerPayment.multiCurrencyBreakdown?.length === 2 && customerPayment.multiCurrencyBreakdown[1].amount > 0"
                  >
                    <td>
                      {{ receiptTranslate("Payment Currencies", "receipt.paymentCurrencies") }}
                    </td>
                    <td>
                      <p
                        class="text-right"
                      >
                        {{formatMoney(customerPayment.multiCurrencyBreakdown[0].amount, customerPayment.multiCurrencyBreakdown[0].currency)}} + {{formatMoney(customerPayment.multiCurrencyBreakdown[1].amount, customerPayment.multiCurrencyBreakdown[1].currency)}}
                      </p>
                    </td>
                  </tr>

                  <tr
                    v-if="
                      customerPayment.receiptType != 'return' &&
                        customerPayment.paymentMethod === 'cash'
                    "
                  >
                    <td>
                      {{ receiptTranslate("Change Due", "receipt.changeDue") }}
                    </td>
                    <td>
                      <p class="text-right">
                        {{ formatDinero(customerPayment.changeDueDinero) }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ receiptTranslate("Total Paid", "receipt.totalPaid") }}
                    </td>
                    <td>
                      <p
                        v-if="customerPayment.receiptType != 'return'"
                        class="text-right"
                      >
                        {{ formatDinero(customerPayment.amountDinero) }}
                      </p>
                      <p
                        v-if="customerPayment.receiptType == 'return'"
                        class="text-right"
                      >
                        ({{ formatDinero(customerPayment.amountDinero) }})
                      </p>
                    </td>
                  </tr>

                  <tr v-if="customerPayment.receiptType != 'return'">
                    <td>
                      {{ receiptTranslate("Balance", "receipt.balance") }}
                    </td>
                    <td>
                      <p class="text-right">
                        {{ formatDinero(customerPayment.balanceDinero) }}
                      </p>
                    </td>
                  </tr>
                  <tr v-if="customerPayment.receiptType != 'return'">
                    <td>
                      {{
                        receiptTranslate(
                          "Payment Notes",
                          "receipt.paymentNotes"
                        )
                      }}
                    </td>
                    <td>
                      <p class="text-right">
                        {{ customerPayment.description }}
                      </p>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div
        id="a4-receipt-footer"
        class="flex justify-center self-center w-full mt-auto"
      >
        <div
          class="flex items-center justify-between pt-2 px-4 mt-2 mb-4"
          style="background: #EFEFEF; bottom: 0px; width: 95%;"
        >
          <p><img src="@/assets/images/print_logo.png" width="80" /></p>
          <p class="thecky-site">thecky.com</p>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import { i18nOrderMessages, Order } from "@/models/sales/Order";
import { parseFormat } from "@/utils/date";
import { useI18n } from "vue-i18n";
import { formatDinero, formatMoney } from "@/utils/money";
import { Transaction } from "@/models/accounting/Transaction";
import JsBarcode from "jsbarcode";

export default defineComponent({
  components: {},

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          ...i18nOrderMessages.en
        },
        ar: {
          ...i18nOrderMessages.ar,
          receipt: {
            salesReceipt: "فاتورة بيع",
            type: "نوع الطلب",
            direct: "مباشر",
            credit: "آجل",
            layaway: "عربون",
            customer: "الزبون",
            cashCustomer: "زبون",
            location: "المتجر",
            orderNo: "رقم الطلب",
            cashier: "موظف الكاشير",
            dateAndTime: "التاريخ والوقت",
            orderNotes: "ملاحظات الطلب",
            pouch: "رقم المحفظة",
            itemName: "اسم المنتج",
            qty: "الكمية",
            price: "السعر",
            finalPrice: "السعر النهائي",
            totalBeforeDiscounts: "المبلغ الكلي قبل التخفيض",
            totalDiscount: "المبلغ الكلي للتخفيض",
            totalDue: "الاجمالي",
            totalItemsSold: "عدد المنتجات المباعة",
            paymentsInfo: "تفاصيل المدفوعات",
            paymentID: "رقم الحركة",
            paymentMethod: "طريقة الدفع",
            cash: "كاش",
            changeDue: "المتبقي",
            totalPaid: "المبلغ المدفوع الكلي",
            balance: "الرصيد",
            paymentNotes: "ملاحظات الحركة",
            paymentCurrencies: "عملات الحركة"
          }
        }
      }
    });

    return { t };
  },
  props: {
    order: {
      type: Order,
      required: true
    },
    transactions: {
      type: Array as PropType<Array<Transaction>>,
      required: true
    },
    barcodeText: {
      type: String,
      required: true
    }
  },
  mounted() {
    setTimeout(() => {
      JsBarcode("#barcode", String(this.order.externalID!.slice()), {
        height: 50,
        displayValue: false
      });
    }, 100);
  },
  created() {
    // Add print CSS when component is created
    this.addPrintCSS();
  },
  beforeUnmount() {
    // Remove print CSS when component is about to be destroyed
    this.removePrintCSS();
  },
  methods: {
    parseFormat,
    formatDinero,
    formatMoney,
    print() {
      window.print();
    },
    calculateTotalItemsSold() {
      let total = 0;
      for (const item of this.order.orderItems) {
        total += item.quantity;
        total -= item.returnedQuantity;
      }

      return total;
    },
    addPrintCSS() {
      // Create a style element with a unique id
      const style = document.createElement("style");
      style.setAttribute("id", "print-css");
      style.textContent = `
        @media print {
          @page:first {
            size: A4;
            margin-left: 0;
            margin-right: 0;
            margin-top: 0px;
          }
  
          @page {
            size: A4;
            margin-left: 0;
            margin-right: 0;
            margin-top: 40px;
            margin-bottom: 30px;
          }
        }`;
      // Append the style to the document head
      document.head.appendChild(style);
    },
    removePrintCSS() {
      // Find and remove the style element
      const style = document.querySelector("#print-css");
      if (style && style.parentNode) {
        style.parentNode.removeChild(style);
      }
    },
    receiptTranslate(value: string, translateKey: string): string {
      return this.$ctx.receiptLanguage == "en"
        ? value
        : this.t(translateKey, this.$ctx.receiptLanguage);
    }
  }
});
</script>
<style>
.thecky-site {
  color: #000;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 3px;
}

#a4-receipt {
  width: 210mm;
  overflow: scroll;
  background-color: #fff;
}

@media print {
  body * {
    page-break-after: avoid;
    visibility: hidden;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  #a4-receipt,
  #a4-receipt * {
    visibility: visible;
  }

  #a4-receipt {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: visible;
    margin: 0cm;
  }

  #a4-receipt-header {
    top: 0;
  }

  #a4-receipt-body {
    position: relative;
  }

  p,
  div {
    page-break-inside: avoid;
  }
}
</style>
