<template>
  <simple-item-form
    :initItem="initItem"
    :successMsg="t('itemWasCreated')"
    :submitAction="submit"
    :enableClearForm="true"
    :clearFormOnSubmit="true"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import SimpleItemForm from './SimpleItemForm.vue'

// models
import Item from '@/models/inventory/Item'
import { useI18n } from 'vue-i18n'

interface Option {
  name: string
  values: string[]
}

export default defineComponent({
  components: { SimpleItemForm },
  name: 'new-item',

  setup() {
    const { t } = useI18n({
      messages: {
        en: { itemWasCreated: 'Item was created successfully' },
        ar: { itemWasCreated: 'تم انشاء المنتج بنجاح' }
      }
    })
    return { t }
  },

  data() {
    return {
      initItem: new Item()
    }
  },

  methods: {
    async submit(item: Item) {
      try {
        await this.$http.post<Item>(Item.ENDPOINT, item)
        await this.$router.push(this.$Route.INVENTORY_ITEMS)
      } catch (error) {
        return error
      }
    }
  }
})
</script>
