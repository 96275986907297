/*
 * errors are defined to be unexpected behavior that needs to be logged
 * if the user inputs a wrong email address, that's not an error that needs to be logged
 * if the server doesn't respond, then sure, that needs to be logged
 */

import { useI18nInstance } from '@/plugins/i18n'

interface DebugInfo {
  // it must include a scope
  scope: string
  // and any number of additional information
  [info: string]: unknown
}

// An error will be created by the Errors factory only
class Error {
  static i18nInstance = useI18nInstance()
  // must have a unique code, assigned by the Errors factory
  private code: number
  // description for developers
  private desc: string

  private usrMsgI18nKey: string
  // to be passed at runtime
  private usrMsgI18nValues?: Record<string, unknown>

  // the real error to bind at runtime for debugging purposes
  private debugInfo?: Record<string, unknown>

  constructor(code: number, desc: string, usrMsgI18nKey: string) {
    this.code = code
    this.desc = desc
    this.usrMsgI18nKey = usrMsgI18nKey
  }

  // sometimes we need to add runtime info to the error msg
  bindI18nValues(usrMsgI18nValues: Record<string, unknown>): Error {
    this.usrMsgI18nValues = usrMsgI18nValues
    return this
  }

  bindDebugInfo(debugInfo: DebugInfo): Error {
    this.debugInfo = debugInfo
    return this
  }

  dumpDebugInfo(): string {
    return `code: ${this.code} desc: ${this.desc} debugInfo: ${JSON.stringify(this.debugInfo)}`
  }

  // toString prints user friendly message by default
  toString() {
    const t = Error.i18nInstance.global.t

    if (this.usrMsgI18nValues) {
      return `${t('errors.system.code')} ${this.code}:  ${t(
        this.usrMsgI18nKey,
        this.usrMsgI18nValues
      )}`
    }

    return `${t('errors.system.code')} ${this.code}:  ${t(this.usrMsgI18nKey)}`
  }
}

export default {
  system: {
    SYSTEM_UNEXPECTED_ERR: new Error(1, 'unexpected error', 'errors.system.unexpectedErr'),

    // codes range [11-20]
    http: {
      UNEXPECTED_RESPONSE: new Error(
        11,
        'unexpected response from server',
        'errors.system.http.unexpectedResponse'
      ),
      SERVER_NOT_RESPONDING: new Error(
        12,
        'server failed to respond',
        'errors.system.http.serverNotResponding'
      ),
      FAILED_TO_CONNECT_TO_SERVER: new Error(
        13,
        'failed to connect to server',
        'errors.system.http.failedToConnectToServer'
      )
    }
  }
}
