<template>
  <div class="h-full">
    <div v-if="loading" class="flex items-center justify-center h-full">
      <loading-spinner diameter="64px" />
    </div>

    <alert-badge
      v-else-if="error.title"
      isDanger
      :title="error.title"
      :body="error.body"
      @dismissed="
        () => {
          error.title = ''
          error.body = ''
        }
      "
    />

    <div class="flex flex-col md:flex-row">
      <!-- cart place -->
      <div class="w-full mx-2 md:w-9/12">
        <div class="flex flex-col justify-center w-full md:flex-row">
          <el-autocomplete
            id="item"
            v-model="itemSearchTerm"
            :fetch-suggestions="searchItems"
            :prefix-icon="Search"
            value-key="name"
            :trigger-on-focus="false"
            class="w-full"
            :placeholder="t('searchItems')"
            @select="addItem"
            @keypress.enter.prevent="pressedEnter = true"
            highlight-first-item
          >
          </el-autocomplete>
        </div>

        <div class="mt-2">
          <el-table
            :data="order.orderItems"
            border
            style="width: 100%"
            v-if="!order.empty()"
            :max-height="800"
          >
            <el-table-column width="50" align="center">
              <template #default="scope">
                <span class="text-red-600 cursor-pointer" @click="order.removeItem(scope.$index)">
                  <font-awesome-icon icon="trash" />
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="item.name" :label="t('itemName')"> </el-table-column>
            <el-table-column prop="item.salePriceAmount" :label="t('price')">
              <template #default="scope">
                <span>
                  {{ formatDinero(scope.row.itemSalePriceDinero) }}
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="item.soldAtPriceAmount" :label="t('soldAtPrice')">
              <template #default="scope">
                <div class="flex justify-between">
                  <span>
                    {{ formatDinero(scope.row.soldAtPriceDinero) }}
                  </span>

                  <el-popover
                    placement="bottom"
                    :width="500"
                    trigger="click"
                    v-if="scope.row.item.discountEligible"
                  >
                    <template #reference>
                      <span
                        class="flex items-center justify-center w-5 h-5 text-sm bg-gray-300 rounded-full cursor-pointer hover:bg-gray-400"
                      >
                        <font-awesome-icon icon="percentage" />
                      </span>
                    </template>

                    <div class="flex items-center justify-center" :dir="$ctx.getDir()">
                      <div>
                        <h2>{{ t('applyDiscount') }}</h2>
                        <table class="w-full">
                          <tr>
                            <td>{{ t('currentDiscountValue') }}</td>
                            <td>
                              {{ formatDinero(scope.row.individualDiscountDinero) }}
                            </td>
                          </tr>
                        </table>

                        <span>{{ t('changeDiscountValue') }}</span>
                        <table>
                          <tr>
                            <td>{{ t('discountType') }}</td>
                            <td>
                              <el-switch
                                v-model="scope.row.discountByPercent"
                                :active-text="t('discountByPercent')"
                                :inactive-text="t('discountByMonetaryValue')"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>{{ t('discountValue') }}</td>
                            <td>
                              <el-input
                                v-if="scope.row.discountByPercent"
                                v-model.number="scope.row.percentDiscountAmount"
                                @input="() => scope.row.applyDiscountByPercent()"
                              >
                                <template #prefix>
                                  <font-awesome-icon icon="percentage" />
                                </template>
                              </el-input>
                              <money-input
                                v-else
                                v-model="scope.row.individualDiscountAmount"
                                :currency="scope.row.currency"
                                @dinero-created="
                                  (dineroVal) => {
                                    scope.row.individualDiscountDinero = dineroVal
                                    scope.row.applyDiscountByCash()
                                  }
                                "
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>{{ t('priceAfterDiscount') }}</td>
                            <td>
                              {{ formatDinero(scope.row.soldAtPriceDinero) }}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </el-popover>
                </div>
              </template>
            </el-table-column>

            <el-table-column prop="quantity" :label="t('quantity')">
              <template #default="scope">
                <div class="flex justify-between">
                  <el-input-number
                    v-if="scope.row.item.sellOutOfStock || scope.row.item.unlimitedStock"
                    v-model="scope.row.quantity"
                    :step="1"
                    size="mini"
                    :min="1"
                    controls-position="right"
                  ></el-input-number>
                  <el-input-number
                    v-else
                    v-model="scope.row.quantity"
                    :step="1"
                    size="mini"
                    :min="1"
                    :max="scope.row.item.currentLocationStock.quantity"
                    controls-position="right"
                  ></el-input-number>

                  <span
                    v-if="scope.row.item.isSerialized"
                    class="flex items-center justify-center w-6 h-6 text-sm bg-gray-300 rounded-full cursor-pointer hover:bg-gray-400"
                    @click="
                      () => {
                        insertSerialDialogVisible = true
                        currentOrderItem = scope.row
                      }
                    "
                  >
                    <font-awesome-icon icon="barcode" />
                  </span>
                </div>
              </template>
            </el-table-column>

            <el-table-column prop="itemTotal" :label="t('itemTotal')">
              <template #default="scope">
                {{ formatDinero(scope.row.itemTotal) }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <div class="w-full md:w-3/12" style="font-size: 14px !important">
        <div class="flex flex-col items-center p-3 card">
          <div v-if="!order.empty()" class="w-full" id="order-total">
            <div class="flex justify-between w-full p-3">
              <p>{{ t('totalBeforeDiscounts') }}</p>
              <p class="flex justify-end">
                {{ formatDinero(order.totalBeforeDiscount) }}
              </p>
            </div>

            <div class="flex justify-between w-full p-3 bg-red-100">
              <p>{{ t('totalIndividualDiscount') }}</p>
              <p class="flex justify-end">
                {{ formatDinero(order.totalIndividualDiscounts) }}
              </p>
            </div>

            <div class="flex justify-between w-full p-3 bg-red-100">
              <p>{{ t('totalAdditionalDiscount') }}</p>
              <span class="flex gap-1">
                <p class="flex justify-end">
                  {{ formatDinero(order.totalAdditionalDiscountDinero) }}
                </p>

                <el-popover placement="bottom" :width="500" trigger="click">
                  <template #reference>
                    <span
                      class="flex items-center justify-center w-5 h-5 text-sm bg-gray-300 rounded-full cursor-pointer hover:bg-gray-400"
                    >
                      <font-awesome-icon icon="percentage" />
                    </span>
                  </template>

                  <div
                    class="flex items-center justify-center"
                    :dir="$ctx.getDir()"
                    style="word-wrap: break-word !important"
                  >
                    <div>
                      <h2>{{ t('applyDiscount') }}</h2>
                      <table class="w-full">
                        <tr>
                          <td>{{ t('currentDiscountValue') }}</td>
                          <td>
                            {{ formatDinero(order.totalAdditionalDiscountDinero) }}
                          </td>
                        </tr>
                      </table>

                      <span>{{ t('changeDiscountValue') }}</span>
                      <table>
                        <tr>
                          <td>{{ t('discountType') }}</td>
                          <td>
                            <el-switch
                              v-model="order.discountByPercent"
                              :active-text="t('discountByPercent')"
                              :inactive-text="t('discountByMonetaryValue')"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>{{ t('discountValue') }}</td>
                          <td>
                            <el-input
                              v-if="order.discountByPercent"
                              v-model.number="order.percentDiscountAmount"
                              @input="() => order.applyDiscountByPercent()"
                            >
                              <template #prefix>
                                <font-awesome-icon icon="percentage" />
                              </template>
                            </el-input>
                            <money-input
                              v-else
                              v-model.number="order.totalAdditionalDiscountAmount"
                              :currency="order.currency"
                              @input="order.applyDiscountByCash()"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td style="word-wrap: break-word !important">
                            {{ t('total') }}
                          </td>
                          <td>
                            {{ formatDinero(order.totalAdditionalDiscountDinero) }}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </el-popover>
              </span>
            </div>

            <div class="flex justify-between w-full p-3 bg-green-100">
              <p>{{ t('total') }}</p>
              <p class="flex justify-end">
                {{ formatDinero(order.computeTotal()) }}
              </p>
            </div>
          </div>

          <div class="w-full p-2">
            <hr class="solid" />
          </div>

          <div class="flex flex-col items-center justify-center w-11/12 text-black">
            <horizontal-button
              faIcon="user"
              :title="customerName ? customerName : t('buttons.addCustomer')"
              @click="openCustomerDialog"
            />
          </div>

          <div class="w-full p-2">
            <hr class="solid" />
          </div>

          <div class="flex justify-center w-full text-black">
            <action-button
              v-if="!order.empty()"
              faIcon="forward"
              :title="t('buttons.payment')"
              @click="checkoutDrawerOpen = true"
              isSuccess
            />
          </div>

          <div class="w-full p-2">
            <hr class="solid" />
          </div>

          <div class="flex flex-col items-center w-11/12 gap-1 text-black">
            <horizontal-button v-if="!order.empty()" faIcon="print" :title="t('buttons.quote')" />
          </div>

          <div class="w-full p-2">
            <hr class="solid" />
          </div>

          <div class="flex flex-col items-center w-11/12 text-black">
            <horizontal-button faIcon="times-circle" :title="t('buttons.cancel')" isDanger />
          </div>
        </div>
      </div>

      <el-dialog
        :title="t('selectSerials')"
        v-model="insertSerialDialogVisible"
        width="30%"
        @close="closeSerialDialog"
      >
        <div class="flex items-center justify-center" :dir="$ctx.getDir()">
          <el-select
            v-if="currentOrderItem.item.id"
            filterable
            clearable
            multiple
            class="w-full"
            v-model="currentOrderItem.serialIDs"
            :multiple-limit="currentOrderItem.quantity"
          >
            <el-option
              v-for="(item, i) in currentOrderItem.item.itemStocks[0].itemSerials"
              :key="i"
              :label="item.number"
              :value="item.id"
              ref="serialInput"
            >
              <span :class="{ 'float-right': $ctx.getDir() == 'rtl' }">
                {{ item.number }}
              </span>
            </el-option>
          </el-select>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" @click="closeSerialDialog">{{
              t('buttons.confirm')
            }}</el-button>
          </span>
        </template>
      </el-dialog>

      <el-dialog
        :title="t('selectCustomer')"
        v-model="customerDialogOpen"
        width="30%"
        @close="closeCustomerDialog"
      >
        <div class="flex items-center justify-center" :dir="$ctx.getDir()">
          <el-select
            filterable
            clearable
            class="w-full"
            v-model="order.customerID"
            value-key="name"
            remote
            @change="onCustomerNameChange"
            :remote-method="searchCustomers"
          >
            <el-option
              v-for="option in customers"
              :key="option.id"
              :label="option.contactDisplayName"
              :value="option.id"
            ></el-option>
          </el-select>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" @click="closeCustomerDialog">{{
              t('buttons.confirm')
            }}</el-button>
          </span>
        </template>
      </el-dialog>

      <el-drawer
        title="Checkout"
        v-model="checkoutDrawerOpen"
        :direction="direction"
        @open="openCheckoutDialog"
        @close="inputVal = false"
      >
        <div class="p-5">
          <div class="mb-5">
            <!-- I chose to keep the below as a comment just in case we decided to use labels later on again -->
            <!-- <p class="mb-2 font-semibold">{{ t("selectPaymentType") }}</p> -->
            <el-radio-group v-model="order.paymentType">
              <el-radio-button label="direct" border> {{ t('buttons.direct') }}</el-radio-button>
              <el-radio-button label="layaway" border> {{ t('buttons.layaway') }}</el-radio-button>
              <el-radio-button label="finance" border> {{ t('buttons.finance') }}</el-radio-button>
            </el-radio-group>
          </div>

          <PaymentInput
            :totalDue="order.orderPayments[0].totalDueAmount"
            v-model:changeDue="order.orderPayments[0].changeDueAmount"
            v-model:paidBeforeChange="order.orderPayments[0].paidBeforeChangeAmount"
            v-model:totalPaid="order.orderPayments[0].totalPaidAmount"
            :currency="order.currency"
            :precision="order.precision"
          />

          <div class="flex items-center justify-between mb-2">
            <horizontal-button
              class="text-lg complete-payment"
              :title="t('buttons.completePay')"
              @click="submitOrder"
            />
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import HorizontalButton from '@/components/HorizontalButton.vue'
import ActionButton from '@/components/ActionButton.vue'
import AlertBadge from '@/components/AlertBadge.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'

// models
import { i18nOrderMessages, Order, OrderItem } from '@/models/sales/Order'
import Item from '@/models/inventory/Item'
import Customer from '@/models/customer/Customer'
import { Register, RegisterLog } from '@/models/company/Register'

// tools
import { useI18n } from 'vue-i18n'
import { localizeFieldName } from '@/plugins/i18n'

// helpers
import { formatDinero } from '@/utils/money'
import PaymentInput from '@/views/sales/components/PaymentInput.vue'
import MoneyInput from '@/components/form/MoneyInput.vue'

import { ElPopover } from 'element-plus'
import { Search } from '@element-plus/icons-vue'

export default defineComponent({
  name: 'new-sale',
  setup() {
    const { t } = useI18n({
      useScope: 'global',
      messages: {
        en: {
          ...i18nOrderMessages.en,

          searchItems: 'Enter item barcode or SKU or name',
          buttons: {
            addCustomer: 'Add Customer',
            payment: 'Payment',
            direct: 'Direct',
            layaway: 'Layaway Payment',
            finance: 'Partial',
            quote: 'Save as Quote',
            cancel: 'Cancel Order',
            confirm: 'Confirm',
            completePay: 'Complete Payment'
          },

          selectSerials: 'Select serials',
          selectCustomer: 'Select a customer',
          selectPaymentType: 'Select order type',

          outOfStockErr:
            'Item {name} is out of stock. Please update your stock before selling this item',

          successMsg: 'Sale complete',

          errPaymentMustMatch:
            "Payment must be made in full. If you'd like to pay partially, choose layaway or finance"
        },
        ar: {
          ...i18nOrderMessages.ar,

          searchItems: 'ادخل الباركود او ال SKU او اسم المنتج',
          buttons: {
            addCustomer: 'اضافة زبون',
            payment: 'دفع',
            direct: 'مباشر',
            layaway: 'الدفع بالعربون',
            finance: 'بيع بالدين',
            quote: 'الحفظ كعرض',
            cancel: 'الغاء البيع',
            confirm: 'التأكيد',
            completePay: 'اتمام الدفع'
          },

          selectSerials: 'اختر الارقام',
          selectCustomer: 'اختر زبون',
          selectPaymentType: 'اختر نوع البيع',

          outOfStockErr: 'نفذ مخزون المنتج {name}. يرجى اعادة تحديث المخزن قبل بيع المنتج.',

          successMsg: 'تم اكمال البيع',

          errPaymentMustMatch:
            'يجب ان يتم دفع المبلغ بشكل كامل. لدفع مبلغ جزئي، قم بتحويل نوع الدفع الى دين او عربون'
        }
      }
    })

    return { t, Search }
  },

  components: {
    HorizontalButton,
    ActionButton,
    MoneyInput,
    PaymentInput,
    AlertBadge,
    LoadingSpinner,
    ElPopover
  },

  async mounted() {
    // load register status
    try {
      this.loading = true
      const registerLog = await this.$http.get<RegisterLog>(
        `${Register.ENDPOINT}/status/${this.$ctx.currentRegister.id}`
      )

      this.$ctx.updateRegisterStatus(RegisterLog.from(registerLog).action)

      if (!registerLog.action || registerLog.action === 'close') {
        await this.$router.push(this.$Route.SALES_REGISTERS_OPEN_REGISTER)
      }
    } catch (error) {
      this.error.title = error.title
      this.error.body = error.body
    } finally {
      this.loading = false
    }
  },

  data() {
    const customers = new Array<Customer>()
    const customerName = ''
    const selectedIndex = 0
    const direction = 'rtl'

    return {
      order: new Order(),
      pressedEnter: false,
      itemSearchTerm: '',
      error: { title: '', body: '' },

      currentOrderItem: new OrderItem(new Item()),
      insertSerialDialogVisible: false,

      customerDialogOpen: false,
      customers,
      customerName,
      selectedIndex,

      checkoutDrawerOpen: false,
      direction,

      loading: false
    }
  },

  methods: {
    onCustomerNameChange(value: string) {
      this.selectedIndex = this.customers.findIndex((customer) => customer.id === value)
    },

    resetState() {
      this.order = new Order()
      this.pressedEnter = false
      this.itemSearchTerm = ''
      this.error = { title: '', body: '' }

      this.currentOrderItem = new OrderItem(new Item())
      this.insertSerialDialogVisible = false

      this.customerDialogOpen = false
      this.customers = new Array<Customer>()

      this.checkoutDrawerOpen = false
    },

    formatDinero,

    openSerialDialog(oi: OrderItem) {
      this.currentOrderItem = oi
      this.insertSerialDialogVisible = true
    },

    closeSerialDialog() {
      this.currentOrderItem = new OrderItem(new Item())
      this.insertSerialDialogVisible = false
    },

    async searchItems(query: string, cb: Function) {
      if (this.pressedEnter) {
        this.itemSearchTerm = ''
      }

      // if an item already exists in the table, don't do remote search
      const cachedItem = this.order.findByBarcode(query) || this.order.findBySKU(query)
      if (cachedItem) {
        this.addItem(cachedItem)
        cb([])
        return
      }

      try {
        const url = `${Item.ENDPOINT}?q=${query}`
        let items = await this.$http.get<Item[]>(url)
        items = items.map((item) => Item.from(item))

        // when entering a barcode, the scanner hits enter by default
        // so if we get back one result, we wanna append that right away
        // it makes the process much faster than make the user select by themselves
        if (this.pressedEnter && items.length === 1) {
          this.addItem(items[0])
          cb([])
        } else {
          // show them all options
          cb(items)
        }
      } catch (error) {
        this.$alertModal.showDanger({ title: error.title })
        cb([])
      }

      this.pressedEnter = false
    },

    openCustomerDialog() {
      this.$ctx.getDir() === 'rtl' ? (this.direction = 'rtl') : (this.direction = 'ltr')

      this.customerDialogOpen = true
    },

    closeCustomerDialog() {
      this.customerName = this.customers[this.selectedIndex].contactDisplayName
      this.customerDialogOpen = false
    },

    async searchCustomers(query: string) {
      try {
        const url = `${Customer.ENDPOINT}?q=${query}`
        const customers = await this.$http.get<Customer[]>(url)
        this.customers = customers.map((customer) => Customer.from(customer))
      } catch (error) {
        this.$alertModal.showDanger({ title: error.title })
        this.customers = []
      }
    },

    addItem(selected: Item) {
      this.itemSearchTerm = ''
      // don't add items that are out of stock!
      if (
        selected.currentLocationStock.quantity <= 0 &&
        !(selected.unlimitedStock || selected.sellOutOfStock)
      ) {
        this.$alertModal.showDanger({
          title: this.t('outOfStockErr', { name: selected.name })
        })
        return
      }

      const oi = this.order.addItem(selected)
      if (selected.isSerialized) {
        this.openSerialDialog(oi)
      }
    },

    openCheckoutDialog() {
      // this.order.prepareForCheckout();
      if (!this.validateOrder()) {
        this.checkoutDrawerOpen = false
      }
    },

    // having validate here simplifies things
    // since i18n is already available
    validateOrder() {
      const errors: string[] = []

      this.order.orderItems.forEach((oi) => {
        if (oi.item?.isSerialized && oi.serialIDs.length !== oi.quantity) {
          const errMsg = `${oi.item.name}: ${this.t('validation.matchingLength', {
            thisField: localizeFieldName('lenSerialNumbers', i18nOrderMessages),
            thatField: localizeFieldName('quantity', i18nOrderMessages)
          })}`
          errors.push(errMsg)
        }
      })

      if (errors.length) {
        this.error.title = this.t('validation.inputErrors')
        this.error.body = errors.join('\n')
        return false
      }

      return true
    },

    validatePayment() {
      // const totalDue = this.order.orderPayments[0].totalDueAmount;
      // const totalPaid = this.order.orderPayments[0].totalPaidAmount;

      // if (this.order.paymentType === "direct" && totalDue !== totalPaid) {
      //   this.error.title = this.t("errPaymentMustMatch");
      //   this.checkoutDrawerOpen = false;
      //   return false;
      // }

      return true
    },

    async submitOrder() {
      if (!this.validateOrder() || !this.validatePayment()) return

      // print receipt if all good
      this.error.title = ''
      this.error.body = ''

      this.order.preparePayload(
        this.$ctx.currentUser,
        this.$ctx.currentRegister,
        this.$ctx.currentLocation
      )

      try {
        const o = await this.$http.post<Order>(Order.ENDPOINT, this.order)
        this.$alertModal.showSuccess({ title: this.t('successMsg') })
        await this.$router.push(this.$Route.SALES_ORDERS_ORDER_RECEIPT.replace(':id', o.id || ''))
      } catch (error) {
        this.error.title = error.title
        this.error.body = error.body
        this.checkoutDrawerOpen = false
      }
    }
  }
})
</script>

<style sc>
.card {
  background: var(--secondary-bg-color);
}

#el-drawer__title {
  margin-bottom: 0px;
}

.complete-payment {
  background-color: #409eff;
  color: white;
}
</style>
