<template>
  <div class="m-3" style="height: 90vh">
    <div class="grid grid-cols-12 items-center content-center gap-2">
      <el-select class="input-label col-span-12 md:col-span-2" v-model="selectedValue">
        <el-option key="quickView" :label="$t('views.common.listView.quickView')" value="quickView">
        </el-option>
        <el-option key="allItems" :label="$t('views.common.listView.allItems')" value="allItems">
        </el-option>
        <el-option
          key="export"
          :label="$t('views.common.listView.selectedRows.export')"
          value="export"
        >
        </el-option>
      </el-select>

      <el-input
        :placeholder="$t('views.common.listView.searchRows')"
        class="w-full col-span-12 md:col-span-10"
        :prefix-icon="Search"
        v-model="searchTerm"
      />
    </div>

    <ag-grid-vue
      v-if="rowModelType"
      class="ag-theme-alpine mt-4 md:mt-0"
      :columnDefs="columnDefs"
      rowSelection="multiple"
      :enable-rtl="$ctx.getDir() === 'rtl'"
      style="height: 85vh"
      :rowData="rowData"
      :pagination="selectedValue == 'allItems'"
      :paginationAutoPageSize="selectedValue == 'allItems'"
      @cell-double-clicked="onCellDoubleClicked"
      :defaultColDef="defaultColDef"
      :onGridReady="onGridReady"
      :rowBuffer="rowBuffer"
      :rowModelType="rowModelType"
      :cacheBlockSize="cacheBlockSize"
      :cacheOverflowSize="cacheOverflowSize"
      :maxConcurrentDatasourceRequests="maxConcurrentDatasourceRequests"
      :infiniteInitialRowCount="infiniteInitialRowCount"
      :maxBlocksInCache="maxBlocksInCache"
    >
    </ag-grid-vue>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

// components
import { AgGridVue } from 'ag-grid-vue3'
import { ColDef, GridApi, GridOptions, IGetRowsParams, ModuleRegistry } from 'ag-grid-community'
import actionDropdown from '@/views/accounting/customers/CustomerInvoiceMakePayment.vue'

import { i18nOrderMessages, OrderPayment } from '@/models/sales/Order'
import { useI18n } from 'vue-i18n'
import { parseFormat } from '@/utils/date'
import { formatDinero } from '@/utils/money'
import Dinero, { Currency } from 'dinero.js'
import { CustomerInvoice, CustomerInvoicePaginated } from '@/models/sales/CustomerInvoice'
import { Search } from '@element-plus/icons-vue'
import { onBeforeMount } from 'vue'

import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model'
import { ref } from 'vue'
ModuleRegistry.registerModules([InfiniteRowModelModule])


export default defineComponent({
  name: 'customers',

  setup() {
    const { t } = useI18n({
      messages: {
        ...i18nOrderMessages,
        en: {
          customer: 'Customer',
          invoiceNo: 'Invoice #',
          invoiceDate: 'Invoice Date',
          dueDate: 'Due Date',
          type: 'Type',
          amountPaid: 'Amount Paid',
          balance: 'Balance',
          action: 'Action',
          total: 'Total',
          newCustomer: 'New Customer',
          cashCustomer: 'Cash Customer'
        },
        ar: {
          customer: 'الزبون',
          invoiceNo: 'رقم الفاتورة',
          invoiceDate: 'تاريخ الفاتورة',
          dueDate: 'تاريخ الاستحقاق',
          type: 'نوع الفاتورة',
          amountPaid: 'المبلغ المدفوع',
          balance: 'الرصيد',
          action: 'العمليات',
          total: 'المجموع',
          newCustomer: 'زبون جديد',
          cashCustomer: 'زبون'
        }
      },
      useScope: 'global'
    })

    const rowBuffer = ref<any>(null)
    const rowSelection = ref<any>(null)
    const rowModelType = ref<any>(null)
    const cacheBlockSize = ref<any>(null)
    const cacheOverflowSize = ref<any>(null)
    const maxConcurrentDatasourceRequests = ref<any>(null)
    const infiniteInitialRowCount = ref<any>(null)
    const maxBlocksInCache = ref<any>(null)

    onBeforeMount(() => {
      rowBuffer.value = 0
      rowSelection.value = 'multiple'
      rowModelType.value = 'infinite'
      cacheBlockSize.value = 100
      cacheOverflowSize.value = 100
      maxConcurrentDatasourceRequests.value = 2
      infiniteInitialRowCount.value = 0
      maxBlocksInCache.value = 10
    })


    return {
      t,
      Search,
      rowBuffer,
      rowSelection,
      rowModelType,
      cacheBlockSize,
      cacheOverflowSize,
      maxConcurrentDatasourceRequests,
      infiniteInitialRowCount,
      maxBlocksInCache
    }
  },

  data() {
    const columnDefs: ColDef[] = []
    const rowData: CustomerInvoice[] = []
    const gridOptions: GridOptions = {}
    const defaultColDef: ColDef = {}

    return {
      columnDefs,
      rowData,
      gridOptions,
      defaultColDef,

      searchTerm: '',
      selectedValue: 'quickView',
      gridApi: undefined as GridApi | undefined
    }
  },

  components: {
    AgGridVue
  },

  beforeMount() {
    this.defaultColDef = {
      sortable: false,
      filter: false,
      resizable: true,
      minWidth: 150
    }

    this.columnDefs = [
      {
        field: '-',
        hide: true,
        lockVisible: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          newRowsAction: 'keep'
        }
      },
      {
        headerName: this.t('customer'),
        field: 'customer.contactDisplayName',
        valueGetter: (params) => {
          const ci = params.node?.data as CustomerInvoice
          if (!ci) return ''
          if (!ci.customer) {
            return this.t('cashCustomer')
          }
          return ci.customer?.contactDisplayName
        },
        cellStyle: { textAlign: 'center' },
        checkboxSelection: true,
        headerCheckboxSelection: true
      },
      {
        headerName: this.t('invoiceNo'),
        field: 'externalID',
        cellStyle: { textAlign: 'center' }
      },
      {
        headerName: this.t('invoiceDate'),
        field: 'createdAt',
        valueFormatter: (params) => {
          return parseFormat(params.value)
        },
        cellStyle: { textAlign: 'center' }
      },
      {
        headerName: this.t('dueDate'),
        field: 'billDueDate',
        valueFormatter: (params) => {
          return parseFormat(params.value)
        },
        cellStyle: { textAlign: 'center' }
      },
      {
        headerName: this.t('type'),
        field: 'paymentType',
        cellStyle: { textAlign: 'center' }
      },
      {
        headerName: this.t('total'),
        field: 'totalDueAmount',
        valueFormatter: (params) => {
          const invoice = params.node?.data as CustomerInvoice
          if (!invoice) return '';
          return formatDinero(invoice.totalDueDinero)
        },
        cellStyle: { textAlign: 'center' }
      },
      {
        headerName: this.t('amountPaid'),
        field: 'totalPaidAmount',

        valueFormatter: ({ data }) => {
          const invoice = data as CustomerInvoice
          if (!invoice) return '';
          const dineroValue = Dinero({
            amount: invoice.totalPaidAmount,
            currency: invoice.currency as Currency,
            precision: invoice.precision
          })
          return formatDinero(dineroValue)
        },
        cellStyle: { textAlign: 'center' }
      },
      {
        headerName: this.t('balance'),

        valueFormatter: ({ data }) => {
          const invoice = data as CustomerInvoice
          if (!invoice) return '';
          const dineroValue = Dinero({
            amount: invoice.totalBalanceAmount,
            currency: invoice.currency as Currency,
            precision: invoice.precision
          })
          return formatDinero(dineroValue)
        },
        cellStyle: { textAlign: 'center' }
      },
      {
        headerName: this.t('action'),
        //field: "action",
        cellRenderer: actionDropdown
      }
    ]
  },

  mounted() {
    this.gridApi?.sizeColumnsToFit()
  },

  watch: {
    searchTerm() {
      this.filterItems()
    },
    async selectedValue(newValue, oldValue) {
      if (oldValue == 'delete' || oldValue == 'export') {
        return
      }

      if (this.selectedValue === 'delete' || this.selectedValue === 'export') {
        const selected = this.gridApi?.getSelectedRows()

        if (selected?.length) {
          if (this.selectedValue === 'export') {
            this.gridApi?.exportDataAsCsv({ onlySelected: true })
            this.$alertModal.showSuccess({
              title: this.$t('views.common.listView.selectedRows.exportSuccess')
            })
          }

          // deselect
          selected.length = 0
        }
        this.selectedValue = oldValue
      } else if (this.selectedValue == 'allItems') {
        this.rowModelType = null
        this.defaultColDef.sortable = true
        this.defaultColDef.filter = true
        setTimeout(() => {
          this.rowModelType = 'clientSide'
        }, 100)
      } else if (this.selectedValue == 'quickView') {
        this.rowModelType = null
        this.defaultColDef.sortable = false
        this.defaultColDef.filter = false
        setTimeout(() => {
          this.rowModelType = 'infinite'
        }, 100)
      }
    }
  },

  methods: {
    async updateRowData() {
      try {
        this.gridApi?.showLoadingOverlay()
        const invoicesRes = await this.$http.get<CustomerInvoice[]>(`${CustomerInvoice.ENDPOINT}`)

        this.rowData = invoicesRes.map((invoice) => CustomerInvoice.from(invoice))
        this.gridApi?.setGridOption('rowData', this.rowData)
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        })
      }

      // this.gridApi?.hideOverlay()
    },

    getDatasource() {
      const dataSource = {
        rowCount: undefined,
        getRows: async (params: IGetRowsParams) => {
          this.gridApi?.showLoadingOverlay()

          let url = `${CustomerInvoice.ENDPOINT}?startRow=${params.startRow}&endRow=${params.endRow}`
          if (params.sortModel.length) {
            url += `&colId=${params.sortModel[0].colId}&sort=${params.sortModel[0].sort}`
          }

          if (Object.keys(params.filterModel).length) {
            url += `&query=${params.filterModel['-'].filter}`
          }

          const res = await this.$http.get<CustomerInvoicePaginated>(url)
          this.rowData = res.data.map((order) => CustomerInvoice.from(order))
          this.gridApi?.hideOverlay()

          const lastRow = res.total
          // call the success callback
          params.successCallback(this.rowData, lastRow)
        }
      }
      return dataSource
    },

    async onGridReady(params: any) {
      this.gridApi = params.api
      if (this.selectedValue == 'quickView') {
        this.gridApi?.setGridOption('datasource', this.getDatasource())
        this.gridApi?.setGridOption('serverSideSortOnServer', true)
      } else if (this.selectedValue == 'allItems') {
        this.gridApi?.setGridOption('serverSideSortOnServer', false)
        this.updateRowData()
      }
    },

    calculateAmountPaid(orderPayments: OrderPayment[]) {
      return orderPayments.reduce((acc, orderPayment) => acc + orderPayment.totalPaidAmount, 0)
    },

    filterItems() {
      if (this.rowModelType == 'infinite') {
        this.gridApi?.setFilterModel({ '-': { filter: this.searchTerm } })
        return
      }
      this.gridApi?.setQuickFilter(this.searchTerm)
    },

    onCellDoubleClicked(event: any) {
      let customerID = 'cash-customer'
      if (event.data.customer) {
        customerID = event.data.customerID
      }
      this.$router.push(this.$Route.ACCOUNTING_CUSTOMER_INVOICES.replace(':id', customerID))
    }
  }
})
</script>

<style></style>
