import View from '@/plugins/navigation/View'
import { FixedSizeStack } from '@/models/data_structures/FixedSizeStack'

/*
- a Tab has a View that it displays

- along with a selectedNavIndex, which is the index
of the selected NavItem on the side navigation

- isSelected is used to change the style of the Tab in the Tab bar.
*/

/**
 * @deprecated This class should not be used
 */
export default class Tab {
  view: View
  isSelected: boolean
  history: FixedSizeStack<View>

  constructor(view: View, isSelected = true) {
    this.view = view
    this.isSelected = isSelected

    // initial state
    // we want to keep track of the 20 most recent views, and no more
    // it's not infinite because it could grow in memory
    this.history = new FixedSizeStack(20)
    this.history.push(view)
  }

  pushView(view: View) {
    if (!this.view.equals(view)) {
      this.history.push(view)
    }
  }

  popView() {
    if (this.history.size() > 1) {
      this.history.pop()
      this.view = this.history.peek()
    }
  }
}
