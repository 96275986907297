<template>
  <div
    class="w-full h-screen flex flex-col items-center justify-center"
    id="login-form"
    :dir="$ctx.getDir()"
  >
    <img @click="onlogoClicked()" src="@/assets/logo.png" class="h-2/6" />
    <el-form
      class="flex w-full items-center flex-col"
      ref="login"
      @submit.prevent.stop="login('login')"
      :model="creds"
      :rules="rules"
      label-position="top"
    >
      <!-- @submit errors -->
      <alert-badge
        class="w-5/6 md:w-4/6 lg:w-3/6"
        isDanger
        :title="error.title"
        :body="error.body"
        @dismissed="
          () => {
            error.title = ''
            error.body = ''
          }
        "
      />

      <input-section class="w-5/6 md:w-2/6 lg:w-1/6">
        <el-form-item :label="t('username')" prop="username" class="input-label" dir="ltr">
          <el-input id="username" v-model="creds.username" required></el-input>
        </el-form-item>

        <el-form-item :label="t('password')" prop="password" class="input-label">
          <el-input
            id="password"
            v-model="creds.password"
            required
            type="password"
            show-password
            dir="ltr"
          >
          </el-input>
        </el-form-item>
      </input-section>

      <input-section class="flex justify-center w-5/6 md:w-2/6 lg:w-1/6">
        <horizontal-button :title="t('submit')" @click="login('login')" />
      </input-section>

      <el-dialog v-model="showDevModeDialog" title="Dev Mode">
        <el-form>
          <el-form-item class="w-full" label="API Url">
            <el-input
              placeholder="http://127.0.0.1:3001/api/v1"
              v-model="apiUrl"
              autocomplete="off"
            />
            <small>example: http://127.0.0.1:3001/api/v1</small>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="showDevModeDialog = false">Cancel</el-button>
            <el-button type="primary" @click="updateApiUrl()"> Confirm </el-button>
          </span>
        </template>
      </el-dialog>
    </el-form>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from "vue";

import HorizontalButton from "@/components/HorizontalButton.vue";
import InputSection from "@/components/form/InputSection.vue";
import AlertBadge from "@/components/AlertBadge.vue";

import { useI18n } from "vue-i18n";
import { useAuth } from "@/plugins/auth";
import { ElDialog, ElForm } from "element-plus";
import { Machine } from "@/models/company/Machine";
import { grantPermission } from "@/plugins/access-control";
import Location from "@/models/company/Location";

// When using the Tauri API npm package:

export default defineComponent({
  name: "login",
  components: {
    HorizontalButton,
    InputSection,
    AlertBadge,
    ElDialog
  },

  beforeCreate() {
    const { resetAuthState } = useAuth();
    resetAuthState();
  },

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          username: "Username",
          password: "Password",
          submit: "Login"
        },
        ar: {
          username: "اسم المستخدم",
          password: "كلمة المرور",
          submit: "تسجيل الدخول"
        }
      },
      useScope: "global"
    });

    const creds = reactive({
      username: "",
      password: ""
    });
    const error = reactive({ title: "", body: "" });

    const rules = {
      username: [{ required: true, message: t("validation.required") }],
      password: [{ required: true, message: t("validation.required") }]
    };

    const showDevModeDialog = ref(false);
    const logoClickedCount = ref(0);
    const apiUrl = ref(localStorage.getItem("api_url"));

    return { t, creds, error, rules, showDevModeDialog, logoClickedCount, apiUrl };
  },
  methods: {
    async login(formName: string) {
      const form = this.$refs[formName] as typeof ElForm;
      const { userLogin } = useAuth();

      form?.validate(async (valid: boolean) => {
        if (!valid) {
          return false;
        }

        /**
         * The machineID depends on where and when are we accessing the app
         * From desktop -> can get hardware ID through Tauri
         *      -> Machine added to the system? great!
         *      -> Otherwise, we will show the user a prompt where they can add the machine.
         * From browser -> we instead retrieve the virtual machine ID and set it.
         */

        try {
          this.$loadingModal.show();
          const user = await userLogin(this.creds);
          user.userPermissions.forEach((up) => {
            grantPermission(up.permission.action, up.permission.object);
          });

          const machineGUID = this.$ctx.machineGUID;

          const machine = await this.$http.get<Machine>(
            `${Machine.ENDPOINT}?machine_guid=${machineGUID ? machineGUID : "virtual"}`
          );
          const m = Machine.from(machine);
          this.$ctx.setMachine(m);
          this.$ctx.setRegister(m.register);
          const location = await this.$http.get<Location>(`/company/locations/${m.locationID}`)
          this.$ctx.setLocation(Location.from(location))

        } catch (err) {
          // Happens in case it's desktop and we're the machine has not been added
          if (err.code === 404) {
            const machine = await this.$http.get<Machine>(
              `${Machine.ENDPOINT}?machine_guid=virtual`
            );
            const m = Machine.from(machine);
            this.$ctx.setMachine(m);
            this.$ctx.setRegister(m.register);
            const location = await this.$http.get<Location>(`/company/locations/${m.locationID}`)
            this.$ctx.currentLocation = Location.from(location)
          } else {
            this.error.title = err.title;
            this.error.body = err.body;
            return false;
          }
        } finally {
          this.$loadingModal.hide();
        }

        await this.$router.replace("/");
      });
    },
    onlogoClicked() {
      this.logoClickedCount += 1;
      if (this.logoClickedCount > 6) {
        this.showDevModeDialog = true;
      }
    },
    updateApiUrl() {
      localStorage.setItem("api_url", String(this.apiUrl));
      this.showDevModeDialog = false;
      window.location.reload();
    }
  }
});
</script>

<style scoped></style>
