<template>
  <div class="m-3" style="height: 90vh">
    <div class="grid grid-cols-12 items-center content-center gap-2">
      <el-select
        class="input-label col-span-12 md:col-span-2"
        v-model="selectedValue"
      >
        <el-option key="quickView" :label="$t('views.common.listView.quickView')" value="quickView">
        </el-option>
        <el-option key="allItems" :label="$t('views.common.listView.allItems')" value="allItems">
        </el-option>
        <el-option key="deletedItems" :label="$t('views.common.listView.deletedItems')" value="deletedItems">
        </el-option>
        <el-option
          key="export"
          :label="$t('views.common.listView.selectedRows.export')"
          value="export"
        >
        </el-option>

        <el-option
          v-if="selectedValue != 'deletedItems'"
          key="delete"
          :label="$t('views.common.listView.selectedRows.delete')"
          value="delete"
        >
        </el-option>
        <el-option
          v-if="selectedValue == 'deletedItems'"
          key="restore"
          :label="$t('views.common.listView.selectedRows.restore')"
          value="restore"
        >
        </el-option>
      </el-select>

      <el-input
        :placeholder="$t('views.common.listView.searchRows')"
        class="w-full col-span-12 md:col-span-8"
        :prefix-icon="Search"
        v-model="searchTerm"
      />

      <div class="w-full col-span-12 md:col-span-2">
        <template v-if="$can('create', '/examination/examinations')">
          <horizontal-button
            :title="$t('views.examinations.newExamination')"
            @click.prevent="$router.push($Route.EXAMINATIONS_NEW_EXAMINATION)"
          />
        </template>
      </div>
    </div>

    <ag-grid-vue
      v-if="rowModelType"
      class="ag-theme-alpine mt-4 md:mt-0"
      :columnDefs="columnDefs"
      rowSelection="multiple"
      :enable-rtl="$ctx.getDir() === 'rtl'"
      style="height: 85vh"
      @cell-double-clicked="onCellDoubleClicked"
      :defaultColDef="defaultColDef"
      :pagination="selectedValue == 'allItems'"
      :paginationAutoPageSize="selectedValue == 'allItems'"
      :onGridReady="onGridReady"
      :rowBuffer="rowBuffer"
      :rowModelType="rowModelType"
      :cacheBlockSize="cacheBlockSize"
      :cacheOverflowSize="cacheOverflowSize"
      :maxConcurrentDatasourceRequests="maxConcurrentDatasourceRequests"
      :infiniteInitialRowCount="infiniteInitialRowCount"
      :maxBlocksInCache="maxBlocksInCache"
    >
    </ag-grid-vue>

    <div class="mt-2">{{ $t('views.common.listView.count') }}: {{ count }}</div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount } from 'vue'

// components
import { AgGridVue } from 'ag-grid-vue3'
import {
  ColDef,
  GridApi,
  GridOptions,
  IGetRowsParams,
  InfiniteRowModelModule,
  ModuleRegistry
} from 'ag-grid-community'
import HorizontalButton from '@/components/HorizontalButton.vue'

import Examination, {
  ExaminationPaginated,
  examinationI18nMessages
} from '@/models/customer/Examination'
import { useI18n } from 'vue-i18n'
import { formatPostgresDate, parseFormat } from '@/utils/date'
import { customerI18nMessages } from '@/models/customer/Customer'
import ExaminationCellRenderer from '@/views/examinations/ExaminationCellRenderer.vue'
import { Search } from '@element-plus/icons-vue'
import { ref } from 'vue'
ModuleRegistry.registerModules([InfiniteRowModelModule])

export default defineComponent({
  name: 'examinations',

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          ...customerI18nMessages.en,
          ...examinationI18nMessages.en
        },
        ar: {
          ...customerI18nMessages.ar,
          ...examinationI18nMessages.ar
        }
      }
    })

    return { t, Search }
  },

  data() {
    const columnDefs: ColDef[] = []
    const rowData: Examination[] = []
    const gridOptions: GridOptions = {}
    const defaultColDef: ColDef = {}

    const rowBuffer = ref<any>(null)
    const rowSelection = ref<any>(null)
    const rowModelType = ref<any>(null)
    const cacheBlockSize = ref<any>(null)
    const cacheOverflowSize = ref<any>(null)
    const maxConcurrentDatasourceRequests = ref<any>(null)
    const infiniteInitialRowCount = ref<any>(null)
    const maxBlocksInCache = ref<any>(null)

    onBeforeMount(() => {
      rowBuffer.value = 0
      rowSelection.value = 'multiple'
      rowModelType.value = 'infinite'
      cacheBlockSize.value = 100
      cacheOverflowSize.value = 100
      maxConcurrentDatasourceRequests.value = 2
      infiniteInitialRowCount.value = 0
      maxBlocksInCache.value = 10
    })

    return {
      columnDefs,
      rowData,
      gridOptions,
      defaultColDef,

      searchTerm: '',
      selectedValue: 'quickView',
      gridApi: undefined as GridApi | undefined,
      rowBuffer,
      rowSelection,
      rowModelType,
      cacheBlockSize,
      cacheOverflowSize,
      maxConcurrentDatasourceRequests,
      infiniteInitialRowCount,
      maxBlocksInCache,
      count: 0
    }
  },

  components: {
    AgGridVue,
    HorizontalButton
  },

  async beforeMount() {
    this.defaultColDef = {
      sortable: false,
      filter: false,
      resizable: true,
      minWidth: 150
    }

    this.columnDefs = [
      {
        field: '-',
        hide: true,
        lockVisible: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          newRowsAction: 'keep'
        }
      },
      {
        headerName: `${this.t('exam')} #`,
        field: 'externalID',
        checkboxSelection: true,
        headerCheckboxSelection: true
      },
      {
        headerName: this.t('models.common.date'),
        field: 'createdAt',
        cellStyle: { textAlign: 'center' },
        valueGetter: (params) => {
          if (!params.data) return ''
          const examination = params.node?.data as Examination
          if (examination.createdAt) {
            return formatPostgresDate(String(examination.createdAt), 'DD/MM/YYYY')
          }
          return ''
        }
      },
      {
        headerName: this.t('models.common.time'),
        field: 'createdAt',
        cellStyle: { direction: 'ltr !important', textAlign: 'center' },
        valueGetter: (params) => {
          if (!params.data) return ''
          const examination = params.node?.data as Examination
          if (examination.createdAt) {
            return formatPostgresDate(String(examination.createdAt), 'hh:mm A')
          }
          return ''
        }
      },
      {
        headerName: this.t('name'),
        field: 'customer',
        valueGetter: (params) => {
          if (!params.data) return ''
          const examination = params.node?.data as Examination
          if (examination.customer) {
            return `${examination.customer?.firstName} ${examination.customer?.lastName}`
          }
          return ''
        }
      },
      {
        headerName: this.t('optometrist'),
        field: 'optometrist',
        valueGetter: (params) => {
          if (!params.data) return ''
          const examination = params.node?.data as Examination
          if (examination.examinator) {
            return `${examination.examinator?.firstName} ${examination.examinator?.lastName}`
          }
          return ''
        }
      },
      {
        cellRenderer: ExaminationCellRenderer,
        cellRendererParams: {
          onPrintBtnClicked: this.onPrintBtnClicked
        }
      }
    ]

    try {
      this.count = (
        await this.$http.get<{ count: number }>('/helpers/count?model=examinations')
      ).count
    } catch (error) {
      this.$alertModal.showDanger({ title: error.title, body: error.body })
    }
  },

  mounted() {
    this.gridApi?.sizeColumnsToFit()
  },

  watch: {
    searchTerm() {
      this.filterResult()
    },
    async selectedValue(newValue, oldValue) {
      if (oldValue == 'delete' || oldValue == 'export' || oldValue == 'restore') {
        return
      }

      if (this.selectedValue === 'delete' || this.selectedValue === 'export' || this.selectedValue === 'restore') {
        const selected = this.gridApi?.getSelectedRows()

        if (selected?.length) {
          if (this.selectedValue === 'delete') {
            // send request to archive
            await this.delete(selected)
            if (oldValue == 'quickView') {
              this.gridApi?.refreshInfiniteCache();
            } else {
              await this.updateRowData()
            }
          } else if (this.selectedValue === 'restore') {
            // send request to archive
            await this.restoreItems(selected)
            await this.updateRowData(oldValue == 'deletedItems')
          } else {
            this.gridApi?.exportDataAsCsv({ onlySelected: true })
            this.$alertModal.showSuccess({
              title: this.$t('views.common.listView.selectedRows.exportSuccess')
            })
          }

          // deselect
          selected.length = 0
        }
        this.selectedValue = oldValue
      } else if (this.selectedValue == 'allItems') {
        this.rowModelType = null
        this.defaultColDef.sortable = true
        this.defaultColDef.filter = true
        setTimeout(() => {
          this.rowModelType = 'clientSide'
        }, 100)
      } else if (this.selectedValue == 'quickView') {
        this.rowModelType = null
        this.defaultColDef.sortable = false
        this.defaultColDef.filter = false
        setTimeout(() => {
          this.rowModelType = 'infinite'
        }, 100)
      } else if (this.selectedValue == 'deletedItems') {
        this.rowModelType = null
        this.defaultColDef.sortable = true
        this.defaultColDef.filter = true
        setTimeout(() => {
          this.rowModelType = 'clientSide'
        }, 100)
      }
    }
  },

  methods: {
    onPrintBtnClicked(action: string, row: Examination) {
      if (action == 'id_card') {
        /* eslint-disable */
        this.$router.push({ name: 'examination-id-print', params: { examination_id: row.id } })
      } else if (action == 'print') {
        /* eslint-disable */
        this.$router.push({ name: 'examination-receipt', params: { examination_id: row.id } })
      }
    },
    async updateRowData(softDeleted: boolean = false) {
      this.gridApi?.showLoadingOverlay()

      try {
        const data = await this.$http.get<Examination[]>(Examination.ENDPOINT)
        if (softDeleted) {
          this.rowData = data.map((examinaton) => Examination.from(examinaton)).filter((examinaton) => examinaton.deletedAt != null)
        } else {
          this.rowData = data.map((examinaton) => Examination.from(examinaton)).filter((examinaton) => examinaton.deletedAt == null)
        }
        this.gridApi?.setGridOption('rowData', this.rowData)
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        })
      }

      this.gridApi?.hideOverlay()
    },
    getDatasource() {
      const dataSource = {
        rowCount: undefined,
        getRows: async (params: IGetRowsParams) => {
          this.gridApi?.showLoadingOverlay()

          let url = `${Examination.ENDPOINT}?startRow=${params.startRow}&endRow=${params.endRow}`
          if (params.sortModel.length) {
            url += `&colId=${params.sortModel[0].colId}&sort=${params.sortModel[0].sort}`
          }

          if (Object.keys(params.filterModel).length) {
            url += `&query=${params.filterModel['-'].filter}`
          }

          const res = await this.$http.get<ExaminationPaginated>(url)
          this.rowData = res.data.map((examination) => Examination.from(examination))
          this.gridApi?.hideOverlay()

          const lastRow = res.total
          // call the success callback
          params.successCallback(this.rowData, lastRow)
        }
      }
      return dataSource
    },

    async onGridReady(params: any) {
      this.gridApi = params.api
      if (this.selectedValue == 'quickView') {
        this.gridApi?.setGridOption('datasource', this.getDatasource())
        this.gridApi?.setGridOption('serverSideSortOnServer', true)
      } else if (this.selectedValue == 'allItems') {
        this.gridApi?.setGridOption('serverSideSortOnServer', false)
        this.updateRowData()
      } else if (this.selectedValue == 'deletedItems') { 
        this.gridApi?.setGridOption('serverSideSortOnServer', false)
        await this.updateRowData(true)
      }
    },
    async delete(examinations: Examination[]) {
      if (confirm(this.$t('views.common.listView.selectedRows.deleteConfirm'))) {
        try {
          await this.$http.delete(Examination.ENDPOINT, { data: examinations })
          this.$alertModal.showSuccess({
            title: this.$t('views.common.listView.selectedRows.deleteSuccess')
          })
        } catch (error) {
          this.$alertModal.showDanger({
            title: error.title,
            body: error.body
          })
        }
      }
    },
    async restoreItems(examinations: Examination[]) {
      if (confirm(this.$t('views.common.listView.selectedRows.restoreConfirm'))) {
        try {
          await this.$http.post('/helpers/restore-deleted', { data: examinations, table: 'examinations' })
          this.$alertModal.showSuccess({
            title: this.$t('views.common.listView.selectedRows.restoreSuccess')
          })
        } catch (error) {
          this.$alertModal.showDanger({
            title: error.title,
            body: error.body
          })
        }
      }
    },
    filterResult() {
      if (this.rowModelType == 'infinite') {
        this.gridApi?.setFilterModel({ '-': { filter: this.searchTerm } })
        return
      }
      this.gridApi?.setQuickFilter(this.searchTerm)
    },
    onCellDoubleClicked(event: any) {
      this.$router.push(
        this.$Route.EXAMINATIONS_EXAMINATION_VIEW.replace(':examination_id', event.data.id)
      )
    }
  }
})
</script>

<style></style>
