import Timestamps from './Timestamps'

export const nilUUID = '00000000-0000-0000-0000-000000000000'

export default class UUIDBase extends Timestamps {
  id: string | undefined = undefined
  externalID: string | undefined = undefined

  constructor() {
    super()
    this.parseTime(this.createdAt, this.updatedAt, this.deletedAt)
  }
}
