import UUIDBase from '../UUIDBase'

export default class Location extends UUIDBase {
  static from(json: Record<string, unknown> | Location) {
    return Object.assign(new Location(), json)
  }

  static ENDPOINT = '/company/locations'

  enName = ''
  arName = ''
  type = ''
  phone = ''
  streetAddress = ''
  city = ''
  postalCode = ''
  country = ''

  safeAccountID = ""
}
