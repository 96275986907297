<template>
  <div class="input-group">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'input-group'
})
</script>

<style>
h1 {
  @apply p-2 text-lg py-3 font-bold;
}

h2 {
  @apply p-2 py-3 font-bold;
}

h3 {
  @apply p-2 py-3 font-bold;
}

.input-group {
  @apply flex flex-col justify-between w-full;
}

@screen md {
  .input-group {
    @apply flex-row gap-2;
  }

  .input-group > .input-label {
    @apply w-6/12;
  }
}
</style>
