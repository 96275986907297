<template>
  <examination-form
    v-if="initCustomer"
    :initExamination="initExamination"
    :initCustomer="initCustomer"
    :successMsg="t('created')"
    :submitAction="submit"
    enableClearForm
    clearFormOnSubmit
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ExaminationForm from '@/views/examinations/ExaminationForm.vue'

import Examination from '@/models/customer/Examination'
import Customer from '@/models/customer/Customer'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'new-examination',

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          created: 'Examination was created successfully'
        },
        ar: {
          created: 'تم انشاءالفحص بنجاح'
        }
      }
    })

    return { t }
  },

  components: {
    ExaminationForm
  },
  data() {
    return {
      initExamination: new Examination(),
      initCustomer: null as Customer | null
    }
  },
  async mounted() {
    if (this.$route.query.customer_id) {
      this.initCustomer = await this.$http.get<Customer>(
        Customer.ENDPOINT + `/${this.$route.query.customer_id}`
      )
      this.initExamination.customerID = String(this.$route.query.customer_id)
    } else {
      this.initCustomer = new Customer()
    }
  },
  methods: {
    async submit(examination: Examination) {
      try {
        await this.$http.post(Examination.ENDPOINT, examination)
        await this.$router.back()
      } catch (error) {
        return error
      }
    }
  }
})
</script>

<style></style>
