<template>
  <el-drawer v-model="drawerOpen" @close="drawerOpen = false">
    <div :dir="$ctx.getDir">
      <component :is="component" />
    </div>
  </el-drawer>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'

import { useEmitter } from '@/plugins/mitt'

import { ElDrawer } from 'element-plus'

export default defineComponent({
  setup() {
    const drawerOpen = ref(false)
    const component = ref('')
    const emitter = useEmitter()

    onMounted(() => {
      emitter.on('show-drawer', (e) => {
        drawerOpen.value = true
        component.value = e.component
      })
    })

    return {
      drawerOpen,
      component
    }
  },
  components: { ElDrawer }
})
</script>

<style scoped></style>
