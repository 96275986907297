<template>
  <div class="grid grid-cols-1 m-8 bg-white shadow-md p-4 gap-4">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
      <div class="grid grid-cols-1 gap-4 w-96">
        <h2 class="col-span-1 text-lg flex items-center gap-2">
          <span class="border-black border-b-2">{{ t('customerInfo') }}</span>
          <router-link :to="editUrl">
            <font-awesome-icon icon="edit" />
          </router-link>
        </h2>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 px-4">
          <div class="font-bold">{{ t('name') }}:</div>
          <div class="col-span-1">{{ initCustomer.firstName }} {{ initCustomer.lastName }}</div>
        </div>

        <div v-if="initCustomer.businessName" class="grid grid-cols-1 md:grid-cols-2 gap-4 px-4">
          <div class="font-bold">{{ t('businessName') }}:</div>
          <div class="col-span-1">
            {{ initCustomer.businessName }}
          </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 px-4">
          <div class="font-bold">{{ t('phone') }}:</div>
          <div class="col-span-1" style="direction: ltr">
            {{ initCustomer.phone }}
          </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 px-4">
          <div class="font-bold">{{ t('phone2') }}:</div>
          <div class="col-span-1" style="direction: ltr">
            {{ initCustomer.phone2 }}
          </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 px-4">
          <div class="font-bold">{{ t('email') }}:</div>
          <div class="col-span-1">
            {{ initCustomer.email }}
          </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 px-4">
          <div class="font-bold">{{ t('address') }}:</div>
          <div class="col-span-1">
            {{ initCustomer.streetAddress }}
          </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 px-4">
          <div class="font-bold">{{ t('createdDate') }}:</div>
          <div class="col-span-1">
            {{ formatPostgresDate(initCustomer.createdAt) }}
          </div>
        </div>
      </div>
      <div class="self-start grid grid-cols-1 gap-4 w-80">
        <h2 class="col-span-1 text-lg flex items-center gap-2">
          <span class="border-black border-b-2">{{ t('purchaseHistory') }}</span>
        </h2>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 px-4">
          <div class="font-bold col-span-1">{{ t('totalPurchases') }}:</div>
          <div class="col-span-1">
            {{ initInvoices.length }}
          </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 px-4">
          <div class="font-bold col-span-1">{{ t('totalAmount') }}:</div>
          <div class="col-span-1">
            {{ toDinero(totalDueAmount, currency, precision) }}
          </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 px-4">
          <div class="font-bold col-span-1">{{ t('totalBalance') }}:</div>
          <div class="col-span-1">
            {{ toDinero(balance, currency, precision) }}
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-end">
      <div class="md:w-2/12 m-2">
        <horizontal-button
          :title="t('accountStatement')"
          @click.prevent="
            $router.push({
              name: 'account-statement',
              params: { customer_id: initCustomer.id }
            })
          "
        />
      </div>

      <div class="md:w-2/12 m-2">
        <horizontal-button
          :title="$t('actions.newExam')"
          @click.prevent="
            $router.push({
              name: 'new-examination',
              query: { customer_id: initCustomer.id }
            })
          "
        />
      </div>

      <div class="md:w-2/12 m-2">
        <horizontal-button
          :title="$t('actions.newSale')"
          @click.prevent="
            $router.push({
              path: $Route.SALES_NEW_SALE,
              query: { customer_id: initCustomer.id }
            })
          "
          isSuccess
        />
      </div>
    </div>

    <div class="col-span-full border-t-2 pt-2">
      <h2 class="col-span-1 md:col-span-2 text-lg flex items-center gap-2">
        <span class="border-black border-b-2">{{ t('purchaseHistory') }}</span>
      </h2>
      <ag-grid-vue
        class="ag-theme-alpine"
        :columnDefs="columnDefs"
        :rowData="initInvoices"
        rowSelection="multiple"
        :gridOptions="invoicesGridOptions"
        :enable-rtl="$ctx.getDir() === 'rtl'"
        style="height: 40vh"
        :pagination="true"
        :paginationAutoPageSize="true"
        :defaultColDef="defaultColDef"
        @cell-double-clicked="onOrderCellDoubleClicked"
        :onGridReady="onOrderGridReady"
      >
      </ag-grid-vue>
    </div>

    <div class="col-span-full">
      <h2 class="col-span-1 md:col-span-2 text-lg flex items-center gap-2">
        <span class="border-black border-b-2">{{ t('examinationHistory') }}</span>
      </h2>
      <ag-grid-vue
        class="ag-theme-alpine"
        :columnDefs="examinationColumnDefs"
        :rowData="initExaminations"
        :gridOptions="examinationsGridOptions"
        rowSelection="multiple"
        :enable-rtl="$ctx.getDir() === 'rtl'"
        style="height: 40vh"
        :pagination="true"
        :paginationAutoPageSize="true"
        :defaultColDef="defaultColDef"
        @cell-double-clicked="onExaminationCellDoubleClicked"
        :onGridReady="onExaminationGridReady"
      >
      </ag-grid-vue>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'

import Customer, { customerI18nMessages } from '@/models/customer/Customer'
import Examination, { examinationI18nMessages } from '@/models/customer/Examination'
import { useI18n } from 'vue-i18n'
import { CustomerInvoice } from '@/models/sales/CustomerInvoice'
import { ColDef, GridApi, GridOptions } from 'ag-grid-community'
import { AgGridVue } from 'ag-grid-vue3'
import { Route as $Route } from '@/router/routes'
import { useRoute, useRouter } from 'vue-router'
import { useHTTP } from '@/plugins/http'
import HorizontalButton from '@/components/HorizontalButton.vue'
import { formatPostgresDate, parseFormat } from '@/utils/date'
import ExaminationCellRenderer from '@/views/examinations/ExaminationCellRenderer.vue'
import Dinero, { Currency } from 'dinero.js'
import { formatDinero } from '@/utils/money'

const { t } = useI18n({
  messages: {
    en: {
      ...customerI18nMessages.en,
      ...examinationI18nMessages.en,
      customerInfo: 'Customer Info',
      type: 'Type',
      phone: 'Phone #',
      phone2: 'Phone #2',
      rep: 'Rep',
      email: 'Email',
      balance: 'Balance',
      address: 'Address',
      purchases: 'Total Purchases',
      noPhone: 'No Phone # was provided',
      noEmail: 'No Email was provided',
      noAddress: 'No Address was provided',
      receipt: 'Receipt #',
      date: 'Date',
      receiptTotal: 'Receipt Total',
      paymentType: 'Payment Type',
      cashier: 'Cashier',
      location: 'Location',
      purchaseHistory: 'Purchase History',
      examinationHistory: 'Examination History',
      totalBalance: 'Total Balance',
      totalAmount: 'Total Amount',
      totalPurchases: 'Total Purchases',
      accountStatement: 'Account Statement',
      amountPaid: 'Amount Paid',
      createdDate: 'Created Date',
    },
    ar: {
      ...customerI18nMessages.ar,
      ...examinationI18nMessages.ar,
      customerInfo: 'بيانات الزبون',
      type: 'النوع',
      phone: 'رقم الهاتف',
      phone2: 'رقم الهاتف 2',
      rep: 'Rep',
      email: 'البريد الالكتروني',
      balance: 'الرصيد',
      address: 'العنوان',
      purchases: 'عدد المشتريات الكلي',
      noPhone: 'لا يوجد رقم هاتف لعرضه',
      noEmail: 'لا يوجد بريد الكتروني لعرضه',
      noAddress: 'لا يوجد عنوان لعرضه',
      receipt: 'التسلسل',
      date: 'التاريخ',
      receiptTotal: 'المجموع الكلي',
      paymentType: 'النوع',
      cashier: 'البائع',
      location: 'الموقع',
      purchaseHistory: 'تاريخ المشتريات',
      examinationHistory: 'تاريخ الفحوصات',
      totalBalance: 'الرصيد الكلي',
      totalAmount: 'المبلغ الإجمالي',
      totalPurchases: 'أجمالي المشتريات',
      accountStatement: 'كشف الحساب',
      amountPaid: 'المبلغ المدفوع',
      createdDate: 'تاريخ الانشاء',
    }
  }
})

const invoicesGridOptions: GridOptions = {}
const examinationsGridOptions: GridOptions = {}

const columnDefs: ColDef[] = [
  {
    headerName: t('receipt'),
    field: 'externalID',
    checkboxSelection: true,
    headerCheckboxSelection: true
  },
  {
    headerName: t('date'),
    field: 'billDueDate',
    cellStyle: { textAlign: 'center' },
    valueFormatter: (params) => {
      const createdAt = new Date(params.value)
      return parseFormat(createdAt)
    }
  },
  {
    headerName: t('paymentType'),
    field: 'paymentType'
  },
  {
    headerName: t('receiptTotal'),
    valueFormatter: (params: any) => {
      const customerInvoice = params.node?.data as CustomerInvoice
      return formatDinero(customerInvoice.totalDueDinero)
    },
  },
  {
    headerName: t('amountPaid'),
    field: 'paidAmount',
    valueFormatter: (params) => {
      const customerInvoice = params.node?.data as CustomerInvoice
      return formatDinero(customerInvoice.totalPaidDinero)
    },
    cellStyle: { textAlign: 'center' }
  },
  {
    headerName: t('balance'),
    valueFormatter: (params) => {
      const customerInvoice = params.node?.data as CustomerInvoice
      return formatDinero(customerInvoice.totalBalanceDinero)
    },
  },
  {
    headerName: t('cashier'),
    valueFormatter: (params) => {
      const customerInvoice = params.node?.data as CustomerInvoice
      return customerInvoice.cashierName
    },
  },
]

function onPrintBtnClicked(action: string, row: Examination) {
  if (action == 'id_card') {
    /* eslint-disable */
    $router.push({ name: 'examination-id-print', params: { examination_id: row.id } })
  } else if (action == 'print') {
    /* eslint-disable */
    $router.push({ name: 'examination-receipt', params: { examination_id: row.id } })
  }
}

const examinationColumnDefs: ColDef[] = [
  {
    headerName: `${t('exam')} #`,
    field: 'externalID',
    checkboxSelection: true,
    headerCheckboxSelection: true
  },
  {
    headerName: t('date'),
    field: 'createdAt',
    cellStyle: { textAlign: 'center' },
    valueFormatter: (params) => {
      const createdAt = new Date(params.value)
      return parseFormat(createdAt)
    }
  },
  {
    headerName: t('optometrist'),
    field: 'optometrist',
    valueGetter: (params) => {
      const examination = params.node?.data as Examination
      if (examination.examinator) {
        return `${examination.examinator?.firstName} ${examination.examinator?.lastName}`
      }
      return ''
    }
  },
  {
    cellRenderer: ExaminationCellRenderer,
    cellRendererParams: {
      onPrintBtnClicked: onPrintBtnClicked
    }
  }
]

const defaultColDef: ColDef = {
  sortable: true,
  filter: true,
  resizable: true,
  minWidth: 150
}

const initCustomer = ref(new Customer())
const initInvoices = ref<CustomerInvoice[]>([])
const initExaminations = ref<Examination[]>([])
const balance = ref(0)
const totalDueAmount = ref(0)
const currency = ref('')
const precision = ref(0)
const $route = useRoute()
const $router = useRouter()
const $http = useHTTP()

const orderGridApi = ref<GridApi | undefined>()
const examinationGridApi = ref<GridApi | undefined>()

function onOrderGridReady(params: any) {
  orderGridApi.value = params.api;
  orderGridApi.value?.sizeColumnsToFit()
}

function onExaminationGridReady(params: any) {
  examinationGridApi.value = params.api;
  examinationGridApi.value?.sizeColumnsToFit()
}

const editUrl = computed(() => {
  const id = $route.params.id
  return $Route.CUSTOMERS_CUSTOMER.replace(':id', id as string)
})

async function loadCustomer() {
  const id = $route.params.id as string
  const url = `${Customer.ENDPOINT}/${id}`
  const customer = await $http.get<Customer>(url)
  if (customer) Object.assign(initCustomer.value, Customer.from(customer))

  const invoicesUrl = `${Customer.INVOICES_ENDPOINT}${id}`
  const { invoices, totalBalanceAmount } =
    (await $http.get<{
      invoices: CustomerInvoice[]
      totalBalanceAmount: number
    }>(invoicesUrl)) || []

  initInvoices.value = invoices.map(i => CustomerInvoice.from(i))
  balance.value = totalBalanceAmount

  totalDueAmount.value = 0
  for (const invoice of initInvoices.value) {
    if (invoice.totalDueAmount) totalDueAmount.value += invoice.totalDueAmount
    precision.value = invoice.precision
    currency.value = invoice.currency
  }

  const examination = await $http.get<Examination[]>(
    Examination.ENDPOINT + '?customer_id=' + customer.id
  )
  initExaminations.value = examination
}

onMounted(() => {
  loadCustomer()
})

function onOrderCellDoubleClicked(event: any) {
  /* eslint-disable @typescript-eslint/camelcase  */
  if (event.data.referenceableType == 'sales.Order')
    $router.push({
      name: 'order',
      params: {
        id: event.data.referenceableID
      }
    })
}

function onExaminationCellDoubleClicked(event: any) {
  /* eslint-disable @typescript-eslint/camelcase  */
  $router.push({
    name: 'examination',
    params: {
      examination_id: event.data.id
    }
  })
}

function toDinero(amount: number, currency: any, precision: number) {
  let result = ''
  try {
    const dinero = Dinero({
      amount: amount,
      currency: (currency as Currency) || 'IQD',
      precision: precision
    })

    result = formatDinero(dinero)
  } catch (error) {
    console.log(error)
  }

  return result
}
</script>

<style></style>
