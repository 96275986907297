<script lang="ts">
import { defineComponent } from 'vue'
import MoneyInput from '@/components/form/MoneyInput.vue'
import NewMoneyInput from '@/components/form/NewMoneyInput.vue'
import { formatMoney } from '../../utils/money'
import { useI18n } from 'vue-i18n'
import dinero from 'dinero.js'

/**
 * UserInput:
 *
 * - paidInEpaymentDefaultCurrency
 * - paidBeforeChangeInDefaultCurrency
 * - paidInEpaymentSecondaryCurrency
 * - paidBeforeChangeInSecondaryCurrency
 * - exchangeRate
 *
 * -------------------------------------------------------------------------------------------------
 *
 * Calculated and passed to the component:
 * - TotalDue
 *
 * -------------------------------------------------------------------------------------------------
 *
 * Calculated and autocorrected by this component
 * - TotalPaid (in default currency)
 * - changeDue (in default currency)
 * - totalPaidInEpayment
 * - totalPaidInCash
 * - paidInEpaymentDefaultCurrency (autocorrected)
 * - paidInEpaymentSecondaryCurrency
 * - paidInCashDefaultCurrency (autocorrected)
 * - paidInCashSecondaryCurrency
 *
 * -------------------------------------------------------------------------------------------------
 * Example: a user pays IQD 100,000 in cash, 200,000 IQD in IQD Epayment, $100 in USD cash, 200 in USD Epayment.
 * Exchange rate: 1 USD = 1300 IQD
 * Total due = 700,000 IQD
 *
 * First, use the e-payment in IQD
 * 700,000 IQD - 200,000 IQD = 500,000 IQD
 * then use the e-payment in USD
 * 500,000 IQD - ($200 * 1300) = 240,000 IQD
 *
 * then use the cash in IQD
 * 240,000 IQD - 100,000 = 140,000 IQD
 * then use the cash in USD
 * 140,000 - $100 * 1130 = 10,000 IQD and that's the change
 *
 * -------------------------------------------------------------------------------------------------
 *                                       TotalDue
 *                                          -
 *   totalPaidInEpayment = [paidInEpaymentDefaultCurrency + convert(paidInEpaymentSecondaryCurrency)]
 *                                          -
 *   totalPaidInCash = [paidInCashDefaultCurrency + convert(paidInCashSecondaryCurrency)]
 *                                          =
 *                       ChangeDue (in default currency, if any change)
 *  ------------------------------------------------------------------------------------------------
 *            totalPaid = min(totalDue, totalPaidInEpayment + totalPaidInCash)
 *  ------------------------------------------------------------------------------------------------
 */

export default defineComponent({
  name: 'MultiCurrencyPaymentInput',

  components: { NewMoneyInput, MoneyInput },

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          total: 'Total Due',
          cash: 'Amount Paid',
          changeDue: 'Change Due',
          totalPaid: 'Total received',
          iqdCashReceived: 'Cash Received in IQD',
          iqdEpaymentReceived: 'E-payment Received in IQD',
          usdCashReceived: 'Cash Received in USD',
          usdEpaymentReceived: 'E-payment Received in USD',
          exchangeRate: 'exchangeRate',
        },
        ar: {
          total: 'المبلغ المطلوب',
          cash: 'المبلغ المدفوع',
          changeDue: 'المبلغ الباقي',
          totalPaid: 'المبلغ المستحصل',
          iqdCashReceived: 'المدفوع نقدا بالدينار',
          iqdEpaymentReceived: 'المدفوع الكترونيا بالدينار',
          usdCashReceived: 'المدفوع نقدا بالدولار',
          usdEpaymentReceived: 'المدفوع الكترونيا بالدولار',
          exchangeRate: 'سعر التصريف',
        }
      }
    })

    return { t }
  },

  // What other components will provide
  props: {
    // IN DEFAULT CURRENCY
    totalDue: {
      type: Number,
      required: true
    },

    // In Default Currency
    totalPaidInEpayment: {
      type: Number,
      required: true
    },

    // In Default Currency
    totalPaidInCashBeforeChange: {
      type: Number,
      required: true
    },

    // In Default Currency
    changeDue: {
      type: Number,
      required: true
    },

    // totalPaidInEpayment + totalPaidInCash (after change)
    totalPaid: {
      type: Number,
      required: true
    },

    // produced and calculated by this component
    cashMultiCurrencyBreakdown: {
      type: Array
    },

    ePaymentMultiCurrencyBreakdown: {
      type: Array
    }
  },

  // state:
  data() {

    const defaultCurrency = this.$ctx.currency;

    return {
      defaultCurrency,

      secondaryCurrency: defaultCurrency === 'IQD' ? 'USD' : 'IQD',

      // IQD
      paidInCashIQD: 0,
      paidInEpaymentIQD: 0,

      // USD
      paidInCashUSD: 0,
      paidInEpaymentUSD: 0,

      IQDToUSDExchangeRate: this.$ctx.exchangeRates.USD,
      USDToIQDExchangeRate: this.$ctx.exchangeRates.IQD,

      // Total
      totalCashAndEpaymentBeforeChange: 0,

      // tabs
      selectedTabCurrency: defaultCurrency,
      tabs: defaultCurrency === 'IQD' ? ['IQD', 'USD'] : ['USD', 'IQD']
    }
  },

  // change values anytime user input changes
  watch: {
    paidInCashIQD() {
      this.updateTotal()
    },

    paidInEpaymentIQD() {
      this.updateTotal()
    },

    paidInCashUSD() {
      this.updateTotal()
    },

    paidInEpaymentUSD() {
      this.updateTotal()
    },

    IQDToUSDExchangeRate() {
      this.updateTotal()
    },

    USDToIQDExchangeRate() {
      this.updateTotal()
    }
  },

  // provide updated values to the caller component

  // methods
  methods: {
    formatMoney,
    updateTotal() {
      // values given to component that we need in doing the math below
      const totalDue = this.totalDue;
      const exchangeRate = this.defaultCurrency === "IQD" ? this.USDToIQDExchangeRate : this.IQDToUSDExchangeRate;
      const defaultCurrencyPrecision = this.defaultCurrency === "IQD" ? 0 : 2;
      const secondaryCurrencyPrecision = this.secondaryCurrency === "IQD" ? 0 : 2;

      // first define which is default currency and which is secondary
      // User input
      const paidInCashDefaultCurrencyBeforeChange = this.defaultCurrency === "IQD" ? this.paidInCashIQD : this.paidInCashUSD / 100;
      const paidInCashSecondaryCurrencyBeforeChange = this.secondaryCurrency === "IQD" ? this.paidInCashIQD : this.paidInCashUSD / 100;

      const totalPaidInCashBeforeChangeConverted = paidInCashDefaultCurrencyBeforeChange + Math.round(paidInCashSecondaryCurrencyBeforeChange * exchangeRate)

      // User input before autocorrection
      let paidInEpaymentDefaultCurrency = this.defaultCurrency === "IQD" ? this.paidInEpaymentIQD : this.paidInEpaymentUSD / 100;
      let paidInEpaymentSecondaryCurrency = this.secondaryCurrency === "IQD" ? this.paidInEpaymentIQD : this.paidInEpaymentUSD / 100;

      // The algorithm is simple
      // first deduct default currency epayment
      // then deduct secondary currency epayment
      // then deduct cash of both default and secondary

      // we start by autocorrecting epayment
      let remainingBalance = totalDue;

      paidInEpaymentDefaultCurrency = Math.min(paidInEpaymentDefaultCurrency, remainingBalance)
      remainingBalance -= paidInEpaymentDefaultCurrency

      paidInEpaymentSecondaryCurrency = Math.min(paidInEpaymentSecondaryCurrency, Math.round(remainingBalance / exchangeRate))
      const paidInEpaymentSecondaryCurrencyConverted = Math.round(paidInEpaymentSecondaryCurrency * exchangeRate)
      remainingBalance -= paidInEpaymentSecondaryCurrencyConverted

      // update user input to match the autocorrected values
      if (this.defaultCurrency === "IQD") {
        this.paidInEpaymentIQD = paidInEpaymentDefaultCurrency
        this.paidInEpaymentUSD = paidInEpaymentSecondaryCurrency
      } else {
        this.paidInEpaymentUSD = paidInEpaymentDefaultCurrency
        this.paidInEpaymentIQD = paidInEpaymentSecondaryCurrency
      }

      // finally, deduct cash
      // if cash > remainingBalance, then only deduct the balance value  -- the rest is change
      // otherwise, cash deducted is the value provided by customer
      const totalPaidInCash = Math.min(paidInCashDefaultCurrencyBeforeChange + Math.round(paidInCashSecondaryCurrencyBeforeChange * exchangeRate), remainingBalance)
      console.log( Math.round(paidInCashSecondaryCurrencyBeforeChange * exchangeRate))

      // values we need to update every time user input changes
      const newTotalPaidInCashBeforeChange = totalPaidInCashBeforeChangeConverted
      const newTotalPaidInEpayment = paidInEpaymentDefaultCurrency + paidInEpaymentSecondaryCurrencyConverted;
      const newTotalPaid = totalPaidInCash + newTotalPaidInEpayment;

      const newChangeDue = totalPaidInCashBeforeChangeConverted - totalPaidInCash
      const newMultiCurrencyBreakdownForCash: Array<{}> = [
        {currency: this.defaultCurrency, precision: defaultCurrencyPrecision, amount: paidInCashDefaultCurrencyBeforeChange * (Math.pow(10,  defaultCurrencyPrecision))},
        {currency: this.secondaryCurrency, precision: secondaryCurrencyPrecision, amount: paidInCashSecondaryCurrencyBeforeChange * (Math.pow(10, secondaryCurrencyPrecision)), exchangeRate: exchangeRate}
      ]

      const newMultiCurrencyBreakdownForEpayment: Array<{}> = [
        {currency: this.defaultCurrency, precision: defaultCurrencyPrecision, amount: paidInEpaymentDefaultCurrency},
        {currency: this.secondaryCurrency, precision: secondaryCurrencyPrecision, amount: paidInEpaymentSecondaryCurrency, exchangeRate: exchangeRate}
      ]

      // update the comprehensive total, just for display in this component
      this.totalCashAndEpaymentBeforeChange = totalPaidInCashBeforeChangeConverted + newTotalPaidInEpayment

      // after doing all the calculations, do the update
      this.$emit('update:totalPaidInCashBeforeChange', newTotalPaidInCashBeforeChange)
      this.$emit('update:totalPaidInEpayment', newTotalPaidInEpayment)
      // newTotalPaidInCash + newTotalPaidInEpayment
      this.$emit('update:totalPaid', newTotalPaid)
      // change = max(totalDue - totalPaid, 0)
      this.$emit('update:changeDue', newChangeDue)
      this.$emit('update:cashMultiCurrencyBreakdown', newMultiCurrencyBreakdownForCash)
      this.$emit('update:ePaymentMultiCurrencyBreakdown', newMultiCurrencyBreakdownForEpayment)
    },

    addToPaidInCashIQD(val: number) {
      this.paidInCashIQD += val
    },

    addToPaidInCashUSD(val: number) {
      this.paidInCashUSD += val
    },

    cashReceivedInputFocus() {
      this.$nextTick(() => {
        /* @ts-ignore */
        const cashReceivedInput = this.defaultCurrency === "IQD" ? this.$refs['iqdCashInput'] : this.$refs['usdCashInput'];

        if (cashReceivedInput) {
          /* @ts-ignore */
          cashReceivedInput.focus()
        }
      })
    },
  },

  mounted() {
    const interval = setInterval(() => {
      const cashReceivedInput = this.defaultCurrency === "IQD" ? this.$refs['iqdCashInput'] : this.$refs['usdCashInput'];
      if (cashReceivedInput) {
        cashReceivedInput.focus()
        clearInterval(interval)
      }
    }, 50)
  }
})
</script>

<template>
  <!-- default currency appears first -->

  <div>
    <div class="w-full">
      <div class="flex justify-between items-center text-base font-bold mx-5" :dir="$ctx.getDir()">
        <div class="">{{t("total")}}</div>
        <div dir="ltr" class="bg-yellow-100 w-1/2 p-1"> {{formatMoney(totalDue, "IQD")}}</div>
      </div>
    </div>

    <div class="w-full p-2">
      <hr class="solid" />
    </div>

    <!-- tab header -->
    <div class="flex w-full mb-2">
      <div
        v-for="tab in tabs"
        :key="tab"
        class="w-1/2 py-2 text-center cursor-pointer border-blue-300 hover:border-b-4 mx-5 font-bold"
        :class="{'border-b-4': selectedTabCurrency === tab}"
        @click="selectedTabCurrency = tab"
      >
        {{ tab }}
      </div>
    </div>

    <div class="flex justify-center flex-col items-center w-full">

      <!-- tab body -->
      <div v-if="selectedTabCurrency === 'IQD'" class="mx-5">
        <div class="flex justify-center">
          <div id="Iqd-currency" class="w-full">
            <div class="flex justify-between">
              <img
                class="w-3/12 cursor-pointer px-2 transition ease-in-out delay-100 hover:scale-110 duration-100"
                src="@/assets/paperMoney/250.webp"
                alt="250 iqd"
                @click="addToPaidInCashIQD(250)"
              />
              <img
                class="w-3/12 cursor-pointer px-2 transition ease-in-out delay-100 hover:scale-110 duration-100"
                src="@/assets/paperMoney/500.webp"
                alt="500 iqd"
                @click="addToPaidInCashIQD(500)"
              />
              <img
                class="w-3/12 cursor-pointer px-2 transition ease-in-out delay-100 hover:scale-110 duration-100"
                src="@/assets/paperMoney/1000.webp"
                alt="1000 iqd"
                @click="addToPaidInCashIQD(1000)"
              />

              <img
                class="w-3/12 cursor-pointer px-2 transition ease-in-out delay-100 hover:scale-110 duration-100"
                src="@/assets/paperMoney/5000.webp"
                alt="5000 iqd"
                @click="addToPaidInCashIQD(5000)"
              />
            </div>

            <div class="flex justify-between mt-4">
              <img
                class="w-4/12 cursor-pointer px-2 transition ease-in-out delay-100 hover:scale-110 duration-100"
                src="@/assets/paperMoney/10.webp"
                alt="10000 iqd"
                @click="addToPaidInCashIQD(10000)"
              />
              <img
                class="w-4/12 cursor-pointer px-2 transition ease-in-out delay-100 hover:scale-110 duration-100"
                src="@/assets/paperMoney/25.webp"
                alt="25000 iqd"
                @click="addToPaidInCashIQD(25000)"
              />
              <img
                class="w-4/12 cursor-pointer px-2 transition ease-in-out delay-100 hover:scale-110 duration-100"
                src="@/assets/paperMoney/50.webp"
                alt="50000 iqd"
                @click="addToPaidInCashIQD(50000)"
              />
            </div>

          </div>
        </div>

        <div class="flex justify-between items-center my-3 mt-6 text-primary w-full">
          <h1 class="2xl:text-l lg:text-l text-m">
            {{defaultCurrency == 'IQD' ? '(a)' : '(c)'}} {{ t('iqdCashReceived') }}
          </h1>
          <div class="line border w-1/12 mx-2"></div>
          <h1 class="2xl:text-l lg:text-l text-m">
            {{ formatMoney(paidInCashIQD, 'IQD') }}
          </h1>
        </div>

        <div :dir="$ctx.getDir()" class="w-full">
          <div class="w-full mb-5">
            <div class="flex items-center gap-2">
              <money-input
                v-model="paidInCashIQD"
                currency="IQD"
                ref="iqdCashInput"
              />
            </div>
          </div>
        </div>

        <div class="flex justify-between items-center my-3 text-primary w-full" v-if="defaultCurrency === 'IQD'">
          <h1 class="2xl:text-l lg:text-l text-m">
            {{defaultCurrency == 'IQD' ? '(b)' : '(d)'}} {{ t('iqdEpaymentReceived') }}
          </h1>
          <div class="line border w-1/12 mx-2"></div>
          <h1 class="2xl:text-l lg:text-l text-m">
            {{ formatMoney(paidInEpaymentIQD, 'IQD') }}
          </h1>
        </div>

        <div class="w-full mb-5" v-if="defaultCurrency === 'IQD'">
          <!-- I chose to keep the below as a comment just in case we decided to use labels later on -->
          <!-- <p class="mb-2 font-semibold">{{ t("cash") }}</p> -->
          <div class="flex items-center gap-2">
            <money-input
              v-model="paidInEpaymentIQD"
              currency="IQD"
              ref="iqdEpaymentInput"
            />
          </div>
        </div>


        <template v-if="secondaryCurrency === 'IQD'">
          <div class="flex justify-between items-center my-3 text-primary w-full">
            <h1 class="2xl:text-l lg:text-l text-m">
              {{ t('exchangeRate') }}
            </h1>
            <div class="line border w-1/12 mx-2"></div>
            <h1 class="2xl:text-l lg:text-l text-m" dir="ltr">
              1 IQD = {{ IQDToUSDExchangeRate }} USD
            </h1>
          </div>

          <div class="w-full mb-5">
            <div class="flex items-center gap-2">
              <el-input
                v-model="IQDToUSDExchangeRate"
                ref="iqdToUsdInput"
              />
            </div>
          </div>
        </template>
      </div>

      <div v-if="selectedTabCurrency === 'USD'" class="mx-5">
        <div class="flex justify-center">

          <div id="Iqd-currency" class="w-full">
            <div class="flex justify-between">
              <img
                class="w-3/12 cursor-pointer px-2 transition ease-in-out delay-100 hover:scale-110 duration-100"
                src="@/assets/paperMoney/1usd.webp"
                alt="1 USD"
                @click="addToPaidInCashUSD(100)"
              />
              <img
                class="w-3/12 cursor-pointer px-2 transition ease-in-out delay-100 hover:scale-110 duration-100"
                src="@/assets/paperMoney/2usd.webp"
                alt="2 USD"
                @click="addToPaidInCashUSD(200)"
              />
              <img
                class="w-3/12 cursor-pointer px-2 transition ease-in-out delay-100 hover:scale-110 duration-100"
                src="@/assets/paperMoney/5usd.webp"
                alt="5 USD"
                @click="addToPaidInCashUSD(500)"
              />
              <img
                class="w-3/12 cursor-pointer px-2 transition ease-in-out delay-100 hover:scale-110 duration-100"
                src="@/assets/paperMoney/10usd.webp"
                alt="10 USD"
                @click="addToPaidInCashUSD(1000)"
              />
            </div>

            <div class="flex justify-between mt-4">
              <img
                class="w-4/12 cursor-pointer px-2 transition ease-in-out delay-100 hover:scale-110 duration-100"
                src="@/assets/paperMoney/20usd.webp"
                alt="20 USD"
                @click="addToPaidInCashUSD(2000)"
              />
              <img
                class="w-4/12 cursor-pointer px-2 transition ease-in-out delay-100 hover:scale-110 duration-100"
                src="@/assets/paperMoney/50usd.webp"
                alt="50 USD"
                @click="addToPaidInCashUSD(5000)"
              />
              <img
                class="w-4/12 cursor-pointer px-2 transition ease-in-out delay-100 hover:scale-110 duration-100"
                src="@/assets/paperMoney/100usd.webp"
                alt="100 USD"
                @click="addToPaidInCashUSD(10000)"
              />
            </div>

          </div>
        </div>

        <div class="flex justify-between items-center my-3 mt-6 text-primary w-full">
          <h1 class="2xl:text-l lg:text-l text-m">
            {{ this.defaultCurrency === 'USD' ? '(a)' : '(c)'}} {{ t('usdCashReceived') }}
          </h1>
          <div class="line border w-1/12 mx-2"></div>
          <h1 class="2xl:text-l lg:text-l text-m">
            {{ formatMoney(paidInCashUSD, 'USD') }}
          </h1>


        </div>

        <div :dir="$ctx.getDir()" class="w-full">
          <div class="w-full mb-5">
            <div class="flex items-center gap-2">
              <new-money-input
                v-model="paidInCashUSD"
                currency="USD"
                ref="usdCashInput"
              />
            </div>
          </div>

          <template v-if="secondaryCurrency === 'USD'">
            <div class="flex flex justify-between items-center my-3 text-primary w-full">
              <h1 class="2xl:text-l lg:text-l text-m">
                {{ t('exchangeRate') }}
              </h1>
              <div class="line border w-1/12 mx-2"></div>
              <h1 class="2xl:text-l lg:text-l text-m" dir="ltr">
                1 USD = {{ USDToIQDExchangeRate }} IQD
              </h1>
            </div>

            <div class="w-full">
              <div class="flex items-center gap-2">
                <el-input
                  v-model="USDToIQDExchangeRate"
                  ref="usdToIqdInput"
                />
              </div>
            </div>
          </template>
        </div>

        <div class="flex justify-between items-center my-3 text-primary w-full" v-if="defaultCurrency=== 'USD'">
          <h1 class="2xl:text-l lg:text-l text-m">
            {{ this.defaultCurrency === 'USD' ? '(b)' : '(d)'}} {{ t('usdEpaymentReceived') }}
          </h1>
          <div class="line border w-1/12 mx-2"></div>
          <h1 class="2xl:text-l lg:text-l text-m">
            {{ formatMoney(paidInEpaymentUSD, 'USD') }}
          </h1>
        </div>

        <div class="w-full" v-if="defaultCurrency === 'USD'">
          <!-- I chose to keep the below as a comment just in case we decided to use labels later on -->
          <!-- <p class="mb-2 font-semibold">{{ t("cash") }}</p> -->
          <div class="flex items-center gap-2">
            <money-input
              v-model="paidInEpaymentUSD"
              currency="USD"
              ref="usdEpaymentInput"
            />
          </div>
        </div>
      </div>

      <div id="totals" v-if="defaultCurrency === 'IQD'" class="w-full text-sm mt-1" dir="ltr">

        <div class="w-full p-2">
          <hr class="solid" />
        </div>

        <div class="mx-5 flex mt-1">
          <div dir="ltr"> &nbsp &nbsp(a) {{formatMoney(paidInCashIQD, "IQD")}}</div>
        </div>
        <div class="mx-5 flex">
          <div dir="ltr"> + (b) {{formatMoney(paidInEpaymentIQD, "IQD")}}</div>
        </div>
        <div class="mx-5 flex">
          <div dir="ltr"> + (c) {{formatMoney(paidInCashUSD, "USD")}} x {{USDToIQDExchangeRate}} = {{formatMoney(Math.round(paidInCashUSD * USDToIQDExchangeRate / 100), "IQD")}}</div>
        </div>
<!--        <div class="mx-5 flex">-->
<!--          <div dir="ltr"> + (d) {{formatMoney(paidInEpaymentUSD, "USD")}} x {{USDToIQDExchangeRate}} = {{formatMoney(paidInEpaymentUSD * USDToIQDExchangeRate, "IQD")}}</div>-->
<!--        </div>-->

        <div class="w-full p-2">
          <hr class="solid" />
        </div>

        <div class="flex justify-between items-center text-base font-bold mx-5" :dir="$ctx.getDir()">
          <div class="">{{t("totalPaid")}}</div>
          <div dir="ltr" class="bg-green-100 w-1/2 p-1"> {{formatMoney(totalCashAndEpaymentBeforeChange, "IQD")}}</div>
        </div>

        <div class="flex justify-between items-center text-base font-bold mx-5 mt-1" :dir="$ctx.getDir()">
          <div>{{t("changeDue")}}</div>
          <div dir="ltr" class="bg-red-100 w-1/2 p-1"> {{formatMoney(changeDue, "IQD")}}</div>
        </div>

      </div>


      <div id="totals" v-if="defaultCurrency === 'USD'" class="w-full text-sm mt-1" dir="ltr">

        <div class="w-full p-2">
          <hr class="solid" />
        </div>

        <div class="mx-5 flex mt-1">
          <div dir="ltr"> &nbsp &nbsp(a) {{formatMoney(paidInCashUSD, "USD")}}</div>
        </div>
        <div class="mx-5 flex">
          <div dir="ltr"> + (b) {{formatMoney(paidInEpaymentUSD, "USD")}}</div>
        </div>
        <div class="mx-5 flex">
          <div dir="ltr"> + (c) {{formatMoney(paidInCashIQD, "IQD")}} x {{IQDToUSDExchangeRate}} = {{formatMoney(paidInCashIQD * IQDToUSDExchangeRate, "USD")}}</div>
        </div>
<!--        <div class="mx-5 flex">-->
<!--          <div dir="ltr"> + (d) {{formatMoney(paidInEpaymentIQD, "IQD")}} x {{IQDToUSDExchangeRate}} = {{formatMoney(paidInEpaymentIQD * IQDToUSDExchangeRate, "USD")}}</div>-->
<!--        </div>-->

        <div class="w-full p-2">
          <hr class="solid" />
        </div>

        <div class="flex justify-between items-center text-base font-bold mx-5" :dir="$ctx.getDir()">
          <div class="">{{t("totalPaid")}}</div>
          <div dir="ltr" class="bg-green-100 w-1/2 p-1">{{formatMoney(totalCashAndEpaymentBeforeChange, "USD")}}</div>
        </div>

        <div class="flex justify-between items-center text-base font-bold mx-5 mt-1" :dir="$ctx.getDir()">
          <div>{{t("changeDue")}}</div>
          <div dir="ltr" class="bg-red-100 w-1/2 p-1"> {{formatMoney(changeDue, "USD")}}</div>
        </div>

      </div>
    </div>
  </div>

</template>

<style scoped>

</style>