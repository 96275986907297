<template>
  <div v-if="loading" class="flex h-full justify-center items-center">
    <loading-spinner diameter="64px" />
  </div>

  <alert-badge
    v-else-if="error.title && !hasLoaded"
    isDanger
    :title="error.title"
    :body="error.body"
    @dismissed="
      () => {
        error.title = ''
        error.body = ''
      }
    "
  />

  <div v-else class="w-3/6 md:w-1/3 bg-white p-5 mx-2 mt-2">
    <alert-badge
      isDanger
      :title="error.title"
      :body="error.body"
      @dismissed="
        () => {
          error.title = ''
          error.body = ''
        }
      "
    />

    <div>
      <multi-currency-payment-input
        v-model:total-paid="orderPayment.totalPaidAmount"
        v-model:change-due="orderPayment.changeDueAmount"
        v-model:total-paid-in-cash-before-change="orderPayment.reqOnlyTotalCashPaidAmount"
        v-model:total-paid-in-epayment="orderPayment.reqOnlyTotalEpaymentAmount"
        v-model:cash-multi-currency-breakdown="orderPayment.reqOnlyCashMultiCurrencyBreakdown"
        v-model:e-payment-multi-currency-breakdown="orderPayment.reqOnlyEPaymentMultiCurrencyBreakdown"
        :total-due="orderPayment.totalDueAmount"
        />
    </div>

    <div class="flex justify-between items-center mb-2 mt-2">
      <horizontal-button :title="t('placePay')" isSuccess @click="openCashierPinDialog" />
    </div>

    <div class="mt-1">
      <div>{{ t('notes') }}</div>
      <div>
        <el-input v-model="orderPayment.notes" :rows="2" type="textarea" placeholder="" />
      </div>
    </div>
  </div>

  <el-dialog v-model="cashierPinDialogOpen" width="30%" @close="cashierPinDialogOpen = false">
    <div>
      <!-- Enter the pin -->
      <div class="my-2">
        <p>{{ t('enterCashierPin') }}</p>
      </div>

      <!-- password input up to 6 chars -->
      <div class="my-2">
        <el-input
          id="password"
          v-model="cashierPin"
          type="password"
          required
          show-password
          autofocus
          dir="ltr"
          :maxlength="4"
          :minlength="4"
          ref="cashierPinInput"
          @keyup.enter="submitPayment"
        />
      </div>

      <!-- finish payment -->
      <div class="flex items-center justify-between mb-2">
        <horizontal-button
          class="text-lg complete-payment"
          :title="t('placePay')"
          @click="submitPayment"
          :rounded="false"
        />
      </div>
    </div>
  </el-dialog>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, nextTick, ref, onMounted } from 'vue'

import LoadingSpinner from '@/components/LoadingSpinner.vue'
import AlertBadge from '@/components/AlertBadge.vue'
import HorizontalButton from '@/components/HorizontalButton.vue'

import { useI18n } from 'vue-i18n'
import { CustomerInvoice, CustomerInvoicePayment } from '@/models/sales/CustomerInvoice'
import { useHTTP } from '@/plugins/http'
import { useAlertModal } from '@/plugins/alert-modal/alert-modal'
import { useRouter } from 'vue-router'
import { Route } from '@/router/routes'
import { Transaction } from '@/models/accounting/Transaction'
import MultiCurrencyPaymentInput from '@/components/money/MultiCurrencyPaymentInput.vue'

export default defineComponent({
  name: 'order-payment',

  components: {
    MultiCurrencyPaymentInput,
    LoadingSpinner,
    AlertBadge,
    HorizontalButton,
  },

  setup() {
    const http = useHTTP()
    const alertModal = useAlertModal()
    const router = useRouter()
    const params = router.currentRoute.value.params
    const loading = ref(false)
    const hasLoaded = ref(false)
    const cashierPinDialogOpen = ref(false)
    const cashierPin = ref('')
    const cashierPinInput = ref()
    const paymentInput2 = ref(null)
    let invoice: CustomerInvoice
    const orderPayment = ref(new CustomerInvoicePayment())
    orderPayment.value.changeDueAmount = 0
    orderPayment.value.paidBeforeChangeAmount = 0
    const error = ref({
      title: '',
      body: ''
    })

    const { t } = useI18n({
      messages: {
        en: {
          successMsg: 'Payment made successfully',
          errInvalidAmount: 'Invalid amount. Must be bigger > 0',
          errPaymentIsComplete: 'Payments are already complete, nothing to pay',
          placePay: 'Place payment',
          enterCashierPin: 'Enter cashier pin',
          errCashierPinRequired: 'Cashier pin is required'
        },
        ar: {
          successMsg: 'تم الدفع بنجاح',
          errInvalidAmount: 'يجب ان يكون المبلغ المدفوع اكبر من 0',
          errPaymentIsComplete: 'تم دفع المبلغ كاملا',
          placePay: 'اتمام الدفع',
          enterCashierPin: 'ادخل رمز الكاشير الخاص بك',
          errCashierPinRequired: 'يرجى ادخال رمز الكاشير لاتمام البيع'
        }
      }
    })

    onBeforeMount(async () => {
      try {
        loading.value = true
        const invoiceRes = await http.get<CustomerInvoice>(
          `${CustomerInvoice.ENDPOINT}/${params.id}`
        )

        invoice = CustomerInvoice.from(invoiceRes)

        orderPayment.value.currency = invoice.currency
        orderPayment.value.precision = invoice.precision
        orderPayment.value.customerInvoiceID = invoice.id

        const paymentRemaining = invoice.totalBalanceAmount
        orderPayment.value.totalDueAmount = paymentRemaining
        if (paymentRemaining === 0) {
          alertModal.showDanger({
            title: t('errPaymentIsComplete')
          })

          await router.push(
            Route.SALES_ORDERS_ORDER.replace(':id', invoice.referenceableID as string)
          )
        }
      } catch (err) {
        error.value.title = err.title
        error.value.body = err.body
        loading.value = false
        hasLoaded.value = true
      } finally {
        loading.value = false
        hasLoaded.value = true
      }
    })

    async function submitPayment() {
      if (cashierPin.value === '') {
        cashierPinDialogOpen.value = false
        error.value.title = t('errCashierPinRequired')
        return
      }

      // if (orderPayment.value.totalPaidAmount === undefined) {
      //   return
      // }
      orderPayment.value.totalPaidAmount =
        orderPayment.value.reqOnlyTotalCashPaidAmount +
        orderPayment.value.reqOnlyTotalEpaymentAmount

      if (
        orderPayment.value.reqOnlyTotalCashPaidAmount <= 0 &&
        orderPayment.value.reqOnlyTotalEpaymentAmount <= 0
      ) {
        cashierPinDialogOpen.value = false
        error.value.title = t('validation.inputErrors')
        error.value.body = t('errInvalidAmount')
        return
      }
      let tx: Transaction[]
      try {
        tx = await http.post<Transaction[]>(
          `${CustomerInvoicePayment.ENDPOINT}/payments`,
          orderPayment.value,
          { headers: { pin: cashierPin.value } }
        )

        alertModal.showSuccess({
          title: t('successMsg')
        })

        await router.push(
          Route.SALES_TRANSACTIONS_RECEIPT.replace(
            ':transactionID',
            tx.map((t) => t.id).join('&') || ''
          )
        )
      } catch (err) {
        error.value.title = err.title
        error.value.body = err.body
        cashierPinDialogOpen.value = false
      }
    }

    function openCashierPinDialog() {
      cashierPinDialogOpen.value = true
      nextTick(() => {
        setTimeout(() => {
          cashierPinInput.value.focus()
        }, 0)
      })
    }

    return {
      t,
      orderPayment,
      submitPayment,
      error,
      loading,
      hasLoaded,
      cashierPin,
      cashierPinDialogOpen,
      cashierPinInput,
      openCashierPinDialog,
      paymentInput2
    }
  },
})
</script>

<style></style>
