<template>
  <div class="grid grid-cols-1 m-8 bg-white shadow-md p-4 gap-4">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
      <div class="grid grid-cols-1 gap-4 w-[400px]">
        <h2 class="col-span-1 text-lg flex items-center gap-2">
          <span class="border-black border-b-2">{{ t('vendorInfo') }}</span>
          <router-link :to="editUrl">
            <font-awesome-icon icon="edit" />
          </router-link>
        </h2>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 px-4">
          <div class="font-bold">{{ t('name') }}:</div>
          <div class="col-span-1">{{ initVendor.name }}</div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 px-4">
          <div class="font-bold">{{ t('contactDisplayName') }}:</div>
          <div class="col-span-1">
            {{ initVendor.contactDisplayName }}
          </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 px-4">
          <div class="font-bold">{{ t('phone') }}:</div>
          <div class="col-span-1" style="direction: ltr">
            {{ initVendor.phone }}
          </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 px-4">
          <div class="font-bold">{{ t('email') }}:</div>
          <div class="col-span-1">
            {{ initVendor.email }}
          </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 px-4">
          <div class="font-bold">{{ t('streetAddress') }}:</div>
          <div class="col-span-1">
            {{ initVendor.streetAddress }}
          </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 px-4">
          <div class="font-bold">{{ t('createdDate') }}:</div>
          <div class="col-span-1">
            {{ formatPostgresDate(initVendor.createdAt) }}
          </div>
        </div>
      </div>
      <div class="self-start grid grid-cols-1 gap-4 w-80">
        <h2 class="col-span-1 text-lg flex items-center gap-2">
          <span class="border-black border-b-2">{{ t('ordersHistory') }}</span>
        </h2>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 px-4">
          <div class="font-bold col-span-1">{{ t('totalOrders') }}:</div>
          <div class="col-span-1">
            {{ initInvoices.length }}
          </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 px-4">
          <div class="font-bold col-span-1">{{ t('totalAmount') }}:</div>
          <div class="col-span-1">
            {{ toDinero(totalDueAmount, currency, precision) }}
          </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 px-4">
          <div class="font-bold col-span-1">{{ t('totalBalance') }}:</div>
          <div class="col-span-1">
            {{ toDinero(balance, currency, precision) }}
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-end">
      <div class="md:w-2/12 m-2">
        <horizontal-button
          :title="t('accountStatement')"
          @click.prevent="
            $router.push({
              name: 'vendor-account-statement',
              params: { vendor_id: initVendor.id }
            })
          "
        />
      </div>
    </div>

    <div class="col-span-full border-t-2 pt-2">
      <h2 class="col-span-1 md:col-span-2 text-lg flex items-center gap-2">
        <span class="border-black border-b-2">{{ t('ordersHistory') }}</span>
      </h2>
      <ag-grid-vue
        class="ag-theme-alpine"
        :columnDefs="columnDefs"
        :rowData="initInvoices"
        rowSelection="multiple"
        :gridOptions="gridOptions"
        :enable-rtl="$ctx.getDir() === 'rtl'"
        style="height: 40vh"
        :pagination="true"
        :paginationAutoPageSize="true"
        :defaultColDef="defaultColDef"
        @cell-double-clicked="onCellDoubleClicked"
        :onGridReady="onGridReady"
      >
      </ag-grid-vue>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'

import Vendor, { vendorI18nMessages } from '@/models/vendor/Vendor'
import { useI18n } from 'vue-i18n'
import { Route as $Route } from '@/router/routes'
import { useRoute, useRouter } from 'vue-router'
import { useHTTP } from '@/plugins/http'
import { formatPostgresDate, parseFormat } from '@/utils/date'
import Dinero, { Currency } from 'dinero.js'
import { formatDinero } from '@/utils/money'
import VendorBill from '@/models/vendor/VendorBill'
import { ColDef, GridApi, GridOptions } from 'ag-grid-community'
import { AgGridVue } from 'ag-grid-vue3'
import HorizontalButton from '@/components/HorizontalButton.vue'

const { t } = useI18n({
  messages: {
    en: {
      vendorInfo: 'Vendor Info',
      noPhone: 'No Phone # was provided',
      noEmail: 'No Email was provided',
      ...vendorI18nMessages.en,
      ordersHistory: 'Orders History',
      totalBalance: 'Total Balance',
      totalAmount: 'Total Amount',
      totalOrders: 'Total Orders',
      vendor: 'Vendor',
      billNo: 'Bill No',
      billDate: 'Bill Date',
      dueDate: 'Due Date',
      total: 'Total',
      amountPaid: 'Amount Paid',
      balance: 'Balance',
      cashier: 'Cashier',
      accountStatement: 'Account Statement',
      createdDate: 'Created Date',
    },
    ar: {
      vendorInfo: 'بيانات الموزع',
      noPhone: 'لا يوجد رقم هاتف لعرضه',
      noEmail: 'لا يوجد بريد الكتروني لعرضه',
      ...vendorI18nMessages.ar,
      ordersHistory: 'تاريخ الطلبات',
      totalBalance: 'الرصيد الكلي',
      totalAmount: 'المبلغ الإجمالي',
      totalOrders: 'أجمالي الطلبات',
      vendor: 'الموزع',
      billNo: 'رقم الفاتورة',
      billDate: 'تاريخ الفاتورة',
      dueDate: 'تاريخ الاستحقاق',
      amountPaid: 'المبلغ المدفوع',
      balance: 'الرصيد',
      cashier: 'الكاشير',
      total: 'المجموع',
      accountStatement: 'كشف الحساب',
      createdDate: 'تاريخ الانشاء',
    }
  }
})

const $route = useRoute()
const $router = useRouter()
const $http = useHTTP()

const initVendor = ref(new Vendor())
const initInvoices = ref<VendorBill[]>([])
const balance = ref(0)
const totalDueAmount = ref(0)
const currency = ref('')
const precision = ref(0)

const columnDefs = ref<ColDef[]>([
  {
    headerName: t('billNo'),
    field: 'externalID'
  },
  {
    headerName: t('billDate'),
    field: 'createdAt',
    valueFormatter: (params: any) => {
      return parseFormat(params.value)
    },
    cellStyle: { textAlign: 'center' },
    minWidth: 250
  },
  {
    headerName: t('dueDate'),
    field: 'billDueDate',
    valueFormatter: (params: any) => {
      return parseFormat(params.value)
    },
    cellStyle: { textAlign: 'center' },
    minWidth: 250
  },
  {
    headerName: t('total'),
    field: 'totalDueAmount',
    valueFormatter: (params: any) => {
      const vendorBill = params.node?.data as VendorBill
      return formatDinero(vendorBill.totalDueDinero)
    },
    cellStyle: { textAlign: 'center' }
  },
  {
    headerName: t('amountPaid'),
    field: 'paidAmount',
    valueFormatter: (params) => {
      const vendorBill = params.node?.data as VendorBill
      return formatDinero(vendorBill.totalPaidDinero)
    },
    cellStyle: { textAlign: 'center' }
  },
  {
    headerName: t('balance'),
    valueFormatter: (params) => {
      const vendorBill = params.node?.data as VendorBill
      return formatDinero(vendorBill.totalBalanceDinero)
    },
    cellStyle: { textAlign: 'center' }
  },
  {
    headerName: t('cashier'),
    valueFormatter: (params) => {
      const vendorBill = params.node?.data as VendorBill
      if (vendorBill.userID) {
        return vendorBill.user.firstName
      }
      return ''
    }
  }
])

const gridOptions: GridOptions = {}

const defaultColDef: ColDef = {
  sortable: true,
  filter: true,
  resizable: true,
  minWidth: 150
}

const gridApi = ref<GridApi | undefined>()

function onGridReady(params: any) {
  gridApi.value = params.api
  gridApi.value?.sizeColumnsToFit()
}

function onCellDoubleClicked(event: any) {
  $router.push({
    name: 'vendorBill',
    params: {
      id: event.data.id
    }
  })
}

const editUrl = computed(() => {
  const id = $route.params.id
  return $Route.VENDORS_VENDOR.replace(':id', id as string)
})

async function loadVendor() {
  const id = $route.params.id as string
  const url = `${Vendor.ENDPOINT}/${id}`
  const vendor = await $http.get<Vendor>(url)
  if (vendor) Object.assign(initVendor.value, Vendor.from(vendor))

  const invoicesUrl = `${VendorBill.ENDPOINT}?vendor_id=${id}`
  const { invoices, totalBalanceAmount } =
    (await $http.get<{
      invoices: VendorBill[]
      totalBalanceAmount: number
    }>(invoicesUrl)) || []

  initInvoices.value = invoices.map((i) => VendorBill.from(i))
  balance.value = totalBalanceAmount

  totalDueAmount.value = 0
  for (const invoice of initInvoices.value) {
    if (invoice.totalDueAmount) totalDueAmount.value += invoice.totalDueAmount
    precision.value = invoice.precision
    currency.value = invoice.currency
  }
}

function toDinero(amount: number, currency: any, precision: number) {
  let result = ''
  try {
    const dinero = Dinero({
      amount: amount,
      currency: (currency as Currency) || 'IQD',
      precision: precision
    })

    result = formatDinero(dinero)
  } catch (error) {
    console.log(error)
  }

  return result
}

onMounted(() => {
  loadVendor()
})
</script>

<style></style>
