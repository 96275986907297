<template>
  <div class="chart-card">
    <div class="chart-title">
      <h3>{{ title }}</h3>
    </div>
    <apexchart
      :series="[
        {
          data: chartData
        }
      ]"
      type="bar"
      :options="options"
    ></apexchart>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { pdfCustomIcon } from './PdfCustomIcon'

export default defineComponent({
  name: 'bar-chart',
  props: {
    xAxis: {
      type: Array,
      default: () => []
    },
    chartData: {
      type: Array,
      default: () => []
    },
    title: {
      type: String
    }
  },
  data: function () {
    return {
      options: {
        chart: {
          type: 'bar',
          height: '90%',
          toolbar: {
            tools: {
              customIcons: [pdfCustomIcon]
            }
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: this.xAxis
        }
      }
    }
  }
})
</script>

<style scoped>
.chart-card {
  width: 100%;
  background-color: white;
  padding: 1rem;
  border-radius: 5px;
  @apply mt-5;
}

.chart-title {
  text-align: center;
}
</style>
