<template>
  <brand-form
    :initBrand="initBrand"
    :successMsg="t('brandCreated')"
    :submitAction="submit"
    enableClearForm
    clearFormOnSubmit
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import BrandForm from '@/views/inventory/brands/BrandForm.vue'

import Brand from '@/models/inventory/Brand'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'new-brand',
  components: {
    BrandForm
  },
  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          brandCreated: 'Brand was created successfully'
        },
        ar: {
          brandCreated: 'تم انشاء العلامة التجارية بنجاح'
        }
      }
    })

    return { t }
  },
  data() {
    return {
      initBrand: new Brand()
    }
  },

  methods: {
    async submit(brand: Brand) {
      try {
        await this.$http.post(Brand.ENDPOINT, brand)
        await this.$router.push(this.$Route.INVENTORY_ITEM_BRANDS)
      } catch (error) {
        return error
      }
    }
  }
})
</script>

<style></style>
