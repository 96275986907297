<template>
  <new-bill-form
    :initBill="initBill"
    :successMsg="t('billWasCreated')"
    :submitAction="submit"
    :enableClearForm="true"
    :clearFormOnSubmit="true"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import NewBillForm from './NewBillForm.vue'

// models
import { useI18n } from 'vue-i18n'
import VendorBill from '@/models/vendor/VendorBill'

export default defineComponent({
  components: { NewBillForm },
  name: 'new-bill',

  setup() {
    const { t } = useI18n({
      messages: {
        en: { billWasCreated: 'Bill was created successfully' },
        ar: { billWasCreated: 'تم انشاء المنتج بنجاح' } //needs to correct the translation
      }
    })
    return { t }
  },

  data() {
    return {
      initBill: new VendorBill()
    }
  },

  methods: {
    async submit(bill: VendorBill) {
      try {
        await this.$http.post<VendorBill>(VendorBill.ENDPOINT, bill)
      } catch (error) {
        return error
      }
    }
  }
})
</script>
