export default {
  en: {
    select: 'Select',
    startDate: 'Start date',
    endDate: 'End date',
    errors: {
      system: {
        code: 'Error code',
        unexpectedErr: 'Unexpected system error',

        http: {
          unexpectedResponse: 'Server error: please contact support',
          serverNotResponding: "Server isn't responding: restart server or contact support",
          failedToConnectToServer: 'Failed to connect to server: please contact support'
        }
      },

      client: {
        tabs: {
          maxNumExceeded: 'cannot open more than {tabsNum} tabs',
          cannotCloseMainWindow: 'Cannot close main window'
        },

        accessControl: {
          unauthorized: 'Action unauthorized',
          lt: 'Action unauthorized: {lhs} must be less than {rhs}',
          lte: 'Action unauthorized: {lhs} must be less than or equal to {rhs}',
          eq: 'Action unauthorized: {lhs} must be equal to {rhs}',
          nq: 'Action unauthorized: {lhs} must not be equal to {rhs}',
          gt: 'Action unauthorized: {lhs} must be greater than {rhs}',
          gte: 'Action unauthorized: {lhs} must be greater than or equal to {rhs}'
        }
      }
    },

    actions: {
      saveChanges: 'Save changes',
      saveChangesSuccess: 'Changes were successfully saved',
      clearButton: 'Clear fields',
      print: 'Print',
      generateReport: 'Generate Report',
      newSale: 'New Sale',
      newReturn: 'New Return',
      newExam: 'New Exam'
    },

    models: {
      common: {
        id: 'ID',
        date: 'Date',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        deletedAt: 'Deleted at',
        time: 'Time',
        label: 'Key',
        value: 'Value'
      }
    },

    validation: {
      inputErrors: 'Input errors',
      required: "Can't leave field empty",
      minLength: '{field} must have length at least {len}',
      maxLength: 'must have length less than {len}',
      positive: '{field} must have a positive value',
      nonNegative: '{field} must have a value >= 0',
      unsupportedImage: 'unsupported image type',
      matchingLength: '{thisField} must have a length equal to {thatField}'
    },

    views: {
      common: {
        listView: {
          quickView: 'Quick View',
          allItems: 'All Items',
          deletedItems: "Deleted Items",
          selectedRows: {
            options: 'Selected rows options...',
            delete: 'Delete selected rows',
            deleteConfirm: 'Are you sure you want to delete selected rows?',
            deleteSuccess: 'The selected rows have been deleted',
            export: 'Export selected rows',
            exportSuccess: 'Selected rows were successfully exported',
            restore: 'Restore selected rows',
            restoreConfirm: 'Are you sure you want to restore selected rows?',
            restoreSuccess: 'The selected rows have been restored',
          },

          searchRows: 'Search rows',
          count: 'Total # of rows',
        }
      },

      home: {
        home: 'Home'
      },

      login: 'Login',

      sales: {
        sales: 'Sales',
        newSale: 'New Sale',
        orderPayment: 'Order Payment',
        receipt: 'Receipt',
        orders: {
          orders: 'Orders',
          order: 'Order',
          returnOrder: 'Return Order',
          orderPayment: 'Order Payment'
        },
        registers: {
          openRegister: 'Open Register',
          closeRegister: 'Close Register'
        },
        transactions: {
          transactions: 'Transactions'
        }
      },

      inventory: {
        inventory: 'Inventory',

        items: {
          items: 'Items',
          newSimpleItem: 'New Item',
          simpleItem: 'Edit Item'
        },

        brands: {
          brands: 'Brands',
          newBrand: 'new Brand',
          brand: 'Edit Brand'
        },

        customPrice: {
          customPrices: 'Custom Prices',
          newCustomPriceType: 'New Custom Price Type'
        },

        categories: {
          categories: 'Categories',
          newCategory: 'New Category',
          category: 'Category'
        },

        tags: {
          tags: 'Tags',
          newTag: 'New Tag',
          tag: 'Edit Tag'
        },

        inventoryReceivings: {
          inventoryReceivings: 'Inventory Receivings',
          newInventoryReceiving: 'New Inventory Receiving',
          inventoryReceiving: 'Inventory Receiving'
        },

        inventoryAdjustments: {
          inventoryAdjustments: 'Inventory Adjustments',
          newInventoryAdjustment: 'New Inventory Adjustment',
          inventoryAdjustment: 'Inventory Adjustment'
        },

        inventoryTransfers: {
          inventoryTransfers: 'Inventory Transfer',
          newInventoryTransfer: 'New Inventory Transfer',
          receiveInventoryTransfer: 'Receive Inventory Transfer',
          inventoryTransfer: 'Inventory Transfer'
        },

        inventoryReturnToVendors: {
          inventoryReturnToVendors: 'Inventory Return'
        },

        printBarcode: {
          printBarcode: 'Print Barcode'
        }
      },

      customers: {
        customers: 'Customers',
        newCustomer: 'New Customer',
        customer: 'Edit Customer'
      },

      examinations: {
        examinations: 'Examinations',
        newExamination: 'New Examination'
      },

      vendors: {
        vendors: 'Vendors',
        newVendor: 'New Vendor',
        vendor: 'Edit Vendor'
      },

      hr: {
        hr: 'HR',
        viewSchedule: 'View Schedule',
        assignShift: 'Assign Shift',
        employees: {
          employees: 'Employees',
          newEmployee: 'New Employee'
        },
        employeeSchedule: {
          employeeSchedule: 'Employee Schedule',
          newEmployeeSchedule: 'New Employee Schedule'
        },
        clockInOut: 'Clock In/Out'
      },

      accounting: {
        accounting: 'Accounting',

        accounts: {
          accounts: 'Accounts',
          account: 'Account Info',
          newAccount: 'New Account'
        },

        transactions: {
          transactions: 'Transactions',
          transaction: 'Transaction Info',
          newTransaction: 'New Transaction'
        },

        vendors: {
          newBill: 'New Bill'
        },
        company: {
          newExpense: 'New Expense'
        }
      },

      reports: {
        reports: 'Reports',
        agGridToolbar: {
          fromDate: 'From Date',
          toDate: 'To Date'
        },
        emptyState: 'try different store or date',
        salesSummary: {
          salesSummaryNoSales: {
            title: 'Sales Summary By Number Of Sales',
            noOfSales: '# Of Sales',
            noOfReturns: '# Of Returns'
          },
          salesSummaryValues: {
            title: 'Sales Summary By Sales Values',
            totalSales: 'Total Sales',
            totalReturns: 'Total Returns',
            netSales: 'Net Sales'
          }
        }
      },
      settings: {
        settings: 'Settings',
        tabs: {
          printers: 'Printers',
          receipt: 'Receipt size',
          currencies: 'Currencies',
          language: 'Language',
          license: 'License'
        }
      }
    },

    dialogs: {
      confirm: 'Confirm',
      cancel: 'Cancel',
      completePay: 'Process Payment',
      enterCashierPin: 'Enter cashier pin',
      errCashierPinRequired: 'Cashier pin is required',
    }
  },
  ar: {
    select: 'اختر',
    startDate: 'من تاريخ',
    endDate: 'الى تاريخ',
    errors: {
      system: {
        code: 'خطأ رقم',
        unexpectedErr: 'خطأ غير متوقع في النظام',

        http: {
          unexpectedResponse: 'خطأ في الخادم: يرجى الاتصال بوحدة الدعم',
          serverNotResponding:
            'تعذر الرد من الخادم: يرجى اعادة تشغيل الخادم او الاتصال بوحدة الدعم',
          failedToConnectToServer: 'تعذر الاتصال بالخادم: يرجى الاتصال بوحدة الدعم'
        }
      },

      client: {
        tabs: {
          maxNumExceeded: 'لايمكنك اضافة نافذة جديدة. العدد الاقصى للنوافذ هو {tabsNum}',
          cannotCloseMainWindow: 'لايمكنك اغلاق النافذة الرئيسية'
        },

        accessControl: {
          unauthorized: 'ليس لديك الصلاحية لهذه العملية',
          lt: '@:errors.client.accessControl.unauthorized: يجب ان تكون قيمة {lhs} ادنى من {rhs}',
          lte: '@:errors.client.accessControl.unauthorized: يجب ان تكون قيمة {lhs} ادنى من او تساوي {rhs}',
          eq: '@:errors.client.accessControl.unauthorized: يجب ان تكون قيمة {lhs} مساوية {rhs}',
          nq: '@:errors.client.accessControl.unauthorized: يجب ان تكون قيمة {lhs} لايمكن ان تساوي {rhs}',
          gt: '@:errors.client.accessControl.unauthorized: يجب ان تكون قيمة {lhs} اعظم من {rhs}',
          gte: '@:errors.client.accessControl.unauthorized: يجب ان تكون قيمة {lhs} اعظم من او تساوي {rhs}'
        }
      }
    },

    actions: {
      saveChanges: 'حفظ البيانات',
      saveChangesSuccess: 'تم حفظ التعديلات بنجاح',
      clearButton: 'تفريغ الحقول',
      print: 'إطبع',
      generateReport: 'انشاء التقرير',
      newSale: 'بيع جديد',
      newReturn: 'ارجاع جديد',
      newExam: 'فحص جديد'
    },

    models: {
      common: {
        id: 'ID',
        date: 'التاريخ',
        createdAt: 'تاريخ الانشاء',
        updatedAt: 'تاريخ التحديث',
        deletedAt: 'تاريخ الحذف',
        time: 'الوقت',
        label: 'الحقل',
        value: 'القيمة'
      }
    },

    validation: {
      inputErrors: 'اخطاء الادخال',
      required: 'لايمكن ترك الحقل فارغا',
      minLength: 'يجب ان يكون طول الحقل على الاقل {len}',
      maxLength: 'يجب ان يكون طول الحقل اقل من {len}',
      positive: 'يجب ان تكون قيمة {field} موجبة',
      nonNegative: '{field} يجب ان تكون قيمته >= 0',
      unsupportedImage: 'صيغة الصورة غير مدعومة',
      matchingLength: '{thisField} يجب ان يتساوى مع {thatField}'
    },

    views: {
      common: {
        listView: {
          quickView: 'عرض سريع',
          allItems: 'جميع العناصر',
          deletedItems: "المحذوفات",
          selectedRows: {
            options: 'خيارات المواد المحددة',
            delete: 'مسح المواد المحددة',
            deleteConfirm: 'يرجى التأكيد على مسح المواد المحددة',
            deleteSuccess: 'تم مسح المواد المحددة بنجاح',
            export: 'تصدير المواد المحددة',
            exportSuccess: 'تم تصدير المواد المحددة بنجاح',
            restore: "استرجاع العناصر المحددة",
            restoreConfirm: 'يرجى التأكيد على استرجاع المواد المحددة',
            restoreSuccess: 'تم استرجاع العناصر المحددة بنجاح',
          },

          searchRows: 'ابحث...',

          count: 'العدد الكلي',
        }
      },

      home: {
        home: 'الرئيسية'
      },

      login: 'تسجيل الدخول',

      sales: {
        sales: 'المبيعات',
        newSale: 'بيع جديد',
        orderPayment: 'تسديد فاتورة',
        orders: {
          orders: 'الفواتير',
          order: 'الفاتورة',
          returnOrder: 'ارجاع المبيعات',
          orderPayment: 'الدفع'
        },
        receipt: 'الفاتورة',
        registers: {
          openRegister: 'فتح الصندوق',
          closeRegister: 'غلق الصندوق'
        },
        transactions: {
          transactions: 'العمليات'
        }
      },

      inventory: {
        inventory: 'المخزن',

        items: {
          items: 'المنتجات',
          newSimpleItem: 'اضافة منتج',
          simpleItem: 'تعديل منتج'
        },

        brands: {
          brands: 'العلامات التجارية',
          newBrand: 'اضافة علامة تجارية',
          brand: 'تعديل علامة تجارية'
        },

        customPrice: {
          customPrices: 'الاسعار الاخرى',
          newCustomPriceType: 'اضافة نوع جديد'
        },

        categories: {
          categories: 'المجاميع',
          newCategory: 'اضافة مجموعة',
          category: 'تعديل مجموعة'
        },

        tags: {
          tags: 'الوسوم',
          newTag: 'اضافة وسم',
          tag: 'تعديل وسم'
        },

        inventoryReceivings: {
          inventoryReceivings: 'استلام بضاعة',
          newInventoryReceiving: 'استلام بضاعة جديدة',
          inventoryReceiving: 'عرض استلام بضاعة'
        },

        inventoryAdjustments: {
          inventoryAdjustments: 'تعديل البضاعة',
          newInventoryAdjustment: 'تعديل بضاعة جديد',
          inventoryAdjustment: 'عرض تعديل البضاعة'
        },

        inventoryTransfers: {
          inventoryTransfers: 'نقل البضاعة',
          newInventoryTransfer: 'انشاء نقل بضاعة',
          receiveInventoryTransfer: 'استلام بضاعة منقولة',
          inventoryTransfer: 'عرض نقل البضاعة'
        },

        inventoryReturnToVendors: {
          inventoryReturnToVendors: 'ارجاع البضاعة'
        },

        printBarcode: {
          printBarcode: 'طباعة الباركودات'
        }
      },

      customers: {
        customers: 'الزبائن',
        newCustomer: 'اضافة زبون',
        customer: 'تعديل زبون'
      },

      examinations: {
        examinations: 'الفحوصات',
        newExamination: 'فحص جديد'
      },

      vendors: {
        vendors: 'الموزعين',
        newVendor: 'اضافة موزع',
        vendor: 'تعديل موزع'
      },

      hr: {
        hr: 'الموارد البشرية',
        viewSchedule: 'جدول المناوبات',
        assignShift: 'تعيين مناوبة عمل',
        employees: {
          employees: 'الموظفين',
          newEmployee: 'اضافة موظف'
        },
        employeeSchedule: {
          employeeSchedule: 'جدول الموظفين',
          newEmployeeSchedule: 'تعيين مناوبة عمل'
        },
        clockInOut: 'تسجيل الدخول/الخروج'
      },

      accounting: {
        accounting: 'المحاسبة',

        accounts: {
          accounts: 'الحسابات',
          account: 'عرض الحساب',
          newAccount: 'حساب جديد'
        },

        transactions: {
          transactions: 'الحركات المالية',
          transaction: 'عرض حركة مالية',
          newTransaction: 'تسجيل حركة مالية'
        },

        vendors: {
          newBill: 'فاتورة جديدة'
        },
        company: {
          newExpense: 'ادخال مصروف جديد'
        }
      },

      reports: {
        reports: 'التقارير',
        agGridToolbar: {
          fromDate: 'من',
          toDate: 'الى'
        },
        emptyState: 'قم بتجربة متجر اخر او تاريخ مختلف',
        salesSummary: {
          salesSummaryNoSales: {
            title: 'عدد مبيعات وترجيعات اليوم',
            noOfSales: 'عدد المبيعات',
            noOfReturns: 'عدد الترجيعات'
          },
          salesSummaryValues: {
            title: 'القيمة المالية لمبيعات وترجيعات اليوم',
            totalSales: 'قيمة المبيعات',
            totalReturns: 'قيمة الترجيعات',
            netSales: 'Net Sales'
          }
        }
      },

      settings: {
        settings: 'الاعدادات',
        tabs: {
          printers: 'الطابعات',
          receipt: 'حجم الوصولات',
          currencies: 'سعر التصريف',
          language: 'اللغة',
          license: 'الرخصة'
        }
      }
    },

    dialogs: {
      confirm: 'تأكيد',
      cancel: 'الغاء',
      completePay: 'أتمام الدفع',
      enterCashierPin: 'ادخل رمز الكاشير الخاص بك',
      errCashierPinRequired: 'يرجى ادخال رمز الكاشير لاتمام البيع',
    }
  }
}
