import UUIDBase from '@/models/UUIDBase'
import Location from '@/models/company/Location'
import User from '@/models/user/User'
import { Register } from '@/models/company/Register'

export const employeeClockI18nMessages = {}

export default class EmployeeClock extends UUIDBase {
  static from(json: Record<string, unknown> | EmployeeClock) {
    return Object.assign(new EmployeeClock(), json)
  }

  static ENDPOINT = '/hr/employeeclocks'

  locationID = ''
  location?: Location

  userID = ''
  user?: User

  registerID = ''
  register?: Register

  clockType = ''
}
