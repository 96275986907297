import View from '@/plugins/navigation/View'
import { routes } from './routing'
import { Route } from './types'

/**
 * @deprecated This method should not be used
 */
interface NavItem {
  route: string
  view: View
  icon: string
}

/**
 * @deprecated This method should not be used
 */
// to reduce errors & avoid using string literals
const navItems: NavItem[] = [
  { route: '/', view: routes[Route.HOME].view(), icon: 'home' },
  {
    route: '/sales',
    view: routes[Route.SALES].view(),
    icon: 'dollar-sign'
  },
  {
    route: '/inventory',
    view: routes[Route.INVENTORY].view(),
    icon: 'warehouse'
  },
  {
    route: Route.CUSTOMERS,
    view: routes[Route.CUSTOMERS].view(),
    icon: 'users'
  },
  {
    route: Route.VENDORS,
    view: routes[Route.VENDORS].view(),
    icon: 'users'
  },
  {
    route: Route.HR,
    view: routes[Route.HR].view(),
    icon: 'address-book'
  },
  {
    route: Route.ACCOUNTING,
    view: routes[Route.ACCOUNTING].view(),
    icon: 'calculator'
  },
  {
    route: Route.REPORTS,
    view: routes[Route.REPORTS].view(),
    icon: 'flag'
  },
  {
    route: Route.SETTINGS,
    view: routes[Route.SETTINGS].view(),
    icon: 'cogs'
  }
]

/**
 * @deprecated This method should not be used
 */
export function useNavItems() {
  return navItems
}
