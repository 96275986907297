<template>
  <div class="m-3" style="height: 90vh" :dir="$ctx.getDir()">
    <div class="grid grid-cols-12 items-center content-center gap-2">
      <el-select
        class="input-label col-span-12 md:col-span-2"
        v-model="selectedValue"
      >
        <el-option key="quickView" :label="$t('views.common.listView.quickView')" value="quickView">
        </el-option>
        <el-option key="allItems" :label="$t('views.common.listView.allItems')" value="allItems">
        </el-option>
        <el-option
          key="export"
          :label="$t('views.common.listView.selectedRows.export')"
          value="export"
        >
        </el-option>
      </el-select>

      <el-input
        :placeholder="t('searchReceipt')"
        class="w-full col-span-12 md:col-span-10"
        :prefix-icon="Search"
        v-model="searchTerm"
        ref="searchBar"
      />
    </div>

    <ag-grid-vue
      v-if="rowModelType"
      class="ag-theme-alpine mt-4 md:mt-0"
      :columnDefs="columnDefs"
      rowSelection="multiple"
      :enable-rtl="$ctx.getDir() === 'rtl'"
      style="height: 85vh"
      :rowData="rowData"
      :pagination="selectedValue == 'allItems'"
      :paginationAutoPageSize="selectedValue == 'allItems'"
      :defaultColDef="defaultColDef"
      @cell-double-clicked="onCellDoubleClicked"
      :onGridReady="onGridReady"
      :inifiniteInitialRowCount="1000"
      :components="components"
      :rowBuffer="rowBuffer"
      :rowModelType="rowModelType"
      :cacheBlockSize="cacheBlockSize"
      :cacheOverflowSize="cacheOverflowSize"
      :maxConcurrentDatasourceRequests="maxConcurrentDatasourceRequests"
      :infiniteInitialRowCount="infiniteInitialRowCount"
      :maxBlocksInCache="maxBlocksInCache"
    >
    </ag-grid-vue>
    <div class="mt-2">{{ $t('views.common.listView.count') }}: {{ count }}</div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

// components
import { AgGridVue } from 'ag-grid-vue3'
import {
  ColDef,
  GridApi,
  GridOptions,
  IDatasource,
  IGetRowsParams,
  ModuleRegistry,
  ValueFormatterParams
} from 'ag-grid-community'

import { i18nOrderMessages, Order, OrdersPaginated } from '@/models/sales/Order'
import { useI18n } from 'vue-i18n'
import { logError } from '@/utils/log'
import { formatPostgresDate } from '@/utils/date'
import { formatMoney } from '@/utils/money'
import { Search } from '@element-plus/icons-vue'
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model'
import { onBeforeMount } from 'vue'
import { ref } from 'vue'
ModuleRegistry.registerModules([InfiniteRowModelModule])

export default defineComponent({
  name: 'new-return-order',

  setup() {
    const { t } = useI18n({
      messages: {
        en: { ...i18nOrderMessages.en, searchReceipt: 'Enter receipt number' },
        ar: { ...i18nOrderMessages.ar, searchReceipt: 'ادخل رقم الفاتورة' }
      }
    })

    const rowBuffer = ref<any>(null)
    const rowSelection = ref<any>(null)
    const rowModelType = ref<any>(null)
    const cacheBlockSize = ref<any>(null)
    const cacheOverflowSize = ref<any>(null)
    const maxConcurrentDatasourceRequests = ref<any>(null)
    const infiniteInitialRowCount = ref<any>(null)
    const maxBlocksInCache = ref<any>(null)

    onBeforeMount(() => {
      rowBuffer.value = 0
      rowSelection.value = 'multiple'
      rowModelType.value = 'infinite'
      cacheBlockSize.value = 100
      cacheOverflowSize.value = 100
      maxConcurrentDatasourceRequests.value = 2
      infiniteInitialRowCount.value = 0
      maxBlocksInCache.value = 10
    })

    return {
      t,
      Search,
      rowBuffer,
      rowSelection,
      rowModelType,
      cacheBlockSize,
      cacheOverflowSize,
      maxConcurrentDatasourceRequests,
      infiniteInitialRowCount,
      maxBlocksInCache
    }
  },

  data() {
    const columnDefs: ColDef[] = []
    const rowData: Order[] = []
    const gridOptions: GridOptions = {}
    const defaultColDef: ColDef = {}
    const datasource: IDatasource = {} as IDatasource
    const components = {
      loadingRenderer: (params: ValueFormatterParams) => {
        return params.value !== undefined
          ? params.value
          : '<img src="https://www.ag-grid.com/example-assets/loading.gif">'
      }
    }

    return {
      dateRange: '',
      columnDefs,
      rowData,
      gridOptions,
      defaultColDef,
      components,
      datasource,
      searchTerm: '',
      selectedValue: 'quickView',
      gridApi: undefined as GridApi | undefined,
      count: 0
    }
  },

  components: {
    AgGridVue
  },

  async beforeMount() {
    this.defaultColDef = {
      resizable: true,
      minWidth: 50,
      sortable: false,
      filter: false
    }

    this.columnDefs = [
      {
        field: '-',
        hide: true,
        lockVisible: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          newRowsAction: 'keep'
        }
      },
      {
        headerName: this.t('orderNumber'),
        field: 'externalID',
        checkboxSelection: true,
        headerCheckboxSelection: true
      },
      {
        headerName: this.$t('models.common.date'),
        field: 'createdAt',
        cellStyle: { direction: 'ltr' },
        valueGetter: (params) => {
          const order = params.node?.data as Order
          if (!order) return ''
          if (order.createdAt) {
            const createdAt = new Date(order.createdAt)
            return formatPostgresDate(String(createdAt), 'DD/MM/YYYY')
          }
          return ''
        }
      },
      {
        headerName: this.$t('models.common.time'),
        field: 'createdAt',
        cellStyle: { direction: 'ltr' },
        valueGetter: (params) => {
          const order = params.node?.data as Order
          if (!order) return ''
          if (order.createdAt) {
            const createdAt = new Date(order.createdAt)
            return formatPostgresDate(String(createdAt), 'hh:mm A')
          }
          return ''
        }
      },
      {
        headerName: this.t('type'),
        field: 'paymentType',
        valueGetter: (params) => {
          const order = params.node?.data as Order
          if (!order) return ''

          // check if order is returned
          if (order.orderItems.filter((oi) => oi.quantity > oi.returnedQuantity).length < 1) {
            return this.t('typeReturn')
          }

          let orderType = ''
          switch (order.invoice.paymentType ?? '') {
            case 'direct':
              orderType = 'typedirect'
              break
            case 'credit_sale':
              orderType = 'typecredit'
              break
            case 'layaway':
              orderType = 'typelayaway'
              break
          }

          return this.t(orderType)
        },
        cellStyle: (params) => {
          const base = {
            textAlign: 'center',
            backgroundColor: 'transparent',
            color: 'black'
          }
          const order = params.node?.data as Order
          if (!order) return base

          // check if order is returned
          if (order.orderItems.filter((oi) => oi.quantity > oi.returnedQuantity).length < 1) {
            base['color'] = 'red'
            return base
          }

          return base
        }
      },
      {
        headerName: this.t('total'),
        field: 'invoice.totalDueAmount',
        valueFormatter: ({ data }) => {
          const order = data as Order
          if (!order) return ''
          return order.invoice?.totalDueAmount != undefined
            ? formatMoney(order.invoice?.totalDueAmount, order.invoice.currency)
            : ''
        }
      },
      {
        headerName: this.t('totalPaid'),
        valueGetter: (params) => {
          const order = params.node?.data as Order
          console.log('order')
          console.log(order)
          if (!order) return ''

          return formatMoney(order.invoice.totalPaidAmount ?? 0, order.invoice.currency)
        }
      },
      {
        headerName: this.t('balance'),
        valueGetter: (params) => {
          const order = params.node?.data as Order
          if (!order) return ''
          return formatMoney(order.invoice.totalBalanceAmount ?? 0, order.invoice.currency)
        }
      },
      {
        headerName: this.t('user'),
        field: 'user.fullName',
        cellStyle: { textAlign: 'center' }
      },
      {
        headerName: this.t('customer'),
        field: 'invoice.customer.contactDisplayName',
        cellStyle: { textAlign: 'center' }
      },
      {
        headerName: this.t('isDelivered'),
        valueFormatter: ({ data }) => {
          const order = data as Order
          if (!order) return ''
          return order.isDelivered ? '✔' : '✖'
        },
        cellStyle: (params) => {
          const base = {
            textAlign: 'center',
            backgroundColor: 'transparent',
            color: 'black'
          }
          const order = params.node?.data as Order
          if (!order) return base

          if (order.isDelivered) {
            base['color'] = 'green'
          } else {
            base['color'] = 'red'
          }

          return base
        }
      }
    ];

    try {
      this.count = (await this.$http.get<{ count: number }>('/helpers/count?model=orders')).count
    } catch (error) {
      this.$alertModal.showDanger({ title: error.title, body: error.body })
    }
  },

  mounted() {
    this.gridApi?.sizeColumnsToFit()
  },

  watch: {
    searchTerm() {
      this.filterItems()
    },
    async selectedValue(newValue, oldValue) {
      if (oldValue == 'delete' || oldValue == 'export') {
        return
      }

      if (this.selectedValue === 'delete' || this.selectedValue === 'export') {
        const selected = this.gridApi?.getSelectedRows()

        if (selected?.length) {
          if (this.selectedValue === 'export') {
            this.gridApi?.exportDataAsCsv({ onlySelected: true })
            this.$alertModal.showSuccess({
              title: this.$t('views.common.listView.selectedRows.exportSuccess')
            })
          }

          // deselect
          selected.length = 0
        }
        this.selectedValue = oldValue
      } else if (this.selectedValue == 'allItems') {
        this.rowModelType = null
        this.defaultColDef.sortable = true
        this.defaultColDef.filter = true
        setTimeout(() => {
          this.rowModelType = 'clientSide'
        }, 100)
      } else if (this.selectedValue == 'quickView') {
        this.rowModelType = null
        this.defaultColDef.sortable = false
        this.defaultColDef.filter = false
        setTimeout(() => {
          this.rowModelType = 'infinite'
        }, 100)
      }
    }
  },

  methods: {
    async updateRowData() {
      this.gridApi?.showLoadingOverlay()

      try {
        const data = await this.$http.get<Order[]>(Order.ENDPOINT)
        this.rowData = data.map((order) => Order.from(order))
        this.gridApi?.setGridOption('rowData', this.rowData)
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        })
      }
      this.gridApi?.hideOverlay()
    },

    getDatasource() {
      const dataSource = {
        rowCount: undefined,
        getRows: async (params: IGetRowsParams) => {
          this.gridApi?.showLoadingOverlay()

          let url = `${Order.ENDPOINT}?startRow=${params.startRow}&endRow=${params.endRow}`
          if (params.sortModel.length) {
            url += `&colId=${params.sortModel[0].colId}&sort=${params.sortModel[0].sort}`
          }

          if (Object.keys(params.filterModel).length) {
            url += `&query=${params.filterModel['-'].filter}`
          }

          const res = await this.$http.get<OrdersPaginated>(url)
          this.rowData = res.data.map((order) => Order.from(order))
          this.gridApi?.hideOverlay()

          const lastRow = res.total
          // call the success callback
          params.successCallback(this.rowData, lastRow)
        }
      }
      return dataSource
    },

    async onGridReady(params: any) {
      this.gridApi = params.api
      if (this.selectedValue == 'quickView') {
        this.gridApi?.setGridOption('datasource', this.getDatasource())
        this.gridApi?.setGridOption('serverSideSortOnServer', true)
      } else if (this.selectedValue == 'allItems') {
        this.gridApi?.setGridOption('serverSideSortOnServer', false)
        this.updateRowData()
      }
    },


    filterItems() {
      if (this.rowModelType == 'infinite') {
        this.gridApi?.setFilterModel({ '-': { filter: this.searchTerm } })
        return
      }
      this.gridApi?.setQuickFilter(this.searchTerm)
    },

    onCellDoubleClicked(event: any) {
      this.$router.push(`${this.$Route.SALES_ORDERS_RETURN_ORDERS}/${event.data.id}`)
    }
  }
})
</script>

<style></style>
