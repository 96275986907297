import UUIDBase from '../UUIDBase'
import User from '../user/User'
import Customer from './Customer'

export const examinationI18nMessages = {
  en: {
    optometrist: 'Optometrist',
    examinationDate: 'Examination Date',
    examinationNumber: 'Examination Number',
    exam: 'Exam'
  },
  ar: {
    optometrist: 'الفاحص',
    examinationDate: 'تاريخ الفحص',
    examinationNumber: 'رقم الفحص',
    exam: 'االفحص'
  }
}
export interface ExaminationPaginated {
  data: Array<Examination>,
  total: number
}

export default class Examination extends UUIDBase {
  static from(json: Record<string, unknown> | Examination) {
    return Object.assign(new Examination(), json)
  }
  static ENDPOINT = '/examination/examinations'

  vaWithoutGlassesOS: string | undefined
  vaWithoutGlassesOD: string | undefined
  vaWithGlassesOS: string | undefined
  vaWithGlassesOD: string | undefined
  notes: string | undefined = ''
  customerID: string | undefined
  customer: Customer | undefined

  userID: string | undefined
  user: User | undefined

  examinatorID: string | undefined
  examinator: User | undefined

  // I.O.P
  iopOD: number | undefined
  iopOS: number | undefined

  // Distance Glasses Data
  distanceODSPH: number | undefined
  distanceODCYL: number | undefined
  distanceODAXIS: number | undefined
  distanceODIPD: number | undefined
  distanceOSSPH: number | undefined
  distanceOSCYL: number | undefined
  distanceOSAXIS: number | undefined

  // Reading Glasses Data
  readingODSPH: number | undefined
  readingODCYL: number | undefined
  readingODAXIS: number | undefined
  readingODIPD: number | undefined
  readingOSSPH: number | undefined
  readingOSCYL: number | undefined
  readingOSAXIS: number | undefined
  readingAdd: boolean | undefined = true

  // Contact Lenses Data
  contactODSPH: number | undefined
  contactODCYL: number | undefined
  contactODAXIS: number | undefined

  contactOSSPH: number | undefined
  contactOSCYL: number | undefined
  contactOSAXIS: number | undefined
}
