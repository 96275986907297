<template>
  <div>
    <div v-if="loading" class="flex items-center justify-center h-full">
      <loading-spinner diameter="64px" />
    </div>

    <alert-badge
      v-else-if="error.title"
      isDanger
      :title="error.title"
      :body="error.body"
      @dismissed="
        () => {
          error.title = ''
          error.body = ''
        }
      "
    />

    <div class="m-3" style="height: 90vh">
      <div class="flex flex-col items-center content-center gap-2 md:flex-row">
        <el-select class="input-label" v-model="location">
          <el-option
            v-for="(loc, i) in locations"
            :key="i"
            :label="$ctx.locale === 'en' ? loc.enName : loc.arName"
            :value="loc.id"
          >
          </el-option>
        </el-select>

        <el-date-picker
          v-model="period"
          type="daterange"
          range-separator="to"
          :start-placeholder="t('startDate')"
          :end-placeholder="t('endDate')"
        >
        </el-date-picker>
      </div>

      <div
        v-if="rowData.length === 0"
        class="flex flex-col justify-center items-center h-5/6 w-full"
      >
        <img src="@/assets/emptyFolder.png" alt="empty folder" width="250" />
        <h1 class="uppercase">{{ $t('views.reports.emptyState') }}</h1>
      </div>

      <bar-chart v-else :title="t('salesByItem')" :xAxis="items" :chartData="totals" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import AlertBadge from '@/components/AlertBadge.vue'
import Location from '@/models/company/Location'
import BarChart from '@/components/charts/BarChart.vue'
import { useI18n } from 'vue-i18n'
import { Dinero as DineroType } from 'dinero.js'
import { getPath } from './utils'
import dayjs from 'dayjs'

class SaleByItem {
  static from(json: Record<string, unknown> | SaleByItem) {
    return Object.assign(new SaleByItem(), json)
  }

  static ENDPOINT = 'reports/sales/sales-by-item'

  itemName?: string
  itemID?: string
  totalSoldAmount?: number
  totalSoldCurrency?: string
  totalSoldPrecision?: number
  totalSoldDinero?: DineroType
}
export default defineComponent({
  name: 'sales-by-item',
  components: {
    LoadingSpinner,
    AlertBadge,
    BarChart
  },
  data() {
    const rowData: SaleByItem[] = []
    const items: string[] = []
    const totals: number[] = []
    const messages = {
      en: {
        startDate: 'Start Date',
        endDate: 'End Date',
        salesByItem: 'Sales By Item'
      },
      ar: {
        startDate: 'Start Date',
        endDate: 'End Date',
        salesByItem: 'Sales By Item'
      }
    }

    const { t } = useI18n({
      messages
    })
    return {
      rowData,
      error: {
        title: '',
        body: ''
      },
      loading: false,
      locations: new Array<Location>(),
      location: '',
      items,
      period: [dayjs().startOf('month').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')], // the default period is from the first day of the month to the current day
      t,
      totals
    }
  },
  async beforeMount() {
    const locationsResp = await this.$http.get<Location[]>(Location.ENDPOINT)
    this.locations = locationsResp.map((cat) => Location.from(cat))
    this.updateRowData()
  },
  watch: {
    period: {
      handler: function () {
        this.updateRowData()
      }
    },
    location: {
      handler: function () {
        this.updateRowData()
      }
    }
  },
  methods: {
    async updateRowData() {
      try {
        const salesByItemResp = await this.$http.get<SaleByItem[]>(
          getPath(SaleByItem.ENDPOINT, this.location, this.period)
        )
        this.rowData = salesByItemResp.map((saleByItem) => SaleByItem.from(saleByItem))
        this.rowData.forEach((item) => {
          //here separating the names and values so we can send it to the chart
          this.items.push(item.itemName ? item.itemName : '')
          this.totals.push(item.totalSoldAmount ? item.totalSoldAmount : 0)
        })
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        })
      }
      this.loading = false
    }
  }
})
</script>

<style>
.el-date-editor .el-range-separator {
  width: auto !important;
}
</style>
