<template>
  <div class="m-3" style="height: 90vh">
    <div class="grid grid-cols-12 items-center content-center gap-2">
      <el-select
        class="input-label col-span-12 md:col-span-2"
        v-model="selectedValue"
      >
        <el-option key="allItems" :label="$t('views.common.listView.allItems')" value="allItems">
        </el-option>
        <el-option
          key="deletedItems"
          :label="$t('views.common.listView.deletedItems')"
          value="deletedItems"
        >
        </el-option>
        <el-option
          key="export"
          :label="$t('views.common.listView.selectedRows.export')"
          value="export"
        >
        </el-option>
        <el-option
          v-if="selectedValue != 'deletedItems'"
          key="delete"
          :label="$t('views.common.listView.selectedRows.delete')"
          value="delete"
        >
        </el-option>
        <el-option
          v-if="selectedValue == 'deletedItems'"
          key="restore"
          :label="$t('views.common.listView.selectedRows.restore')"
          value="restore"
        >
        </el-option>
      </el-select>

      <el-input
        :placeholder="$t('views.common.listView.searchRows')"
        class="w-full col-span-12 md:col-span-8"
        :prefix-icon="Search"
        v-model="searchTerm"
      />

      <div class="w-full col-span-12 md:col-span-2">
        <template v-if="$can('create', '/user/users')">
          <horizontal-button
            :title="$t('views.hr.employees.newEmployee')"
            @click.prevent="$router.push($Route.HR_EMPLOYEES_NEW_EMPLOYEE)"
          />
        </template>
      </div>
    </div>

    <ag-grid-vue
      v-if="rowModelType"
      class="ag-theme-alpine mt-4 md:mt-0"
      :columnDefs="columnDefs"
      :rowData="rowData"
      rowSelection="multiple"
      :rowModelType="rowModelType"
      :enable-rtl="$ctx.getDir() === 'rtl'"
      style="height: 85vh"
      :pagination="true"
      :paginationAutoPageSize="true"
      :defaultColDef="defaultColDef"
      @cell-double-clicked="onCellDoubleClicked"
      :onGridReady="onGridReady"
    >
    </ag-grid-vue>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

// components
import { AgGridVue } from 'ag-grid-vue3'
import { ColDef, GridApi, GridOptions } from 'ag-grid-community'
import HorizontalButton from '@/components/HorizontalButton.vue'
import { useI18n } from 'vue-i18n'
import User, { userI18nMessages } from '@/models/user/User'
import { Search } from '@element-plus/icons-vue'

export default defineComponent({
  name: 'employees',

  setup() {
    const { t } = useI18n({
      messages: userI18nMessages
    })

    return { t, Search }
  },

  data() {
    const columnDefs: ColDef[] = []
    const rowData: User[] = []
    const gridOptions: GridOptions = {}
    const defaultColDef: ColDef = {}

    return {
      columnDefs,
      rowData,
      gridOptions,
      defaultColDef,

      searchTerm: '',
      selectedValue: 'allItems',
      rowModelType: 'clientSide' as string|null,
      gridApi: undefined as GridApi | undefined
    }
  },

  components: {
    AgGridVue,
    HorizontalButton
  },

  beforeMount() {
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
      minWidth: 150
    }

    this.columnDefs = [
      {
        headerName: this.t('fullName'),
        field: 'fullName',
        checkboxSelection: true,
        headerCheckboxSelection: true
      },
      {
        headerName: this.t('username'),
        field: 'username',
        cellStyle: { textAlign: 'center' }
      },
      {
        headerName: this.t('email'),
        field: 'email',
        cellStyle: { textAlign: 'center' }
      },
      {
        headerName: this.t('phone'),
        field: 'phone',
        cellStyle: { direction: 'ltr !important', textAlign: 'center' }
      },
      {
        headerName: this.t('city'),
        field: 'city'
      }
    ]
  },

  mounted() {},

  watch: {
    searchTerm() {
      this.filterResult()
    },
    async selectedValue(newValue, oldValue) {
      if (oldValue == 'delete' || oldValue == 'export' || oldValue == 'restore') {
        return
      }

      if (this.selectedValue === 'delete' || this.selectedValue === 'export' || this.selectedValue === 'restore') {
        const selected = this.gridApi?.getSelectedRows()

        if (selected?.length) {
          if (this.selectedValue === 'delete') {
            // send request to archive
            await this.deleteItems(selected)
            await this.updateRowData()
          } else if (this.selectedValue === 'restore') {
            // send request to archive
            await this.restoreItems(selected)
            await this.updateRowData(oldValue == 'deletedItems')
          } else {
            this.gridApi?.exportDataAsCsv({ onlySelected: true })
            this.$alertModal.showSuccess({
              title: this.$t('views.common.listView.selectedRows.exportSuccess')
            })
          }

          // deselect
          selected.length = 0
        }
        this.selectedValue = oldValue
      } else if (this.selectedValue == 'allItems') {
        this.rowModelType = null
        this.defaultColDef.sortable = true
        this.defaultColDef.filter = true
        setTimeout(() => {
          this.rowModelType = 'clientSide'
        }, 100)
      } else if (this.selectedValue == 'deletedItems') {
        this.rowModelType = null
        this.defaultColDef.sortable = true
        this.defaultColDef.filter = true
        setTimeout(() => {
          this.rowModelType = 'clientSide'
        }, 100)
      }
    }
  },

  methods: {
    async updateRowData(softDeleted: boolean = false) {
      this.gridApi?.showLoadingOverlay()

      try {
        const data = await this.$http.get<User[]>(User.ENDPOINT)
        if (softDeleted) {
          this.rowData = data.map((employee) => User.from(employee)).filter((employee) => employee.deletedAt != null)
        } else {
          this.rowData = data.map((employee) => User.from(employee)).filter((employee) => employee.deletedAt == null)
        }
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        })
      }

      this.gridApi?.hideOverlay()
    },

    async onGridReady(params: any) {
      this.gridApi = params.api
      this.gridApi?.sizeColumnsToFit()
      if (this.selectedValue == 'allItems') {
        this.updateRowData()
      } else if (this.selectedValue == 'deletedItems') { 
        await this.updateRowData(true)
      }
    },

    async deleteItems(employees: User[]) {
      if (confirm(this.$t('views.common.listView.selectedRows.deleteConfirm'))) {
        try {
          await this.$http.delete(User.ENDPOINT, { data: employees })
          this.$alertModal.showSuccess({
            title: this.$t('views.common.listView.selectedRows.deleteSuccess')
          })
        } catch (error) {
          this.$alertModal.showDanger({
            title: error.title,
            body: error.body
          })
        }
      }
    },

    async restoreItems(employees: User[]) {
      if (confirm(this.$t('views.common.listView.selectedRows.restoreConfirm'))) {
        try {
          await this.$http.post('/helpers/restore-deleted', { data: employees, table: 'users' })
          this.$alertModal.showSuccess({
            title: this.$t('views.common.listView.selectedRows.restoreSuccess')
          })
        } catch (error) {
          this.$alertModal.showDanger({
            title: error.title,
            body: error.body
          })
        }
      }
    },

    filterResult() {
      this.gridApi?.setQuickFilter(this.searchTerm)
    },

    onCellDoubleClicked(event: any) {
      this.$router.push(this.$Route.HR_EMPLOYEES_EMPLOYEE.replace(':id', event.data.id))
    }
  }
})
</script>

<style></style>
