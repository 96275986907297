<template>
  <div v-if="loading" class="flex h-full justify-center items-center">
    <loading-spinner diameter="64px" />
  </div>

  <alert-badge
    v-else-if="error.title"
    isDanger
    :title="error.title"
    :body="error.body"
    @dismissed="
      () => {
        error.title = ''
        error.body = ''
      }
    "
  />

  <div v-else class="flex flex-col md:flex-row">
    <div class="w-full mx-2 md:w-9/12">
      <div class="mt-2">
        <el-table
          :data="inventoryReceiving.inventoryReceivingItems"
          stripe
          border
          style="width: 100%"
          height="300"
          :max-height="800"
          class="card rounded"
          header-cell-class-name="my-header"
        >
          <el-table-column :min-width="40" type="index" label="#" />
          <el-table-column :min-width="90" prop="item.externalID" :label="t('itemCode')" />
          <el-table-column :min-width="220" :label="t('itemName')">
            <template #default="scope">
              <div class="truncate">
                {{ scope.row.item.name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :min-width="120"
            prop="costDinero"
            :label="t('cost')"
            align="center"
          >
            <template #default="scope">
              <div class="flex justify-between">
                <span>
                  {{ formatDinero(scope.row.costDinero) }}
                </span>
              </div>
            </template>
          </el-table-column>

          <el-table-column :min-width="120" :label="t('quantity')">
            <template #default="scope">
              <div class="flex items-center">
                <div class="px-2 pt-1">
                  {{ scope.row.quantity }}
                </div>
                <el-popover
                  v-if="scope.row.inventoryReceivingItemSerials.length"
                  placement="bottom-start"
                  :title="t('serialNumbers')"
                  :min-width="200"
                  trigger="hover"
                >
                  <template #default>
                    <p v-for="(serial, i) in scope.row.inventoryReceivingItemSerials" :key="i">
                      {{ serial.itemSerial.number }}
                    </p>
                  </template>
                  <template #reference>
                    <div class="flex justify-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        height="16px"
                        width="16px"
                        version="1.1"
                        id="Capa_1"
                        viewBox="0 0 15.273 15.273"
                        xml:space="preserve"
                      >
                        <g>
                          <path
                            style="fill: #030104"
                            d="M0,1.803h0.897V13.47H0V1.803z M1.446,13.47h0.505V1.803H1.446V13.47z M2.315,13.47h0.504V1.803   H2.315V13.47z M3.482,13.47h1.104V1.803H3.482V13.47z M5.47,13.47h0.552V1.803H5.47V13.47z M6.878,13.47h2.288V1.803H6.878V13.47z    M12.84,13.47h0.752V1.803H12.84V13.47z M14.248,1.803V13.47h1.025V1.803H14.248z M10.084,13.47h0.492V1.803h-0.492V13.47z    M11.281,13.47h0.492V1.803h-0.492V13.47z"
                          />
                        </g>
                      </svg>
                    </div>
                  </template>
                </el-popover>
              </div>
            </template>
          </el-table-column>

          <el-table-column :min-width="120" prop="itemTotal" :label="t('itemTotal')" align="center">
            <template #default="scope">
              {{ calculateItemTotal(scope) }}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="mt-4 font-bold">{{ t('relatedPayments') }}</div>
      <div class="mt-2">
        <el-table
          :data="transactions"
          stripe
          border
          style="width: 100%"
          :max-height="800"
          class="card rounded"
          header-cell-class-name="my-header2"
        >
          <el-table-column :min-width="60" prop="externalID" :label="t('paymentID')" />
          <el-table-column :min-width="90" prop="amountAmount" :label="t('amount')">
            <template #default="scope">
              {{ formatDinero(scope.row.amountDinero) }}
            </template>
          </el-table-column>
          <!-- <el-table-column :min-width="90" prop="paymentMethod" :label="t('paymentMethod')">
            <template #default="scope">
              {{ t(scope.row.paymentMethod) }}
            </template>
          </el-table-column> -->
          <el-table-column :min-width="110" prop="createdAt" :label="t('date')">
            <template #default="scope">
              <span>
                {{ formatPostgresDate(scope.row.createdAt, 'DD/MM/YYYY hh:mm A') }}
                {{ scope.row.createdAt != scope.row.updatedAt ? `(${t('edited')})` : '' }}
              </span>
            </template>
          </el-table-column>
          <el-table-column :min-width="70" prop="externalID" :label="t('totalPaid')">
            <template #default="scope">
              <span :class="{ 'text-red-600': scope.row.receiptType == 'return' }">
                {{ formatDinero(scope.row.totalPaidAmountDinero) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column :min-width="90" :label="t('owed')">
            <template #default="scope">
              {{ formatDinero(getOwed(scope.row)) }}
            </template>
          </el-table-column>
          <el-table-column :min-width="100" :label="t('cashier')">
            <template #default="scope">
              <div class="flex items-center justify-around">
                <span>
                  {{ `${scope.row.user.firstName} ${scope.row.user.lastName}` }}
                </span>
                <div>
                  <button
                    class="text-black hover:text-blue-600 font-bold"
                    @click="printTx(scope.row)"
                  >
                    <span class="fa fa-print"></span>
                  </button>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

  <div class="w-full md:w-3/12 mt-2" style="font-size: 14px !important">
    <div class="flex flex-col items-center px-3" v-if="inventoryReceiving">
      <div class="w-full">
        <div class="card rounded p-3">
          <div class="flex justify-between p-3 w-full bg-blue-100 rounded">
            <p>{{ t('vendorBillID') }}</p>
            <p class="flex justify-end custom-bold">
              {{ vendorBill?.externalID}}
            </p>
          </div>
          <div class="flex justify-between p-3 w-full bg-gray-100 rounded mt-1">
            <p>{{ t('receivingID') }}</p>
            <p class="flex justify-end custom-bold">
              {{ inventoryReceiving?.externalID }}
            </p>
          </div>
          <div class="flex justify-between p-3 w-full">
            <p>{{ t('models.common.date') }}</p>
            <p class="flex justify-end custom-bold">
              {{ formatPostgresDate(inventoryReceiving?.createdAt, 'DD/MM/YYYY hh:mm A') }}
            </p>
          </div>
          <div class="flex justify-between p-3 w-full">
            <p>{{ t('receivedBy') }}</p>
            <p class="flex justify-end custom-bold">
              {{ inventoryReceiving?.user.fullName }}
            </p>
          </div>
          <div class="flex justify-between w-full p-3">
            <p>{{ t('location') }}</p>
            <p class="flex justify-end custom-bold">
              {{
                $ctx.locale === 'en'
                  ? inventoryReceiving?.location?.enName
                  : inventoryReceiving?.location?.arName
              }}
            </p>
          </div>
          <div class="flex justify-between w-full p-3">
            <p>{{ t('notes') }}</p>
            <p class="flex justify-end custom-bold">
              {{ inventoryReceiving?.notes }}
            </p>
          </div>
        </div>

        <div class="card rounded p-3 mt-4">
          <div class="flex justify-between w-full p-3">
            <p>{{ t('otherCosts') }}</p>
            <p class="flex justify-end custom-bold">
              {{ formatDinero(inventoryReceiving.vendorBill?.otherCostsDinero) }}
            </p>
          </div>

          <div class="flex justify-between w-full p-3">
            <p>{{ t('total') }}</p>
            <p class="flex justify-end custom-bold">
              {{ formatDinero(inventoryReceiving.vendorBill?.totalDueDinero) }}
            </p>
          </div>

          <div class="flex justify-between w-full p-3 bg-green-100 rounded">
            <p>{{ t('paymentsMade') }}</p>
            <p class="flex justify-end custom-bold">
              {{ formatDinero(inventoryReceiving.vendorBill?.totalPaidDinero) }}
            </p>
          </div>
          <div class="flex justify-between w-full p-3 bg-red-100 rounded mt-2">
            <p>{{ t('remainingBalance') }}</p>
            <p class="flex justify-end custom-bold">
              {{ formatDinero(inventoryReceiving.vendorBill?.totalBalanceDinero) }}
            </p>
          </div>
        </div>
      </div>

      <div class="card rounded w-full p-3 mt-4">
        <div class="flex flex-col items-center w-12/12 gap-1 text-black">
          <horizontal-button
            faIcon="money-bill-wave"
            :title="t('makePayment')"
            @click="handleMakePayment()"
          />
        </div>
      </div>
    </div>
  </div>
</div>

</template>

<script lang="ts">
import { defineComponent } from 'vue'

import AlertBadge from '@/components/AlertBadge.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'

// models
import { i18nOrderMessages, OrderItem } from '@/models/sales/Order'

// tools
import { useI18n } from 'vue-i18n'

// helpers
import { formatDinero } from '@/utils/money'
import { parseFormat, formatPostgresDate } from '@/utils/date'
import Dinero from 'dinero.js'
import VendorBill, { vendorBillI18nMessages } from '@/models/vendor/VendorBill'
import InventoryReceiving, { inventoryReceivingI18nMessages } from '@/models/inventory/InventoryReceiving'
import { itemI18nMessages } from '@/models/inventory/Item'
import { Route } from '@/router/routes'
import HorizontalButton from '@/components/HorizontalButton.vue'


export default defineComponent({
  name: 'vendorBill',
  setup() {
    const { t } = useI18n({
      useScope: 'global',
      messages: {
        en: {
          ...vendorBillI18nMessages.en,
          ...inventoryReceivingI18nMessages.en,
          ...i18nOrderMessages.en,
          ...itemI18nMessages.en,
          generalInfoSection: 'General Info',
          label: 'Key',
          value: 'Value',
          receivingID: 'Receiving ID',
          receivedBy: 'Received By',
          paymentsMade: 'Payments Made',
          remainingBalance: 'Remaining Balance',
          makePayment: 'Make Payment',
          moneyOwed: 'Money Owed',
          relatedPayments: 'Related Payments',
          owed: 'Owed',
          date: 'Date',
          paymentID: 'Payment ID',
          amount: 'Amount',
          cashier: 'Cashier',
        },
        ar: {
          ...vendorBillI18nMessages.ar,
          ...inventoryReceivingI18nMessages.ar,
          ...i18nOrderMessages.ar,
          ...itemI18nMessages.ar,
          generalInfoSection: 'المعلومات العامة',
          label: 'الحقل',
          value: 'القيمة',
          paymentID: 'رقم الحركة',

          receivingID: 'رقم الاستلام',
          receivedBy: 'استلم من قبل',
          remainingBalance: 'المبلغ المتبقي',
          makePayment: 'تسديد مبلغ',
          relatedPayments: 'المدفوعات ذات الصلة',
          amount: 'القيمة',

          paymentsMade: 'المبلغ المدفوع',
          moneyOwed: 'المبلغ المطلوب',
          owed: 'الباقي',
          date: 'التاريخ',
          cashier: 'المحاسب',
        }
      }
    })

    function getOwed(row: any) {
      let owed = 0
      owed = row.totalDueAmount > 0 ? row.totalDueAmount - row.amountAmount : 0

      const value = Dinero({
        amount: owed,
        currency: row.currency,
        precision: row.precision
      })

      return value
    }

    const orderTableRowClassName = ({ row, rowIndex }: { row: OrderItem; rowIndex: number }) => {
      if (row.quantity - row.returnedQuantity == 0) {
        return 'danger-row'
      } else if (row.returnedQuantity > 0) {
        return 'warning-row'
      }

      return ''
    }

    return { t, formatPostgresDate, getOwed, orderTableRowClassName }
  },

  data() {
    return {
      vendorBill: new VendorBill(),
      inventoryReceiving: new InventoryReceiving(),
      transactions: null as any,
      error: {
        title: '',
        body: ''
      },
      loading: false
    }
  },

  async beforeMount() {
    await this.load()
  },

  components: {
    AlertBadge,
    LoadingSpinner,
    HorizontalButton
  },

  methods: {
    calculateItemTotal(scope: any) {
      const price = Dinero({
        amount: scope.row.quantity * scope.row.costAmount,
        currency: scope.row.item.costCurrency,
        precision: scope.row.item.costPrecision
      })
      return formatDinero(price)
    },
    async load() {
      // get order
      try {
        this.loading = true
        const resp = await this.$http.get<VendorBill>(
          `${VendorBill.ENDPOINT}/${this.$route.params.id}`
        )
        this.vendorBill = VendorBill.from(resp)

        if (this.vendorBill.referenceableType == 'inventory.InventoryReceiving') {
          const resp = await this.$http.get<InventoryReceiving>(
            `${InventoryReceiving.ENDPOINT}/${this.vendorBill.referenceableID!}`
          )
          this.inventoryReceiving = InventoryReceiving.from(resp)
        }

        this.transactions = this.vendorBill.transactions
          .filter((i) => {
            if (i.isReceipt) return i
          })
          .reverse()
      } catch (error) {
        console.log(error)
        this.error.title = error.title
        this.error.body = error.body
      } finally {
        this.loading = false
      }
    },
    formatDinero,
    parseFormat,
    indexMethod(index: number) {
      return index + 1
    },
    printTx(data: any) {
      this.$router.push(this.$Route.SALES_TRANSACTIONS_RECEIPT.replace(':transactionID', data.id))
    },
    onTxUpdated() {
      this.load()
    },
    handleMakePayment() {
      this.$router.push(
        Route.ACCOUNTING_VENDORS_BILL_PAYMENT.replace(':id', this.vendorBill.id as string)
      )
    }
  }
})
</script>

<style scoped>
.card {
  background: var(--secondary-bg-color);
}
</style>
