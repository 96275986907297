<template>
  <div>
    <div v-if="loading" class="flex items-center justify-center h-full">
      <loading-spinner diameter="64px" />
    </div>

    <!-- @submit errors -->
    <alert-badge
      isDanger
      class="my-2"
      :title="error.title"
      :body="error.body"
      @dismissed="
        () => {
          error.title = ''
          error.body = ''
        }
      "
    />

    <div class="floating-money flex flex-col">
      <div id="floating-money-child" :class="{'bg-yellow-100' : total !== expectedOpenAmount, 'bg-white': total !== expectedOpenAmount }">
        <h1>{{t("floatingMoney")}}</h1>

        <div class="text-center">
          <p>{{t("tooltip")}}</p>
        </div>

        <div class="text-center bg-blue-100 p-2 mt-2" v-if="lastAction">
          <p>{{t("expected")}}: {{formatMoney(expectedOpenAmount, currency)}}</p>
        </div>

        <div class="input-group" >
          <label for="cash">{{t("cash")}}</label>
          <money-input :currency="$ctx.currency" class="opening-amount" v-model="total"></money-input>
        </div>

        <div class="input-group" >
          <label for="notes">{{t("notes")}}</label>
          <el-input type="textarea" id="notes" v-model="notes"></el-input>
        </div>

        <button @click.prevent="openCashierPinDialog">{{t('submitCount')}}</button>
      </div>
      </div>
  </div>

  <el-dialog v-model="cashierPinDialogOpen" width="30%">
    <div>
      <!-- Enter the pin -->
      <div class="my-2">
        <p>{{ t('enterCashierPin') }}</p>
      </div>

      <!-- password input up to 6 chars -->
      <div class="my-2">
        <el-input
          id="password"
          v-model="cashierPin"
          type="password"
          required
          show-password
          autofocus
          dir="ltr"
          :maxlength="4"
          :minlength="4"
          ref="cashierPinInput"
          @keyup.enter="validateSubmit"
        />
      </div>

      <!-- finish payment -->
      <div class="flex items-center justify-between mb-2">
        <horizontal-button
          class="text-lg complete-payment"
          :title="t('submitCount')"
          @click="validateSubmit"
          :rounded="false"
        />
      </div>
    </div>
  </el-dialog>

</template>

<script lang="ts">
import { defineComponent, nextTick } from 'vue'
import AlertBadge from '@/components/AlertBadge.vue'

import { useI18n } from 'vue-i18n'
import { OpenRegisterPayload, Register, RegisterAction, RegisterLog } from '@/models/company/Register'
import MoneyInput from "@/components/form/MoneyInput.vue";
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import { formatMoney } from '../../../utils/money'
import HorizontalButton from '@/components/HorizontalButton.vue';

export default defineComponent({
  name: 'open-register',

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          submitCount: 'Open Register',
          tooltip: 'Please enter opening amount',
          floatingMoney: "Floating Money",
          cash: "Cash",
          expected: "Expected open amount",
          notes: "Notes",
          enterCashierPin: 'Enter cashier pin',
          errCashierPinRequired: 'Cashier pin is required'
        },
        ar: {
          submitCount: 'افتح الخزانة',
          tooltip: 'يرجى ادخال المبلغ الاولي في الخزانة',
          floatingMoney: "الخردة",
          cash: "النقد",
          expected: "مبلغ الخردة المتوقع",
          notes: "الملاحظات",
          enterCashierPin: 'ادخل رمز الكاشير الخاص بك',
          errCashierPinRequired: 'يرجى ادخال رمز الكاشير لاتمام البيع'
        }
      }
    })

    return { t }
  },

  components: {
    LoadingSpinner,
    MoneyInput,
    AlertBadge,
    HorizontalButton
  },

  data() {
    return {
      error: {
        title: '',
        body: ''
      },
      total: 0,
      currency: this.$ctx.currency,
      precision: this.$ctx.currencyPrecision,
      loading: false,
      expectedOpenAmount: 0,
      lastAction: "",
      notes: "",
      cashierPinDialogOpen: false,
      cashierPin: ''
    }
  },

  async beforeMount() {
    try {
      this.loading = true
      const registerLog = await this.$http.get<RegisterAction>(
        `${Register.ENDPOINT}/status/${this.$ctx.currentRegister.id}`
      )

      if (registerLog.action === 'open') {
        await this.$router.push(this.$Route.SALES_REGISTERS_CLOSE_REGISTER)
      }

      if (registerLog.action === 'close') {
        this.lastAction = "close"
        this.expectedOpenAmount = registerLog.actualClosingFloatingAmount || 0
      }
      // else, it means this is the first open ever

    } catch (error) {
      this.error.title = error.title
      this.error.body = error.body
    } finally {
      this.loading = false
    }
  },

  methods: {
    formatMoney,
    async validateSubmit() {
      if (this.cashierPin === '') {
        this.cashierPinDialogOpen = false
        this.error.title = this.t('errCashierPinRequired')
        return
      }

      try {
        const payload: OpenRegisterPayload = {
          actualOpenAmount: this.total,
          currency: this.currency,
          precision: this.precision,
          registerID: this.$ctx.currentRegister.id,
          timestamp: new Date(),
          userID: this.$ctx.currentUser.id,
          action: 'open',
          notes: this.notes
        }

        await this.$http.post(`/company/register-actions`, payload, {headers: { pin: this.cashierPin }})

        await this.$router.push(this.$Route.SALES)
      } catch (error) {
        this.error.title = error.title
        this.error.body = error.body
        this.cashierPinDialogOpen = false
        return error
      }
    },
    openCashierPinDialog() {
      this.cashierPinDialogOpen = true
      nextTick(() => {
        setTimeout(() => {
          /* @ts-ignore */
          this.$refs['cashierPinInput'].focus()
        }, 0)
      })
    }
  }
})
</script>

<style scoped>

.floating-money {
  max-width: 600px;
  margin-left: 20px;
  margin-right: 20px;
  border: 1px solid #ccc;
  padding: 40px;
  border-radius: 10px;
  background: #f9f9f9;
}

#floating-money-child {
  text-align: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.floating-money h1 {
  text-align: center;
}

.input-group {
  margin-top: 15px;
  margin-bottom: 15px;
  background: #fff;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.input-group label {
  display: block;
  font-weight: bold;
  color: #666;
}

.opening-amount input {
  width: 100%;
  padding: 20px !important;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  width: 100%;
  padding: 15px;
  border: none;
  border-radius: 8px;
  background-color: #9AE6B4;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #6bd58f;
  color: white;
}
</style>