<template>
  <div class="m-3" style="height: 90vh">
    <div class="w-full text-xl font-bold mt-2">
      {{ t('quickFacts') }}
    </div>
    <div class="grid grid-cols-2 gap-2">
      <div class="col-span-12 md:col-span-1 chart-card" style="height: 550px">
        <v-chart :option="option1" autoresize />
      </div>
      <div class="col-span-12 md:col-span-1 chart-card" style="height: 550px">
        <v-chart :option="option2" autoresize />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onBeforeMount, provide } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { GaugeChart } from 'echarts/charts'
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
  DataZoomComponent
} from 'echarts/components'
import VChart, { THEME_KEY } from 'vue-echarts'

import { useHTTP } from '@/plugins/http'
import { useAlertModal } from '@/plugins/alert-modal/alert-modal'
import { formatMoney } from '@/utils/money'

// i18n
const { t } = useI18n({
  messages: {
    en: {
      quickFacts: 'Quick Facts',
      totalAvailableItems: 'Total Available Items',
      itemsTotalValue: 'Items Total Value'
    },
    ar: {
      quickFacts: 'معلومات عامة',
      totalAvailableItems: 'أجمالي البضائع المتوفرة',
      itemsTotalValue: 'أجمالي قيمة البضائع'
    }
  }
})

use([
  CanvasRenderer,
  GaugeChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
  DataZoomComponent
])

provide(THEME_KEY, 'light')

const option1 = ref({
  title: {
    text: t('itemsTotalValue'),
    left: 'left'
  },
  toolbox: {
    feature: {
      saveAsImage: {}
    }
  },
  series: [
    {
      name: t('itemsTotalValue'),
      type: 'gauge',

      // startAngle: 90,
      // endAngle: -270,
      pointer: {
        show: false
      },
      progress: {
        show: true,
        overlap: false,
        roundCap: true,
        clip: false,
        itemStyle: {
          borderWidth: 1,
          // borderColor: '#464646'
        }
      },
      axisLine: {
        lineStyle: {
          width: 30
        }
      },
      splitLine: {
        show: false,
        distance: 0,
        length: 10
      },
      axisTick: {
        show: true
      },
      axisLabel: {
        show: false,
        distance: 50
      },
      title: {
        fontSize: 14,
      },
      detail: {
        padding: [5, 40],
        height: 14,
        fontSize: 20,
        // color: 'inherit',
        borderColor: 'inherit',
        // borderRadius: 10,
        // borderWidth: 1,
        formatter: (val: any) => (val ? formatMoney(val, 'IQD') : '')
      },
      // center: ['50%', '60%'],

      data: [
        {
          value: 0,
          detail: {
            valueAnimation: true,
            offsetCenter: ['0%', '0%']
          }
        },
      ]
    }
  ]
})

const option2 = ref({
  title: {
    text: t('totalAvailableItems'),
    left: 'left'
  },
  toolbox: {
    feature: {
      saveAsImage: {}
    }
  },
  series: [
    {
      name: t('totalAvailableItems'),
      type: 'gauge',

      // startAngle: 90,
      // endAngle: -270,
      pointer: {
        show: false
      },
      progress: {
        show: true,
        overlap: false,
        roundCap: true,
        clip: false,
        itemStyle: {
          borderWidth: 1,
          // borderColor: '#464646'
        }
      },
      axisLine: {
        lineStyle: {
          width: 30
        }
      },
      splitLine: {
        show: false,
        distance: 0,
        length: 10
      },
      axisTick: {
        show: true
      },
      axisLabel: {
        show: false,
        distance: 50
      },
      title: {
        fontSize: 14,
      },
      detail: {
        padding: [5, 40],
        height: 14,
        fontSize: 20,
        borderColor: 'inherit',
        // borderRadius: 10,
        // borderWidth: 1,
      },
      // radius: '95%',
      // center: ['50%', '60%'],

      data: [
        {
          value: 90,
          detail: {
            valueAnimation: true,
            offsetCenter: ['0%', '0%']
          }
        },
      ]
    }
  ]
})

// Data
const columnDefs = ref<ColDef[]>([])
const defaultColDef = ref<ColDef>({})

const http = useHTTP()
const alertModal = useAlertModal()

onBeforeMount(async () => {
  await load()
})

// Methods
async function load() {
  try {
    const data = await http.get<any>('/reports/inventory/quick-facts')
    const chartData: any = []
    // option1.value.series[0].data = chartData
    option1.value.series[0].data[0].value = data.items_total_value
    option2.value.series[0].data[0].value = data.total_available_items
  } catch (error) {
    alertModal.showDanger({
      title: error.title,
      body: error.body
    })
  }
}
</script>
