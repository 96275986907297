<template>
  <g id="Group_1" data-name="Group 1">
    <path
      id="Subtraction_2"
      data-name="Subtraction 2"
      d="M95,61H5a5.006,5.006,0,0,1-5-5V5A5.006,5.006,0,0,1,5,0H95a5.006,5.006,0,0,1,5,5V56A5.006,5.006,0,0,1,95,61ZM93,41A14.015,14.015,0,0,0,79,55H93ZM7,41V55H21A14.016,14.016,0,0,0,7,41ZM50,14A17,17,0,1,0,67,31,17.019,17.019,0,0,0,50,14ZM79,7A14.015,14.015,0,0,0,93,21V7ZM7,7V21A14.015,14.015,0,0,0,21,7Z"
      transform="translate(0 19)"
      fill="currentColor"
    />
    <path
      id="Path_5"
      data-name="Path 5"
      d="M27.8-16.992v5.825H3.418v-5.825Zm-9.128,15.1H12.546V-26.27h6.125Z"
      transform="translate(34.582 64.27)"
      fill="currentColor"
    />
  </g>
</template>
