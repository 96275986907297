<template>
  <el-form
    @submit.prevent="openCashierPinDialog"
    :model="transaction"
    :rules="rules"
    ref="transactionForm"
  >
    <input-section>
      <div class="flex flex-col md:flex-row justify-end">
        <div class="md:w-4/12 m-2">
          <horizontal-button
            :title="$t('actions.saveChanges')"
            isSuccess
            faIcon="save"
            @click.prevent="openCashierPinDialog"
          />
        </div>

        <div class="md:w-4/12 m-2">
          <horizontal-button
            :title="$t('actions.clearButton')"
            isDanger
            faIcon="trash"
            @click.prevent="clearForm('transactionForm')"
          />
        </div>
      </div>
    </input-section>

    <!-- @submit errors -->
    <alert-badge
      isDanger
      :title="error.title"
      :body="error.body"
      @dismissed="
        () => {
          error.title = ''
          error.body = ''
        }
      "
    />

    <input-section>
      <h1>{{ t('section') }}</h1>

      <el-form-item :label="t('journalType')" prop="journalType" class="input-label">
        <el-select v-model="transaction.journalType" class="w-full">
          <el-option
            v-for="item in ['deposit', 'withdrawal', 'transfer']"
            :key="item"
            :label="t(item)"
            :value="item"
          >
            <span :class="{ 'float-right': $ctx.getDir() == 'rtl' }">{{ t(item) }}</span>
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="t('amount')" prop="amountAmount" class="input-label">
        <money-input
          v-model="transaction.amountAmount"
          :currency="$ctx.currency"
          @dinero-created="(dinero) => (transaction.amountDinero = dinero)"
          @formatted-money-updated="(val) => (formattedAmount = val)"
        />
      </el-form-item>

      <input-group>
        <el-form-item :label="t('creditedAccount')" class="input-label" prop="creditedAccountID">
          <el-select
            id="credited-acount"
            v-model="transaction.creditedAccountID"
            :trigger-on-focus="false"
            value-key="name"
            class="w-full"
            filterable
            remote
            :remote-method="searchAccounts"
          >
            <el-option
              v-for="option in accounts"
              :key="option.id"
              :label="$ctx.locale === 'ar' ? option.arName : option.enName"
              :value="option.id"
            ></el-option>
          </el-select>
          <div v-if="formattedAmount" class="button-danger px-4 w-full">
            <i class="el-icon-remove"></i> {{ formattedAmount }}
          </div>
        </el-form-item>

        <el-form-item :label="t('debitedAccount')" class="input-label" prop="debitedAccountID">
          <el-select
            id="debited-acount"
            v-model="transaction.debitedAccountID"
            :trigger-on-focus="false"
            value-key="name"
            class="w-full"
            filterable
            remote
            :remote-method="searchAccounts"
          >
            <el-option
              v-for="option in accounts"
              :key="option.id"
              :label="$ctx.locale === 'ar' ? option.arName : option.enName"
              :value="option.id"
            ></el-option>
          </el-select>
          <div v-if="formattedAmount" class="button-success px-4 w-full">
            <i class="el-icon-circle-plus"></i> {{ formattedAmount }}
          </div>
        </el-form-item>
      </input-group>

      <el-form-item :label="t('description')" prop="description" class="input-label">
        <el-input
          id="description"
          v-model="transaction.description"
          :placeholder="t('descriptionPlaceholder')"
          maxlength="255"
          show-word-limit
          clearable
        >
        </el-input>
      </el-form-item>
    </input-section>
  </el-form>
  <el-dialog v-model="cashierPinDialogOpen" width="30%">
      <div>
        <!-- Enter the pin -->
        <div class="my-2">
          <p>{{ t('dialogs.enterCashierPin') }}</p>
        </div>

        <!-- password input up to 6 chars -->
        <div class="my-2">
          <el-input
            id="password"
            v-model="cashierPin"
            type="password"
            required
            show-password
            autofocus
            dir="ltr"
            :maxlength="4"
            :minlength="4"
            ref="cashierPinInput"
            @keyup.enter="validateSubmit('transactionForm')"
          />
        </div>

        <!-- finish payment -->
        <div class="flex items-center justify-between mb-2">
          <horizontal-button
            class="text-lg complete-payment"
            :title="t('dialogs.completePay')"
            @click="validateSubmit('transactionForm')"
            :rounded="false"
          />
        </div>
      </div>
    </el-dialog>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

// components
import HorizontalButton from '@/components/HorizontalButton.vue'
import InputSection from '@/components/form/InputSection.vue'
import AlertBadge from '@/components/AlertBadge.vue'

import { ElForm } from 'element-plus'
import MoneyInput from '@/components/form/MoneyInput.vue'

// models
import { Transaction, transactionI18nMessages } from '@/models/accounting/Transaction'
import { Account } from '@/models/accounting/Account'
import { useI18n } from 'vue-i18n'
import InputGroup from '@/components/form/InputGroup.vue'
import User from '@/models/user/User'

export default defineComponent({
  name: 'transaction-form',

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          ...transactionI18nMessages.en,
          section: 'Transaction Info',
          descriptionPlaceholder: 'Example: paid utility bill',
          accountsMustNotMatch: 'Credited Account must not equal Debited Account',
          successMsg: "Transaction has been recorded"
        },
        ar: {
          ...transactionI18nMessages.ar,
          section: 'معلومات الحركة المالية',
          descriptionPlaceholder: 'مثلا: قمنا بدفع فاتورة الكهرباء',
          accountsMustNotMatch: 'لايمكن لحساب الدائن ان يطابق حساب المدين',
          successMsg: "تم تسجييل الحركة المالية"
        }
      },
      unescape: 'global'
    })

    const cashierPinDialogOpen = ref(false)
    const cashierPin = ref('')

    return { 
      t,  
      cashierPinDialogOpen,
      cashierPin
     }
  },

  components: {
    AlertBadge,
    HorizontalButton,
    InputSection,
    MoneyInput,
    InputGroup,
    ElForm
  },

  data() {
    const accounts = new Array<Account>()

    return {
      transaction: new Transaction(),
      error: { title: '', body: '' },
      formattedAmount: '',
      accounts,
      user: this.$ctx.currentUser,
      rules: {
        journalType: [
          {
            required: true,
            message: this.$t('validation.required'),
            trigger: 'blur'
          }
        ],
        amountAmount: [
          {
            required: true,
            message: this.$t('validation.required'),
            trigger: 'blur'
          },
          {
            type: 'number',
            min: 0
          }
        ],
        debitedAccountID: [
          {
            required: true,
            message: this.$t('validation.required'),
            trigger: 'blur'
          }
        ],
        creditedAccountID: [
          {
            required: true,
            message: this.$t('validation.required'),
            trigger: 'blur'
          }
        ]
      }
    }
  },

  methods: {
    async searchAccounts(query: string) {
      if (!query) {
        return
      }

      try {
        const url = `${Account.ENDPOINT}?q=${query}`
        const accounts = await this.$http.get<Account[]>(url)
        this.accounts = accounts
      } catch (error) {
        this.$alertModal.showDanger({ title: error.title })
        this.accounts = []
      }
    },

    clearForm(formName: string) {
      const form = this.$refs[formName] as typeof ElForm
      form.resetFields()
    },

    async submit(t: Transaction) {
      this.error.title = ''
      this.error.body = ''

      t.preparePayload(this.$ctx.currentLocation, this.$ctx.currentRegister, this.user as User)

      try {
        this.cashierPinDialogOpen = false
        const result = await this.$http.post<Transaction>(Transaction.ENDPOINT, t, {headers: { pin: this.cashierPin }})
        this.$router.push(this.$Route.ACCOUNTING_TRANSACTIONS_TRANSACTION.replace(':id', result.id))
      } catch (error) {
        return error
      }
    },

    validPostings() {
      if (this.transaction.creditedAccountID === this.transaction.debitedAccountID) {
        this.error.title = this.$t('validation.inputErrors')
        this.error.body = this.t('accountsMustNotMatch')
        document.getElementById('main')?.scrollTo({ top: 0 })
        return false
      }

      return true
    },

    openCashierPinDialog() {
      this.cashierPinDialogOpen = true
      this.$nextTick(() => {
        setTimeout(() => {
          /* @ts-ignore */
          this.$refs['cashierPinInput'].focus()
        }, 0)
      })
    },

    async validateSubmit(formName: string) {
      this.error.title = ''

      if (this.cashierPin === '') {
        this.cashierPinDialogOpen = false
        this.error.title = this.t('dialogs.errCashierPinRequired')
        return
      }

      const form = this.$refs[formName] as typeof ElForm
      form?.validate(async (valid: boolean) => {
        if (!valid || !this.validPostings()) {
          document.getElementById('main')?.scrollTo({ top: 0 })
          this.cashierPinDialogOpen = false
          return false
        }

        const resp = await this.submit(this.transaction as Transaction)

        if (resp) {
          this.error.title = resp?.title
          this.error.body = resp?.body
          document.getElementById('main')?.scrollTo({ top: 0 })
          return false
        } else {
          this.clearForm(formName)

          this.$alertModal.showSuccess({
            title: this.t('successMsg'),
            body: ''
          })
        }
        return true
      })
    }
  }
})
</script>

<style scoped>
h1,
h2,
h3 {
  @apply text-lg  font-bold;
}

form {
  @apply flex flex-col items-center;
}
</style>
