export type PermGroup = {
  object?: string
  grantedActions?: boolean[]
}

export const defaultAllFalse: PermGroup[] = [
  {
    object: '/examination/examinations',
    grantedActions: [false, false, false, false]
  },
  {
    object: '/accounting/accounts',
    grantedActions: [false, false, false, false]
  },
  {
    object: '/accounting/transactions',
    grantedActions: [false, false, false, false]
  },
  {
    object: '/customer/customers',
    grantedActions: [false, false, false, false]
  },
  {
    object: '/hr/employeeclocks',
    grantedActions: [false, false, false, false]
  },
  {
    object: '/hr/employeeschedule',
    grantedActions: [false, false, false, false]
  },
  {
    object: '/inventory/inventoryadjustments',
    grantedActions: [false, false, false, false]
  },
  {
    object: '/inventory/inventoryreceivings',
    grantedActions: [false, false, false, false]
  },
  {
    object: '/inventory/inventorytransfers',
    grantedActions: [false, false, false, false]
  },
  {
    object: '/inventory/itembrands',
    grantedActions: [false, false, false, false]
  },
  {
    object: '/inventory/itemcategories',
    grantedActions: [false, false, false, false]
  },
  {
    object: '/inventory/items',
    grantedActions: [false, false, false, false]
  },
  {
    object: '/inventory/itemtags',
    grantedActions: [false, false, false, false]
  },
  {
    object: '/sales/orderpayments',
    grantedActions: [false, false, false, false]
  },
  {
    object: '/sales/orders',
    grantedActions: [false, false, false, false]
  },
  {
    object: '/user/users',
    grantedActions: [false, false, false, false]
  },
  {
    object: '/vendor/vendors',
    grantedActions: [false, false, false, false]
  }
]

export const ownerPermissions: PermGroup[] = [
  {
    object: '/examination/examinations',
    grantedActions: [true, true, true, true]
  },
  {
    object: '/accounting/accounts',
    grantedActions: [true, true, true, true]
  },
  {
    object: '/accounting/transactions',
    grantedActions: [true, true, true, true]
  },
  {
    object: '/customer/customers',
    grantedActions: [true, true, true, true]
  },
  {
    object: '/hr/employeeclocks',
    grantedActions: [true, true, true, true]
  },
  {
    object: '/hr/employeeschedule',
    grantedActions: [true, true, true, true]
  },
  {
    object: '/inventory/inventoryadjustments',
    grantedActions: [true, true, true, true]
  },
  {
    object: '/inventory/inventoryreceivings',
    grantedActions: [true, true, true, true]
  },
  {
    object: '/inventory/inventorytransfers',
    grantedActions: [true, true, true, true]
  },
  {
    object: '/inventory/itembrands',
    grantedActions: [true, true, true, true]
  },
  {
    object: '/inventory/itemcategories',
    grantedActions: [true, true, true, true]
  },
  {
    object: '/inventory/items',
    grantedActions: [true, true, true, true]
  },
  {
    object: '/inventory/itemtags',
    grantedActions: [true, true, true, true]
  },
  {
    object: '/sales/orderpayments',
    grantedActions: [true, true, true, true]
  },
  {
    object: '/sales/orders',
    grantedActions: [true, true, true, true]
  },
  {
    object: '/user/users',
    grantedActions: [true, true, true, true]
  },
  {
    object: '/vendor/vendors',
    grantedActions: [true, true, true, true]
  }
]

export const managerPermissions: PermGroup[] = [
  {
    object: '/accounting/accounts',
    grantedActions: [true, true, true, false]
  },
  {
    object: '/accounting/transactions',
    grantedActions: [true, true, true, false]
  },
  {
    object: '/customer/customers',
    grantedActions: [true, true, true, false]
  },
  {
    object: '/hr/employeeclocks',
    grantedActions: [true, true, true, false]
  },
  {
    object: '/hr/employeeschedule',
    grantedActions: [true, true, true, false]
  },
  {
    object: '/inventory/inventoryadjustments',
    grantedActions: [true, true, true, false]
  },
  {
    object: '/inventory/inventoryreceivings',
    grantedActions: [true, true, true, false]
  },
  {
    object: '/inventory/inventorytransfers',
    grantedActions: [true, true, true, false]
  },
  {
    object: '/inventory/itembrands',
    grantedActions: [true, true, true, false]
  },
  {
    object: '/inventory/itemcategories',
    grantedActions: [true, true, true, false]
  },
  {
    object: '/inventory/items',
    grantedActions: [true, true, true, false]
  },
  {
    object: '/inventory/itemtags',
    grantedActions: [true, true, true, false]
  },
  {
    object: '/sales/orderpayments',
    grantedActions: [true, true, true, false]
  },
  {
    object: '/sales/orders',
    grantedActions: [true, true, true, false]
  },

  {
    object: '/user/users',
    grantedActions: [true, true, true, false]
  },
  {
    object: '/vendor/vendors',
    grantedActions: [true, true, true, false]
  }
]

export const cashierPermissions: PermGroup[] = [
  {
    object: '/accounting/accounts',
    grantedActions: [false, true, false, false]
  },
  {
    object: '/accounting/transactions',
    grantedActions: [false, true, false, false]
  },
  {
    object: '/customer/customers',
    grantedActions: [true, true, true, false]
  },
  {
    object: '/hr/employeeclocks',
    grantedActions: [true, true, false, false]
  },
  {
    object: '/hr/employeeschedule',
    grantedActions: [false, true, false, false]
  },
  {
    object: '/inventory/inventoryadjustments',
    grantedActions: [false, true, false, false]
  },
  {
    object: '/inventory/inventoryreceivings',
    grantedActions: [false, true, false, false]
  },
  {
    object: '/inventory/inventorytransfers',
    grantedActions: [false, true, false, false]
  },
  {
    object: '/inventory/itembrands',
    grantedActions: [false, true, false, false]
  },
  {
    object: '/inventory/itemcategories',
    grantedActions: [false, true, false, false]
  },
  {
    object: '/inventory/items',
    grantedActions: [false, true, false, false]
  },
  {
    object: '/inventory/itemtags',
    grantedActions: [false, true, false, false]
  },
  {
    object: '/sales/orderpayments',
    grantedActions: [true, true, false, false]
  },
  {
    object: '/sales/orders',
    grantedActions: [true, true, false, false]
  },
  {
    object: '/user/users',
    grantedActions: [false, true, false, false]
  },
  {
    object: '/vendor/vendors',
    grantedActions: [false, true, false, false]
  }
]
