<template>
  <div v-if="loading" class="w-full h-screen flex flex-col items-center justify-center">
    <loading-spinner diameter="64px" />
  </div>

  <div
    v-if="!loading"
    class="w-full flex flex-col items-center justify-center"
    :dir="$ctx.getDir()"
  >
    <el-form
      class="flex w-full items-center flex-col"
      ref="login"
      @submit.prevent.stop="reload()"
      :model="data"
      label-position="top"
    >
      <!-- @submit errors -->
      <alert-badge
        class="w-5/6 md:w-4/6 lg:w-3/6"
        isDanger
        :title="error.title"
        :body="error.body"
        @dismissed="
          () => {
            error.title = ''
            error.body = ''
          }
        "
      />

      <input-section class="w-5/12">
        <el-form-item :label="t('hardwareID')" class="input-label" dir="ltr">
          <div class="label">{{ data.hardwareID }}</div>
        </el-form-item>

        <el-form-item :label="t('licenseID')" class="input-label">
          <div class="el-input">
            <div class="el-input__inner">
              {{ license?.id }}
            </div>
          </div>
        </el-form-item>

        <el-form-item :label="t('email')" class="input-label">
          <div class="el-input">
            <div class="el-input__inner">
              {{ license?.client?.email }}
            </div>
          </div>
        </el-form-item>

        <el-form-item :label="t('expiration')" class="input-label">
          <div class="el-input">
            <div class="el-input__inner">
              {{ license?.expiration }}
            </div>
          </div>
        </el-form-item>

        <el-form-item :label="t('status')" class="input-label">
          <div class="el-input">
            <div class="el-input__inner">
              {{ license.enabled ? t('enabled') : t('disabled') }}
            </div>
          </div>
        </el-form-item>
      </input-section>

      <input-section class="flex justify-center w-5/12">
        <horizontal-button class="license-reload-btn" :title="t('reload')" @click="reload()" />
      </input-section>
    </el-form>
  </div>
</template>

<script setup lang="ts">
import { ref, reactive, onMounted } from 'vue'

import HorizontalButton from '@/components/HorizontalButton.vue'
import InputSection from '@/components/form/InputSection.vue'
import AlertBadge from '@/components/AlertBadge.vue'

import { useI18n } from 'vue-i18n'
import { ElForm } from 'element-plus'
import { useHTTP } from '@/plugins/http'
import LoadingSpinner from '@/components/LoadingSpinner.vue'

const http = useHTTP()
const loading = ref(true)

const { t } = useI18n({
  messages: {
    en: {
      hardwareID: 'Hardware ID',
      licenseID: 'License ID',
      reload: 'Reload',
      email: 'Email',
      expiration: 'Expiration',
      status: 'Status',
      enabled: 'Active',
      disabled: 'Disabled'
    },
    ar: {
      hardwareID: 'معرف الجهاز',
      licenseID: 'معرف الرخصة',
      reload: 'اعادة تحميل',
      email: 'الايميل',
      expiration: 'تاريخ انتهاءالرخصة',
      status: 'الحالة',
      enabled: 'فعال',
      disabled: 'ملغى'
    }
  },
  useScope: 'global'
})

const data = reactive({
  hardwareID: ''
})

const license = ref()

const error = reactive({ title: '', body: '' })

async function load() {
  loading.value = true
  const res: any = await http.get('license/hardware-id')
  data.hardwareID = res.machine_id

  license.value = await http.get('license')

  loading.value = false
}

onMounted(() => {
  load()
})

async function reload() {
  loading.value = true
  const res: any = await http.post('license/reload')
  loading.value = false
  load()
}
</script>

<style scoped>
.license-reload-btn {
  font-size: 14px !important;
}

.label {
  display: flex;
  align-items: center;
  height: 40px;
  font-weight: bold;
}
</style>
