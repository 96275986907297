<template>
  <div v-if="loading" class="flex h-full justify-center items-center">
    <loading-spinner diameter="64px" />
  </div>

  <alert-badge
    v-else-if="error.title"
    isDanger
    :title="error.title"
    :body="error.body"
    @dismissed="
      () => {
        error.title = ''
        error.body = ''
      }
    "
  />

  <simple-item-form
    v-else
    :initItem="item"
    :submitAction="update"
    :successMsg="$t('actions.saveChangesSuccess')"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

//components
import SimpleItemForm from './SimpleItemForm.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import AlertBadge from '@/components/AlertBadge.vue'

// models
import Item from '@/models/inventory/Item'

// Context
import Brand from '@/models/inventory/Brand'
import Category from '@/models/inventory/Category'
import Vendor from '@/models/vendor/Vendor'
import Tag from '@/models/inventory/Tag'
import Stock from '@/models/inventory/Stock'

interface Option {
  name: string
  values: string[]
}

export default defineComponent({
  components: { SimpleItemForm, LoadingSpinner, AlertBadge },
  name: 'simple-item',

  data() {
    return {
      loading: true,
      error: { title: '', body: '' },

      item: new Item()
    }
  },

  methods: {
    // update gets called by ItemForm on form submit
    async update(item: Item) {
      try {
        const url = `${Item.ENDPOINT}/${this.$route.params.id}`
        const resp = await this.$http.put<Item>(url, item)
        this.item = Item.from(resp)
      } catch (error) {
        return error
      }
    }
  },

  async beforeMount() {
    // fetch the item by its id
    try {
      const url = `${Item.ENDPOINT}/${this.$route.params.id}`
      const resp = await this.$http.get<Item>(url)
      const nilUUID = '00000000-0000-0000-0000-000000000000'
      // replace any undefined value
      if (!resp.itemBrand || resp.itemBrand.id === nilUUID) resp.itemBrand = new Brand()
      if (!resp.itemCategory || resp.itemCategory.id === nilUUID) resp.itemCategory = new Category()
      if (!resp.vendor || resp.vendor.id === nilUUID) resp.vendor = new Vendor()
      if (!resp.itemTags) resp.itemTags = new Array<Tag>()
      if (!resp.itemStocks) resp.itemStocks = new Array<Stock>()

      this.item = Item.from(resp)
    } catch (errorResp) {
      this.error.title = errorResp.title
      this.error.body = errorResp.body
    }

    this.loading = false
  }
})
</script>

<style></style>
