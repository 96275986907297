<template>
  <div class="m-3" style="height: 90vh">
    <div class="grid grid-cols-12 items-center content-center gap-2">
      <el-select
        class="input-label col-span-12 md:col-span-2"
        v-model="selectedValue"
        @change="applyToSelected"
      >
        <template v-if="$can('read', '/inventory/inventoryreceivings')">
          <el-option
            key="export"
            :label="$t('views.common.listView.selectedRows.export')"
            value="export"
          >
          </el-option>
        </template>
      </el-select>

      <el-input
        :placeholder="$t('views.common.listView.searchRows')"
        class="w-full col-span-12 md:col-span-7"
        :prefix-icon="Search"
        v-model="searchTerm"
      />

      <div class="w-full col-span-12 md:col-span-3">
        <template v-if="$can('create', '/inventory/inventoryreceivings')">
          <horizontal-button
            :title="$t('views.inventory.inventoryReceivings.newInventoryReceiving')"
            @click="$router.push($Route.INVENTORY_INVENTORY_RECEIVINGS_NEW_INVENTORY_RECEIVING)"
          />
        </template>
      </div>
    </div>

    <ag-grid-vue
      class="ag-theme-alpine mt-4 md:mt-0"
      :columnDefs="columnDefs"
      :rowData="rowData"
      rowSelection="multiple"
      :enable-rtl="$ctx.getDir() === 'rtl'"
      style="height: 85vh"
      :pagination="true"
      :paginationAutoPageSize="true"
      :defaultColDef="defaultColDef"
      @cell-double-clicked="onCellDoubleClicked"
      :onGridReady="onGridReady"
    >
    </ag-grid-vue>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

// components
import { AgGridVue } from 'ag-grid-vue3'
import { ColDef, GridApi, GridOptions } from 'ag-grid-community'
import HorizontalButton from '@/components/HorizontalButton.vue'

import InventoryReceiving, {
  inventoryReceivingI18nMessages
} from '@/models/inventory/InventoryReceiving'
import { useI18n } from 'vue-i18n'
import { formatMoney } from '@/utils/money'
import { Search } from '@element-plus/icons-vue'

export default defineComponent({
  name: 'inventory-receivings',

  setup() {
    const { t } = useI18n({
      messages: inventoryReceivingI18nMessages
    })

    return { t, Search }
  },

  data() {
    const columnDefs: ColDef[] = []
    const rowData: InventoryReceiving[] = []
    const gridOptions: GridOptions = {}
    const defaultColDef: ColDef = {}

    return {
      columnDefs,
      rowData,
      gridOptions,
      defaultColDef,

      searchTerm: '',
      selectedValue: '',
      gridApi: undefined as GridApi | undefined
    }
  },

  components: {
    AgGridVue,
    HorizontalButton
  },

  beforeMount() {
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
      minWidth: 150
    }

    this.columnDefs = [
      {
        headerName: this.t('id'),
        field: 'externalID',
        checkboxSelection: true,
        headerCheckboxSelection: true
      },
      {
        headerName: this.$t('models.common.createdAt'),
        field: 'createdAt',
        cellStyle: { direction: 'ltr' },
        valueFormatter: (params) => {
          const createdAt = new Date(params.value)
          return createdAt.toLocaleDateString('en-GB') + ' ' + createdAt.toLocaleTimeString('en-US')
        }
      },
      {
        headerName: this.t('total'),
        field: 'vendorBill.totalDueAmount',
        valueFormatter: ({ data }) => {
          const ir = data as InventoryReceiving
          if (!ir) return ''
          return ir.vendorBill?.totalDueAmount != undefined
            ? formatMoney(ir.vendorBill?.totalDueAmount, ir.vendorBill.currency!)
            : ''
        }
      },
      {
        headerName: this.t('user'),
        field: 'user.fullName'
      },
      {
        headerName: this.t('vendor'),
        field: 'vendor.name'
      },
      {
        headerName: this.t('location'),
        field: 'location.enName'
      },
      {
        headerName: this.t('notes'),
        field: 'notes'
      }
    ]
  },

  mounted() {
  },

  watch: {
    searchTerm() {
      this.filterResult()
    }
  },

  methods: {
    async updateRowData() {
      this.gridApi?.showLoadingOverlay()

      try {
        const data = await this.$http.get<InventoryReceiving[]>(InventoryReceiving.ENDPOINT)
        this.rowData = data.map((inventoryReceing) => InventoryReceiving.from(inventoryReceing))
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        })
      }

      this.gridApi?.hideOverlay()
    },

    async onGridReady(params: any) {
      this.gridApi = params.api
      this.gridApi?.sizeColumnsToFit()
      await this.updateRowData()
    },

    async applyToSelected() {
      if (this.selectedValue === 'export') {
        const selected = this.gridApi?.getSelectedRows()

        if (selected?.length) {
          this.gridApi?.exportDataAsCsv({ onlySelected: true })
          this.$alertModal.showSuccess({
            title: this.$t('views.common.listView.selectedRows.exportSuccess')
          })

          // deselect
          selected.length = 0
        }
      }

      this.selectedValue = ''
    },

    filterResult() {
      this.gridApi?.setQuickFilter(this.searchTerm)
    },

    onCellDoubleClicked(event: any) {
      this.$router.push(
        this.$Route.INVENTORY_INVENTORY_RECEIVINGS_INVENTORY_RECEIVING.replace(':id', event.data.id)
      )
    }
  }
})
</script>

<style></style>
