<script setup lang="ts">
import { ref, defineProps, onMounted } from 'vue'
import { watch } from 'vue-demi'
import { formatMoney } from '@/utils/money'


const model = defineModel({type: Number})
const props = defineProps({currency: {type: String, required: true}})
const emit = defineEmits(["update:modelValue"])

const inputValue = ref("")

onMounted(() => {
  if (model.value) {
    inputValue.value = formatMoney(model.value, props.currency)
  } else {
    inputValue.value = formatMoney(0, props.currency)
  }
})

watch(model, () => {
  if (model.value) {
    inputValue.value = formatMoney(model.value, props.currency)
  }
})

function toNumber(val: string): number {
  return parseInt(inputValue.value.replaceAll(/\D/g, ""));
}

function updateInputValue(val: number) {
  const formatted = formatMoney(val, props.currency)
  emit("update:modelValue", val)
  inputValue.value = formatted
}

watch(inputValue, () => {
  if (inputValue.value) {
    const asNumber = toNumber(inputValue.value)
    updateInputValue(asNumber)
  }
})

function appendZero() {
  if (!inputValue.value) return
  const amount = (toNumber(inputValue.value) || 0) * Math.pow(10, props.currency === "USD" ? 2 : 3)
  updateInputValue(amount)
}

</script>

<template>
  <div class="el-input el-input__wrapper" :class="{ 'is-focus': isFocus }">
    <input
      ref="inputRef"
      v-model="inputValue"
      class="el-input__inner"
      :maxlength="15"
      @keyup.enter="$emit('keyup-enter')"
      @keypress.space="appendZero"
    />
  </div>
</template>

<style scoped>

</style>