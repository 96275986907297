import User from '../user/User'
import Item from '@/models/inventory/Item'
import ItemSerial from './ItemSerial'
import Location from '../company/Location'
import { localizeFieldName, useI18nInstance } from '@/plugins/i18n'
import UUIDBase from '../UUIDBase'

const { t } = useI18nInstance().global

export const inventoryTransferI18nMessages = {
  en: {
    id: 'ID',
    user: 'By employee',
    reason: 'Reason for transfer',
    item: 'Item Name',

    quantity: 'Quantity',

    fromLocation: 'From Location',
    toLocation: 'To Location',
    itemName: 'Item Name',
    serialNumbers: 'Serial Numbers',
    transferOutID: 'Inventory Transfer Reference ID',
    transferType: 'Transfer type',
    transferOutType: 'Transfer method',

    status: 'Status',
    receivedAt: 'Received At'
  },

  ar: {
    id: 'ID',
    user: 'الموظف المسؤول',
    reason: 'سبب النقل',
    item: 'اسم المنتج',

    quantity: 'الكمية',

    fromLocation: 'من الموقع',
    toLocation: 'الى الموقع',
    itemName: 'اسم المنتج',
    serialNumbers: 'Serial Numbers',
    transferOutID: 'رقم نقل البضاعة',
    transferType: 'نوع النقل',
    transferOutType: 'طريقة النقل',

    status: 'الحالة',
    receivedAt: 'تم الاستلام'
  }
}

export class InventoryTransferItemSerial extends UUIDBase {
  inventoryTransferItemID = 0

  itemSerialID = ''
  itemSerial: ItemSerial

  constructor(serialNumber: string, id = '') {
    super()
    this.itemSerial = new ItemSerial(serialNumber)
    if (id) {
      this.itemSerial.id = id
      this.itemSerialID = id
    }
  }
}

export class InventoryTransferItem extends UUIDBase {
  inventoryTransferID?: number

  itemID = ''
  item: Item

  // default quantity
  quantity = 0

  inventoryTransferItemSerials?: InventoryTransferItemSerial[]

  // used as a v-model to simplify things a bit
  // at submit, we process this and turn it into inventoryTransferItemSerials
  toSendSerialIDs?: Array<string>

  constructor(item: Item) {
    super()
    this.item = item
    this.itemID = item.id || ''

    if (item.isSerialized) {
      this.inventoryTransferItemSerials = new Array<InventoryTransferItemSerial>()

      this.toSendSerialIDs = new Array<string>()
    }
  }

  includesSerialNumber(serialNumber: string): boolean {
    return !!this.inventoryTransferItemSerials?.some(
      (serial) => serial.itemSerial.number === serialNumber
    )
  }

  insertSerialNumber(serialNumber: string) {
    if (!this.includesSerialNumber(serialNumber))
      this.inventoryTransferItemSerials?.push(new InventoryTransferItemSerial(serialNumber, ''))
  }

  removeSerialNumber(index: number) {
    this.inventoryTransferItemSerials?.splice(index, 1)
  }

  resetInventoryTransferItemSerials() {
    if (this.inventoryTransferItemSerials) this.inventoryTransferItemSerials.length = 0
  }

  includesItemSerialID(id: string): boolean {
    return !!this.inventoryTransferItemSerials?.some((serial) => serial.itemSerial.id === id)
  }

  insertItemSerialID(id: string) {
    if (!this.includesItemSerialID(id)) {
      this.inventoryTransferItemSerials?.push(new InventoryTransferItemSerial('', id))
    }
  }

  populateInventoryTransferItemSerials(itemSerialIDs?: string[]) {
    if (itemSerialIDs) {
      this.resetInventoryTransferItemSerials()
      itemSerialIDs.forEach((id) => this.insertItemSerialID(id))
    }
  }

  validateSerials() {
    if (this.item.isSerialized && this.inventoryTransferItemSerials?.length !== this.quantity) {
      return t('validation.matchingLength', {
        thisField: localizeFieldName('serialNumbers', inventoryTransferI18nMessages),
        thatField: localizeFieldName('quantity', inventoryTransferI18nMessages)
      })
    }
  }

  validateQuantity() {
    if (this.quantity <= 0) {
      return t('validation.positive', {
        field: localizeFieldName('quantity', inventoryTransferI18nMessages)
      })
    }
  }
}

export default class InventoryTransfer extends UUIDBase {
  user = new User()

  // belongs to
  fromLocation = new Location()
  fromLocationID = ''

  toLocation = new Location()
  toLocationID = ''

  // must be one of in or out
  // default out
  transferType: string

  // must be one of direct or indirect
  transferOutType = ''

  // in case that this inventory is being received
  // we need to reference the sending inventory transfer
  transferOutID?: string

  receivedAt?: Date

  status = ''
  reason = ''

  inventoryTransferItems = new Array<InventoryTransferItem>()

  constructor(transferType: string) {
    super()
    this.transferType = transferType
  }

  static from(json: Record<string, unknown> | InventoryTransfer) {
    const i = Object.assign(new InventoryTransfer(''), json)
    i.user = User.from(i.user)
    i.fromLocation = Location.from(i.fromLocation)
    i.toLocation = Location.from(i.toLocation)
    return i
  }

  static ENDPOINT = '/inventory/inventorytransfers'

  includes(item: Item) {
    for (const ia of this.inventoryTransferItems) {
      if (ia.item.id === item.id) {
        return true
      }
    }

    return false
  }

  findByBarcode(barcode: string): Item | undefined {
    for (const ia of this.inventoryTransferItems) {
      if (ia.item.barcode === barcode) {
        return ia.item
      }
    }
  }

  findBySKU(sku: string): Item | undefined {
    for (const ia of this.inventoryTransferItems) {
      if (ia.item.sku === sku) {
        return ia.item
      }
    }
  }

  addItem(item: Item) {
    if (!this.includes(item)) {
      // insert at index 0
      this.inventoryTransferItems.splice(0, 0, new InventoryTransferItem(item))
    }
  }

  removeItem(index: number) {
    this.inventoryTransferItems.splice(index, 1)
  }

  empty() {
    return this.inventoryTransferItems.length === 0
  }

  prepareSendPayloadData() {
    this.inventoryTransferItems.forEach((iai) => {
      if (iai.item.isSerialized) {
        iai.populateInventoryTransferItemSerials(iai.toSendSerialIDs)
      }
    })
  }
}
