<template>
  <div class="m-3" style="height: 90vh">
    <div class="grid grid-cols-12 items-center content-center gap-2">
      <el-select
        class="input-label col-span-12 md:col-span-3"
        :placeholder="t('select')"
        v-model="location"
        @change="onFilterChange"
      >
        <el-option
          v-for="(loc, i) in locations"
          :key="i"
          :label="$ctx.locale === 'en' ? loc.enName : loc.arName"
          :value="loc.id"
        >
        </el-option>
      </el-select>

      <el-date-picker
        class="col-span-12 md:col-span-1"
        v-model="period"
        type="daterange"
        range-separator="to"
        :start-placeholder="t('startDate')"
        :end-placeholder="t('endDate')"
        @change="onFilterChange"
      >
      </el-date-picker>
    </div>

    <div class="w-full text-xl font-bold mt-2">
      {{ t('totalSales') }}
    </div>

    <div v-if="data" class="flex flex-wrap gap-4 mt-2">
      <el-card style="width: 360px" shadow="never">
        <div class="flex flex-col items-center">
          <div class="text-lg text-gray-500">{{ t('salesAmount') }}</div>
          <div class="text-2xl pt-2">{{ formatMoney(data.sales_amount, $ctx.currency) }}</div>
        </div>
      </el-card>

      <el-card style="width: 360px" shadow="never">
        <div class="flex flex-col items-center">
          <div class="text-lg text-gray-500">{{ t('totalOrders') }}</div>
          <div class="text-2xl pt-2">{{ data.total_orders }}</div>
        </div>
      </el-card>

      <el-card style="width: 360px" shadow="never">
        <div class="flex flex-col items-center">
          <div class="text-lg text-gray-500">{{ t('totalItemsSold') }}</div>
          <div class="text-2xl pt-2">{{ data.total_items_sold }}</div>
        </div>
      </el-card>

      <el-card style="width: 360px" shadow="never">
        <div class="flex flex-col items-center">
          <div class="text-lg text-gray-500">{{ t('totalCashIn') }}</div>
          <div class="text-2xl pt-2">{{ formatMoney(data.total_cash_in, $ctx.currency) }}</div>
        </div>
      </el-card>

      <el-card style="width: 360px" shadow="never">
        <div class="flex flex-col items-center">
          <div class="text-lg text-gray-500">{{ t('totalTransactions') }}</div>
          <div class="text-2xl pt-2">{{ data.total_transactions }}</div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onBeforeMount, provide } from 'vue'
import { useI18n } from 'vue-i18n'
import { useHTTP } from '@/plugins/http'
import { useAlertModal } from '@/plugins/alert-modal/alert-modal'
import { formatMoney } from '@/utils/money'
import dayjs from 'dayjs'
import Location from '@/models/company/Location'

// i18n
const { t } = useI18n({
  messages: {
    en: {
      totalSales: 'Total Sales',
      salesAmount: 'Sales Amount',
      totalOrders: 'Total Orders',
      totalItemsSold: 'Total Items Sold',
      totalCashIn: 'Total Cash In',
      totalTransactions: 'Total Transactions'
    },
    ar: {
      totalSales: 'اجمالي المبيعات',
      salesAmount: 'اجمالي المبيعات',
      totalOrders: 'اجمالي الطلبات',
      totalItemsSold: 'اجمالي عدد المبيوعات',
      totalCashIn: 'اجمالي الكاش',
      totalTransactions: 'اجمالي العمليات'
    }
  }
})

const location = ref(null)
const locations = ref<Location[]>([])
const period = ref<Date[]>([]) // the default period is from the first day of the month to the current day

const data = ref<any>()
const http = useHTTP()
const alertModal = useAlertModal()

onBeforeMount(async () => {
  load()
})

// Methods
async function load() {
  const urlParams = new URLSearchParams()
  urlParams.set('location', location.value || '')
  if (period.value && period.value.length == 2) {
    urlParams.set('from_date', dayjs(period.value[0]).format('YYYY-MM-DD'))
    urlParams.set('to_date', dayjs(period.value[1]).format('YYYY-MM-DD'))
  }

  try {
    const res: any = await http.get<any[]>('/reports/sales/total-sales?' + urlParams.toString())
    data.value = res
  } catch (error) {
    alertModal.showDanger({
      title: error.title,
      body: error.body
    })
  }
}

async function onFilterChange() {
  await load()
}
</script>
