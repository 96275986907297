<template>
  <div>
    <div v-if="loading" class="flex items-center justify-center h-full">
      <loading-spinner diameter="64px" />
    </div>

    <alert-badge
      v-else-if="error.title"
      isDanger
      :title="error.title"
      :body="error.body"
      @dismissed="
        () => {
          error.title = ''
          error.body = ''
        }
      "
    />

    <div class="m-3" style="height: 90vh">
      <div class="flex flex-col items-center content-center gap-2 md:flex-row">
        <el-select class="input-label" v-model="location">
          <el-option
            v-for="(loc, i) in locations"
            :key="i"
            :label="$ctx.locale === 'en' ? loc.enName : loc.arName"
            :value="loc.id"
          >
          </el-option>
        </el-select>

        <el-date-picker
          v-model="period"
          type="daterange"
          range-separator="to"
          :start-placeholder="t('startDate')"
          :end-placeholder="t('endDate')"
        >
        </el-date-picker>
      </div>

      <div
        v-if="rowData.length === 0"
        class="flex flex-col justify-center items-center h-5/6 w-full"
      >
        <img src="@/assets/emptyFolder.png" alt="empty folder" width="250" />
        <h1 class="uppercase">{{ $t('views.reports.emptyState') }}</h1>
      </div>

      <ag-grid-vue
        v-else
        class="ag-theme-alpine"
        :columnDefs="columnDefs"
        :rowData="rowData"
        rowSelection="multiple"
        :enable-rtl="$ctx.getDir() === 'rtl'"
        style="height: 85vh"
        :pagination="true"
        :paginationAutoPageSize="true"
        :defaultColDef="defaultColDef"
        :onGridReady="onGridReady"
      >
      </ag-grid-vue>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import AlertBadge from '@/components/AlertBadge.vue'
import Location from '@/models/company/Location'
import { ColDef, GridApi, GridOptions } from 'ag-grid-community'
import { AgGridVue } from 'ag-grid-vue3'
import Customer, { customerI18nMessages } from '@/models/customer/Customer'
import { useI18n } from 'vue-i18n'
import Dinero from 'dinero.js'
import { Dinero as DineroType } from 'dinero.js'
import { formatDinero } from '@/utils/money'
interface RowData {
  customer: Customer
  totalSpentAmount: number
  totalSpentCurrency: 'IQD' | 'USD'
  totalSpentPrecision: number
  totalSpentDinero: DineroType
}
export default defineComponent({
  components: {
    LoadingSpinner,
    AlertBadge,
    AgGridVue
  },

  data() {
    const columnDefs: ColDef[] = []
    const rowData: RowData[] = []
    const gridOptions: GridOptions = {}
    const defaultColDef: ColDef = {}
    const messages = {
      en: {
        ...customerI18nMessages.en,
        totalSpentAmount: 'Total Spent Amount',
        startDate: 'Start Date',
        endDate: 'End Date'
      },
      ar: {
        ...customerI18nMessages.ar,
        totalSpentAmount: 'مجموع المبلغ المصروف',
        startDate: 'Start Date',
        endDate: 'End Date'
      }
    }

    const { t } = useI18n({
      messages
    })
    return {
      columnDefs,
      rowData,
      gridOptions,
      defaultColDef,
      error: {
        title: '',
        body: ''
      },
      gridApi: undefined as GridApi | undefined, 
      loading: false,
      locations: new Array<Location>(),
      location: new Location(),
      period: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), Date()], // the default period is from the first day of the month to the current day
      t
    }
  },

  async beforeMount() {
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
      minWidth: 100,
      cellStyle: { textAlign: 'center' },
      flex: 1
    }
    this.columnDefs = [
      {
        headerName: this.t('customerDisplayName'),
        field: 'customer.contactDisplayName',
        checkboxSelection: true,
        autoHeight: true,
        cellStyle: { textAlign: 'start' },
        headerCheckboxSelection: true,
        minWidth: 500
      },
      {
        headerName: this.t('totalSpentAmount'),
        field: 'totalSpentAmount',
        type: 'numericColumn',
        filter: 'agNumberColumnFilter',
        valueFormatter: (params) => {
          const data = params.data as RowData
          return formatDinero(data.totalSpentDinero)
        },
        cellStyle: { textAlign: 'center' }
      }
    ]
    const locationRes = await this.$http.get<Location[]>(Location.ENDPOINT)
    this.locations = locationRes.map((cat) => Location.from(cat))
  },

  watch: {
    period: {
      handler: function () {
        this.updateRowData()
      }
    },
    location: {
      handler: function () {
        this.updateRowData()
      }
    }
  },

  methods: {
    async updateRowData() {
      this.gridApi?.showLoadingOverlay()
      //here should formulate the path for the new request depending on the new date and location
      //for now I'm just hitting the customers endpoint
      //this function will be called whenever the location or the period is changed
      try {
        const data = await this.$http.get<Customer[]>(Customer.ENDPOINT)
        //here just generating mock data
        this.rowData = data.map((customer) => {
          return {
            customer: Customer.from(customer),
            totalSpentAmount: 1000000,
            totalSpentCurrency: 'IQD',
            totalSpentPrecision: 0,
            totalSpentDinero: Dinero({
              amount: 1000000,
              currency: 'IQD',
              precision: 0
            })
          }
        })
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        })
      }

      this.gridApi?.hideOverlay()
    },

    async onGridReady(params: any) {
      this.gridApi = params.api
      this.gridApi?.sizeColumnsToFit()
      await this.updateRowData()
    },
  }
})
</script>

<style>
.el-date-editor .el-range-separator {
  width: auto !important;
}
</style>
