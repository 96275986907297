import View from '@/plugins/navigation/View'

/**
 * @deprecated This code should not be used
 */
export interface Props {
  [prop: string]: unknown
}

/**
 * @deprecated This code should not be used
 */
export interface RouteContainer {
  view: (props?: Props) => View
  parent?: string
}

/**
 * @deprecated This code should not be used
 */
export interface Routes {
  [route: string]: RouteContainer
}

/**
 * @deprecated This code should not be used
 */
export enum NavIndex {
  HOME,
  SALES,
  INVENTORY,
  CUSTOMERS,
  VENDORS,
  HR,
  ACCOUNTING,
  REPORTS,
  SETTINGS
}

/**
 * @deprecated This code should not be used
 */
export enum Route {
  HOME = 'home',

  LOGIN = 'LOGIN',

  SALES = 'sales',
  SALES_NEW_SALE = 'sales/new-sale',
  SALES_ORDERS = 'sales/orders',
  SALES_ORDERS_ORDER = 'sales/orders/order',
  SALES_ORDERS_ORDER_RECEIPT = 'sales/orders/order/receipt',
  SALES_ORDERS_RETURN_ORDER = 'sales/orders/return-order',
  SALES_ORDERS_RETURN_ORDER_RECEIPT = 'sales/orders/return-order/receipt',
  SALES_ORDERS_ORDER_PAYMENT = 'sales/orders/order-payment',
  SALES_ORDERS_ORDER_PAYMENT_RECEIPT = 'sales/orders/order-payment/receipt',
  SALES_REGISTERS_OPEN_REGISTER = 'sales/registers/open-register',
  SALES_REGISTERS_CLOSE_REGISTER = 'sales/registers/close-register',

  INVENTORY = 'inventory',
  INVENTORY_ITEMS = 'inventory/items',
  INVENTORY_ITEMS_SIMPLE_ITEM = 'inventory/items/simple-item',
  INVENTORY_ITEMS_NEW_SIMPLE_ITEM = 'inventory/items/new-simple-item',
  INVENTORY_BRANDS = 'inventory/brands',
  INVENTORY_BRANDS_BRAND = 'inventory/brands/brand',
  INVENTORY_BRANDS_NEW_BRAND = 'inventory/brands/new-brand',
  INVENTORY_CATEGORIES = 'inventory/categories',
  INVENTORY_CATEGORIES_CATEGORY = 'inventory/categories/category',
  INVENTORY_CATEGORIES_NEW_CATEGORY = 'inventory/categories/new-category',
  INVENTORY_TAGS = 'inventory/tags',
  INVENTORY_TAGS_TAG = 'inventory/tags/tag',
  INVENTORY_TAGS_NEW_TAG = 'inventory/tags/new-tag',
  INVENTORY_INVENTORY_RECEIVINGS = 'inventory/inventory-receivings',
  INVENTORY_INVENTORY_RECEIVINGS_INVENTORY_RECEIVING = 'inventory/inventory-receivings/inventory-receiving',
  INVENTORY_INVENTORY_RECEIVINGS_NEW_INVENTORY_RECEIVING = 'inventory/inventory-receivings/new-inventory-receiving',
  INVENTORY_INVENTORY_ADJUSTMENTS = 'inventory/inventory-adjustments',
  INVENTORY_INVENTORY_ADJUSTMENTS_INVENTORY_ADJUSTMENT = 'inventory/inventory-adjustments/inventory-adjustments',
  INVENTORY_INVENTORY_ADJUSTMENTS_NEW_INVENTORY_ADJUSTMENT = 'inventory/inventory-adjustments/new-inventory-adjustment',
  INVENTORY_INVENTORY_TRANSFERS = 'inventory/inventory-transfers',
  INVENTORY_INVENTORY_TRANSFERS_INVENTORY_TRANSFER = 'inventory/inventory-transfers/inventory-transfer',
  INVENTORY_INVENTORY_TRANSFERS_NEW_INVENTORY_TRANSFER = 'inventory/inventory-transfers/new-inventory-transfer',
  INVENTORY_INVENTORY_TRANSFERS_RECEIVE_INVENTORY_TRANSFER = 'inventory/inventory-transfers/receive-inventory-transfer',

  INVENTORY_PRINT_BARCODE = '',

  CUSTOMERS = 'customers',
  CUSTOMERS_CUSTOMER = 'customers/customer',
  CUSTOMERS_NEW_CUSTOMER = 'customers/new-customer',

  VENDORS = 'vendors',
  VENDORS_VENDOR = 'vendors/vendor',
  VENDORS_NEW_VENDOR = 'vendors/new-vendor',

  HR = 'hr',
  HR_EMPLOYEES = 'hr/employees',
  HR_EMPLOYEES_EMPLOYEE = 'hr/employees/employee',
  HR_EMPLOYEES_NEW_EMPLOYEE = 'hr/employees/new-employee',
  HR_EMPLOYEE_SCHEDULE = 'hr/employee-schedule',
  HR_EMPLOYEE_SCHEDULE_NEW_EMPLOYEE_SCHEDULE = 'hr/new-employee-schedule',

  ACCOUNTING = 'accounting',
  ACCOUNTING_ACCOUNTS = 'accounting/accounts',
  ACCOUNTING_ACCOUNTS_ACCOUNT = 'accounting/accounts/account',
  ACCOUNTING_ACCOUNTS_NEW_ACCOUNT = 'accounting/accounts/new-account',
  ACCOUNTING_TRANSACTIONS = 'accounting/transactions',
  ACCOUNTING_TRANSACTIONS_TRANSACTION = 'accounting/transactions/transaction',
  ACCOUNTING_TRANSACTIONS_NEW_TRANSACTION = 'accounting/transactions/new-transaction',

  REPORTS = 'reports',

  SETTINGS = 'settings'
}
