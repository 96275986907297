import { Transaction } from '../accounting/Transaction'
import { Order } from '../sales/Order'
import Customer from './Customer'

export default class CustomerAccountStatement {
  static from(json: Record<string, unknown> | CustomerAccountStatement) {
    const accountStatement = Object.assign(new CustomerAccountStatement(), json)
    if (accountStatement.customer)
      accountStatement.customer = Customer.from(accountStatement.customer)
    if (accountStatement.orders)
      accountStatement.orders = accountStatement.orders.map((order) => Order.from(order))
    if (accountStatement.transactions)
      accountStatement.transactions = accountStatement.transactions.map((transaction) =>
        Transaction.from(transaction)
      )
    return accountStatement
  }
  static ENDPOINT = '/reports/customer/account-statement'

  customer?: Customer
  orders: Array<Order> = []
  transactions: Array<Transaction> = []
  printedOn?: string
}
