import UUIDBase from '../UUIDBase'
import { useI18nInstance, localizeFieldName } from '@/plugins/i18n'

const { t } = useI18nInstance().global

export const customPriceTypeI18nMessages = {
  en: {
    enName: 'Name (in Enlgish)',
    arName: 'Name (in Arabic)'
  },
  ar: {
    enName: 'الاسم باللغة الانكليزية',
    arName: 'الاسم باللغة العربية'
  }
}

export default class CustomPriceType extends UUIDBase {
  static ENDPOINT = '/inventory/itemcustompricetypes'

  enName = ''
  arName = ''

  static from(json: Record<string, unknown> | CustomPriceType) {
    return Object.assign(new CustomPriceType(), json)
  }

  validateEnName() {
    if (this.enName.length === 0) {
      return t('validation.required', {
        field: localizeFieldName('enName', customPriceTypeI18nMessages)
      })
    } else if (this.enName.length > 255) {
      return t('validation.maxLength', {
        field: localizeFieldName('enName', customPriceTypeI18nMessages)
      })
    }
  }

  validateArName() {
    if (this.arName.length === 0) {
      return t('validation.required', {
        field: localizeFieldName('arName', customPriceTypeI18nMessages)
      })
    } else if (this.arName.length > 255) {
      return t('validation.maxLength', {
        field: localizeFieldName('arName', customPriceTypeI18nMessages)
      })
    }
  }
}
