<template>
  <div class="m-3" style="height: 90vh">
    <div class="grid grid-cols-12 items-center content-center gap-2">
      <el-select
        class="input-label col-span-12 md:col-span-2"
        v-model="selectedValue"
        @change="applyToSelected"
      >
        <template v-if="$can('read', '/accounting/transactions')">
          <el-option
            key="export"
            :label="$t('views.common.listView.selectedRows.export')"
            value="export"
          >
          </el-option>
        </template>
      </el-select>

      <el-input
        :placeholder="$t('views.common.listView.searchRows')"
        class="w-full col-span-12 md:col-span-8"
        :prefix-icon="Search"
        v-model="searchTerm"
      />

      <div class="w-full col-span-12 md:col-span-2">
        <template v-if="$can('create', '/accounting/transactions')">
          <horizontal-button
            :title="t('newPay')"
            @click.prevent="$router.push($Route.ACCOUNTING_HR_NEW_PAY)"
          />
        </template>
      </div>
    </div>

    <ag-grid-vue
      class="ag-theme-alpine mt-4 md:mt-0"
      :columnDefs="columnDefs"
      :rowData="rowData"
      rowSelection="multiple"
      :enable-rtl="$ctx.getDir() === 'rtl'"
      style="height: 85vh"
      :pagination="true"
      :paginationAutoPageSize="true"
      :defaultColDef="defaultColDef"
      @cell-double-clicked="onCellDoubleClicked"
      :onGridReady="onGridReady"
    >
    </ag-grid-vue>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

// components
import { AgGridVue } from 'ag-grid-vue3'
import { ColDef, GridApi, GridOptions } from 'ag-grid-community'
import HorizontalButton from '@/components/HorizontalButton.vue'

import { useI18n } from 'vue-i18n'
import { formatPostgresDate, parseFormat } from '@/utils/date'
import { formatDinero, formatMoney } from '@/utils/money'
import Dinero from 'dinero.js'
import { Search } from '@element-plus/icons-vue'
import { Transaction } from '@/models/accounting/Transaction'

export class Pay {
  id: string;
  periodStartDate: string = "";
  periodEndDate: string = "";
  totalAmount: number = 0;
  currency: string = "";
  precision: number = 0;
  payType: string = "";
  employeeType: string = "";
  payDate: string = "";
  payFromAccountID: string = "";
  payToAccountID: string = "";
  transactionID: string = "";
  transaction: Transaction = new Transaction();
  notes: string = "";
  period: string[] = []
}

export const payI18nMessages = {
  en: {
    newPay: 'New Pay',
    amount: 'Amount',
    employeeName: 'Employee Account',
    employeeType: 'Employee Type',
    payType: 'Pay Type',
    date: 'Paid On',
    period: 'For Period',
    paidFrom: "Paid from",
    notes: 'Notes',

    employee: "Employee",
    contractor: "Contractor",
    freelancer: "Freelancer",

    startPeriod: "Start Date",
    endPeriod: "End Date",

    salary: "Salary",
    commission: "Commission",
    bonus: "Bonus",
    fees: "Fees"
  },
  ar: {
    newPay: 'عملية دفع جديدة',
    amount: 'المبلغ',
    employeeName: 'حساب الموظف',
    employeeType: 'نوع الموظف',
    payType: 'نوع الدفع',
    date: 'تاريخ الدفع',
    period: 'مدة العمل',
    paidFrom: "حساب الدفع",
    notes: 'الملاحظات',

    employee: "موظف دائم",
    contractor: "موظف مؤقت او مقاول",
    freelancer: "عميل",

    startPeriod: "تاريخ بدأ العمل",
    endPeriod: "تاريخ نهاية العمل",

    salary: "راتب",
    commission: "عمولة",
    bonus: "مكافئة",
    fees: "اجور"
  }
}

export default defineComponent({
  name: 'hr',

  setup() {
    const { t } = useI18n({
      messages: payI18nMessages,
      unescape: 'global'
    })

    return {
      t,
      Search
    }
  },

  data() {
    const columnDefs: ColDef[] = []
    const rowData: Pay[] = []
    const gridOptions: GridOptions = {}
    const defaultColDef: ColDef = {}

    return {
      columnDefs,
      rowData,
      gridOptions,
      defaultColDef,

      searchTerm: '',
      selectedValue: '',
      gridApi: undefined as GridApi | undefined
    }
  },

  components: {
    AgGridVue,
    HorizontalButton
  },

  beforeMount() {
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
      minWidth: 150
    }

    this.columnDefs = [
      {
        headerName: this.t('date'),
        field: 'date',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        cellRenderer: (params) => {
          const pay = params.node?.data as Pay
          return `<div dir="ltr">${formatPostgresDate(pay.payDate || "")}</div>`
        },
      },
      {
        headerName: this.t('period'),
        field: 'period',
        valueFormatter: (params) => {
          const pay = params.node?.data as Pay
          return (
            (pay.period && pay.period[0].toLocaleDateString('en-GB')) +
            ' - ' +
            (pay.period && pay.period[1].toLocaleDateString('en-GB'))
          )
        }
      },
      {
        headerName: this.t('employeeName'),
        field: this.$ctx.locale === "en" ? 'transaction.debitedAccount.enName' : "transaction.debitedAccount.arName"
      },
      {
        headerName: this.t('employeeType'),
        field: 'employeeType',
        valueFormatter: (params) => {
          const pay = params.node?.data as Pay
          return this.t(pay.employeeType)
        }
      },
      {
        headerName: this.t('amount'),
        field: 'totalAmount',
        valueFormatter: (params) => {
          const pay = params.node?.data as Pay
          return formatMoney(pay.totalAmount || 0, pay.currency || "")
        }
      },
      {
        headerName: this.t('paidFrom'),
        field: this.$ctx.locale === "en" ? 'transaction.creditedAccount.enName' : "transaction.creditedAccount.arName"
      },
      {
        headerName: this.t('payType'),
        valueFormatter: (params) => {
          const pay = params.node?.data as Pay
          return this.t(pay.payType)
        }
      },
      {
        headerName: this.t('notes'),
        field: 'notes'
      }
    ]
  },

  mounted() {
    this.gridApi?.sizeColumnsToFit()
  },

  watch: {
    searchTerm() {
      this.filterItems()
    }
  },

  methods: {
    async updateRowData() {
      this.gridApi?.showLoadingOverlay()
      try {
        const data = await this.$http.get<Pay[]>("/accounting/hr");
        this.rowData = data.map(e => {
          if (e.transaction) {
            e.transaction = Transaction.from(e.transaction)
          }
          e.period = [new Date(e.periodStartDate), new Date(e.periodEndDate)]
          return e;
        });
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        });
      }

      this.gridApi?.hideOverlay()
    },

    async onGridReady(params: any) {
      this.gridApi = params.api
      await this.updateRowData()
    },

    async applyToSelected() {
      if (this.selectedValue === 'export') {
        const selected = this.gridApi?.getSelectedRows()

        if (selected?.length) {
          this.gridApi?.exportDataAsCsv({
            onlySelected: true
          })
          this.$alertModal.showSuccess({
            title: this.$t('views.common.listView.selectedRows.exportSuccess')
          })

          // deselect
          selected.length = 0
        }
      }

      this.selectedValue = ''
    },

    filterItems() {
      this.gridApi?.setQuickFilter(this.searchTerm)
    },

    onCellDoubleClicked(event: any) {
      this.$router.push(
        this.$Route.ACCOUNTING_TRANSACTIONS_TRANSACTION.replace(':id', event.data.transaction.id)
      )
    }
  }
})
</script>

<style></style>
