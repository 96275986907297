<template>
  <button class="button" :class="getClasses()">
    <span class="flex w-10/12 justify-between items-center">
      <font-awesome-icon v-if="faIcon" :icon="faIcon" id="fa-icon" />
      <span class="flex justify-center items-center w-full">{{ title }}</span>
    </span>
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'horizontal-button',
  data() {
    return {
      isNormal: !(this.isDanger || this.isSuccess || this.isWarning)
    }
  },
  props: {
    title: {
      required: true,
      type: String
    },
    faIcon: {
      required: false,
      type: String
    },
    isDanger: {
      type: Boolean,
      default: false
    },
    isSuccess: {
      type: Boolean,
      default: false
    },
    isWarning: {
      type: Boolean,
      default: false
    },
    styles: {
      required: false,
      type: String
    },
    rounded: {
      default: true,
      type: Boolean
    }
  },
  methods: {
    getClasses() {
      const roundedClass = this.rounded ? 'rounded-lg ' : ''
      if (this.styles !== undefined) {
        return roundedClass + this.styles
      }

      if (this.isDanger) {
        return roundedClass + ' button-danger'
      } else if (this.isSuccess) {
        return roundedClass + ' button-success'
      } else if (this.isWarning) {
        return roundedClass + ' button-warning'
      } else {
        return roundedClass + ' button-normal'
      }
    }
  }
})
</script>

<style scoped>
.button {
  @apply flex items-center justify-center w-full p-2;
  outline: none;
}
</style>
