<template>
  <div v-if="loading" class="flex items-center justify-center h-full">
    <loading-spinner diameter="64px" />
  </div>

  <alert-badge
    v-else-if="error.title"
    isDanger
    :title="error.title"
    :body="error.body"
    @dismissed="
      () => {
        error.title = ''
        error.body = ''
      }
    "
  />

  <div class="m-3">
    <div class="flex flex-col items-center content-center gap-2 md:flex-row">
      <el-select class="input-label" v-model="location">
        <el-option
          v-for="(loc, i) in locations"
          :key="i"
          :label="$ctx.locale === 'en' ? loc.enName : loc.arName"
          :value="loc.id"
        >
        </el-option>
      </el-select>
    </div>
  </div>
  <div class="m-3" style="height: 90vh">
    <ag-grid-vue
      class="ag-theme-alpine"
      :columnDefs="columnDefs"
      :rowData="rowData"
      rowSelection="multiple"
      :enable-rtl="$ctx.getDir() === 'rtl'"
      style="height: 85vh"
      :pagination="true"
      :paginationAutoPageSize="true"
      :defaultColDef="defaultColDef"
      @cell-double-clicked="onCellDoubleClicked"
      :onGridReady="onGridReady"
    >
    </ag-grid-vue>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import AlertBadge from '@/components/AlertBadge.vue'
import { ColDef, GridApi, GridOptions } from 'ag-grid-community'
import { AgGridVue } from 'ag-grid-vue3'
import { useI18n } from 'vue-i18n'
import Dinero from 'dinero.js'
import { formatDinero } from '@/utils/money'
import dayjs from 'dayjs'
import Location from '@/models/company/Location'
import { useRoute } from 'vue-router'

class InventoryValue {
  static from(json: Record<string, unknown> | InventoryValue) {
    return Object.assign(new InventoryValue(), json)
  }
  static ENDPOINT = 'reports/inventory-value'
  locationName = ''
  locationID = ''
  inventoryValueAmount = 0
  currency?: 'IQD' | 'USD'
  precision = 0
}

export default defineComponent({
  components: {
    LoadingSpinner,
    AlertBadge,
    AgGridVue
  },

  data() {
    const router = useRoute()
    const columnDefs: ColDef[] = []
    const rowData: InventoryValue[] = []
    const gridOptions: GridOptions = {}
    const defaultColDef: ColDef = {}
    const messages = {
      en: {
        startDate: 'Start Date',
        endDate: 'End Date',
        location: 'Location',
        value: 'Value'
      },
      ar: {
        startDate: 'Start Date',
        endDate: 'End Date',
        location: 'Location',
        value: 'Value'
      }
    }

    const { t } = useI18n({
      messages
    })
    return {
      router,
      columnDefs,
      rowData,
      gridOptions,
      defaultColDef,
      error: {
        title: '',
        body: ''
      },
      loading: false,
      gridApi: undefined as GridApi | undefined,
      t,
      locations: new Array<Location>(),
      location: '',
      period: [dayjs().startOf('month').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')] // the default period is from the first day of the month to the current day
    }
  },
  watch: {
    location: {
      handler: function () {
        this.updateRowData()
      }
    }
  },
  async beforeMount() {
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
      minWidth: 100,
      cellStyle: { textAlign: 'center' },
      flex: 1
    }
    this.columnDefs = [
      {
        headerName: this.t('location'),
        field: this.$ctx.getDir() === 'rtl' ? 'arLocationName' : 'enLocationName',
        checkboxSelection: true,
        autoHeight: true,
        cellStyle: { textAlign: 'start' },
        headerCheckboxSelection: true,
        minWidth: 500
      },
      {
        headerName: this.t('value'),
        field: 'inventoryValueAmount',
        type: 'numericColumn',
        filter: 'agNumberColumnFilter',
        cellStyle: { textAlign: 'center' },
        valueFormatter: (params) => {
          const data = params.data as InventoryValue
          return formatDinero(
            Dinero({
              amount: data.inventoryValueAmount,
              currency: data.currency
            })
          )
        }
      }
    ]
    const locationRes = await this.$http.get<Location[]>(Location.ENDPOINT)
    this.locations = locationRes.map((loc) => Location.from(loc))
  },
  mounted() {
  },
  methods: {
    async updateRowData() {
      this.gridApi?.showLoadingOverlay()
      try {
        const res = await this.$http.get<InventoryValue[]>(InventoryValue.ENDPOINT)

        this.rowData = res.map((inventory) => InventoryValue.from(inventory))
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        })
      }

      this.gridApi?.hideOverlay()
    },

    async onGridReady(params: any) {
      this.gridApi = params.api
      this.gridApi?.sizeColumnsToFit()
      await this.updateRowData()
    },

    onCellDoubleClicked(event: any) {
      this.$router.push({
        name: 'inventory-value-details',
        params: { data: JSON.stringify(event.data) }
      })
    }
  }
})
</script>

<style>
.el-date-editor .el-range-separator {
  width: auto !important;
}
</style>
