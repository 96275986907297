<template>
  <div v-if="loading" class="flex items-center justify-center h-full">
    <loading-spinner diameter="64px" />
  </div>

  <alert-badge
    v-else-if="error.title"
    isDanger
    :title="error.title"
    :body="error.body"
    @dismissed="
      () => {
        error.title = ''
        error.body = ''
      }
    "
  />

  <div v-else>
    <img
      style="display: none"
      @load="loadFooter()"
      src="@/assets/images/examination_footer.svg"
      alt=""
      ref="footerImage"
    />
    <img
      style="display: none"
      @load="loadLogo()"
      src="@/assets/images/logo_examination.svg"
      alt=""
      ref="logoImage"
    />
    <div class="flex flex-row">
      <div class="flex flex-col">
        <!-- <action-button :title="t('print')" faIcon="print" @click="print" /> -->
        <action-button :title="t('download')" faIcon="download" @click="download" />
      </div>

      <div class="flex flex-col items-center mx-4 mt-3 px-2 relative" style="direction: ltr">
        <div ref="container" id="container"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import AlertBadge from '@/components/AlertBadge.vue'
import { examinationI18nMessages } from '@/models/customer/Examination'
import Examination from '@/models/customer/Examination'
import { parseFormat, formatPostgresDate, formatAge } from '@/utils/date'
import ActionButton from '@/components/ActionButton.vue'

import Konva from 'konva'

export default defineComponent({
  components: {
    LoadingSpinner,
    AlertBadge,
    ActionButton
  },

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          ...examinationI18nMessages.en,
          print: 'Print',
          download: 'Download'
        },
        ar: {
          ...examinationI18nMessages.ar,
          print: 'اطبع',
          download: 'تحميل'
        }
      }
    })

    function renderExaminationNumber(
      value: number | undefined,
      withoutMinus = false,
      withoutPrecision = false
    ): string {
      if (value == undefined) {
        return ''
      }

      if (withoutMinus && withoutPrecision) {
        return `${value}`
      }

      return value > 0 ? `+${value.toFixed(2)}` : `${value.toFixed(2)}`
    }

    return { t, parseFormat, formatPostgresDate, renderExaminationNumber }
  },
  data() {
    return {
      stage: null as any,
      layer: null as any,
      examination: null as Examination | null,
      loading: true,
      error: {
        title: '',
        body: ''
      }
    }
  },
  async mounted() {
    if (this.$route.params.examination_id) {
      // get order
      try {
        const resp = await this.$http.get<Examination>(
          `${Examination.ENDPOINT}/${this.$route.params.examination_id}`
        )
        this.examination = Examination.from(resp)
      } catch (error) {
        this.error.title = error.title
        this.error.body = error.body
      } finally {
        this.loading = false
        this.$nextTick(() => {
          this.setup()
        })
      }
    }
  },
  methods: {
    loadFooter() {
      // const footer = new Konva.Image({
      //   x: 45,
      //   y: this.stage.height() - 80,
      //   /* @ts-ignore */
      //   image: this.$refs['footerImage'],
      //   height: 17,
      //   width: 300,
      //   scaleX: 2.5,
      //   scaleY: 2.5,
      //   draggable: false
      // })
      // this.layer.add(footer)
    },
    loadLogo() {
      // const logo = new Konva.Image({
      //   x: this.stage.width() / 2 - 20,
      //   y: this.stage.height() - 110,
      //   /* @ts-ignore */
      //   image: this.$refs['logoImage'],
      //   scaleX: 4,
      //   scaleY: 4,
      //   draggable: false
      // })
      // this.layer.add(logo)
    },
    setup() {
      const width = 840.76 // 840.64 // window.innerWidth;
      const height = 529.37 // 530.49 // window.innerHeight;
      console.log(this.$refs)
      this.stage = new Konva.Stage({
        /* @ts-ignore */
        container: this.$refs['container'],
        width: width,
        height: height,
        drawBorder: true
      })

      this.layer = new Konva.Layer()
      this.stage.add(this.layer)

      const bgbox = new Konva.Rect({
        x: 0,
        y: 0,
        width: this.stage.width(),
        height: this.stage.height(),
        fill: 'white',
        strokeWidth: 2
      })
      this.layer.add(bgbox)

      // Create a rectangle to act as the border
      const borderRect = new Konva.Rect({
        width: this.stage.width(),
        height: this.stage.height(),
        stroke: 'black', // Border color
        strokeWidth: 5 // Border width
      })

      // Add the rectangle to the layer
      // this.layer.add(borderRect);

      // const headerText1 = new Konva.Text({
      //   x: 320,
      //   y: 36,
      //   text: 'يرجى الملاحظة أن المتجر غير مسؤول عن أي أضرار ناتجة عن"',
      //   fontSize: 24,
      //   fontFamily: 'Tajawal-Medium',
      //   fill: 'black',
      //   draggable: false
      // })
      // this.layer.add(headerText1)

      // const headerText2 = new Konva.Text({
      //   x: 170,
      //   y: 36,
      //   text: 'الإهمال أو الكسر',
      //   fontSize: 24,
      //   fontFamily: 'Tajawal-Medium',
      //   fontStyle: '800',
      //   fill: 'black',
      //   draggable: false
      // })
      // this.layer.add(headerText2)

      // const headerText3 = new Konva.Text({
      //   x: 680,
      //   y: 66,
      //   text: 'كما ننصح بشدة',
      //   fontSize: 24,
      //   fontFamily: 'Tajawal-Medium',
      //   fill: 'black',
      //   draggable: false
      // })
      // this.layer.add(headerText3)

      // const headerText4 = new Konva.Text({
      //   x: 420,
      //   y: 66,
      //   text: 'بعدم ترك النظارات في السيارة',
      //   fontSize: 24,
      //   fontFamily: 'Tajawal-Medium',
      //   fontStyle: '800',
      //   fill: 'black',
      //   draggable: false
      // })
      // this.layer.add(headerText4)

      // const headerText5 = new Konva.Text({
      //   x: 222,
      //   y: 66,
      //   text: '".لتجنب أي ضرر محتمل',
      //   fontSize: 24,
      //   fontFamily: 'Tajawal-Medium',
      //   fill: 'black',
      //   draggable: false
      // })
      // this.layer.add(headerText5)

      // const footerText = new Konva.Text({
      //   x: this.stage.width() / 2 - 50,
      //   y: this.stage.height() - 54,
      //   text: 'almaaedy.com.iq',
      //   fontSize: 16,
      //   fontFamily: 'Tajawal-Medium',
      //   fill: 'black',
      //   draggable: false
      // })
      // this.layer.add(footerText)

      const line1 = new Konva.Line({
        points: [0, 0, this.stage.width(), 0],
        stroke: 'black',
        strokeWidth: 2,
        lineCap: 'round',
        lineJoin: 'round'
      })
      line1.move({
        x: 0,
        y: 115
      })
      this.layer.add(line1)

      // date
      const dateText = new Konva.Text({
        x: 50,
        y: 140,
        text: 'Date:',
        fontSize: 24,
        fontFamily: 'Tajawal-Medium',
        fontStyle: '800',
        fill: 'black'
      })
      this.layer.add(dateText)

      const dateValueText = new Konva.Text({
        x: 165,
        y: 140,
        text: `${formatPostgresDate(String(this.examination?.createdAt), 'DD-MM-YYYY')}`,
        fontSize: 24,
        fontFamily: 'Tajawal-Medium',
        fontStyle: '800',
        fill: 'black'
      })
      this.layer.add(dateValueText)

      // customer
      const customerText = new Konva.Text({
        x: 50,
        y: 170,
        text: 'Customer:',
        fontSize: 24,
        fontFamily: 'Tajawal-Medium',
        fontStyle: '800',
        fill: 'black'
      })
      this.layer.add(customerText)

      const customerValueText = new Konva.Text({
        x: 165,
        y: 170,
        text: `${this.examination?.customer?.firstName ?? ''} ${
          this.examination?.customer?.lastName ?? ''
        }`,
        fontSize: 24,
        fontFamily: 'Tajawal-Medium',
        fontStyle: '800',
        fill: 'black'
      })
      this.layer.add(customerValueText)

      // age
      const ageText = new Konva.Text({
        x: 50,
        y: 200,
        text: 'Age:',
        fontSize: 24,
        fontFamily: 'Tajawal-Medium',
        fontStyle: '800',
        fill: 'black'
      })
      this.layer.add(ageText)

      const ageValueText = new Konva.Text({
        x: 165,
        y: 200,
        text: `${formatAge(this.examination?.customer?.dateOfBirth!)}`,
        fontSize: 24,
        fontFamily: 'Tajawal-Medium',
        fontStyle: '800',
        fill: 'black'
      })
      this.layer.add(ageValueText)

      // examination id
      const examinationIDText = new Konva.Text({
        x: this.stage.width() - 370,
        y: 140,
        text: 'Exam No:',
        fontSize: 24,
        fontFamily: 'Tajawal-Medium',
        fontStyle: '800',
        fill: 'black',
        draggable: false
      })
      this.layer.add(examinationIDText)

      const examinationIDValueText = new Konva.Text({
        x: this.stage.width() - 230,
        y: 140,
        text: `${this.examination?.externalID}`,
        fontSize: 24,
        fontFamily: 'Tajawal-Medium',
        fontStyle: '800',
        fill: 'black',
        draggable: false
      })
      this.layer.add(examinationIDValueText)

      // optometrist
      const optometristText = new Konva.Text({
        x: this.stage.width() - 370,
        y: 170,
        text: 'Optometrist:',
        fontSize: 24,
        fontFamily: 'Tajawal-Medium',
        fontStyle: '800',
        fill: 'black',
        draggable: false
      })
      this.layer.add(optometristText)

      const optometristValueText = new Konva.Text({
        x: this.stage.width() - 230,
        y: 170,
        text: `${this.examination?.examinator?.firstName ?? ''} ${this.examination?.examinator?.lastName ?? ''}`,
        fontSize: 24,
        fontFamily: 'Tajawal-Medium',
        fontStyle: '800',
        fill: 'black',
        draggable: false
      })
      this.layer.add(optometristValueText)

      const group = new Konva.Group({
        x: 0,
        y: 0,
        draggable: false
      })

      const box = new Konva.Rect({
        x: 50,
        y: 250,
        width: this.stage.width() - 99,
        height: 150,
        stroke: 'black',
        strokeWidth: 2
      })

      group.add(box)

      // table lines
      const hline1 = new Konva.Line({
        points: [50, 0, this.stage.width() - 50, 0],
        stroke: 'black',
        strokeWidth: 2,
        lineCap: 'round',
        lineJoin: 'round'
      })
      hline1.move({
        x: 0,
        y: 290
      })
      group.add(hline1)

      const hline2 = new Konva.Line({
        points: [50, 0, this.stage.width() - 264, 0],
        stroke: 'black',
        strokeWidth: 2,
        lineCap: 'round',
        lineJoin: 'round'
      })
      hline2.move({
        x: 0,
        y: 340
      })
      group.add(hline2)


      const hline3 = new Konva.Line({
        points: [50, 0, 155, 0],
        stroke: 'black',
        strokeWidth: 2,
        lineCap: 'round',
        lineJoin: 'round'
      })
      hline3.move({
        x: 635,
        y: 340
      })
      group.add(hline3)

      let previousX = 150
      for (let i = 0; i < 7; i++) {
        const vline = new Konva.Line({
          points: [0, 0, 0, 150],
          stroke: 'black',
          strokeWidth: 2,
          lineCap: 'round',
          lineJoin: 'round'
        })
        vline.move({
          x: previousX,
          y: 250
        })
        group.add(vline)
        previousX += 107
      }

      // SPH
      const SPHText = new Konva.Text({
        x: 180,
        y: 262,
        text: 'SPH',
        fontSize: 24,
        fontFamily: 'Tajawal-Medium',
        fontStyle: '800',
        fill: 'black',
        draggable: false
      })
      group.add(SPHText)

      // CYL
      const CYLText = new Konva.Text({
        x: 295,
        y: 262,
        text: 'CYL',
        fontSize: 24,
        fontFamily: 'Tajawal-Medium',
        fontStyle: '800',
        fill: 'black',
        draggable: false
      })
      group.add(CYLText)

      // AXIS
      const AXISText = new Konva.Text({
        x: 395,
        y: 262,
        text: 'AXIS',
        fontSize: 24,
        fontFamily: 'Tajawal-Medium',
        fontStyle: '800',
        fill: 'black',
        draggable: false
      })
      group.add(AXISText)

      // ADD
      const ADDText = new Konva.Text({
        x: 500,
        y: 262,
        text: 'ADD',
        fontSize: 24,
        fontFamily: 'Tajawal-Medium',
        fontStyle: '800',
        fill: 'black',
        draggable: false
      })
      group.add(ADDText)

      // IOP
      const IPDText = new Konva.Text({
        x: 610,
        y: 262,
        text: 'IPD',
        fontSize: 24,
        fontFamily: 'Tajawal-Medium',
        fontStyle: '800',
        fill: 'black',
        draggable: false
      })
      group.add(IPDText)

      // IPD
      const IOPText = new Konva.Text({
        x: 715,
        y: 262,
        text: 'I.O.P',
        fontSize: 24,
        fontFamily: 'Tajawal-Medium',
        fontStyle: '800',
        fill: 'black',
        draggable: false
      })
      group.add(IOPText)

      // first row values
      const ODText = new Konva.Text({
        x: 80,
        y: 308,
        text: 'O.D',
        fontSize: 24,
        fontFamily: 'Tajawal-Medium',
        fontStyle: '800',
        fill: 'black',
        draggable: false
      })
      group.add(ODText)

      const SPHValue1Text = new Konva.Text({
        x: 180,
        y: 308,
        text: `${this.renderExaminationNumber(this.examination?.distanceODSPH)}`,
        fontSize: 24,
        fontFamily: 'Tajawal-Medium',
        fontStyle: '800',
        fill: 'black',
        draggable: false
      })
      group.add(SPHValue1Text)

      const CYLValue1Text = new Konva.Text({
        x: 280,
        y: 308,
        text: `${this.renderExaminationNumber(this.examination?.distanceODCYL)}`,
        fontSize: 24,
        fontFamily: 'Tajawal-Medium',
        fontStyle: '800',
        fill: 'black',
        draggable: false
      })
      group.add(CYLValue1Text)

      const AXISValue1Text = new Konva.Text({
        x: 405,
        y: 308,
        text: `${this.renderExaminationNumber(this.examination?.distanceODAXIS, true, true)}`,
        fontSize: 24,
        fontFamily: 'Tajawal-Medium',
        fontStyle: '800',
        fill: 'black',
        draggable: false
      })
      group.add(AXISValue1Text)

      const ADDValue1Text = new Konva.Text({
        x: 505,
        y: 308,
        text: `${this.renderExaminationNumber(this.examination?.readingODSPH)}`,
        fontSize: 24,
        fontFamily: 'Tajawal-Medium',
        fontStyle: '800',
        fill: 'black',
        draggable: false
      })
      group.add(ADDValue1Text)

      const IPDValue1Text = new Konva.Text({
        x: 610,
        y: 330,
        text: `${this.renderExaminationNumber(this.examination?.distanceODIPD, true, true)}`,
        fontSize: 24,
        fontFamily: 'Tajawal-Medium',
        fontStyle: '800',
        fill: 'black',
        draggable: false
      })
      group.add(IPDValue1Text)

      const IOPValue1Text = new Konva.Text({
        x: 725,
        y: 308,
        text: `${this.renderExaminationNumber(this.examination?.iopOD, true, true)}`,
        fontSize: 24,
        fontFamily: 'Tajawal-Medium',
        fontStyle: '800',
        fill: 'black',
        draggable: false
      })
      group.add(IOPValue1Text)

      // second row values
      const OSText = new Konva.Text({
        x: 80,
        y: 358,
        text: 'O.S',
        fontSize: 24,
        fontFamily: 'Tajawal-Medium',
        fontStyle: '800',
        fill: 'black',
        draggable: false
      })
      group.add(OSText)

      const SPHValue2Text = new Konva.Text({
        x: 180,
        y: 358,
        text: `${this.renderExaminationNumber(this.examination?.distanceOSSPH)}`,
        fontSize: 24,
        fontFamily: 'Tajawal-Medium',
        fontStyle: '800',
        fill: 'black',
        draggable: false
      })
      group.add(SPHValue2Text)

      const CYLValue2Text = new Konva.Text({
        x: 280,
        y: 358,
        text: `${this.renderExaminationNumber(this.examination?.distanceOSCYL)}`,
        fontSize: 24,
        fontFamily: 'Tajawal-Medium',
        fontStyle: '800',
        fill: 'black',
        draggable: false
      })
      group.add(CYLValue2Text)

      const AXISValue2Text = new Konva.Text({
        x: 405,
        y: 358,
        text: `${this.renderExaminationNumber(this.examination?.distanceOSAXIS, true, true)}`,
        fontSize: 24,
        fontFamily: 'Tajawal-Medium',
        fontStyle: '800',
        fill: 'black',
        draggable: false
      })
      // AXISValue2Text.x(430 - AXISValue2Text.getTextWidth())
      group.add(AXISValue2Text)

      const ADDValue2Text = new Konva.Text({
        x: 505,
        y: 358,
        text: `${this.renderExaminationNumber(this.examination?.readingOSSPH)}`,
        fontSize: 24,
        fontFamily: 'Tajawal-Medium',
        fontStyle: '800',
        fill: 'black',
        draggable: false
      })
      group.add(ADDValue2Text)

      const IPDValue2Text = new Konva.Text({
        x: 610,
        y: 358,
        text: '',
        fontSize: 24,
        fontFamily: 'Tajawal-Medium',
        fontStyle: '800',
        fill: 'black',
        draggable: false
      })
      group.add(IPDValue2Text)

      const IOPValue2Text = new Konva.Text({
        x: 725,
        y: 358,
        text: `${this.renderExaminationNumber(this.examination?.iopOS, true, true)}`,
        fontSize: 24,
        fontFamily: 'Tajawal-Medium',
        fontStyle: '800',
        fill: 'black',
        draggable: false
      })
      group.add(IOPValue2Text)

      this.layer.add(group)

      setTimeout(() => {
        this.layer.draw()
      }, 1000)
    },
    downloadURI(uri: any, name: any) {
      const link = document.createElement('a')
      link.download = name
      link.href = uri
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    async download() {
      const dataURL = this.stage.toDataURL({ pixelRatio: 3 })
      this.downloadURI(dataURL, `${this.examination?.externalID}-idcard.png`)
    },
    print() {
      const dataURL = this.stage.toDataURL({ pixelRatio: 3 })
      const html = `
                <!DOCTYPE html>
                <html lang="en">
                <head>
                    <meta charset="UTF-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <title>Print Image</title>
                    <style>
                        /* Define any necessary styles here */
                        body { margin: 0; padding: 0; }
                        img { max-width: 100%; }
                    </style>
                </head>
                <body>
                    <!-- Insert the image to be printed -->
                    <img src="${dataURL}" alt="Example Image">
                </body>
                </html>
            `

      const printWindow = window.open('', '_blank')
      if (printWindow) {
        printWindow.document.open()
        printWindow.document.write(html)
        printWindow.document.close()

        const i = setInterval(() => {
          /* @ts-ignore */
          const content = printWindow.document.querySelector('body').innerHTML
          if (content.length) {
            printWindow.print()
            clearInterval(i)
            setTimeout(() => {
              printWindow.close()
            }, 100)
          }
        }, 200)
      }
    }
  }
})
</script>
<style>
#container {
  border-radius: 10px;
  overflow: hidden;
}
</style>
