<template>
  <div>
    <div v-if="loading" class="flex items-center justify-center h-full">
      <loading-spinner diameter="64px" />
    </div>

    <alert-badge
      v-else-if="error.title"
      isDanger
      :title="error.title"
      :body="error.body"
      @dismissed="
        () => {
          error.title = ''
          error.body = ''
        }
      "
    />

    <div class="m-3" style="height: 90vh">
      <div class="flex items-enter justify-center my-8 gap-2 md:flex-row text-xl font-bold">
        {{ $ctx.getDir() === 'rtl' ? rowData.arLocationName : rowData.enLocationName }}
      </div>
      <ag-grid-vue
        class="ag-theme-alpine"
        :columnDefs="columnDefs"
        :rowData="rowData.valuePerCategory"
        rowSelection="multiple"
        :enable-rtl="$ctx.getDir() === 'rtl'"
        style="height: 85vh"
        :pagination="true"
        :paginationAutoPageSize="true"
        :defaultColDef="defaultColDef"
        :onGridReady="onGridReady"
      >
      </ag-grid-vue>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import AlertBadge from '@/components/AlertBadge.vue'
import Location from '@/models/company/Location'
import dayjs from 'dayjs'
import { useRoute } from 'vue-router'
import { ColDef, GridOptions } from 'ag-grid-community'
import { useI18n } from 'vue-i18n'
import { formatDinero } from '@/utils/money'
import Dinero from 'dinero.js'
import { AgGridVue } from 'ag-grid-vue3'

type valuePerCategory = {
  name: string
  value: number
}

type InventoryValueDetails = {
  locationName: string
  locationID: string
  inventoryValueAmount: number
  precision: number
  enLocationName: string
  arLocationName: string
  currency: 'IQD' | 'USD'
  valuePerCategory: valuePerCategory[]
}

export default defineComponent({
  name: 'sales-by-category',
  components: {
    LoadingSpinner,
    AlertBadge,
    AgGridVue
  },
  data() {
    const rowData = {} as InventoryValueDetails
    const route = useRoute()
    const columnDefs: ColDef[] = []
    const gridOptions: GridOptions = {}
    const defaultColDef: ColDef = {}
    const messages = {
      en: {
        categoryName: 'Category Name',
        value: 'Value'
      },
      ar: {
        categoryName: 'Category Name',
        value: 'Value'
      }
    }
    const { t } = useI18n({
      messages
    })
    return {
      columnDefs,
      rowData,
      gridOptions,
      defaultColDef,
      error: {
        title: '',
        body: ''
      },
      loading: false,
      locations: new Array<Location>(),
      location: '',
      period: [dayjs().startOf('month').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')], // the default period is from the first day of the month to the current day
      route,
      t
    }
  },

  async beforeMount() {
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
      minWidth: 100,
      cellStyle: { textAlign: 'center' },
      flex: 1
    }
    this.columnDefs = [
      {
        headerName: this.t('categoryName'),
        field: 'name',
        checkboxSelection: true,
        autoHeight: true,
        cellStyle: { textAlign: 'start' },
        headerCheckboxSelection: true,
        minWidth: 500
      },
      {
        headerName: this.t('value'),
        field: 'value',
        type: 'numericColumn',
        filter: 'agNumberColumnFilter',
        cellStyle: { textAlign: 'center' },
        valueFormatter: (params) => {
          const data = params.data as valuePerCategory
          return formatDinero(
            Dinero({
              amount: data.value,
              currency: this.rowData.currency
            })
          )
        }
      }
    ]

    const locationsRes = await this.$http.get<Location[]>(Location.ENDPOINT)
    this.locations = locationsRes.map((cat) => Location.from(cat))
    this.updateRowData()
  },

  methods: {
    async updateRowData() {
      try {
        this.rowData = JSON.parse(this.route.params.data as string)
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        })
      }
      this.loading = false
    },

    async onGridReady() {
      await this.updateRowData()
    }
  }
})
</script>

<style>
.el-date-editor .el-range-separator {
  width: auto !important;
}
</style>
