<template>
  <div v-if="loading" class="flex items-center justify-center h-full">
    <loading-spinner diameter="64px" />
  </div>

  <alert-badge
    v-else-if="error.title"
    isDanger
    :title="error.title"
    :body="error.body"
    @dismissed="
      () => {
        error.title = ''
        error.body = ''
      }
    "
  />

  <div class="m-3" style="height: 90vh">
    <div class="flex flex-col items-center content-center gap-2 md:flex-row">
      <el-select class="input-label" v-model="location">
        <el-option
          v-for="(location, i) in locations"
          :key="i"
          :label="$ctx.locale === 'en' ? location.enName : location.arName"
          :value="location.id"
        >
        </el-option>
      </el-select>

      <el-date-picker
        v-model="period"
        type="daterange"
        range-separator="to"
        :start-placeholder="t('startDate')"
        :end-placeholder="t('endDate')"
      >
      </el-date-picker>
      <div class="w-1/4">
        <horizontal-button :title="$t('actions.generateReport')" @click="updateRowData()" />
      </div>
    </div>

    <div
      v-if="salesSummary.summaryBySalesValue === undefined"
      class="flex flex-col justify-center items-center h-5/6 w-full"
    >
      <img src="@/assets/emptyFolder.png" alt="empty folder" width="250" />
      <h1 class="uppercase">{{ $t('views.reports.emptyState') }}</h1>
    </div>
    <div v-else class="flex justify-between">
      <div class="chart-card-width" v-if="salesSummary" :key="JSON.stringify(salesSummary)">
        <column-chart
          :xAxis="salesSummary.getChartCategories"
          :chartData="
            salesSummary.getSummaryByNumberOfSalesChartData(
              t('salesSummaryNoSales.noOfSales'),
              t('salesSummaryNoSales.noOfReturns')
            )
          "
          :chartTitle="t('salesSummaryNoSales.title')"
        />
      </div>
      <div class="chart-card-width" v-if="salesSummary" :key="JSON.stringify(salesSummary)">
        <column-chart
          :xAxis="salesSummary.getChartCategories"
          :chartData="
            salesSummary.getSummaryBySalesValueChartData(
              t('salesSummaryValues.totalSales'),
              t('salesSummaryValues.totalReturns'),
              t('salesSummaryValues.netSales')
            )
          "
          :chartTitle="t('salesSummaryValues.title')"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import AlertBadge from '@/components/AlertBadge.vue'
import Location from '@/models/company/Location'
import ColumnChart from '@/components/charts/ColumnChart.vue'
import { useI18n } from 'vue-i18n'
import dayjs from 'dayjs'
import HorizontalButton from '@/components/HorizontalButton.vue'

class SummaryByNumberOfSales {
  static from(json: Record<string, unknown> | SummaryByNumberOfSales) {
    return Object.assign(new SummaryByNumberOfSales(), json)
  }
  storeName = ''
  storeID?: string
  totalSales?: number
  totalReturns?: number
}

class SummaryBySalesValue {
  static from(json: Record<string, unknown> | SummaryBySalesValue) {
    return Object.assign(new SummaryBySalesValue(), json)
  }
  storeName = ''
  storeID?: string
  currency?: string
  precision?: number
  totalSalesAmount?: number
  totalReturnsAmount?: number
  netSalesAmount?: number
}

export class SalesSummary {
  static from(json: Record<string, unknown> | SalesSummary) {
    return Object.assign(new SalesSummary(), json)
  }
  static ENDPOINT = 'reports/sales/sales-summary'
  summaryBySalesValue?: SummaryBySalesValue[]
  summaryByNumberOfSales?: SummaryByNumberOfSales[]

  getChartCategories() {
    return this.summaryBySalesValue ? this.summaryBySalesValue.map((item) => item.storeName) : []
  }

  getSummaryBySalesValueChartData(tSales: string, tReturns: string, netSales: string): ChartData[] {
    const chartData: ChartData[] = [
      {
        name: tSales,
        data: []
      },
      {
        name: tReturns,
        data: []
      }
    ]

    this.summaryBySalesValue?.forEach((item) => {
      chartData[0].data.push(item.totalSalesAmount)
      chartData[1].data.push(item.totalReturnsAmount)
    })

    return chartData
  }

  getSummaryByNumberOfSalesChartData(noOfSales: string, noOfReturns: string): ChartData[] {
    const chartData: ChartData[] = [
      {
        name: noOfSales,
        data: []
      },
      {
        name: noOfReturns,
        data: []
      }
    ]

    this.summaryByNumberOfSales?.forEach((item) => {
      chartData[0].data.push(item.totalSales)
      chartData[1].data.push(item.totalReturns)
    })

    return chartData
  }
}

type ChartData = {
  name: string
  data: (number | undefined)[]
}

export default defineComponent({
  name: 'sales-summary',
  components: {
    LoadingSpinner,
    AlertBadge,
    ColumnChart,
    HorizontalButton
  },
  data() {
    const salesSummary: SalesSummary = {} as SalesSummary
    const categories: string[] = []
    const messages = {
      en: {
        salesSummaryNoSales: {
          title: 'Sales Summary By Number Of Sales',
          noOfSales: '# Of Sales',
          noOfReturns: '# Of Returns'
        },
        salesSummaryValues: {
          title: 'Sales Summary By Sales Values',
          totalSales: 'Total Sales',
          totalReturns: 'Total Returns',
          netSales: 'Net Sales'
        },
        startDate: 'Start Date',
        endDate: 'End Date'
      },
      ar: {
        salesSummaryNoSales: {
          title: 'Sales Summary By Number Of Sales',
          noOfSales: '# Of Sales',
          noOfReturns: '# Of Returns'
        },
        salesSummaryValues: {
          title: 'Sales Summary By Sales Values',
          totalSales: 'Total Sales',
          totalReturns: 'Total Returns',
          netSales: 'Net Sales'
        },
        startDate: 'Start Date',
        endDate: 'End Date'
      }
    }

    const { t } = useI18n({
      messages
    })

    return {
      salesSummary,
      categories,
      error: {
        title: '',
        body: ''
      },
      loading: false,
      locations: new Array<Location>(),
      location: '',
      period: [dayjs().startOf('month').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')], // the default period is from the first day of the month to the current day
      t
    }
  },
  async beforeMount() {
    const locationsResp = await this.$http.get<Location[]>(Location.ENDPOINT)
    this.locations = locationsResp.map((location) => Location.from(location))
  },
  methods: {
    async updateRowData() {
      try {
        this.loading = true
        // will be used when the backend is ready
        // const salesSummaryResp = await this.$http.get<SalesSummary>(
        //   getPath(SalesSummary.ENDPOINT, this.location, this.period)
        // );
        // this.salesSummary = salesSummaryResp;

        //here just generating mock data
        const mockResp = {
          summaryByNumberOfSales: [
            {
              storeName: 'Al-Mansour',
              storeID: '123',
              totalSales: 1001,
              totalReturns: 50
            },
            {
              storeName: 'Al-Karada',
              storeID: '567',
              totalSales: 5000,
              totalReturns: 100
            }
          ],
          summaryBySalesValue: [
            {
              storeName: 'Al-Mansour',
              storeID: '123',
              currency: 'IQD',
              precision: 0,
              totalSalesAmount: 5000,
              totalReturnsAmount: 2000,
              netSalesAmount: 3000
            },
            {
              storeName: 'Al-Karada',
              storeID: '567',
              currency: 'IQD',
              precision: 0,
              totalSalesAmount: 10000,
              totalReturnsAmount: 5000,
              netSalesAmount: 5000
            }
          ]
        }
        this.salesSummary = SalesSummary.from(mockResp)
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        })
      }
      this.loading = false
    }
  }
})
</script>

<style>
.el-date-editor .el-range-separator {
  width: auto !important;
}
.chart-card-width {
  width: 49%;
}
</style>
