<template>
  <div>
    <el-form
      @submit.prevent="validateSubmit('itemForm')"
      novalidate
      :model="item"
      :rules="rules"
      label-position="top"
      ref="itemForm"
      class="flex flex-col items-center"
    >
      <div class="input-section">
        <div class="flex flex-col md:flex-row justify-end">
          <div class="md:w-3/12 m-2">
            <horizontal-button
              :title="$t('actions.saveChanges')"
              isSuccess
              faIcon="save"
              @click.prevent="validateSubmit('itemForm')"
            />
          </div>

          <div class="md:w-3/12 m-2" v-if="enableClearForm">
            <horizontal-button
              :title="$t('actions.clearButton')"
              isDanger
              faIcon="trash"
              @click.prevent="clearForm('itemForm')"
            />
          </div>
        </div>
      </div>

      <!-- @submit errors -->
      <alert-badge
        isDanger
        :title="error.title"
        :body="error.body"
        @dismissed="
          () => {
            error.title = ''
            error.body = ''
          }
        "
      />

      <!-- Item Name -->
      <div class="input-section">
        <h1>{{ t('sections.itemInfo') }}</h1>

        <el-form-item :label="t('name')" prop="name" class="input-label">
          <el-input
            id="name"
            v-model="item.name"
            :placeholder="t('placeholders.item')"
            maxlength="255"
            show-word-limit
            clearable
            required
          >
          </el-input>
        </el-form-item>
      </div>

      <!-- pricing -->
      <div class="input-section">
        <h1>{{ t('sections.pricing') }}</h1>
        <div class="input-group">
          <el-form-item :label="t('priceType')" class="input-label">
            <el-select v-model="item.priceType" filterable class="w-full">
              <el-option v-for="pt in ['static', 'dynamic']" :key="pt" :label="t(pt)" :value="pt">
                <span :class="{ 'float-right': $ctx.getDir() == 'rtl' }">
                  {{ t(pt) }}
                </span>
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item :label="t('listPriceAmount')" prop="listPriceAmount" class="input-label">
            <money-input
              id="list-price"
              :currency="$ctx.currency"
              v-model="item.listPriceAmount"
              @dinero-created="(dinero) => (item.listPriceDinero = dinero)"
              ref="listPriceAmountRef"
            />
          </el-form-item>

          <el-form-item :label="t('salePriceAmount')" prop="salePriceAmount" class="input-label">
            <money-input
              id="sale-price"
              @focusin="updateSalePrice"
              :currency="$ctx.currency"
              v-model="item.salePriceAmount"
              @dinero-created="(dinero) => (item.salePriceDinero = dinero)"
              ref="salePriceAmountRef"
            />
          </el-form-item>

          <!-- <el-form-item
            :label="t('wholesalePriceAmount')"
            prop="wholesalePriceAmount"
            class="input-label"
          >
            <money-input
              id="wholesale-price"
              :currency="$ctx.currency"
              v-model="item.wholesalePriceAmount"
              @dinero-created="dinero => (item.wholesalePriceDinero = dinero)"
              ref="wholesalePriceAmountRef"
            />
          </el-form-item> -->

          <el-form-item :label="t('cost')" prop="costAmount" class="input-label">
            <money-input
              id="cost"
              :currency="$ctx.currency"
              v-model="item.costAmount"
              @dinero-created="(dinero) => (item.costDinero = dinero)"
              ref="costAmountRef"
            />
          </el-form-item>
        </div>
      </div>

      <div
        v-if="Array.isArray(item.customPrices) && item.customPrices.length"
        class="input-section"
      >
        <h1 class="mb-2">{{ t('customPrices') }}</h1>

        <div class="grid grid-cols-1 md:grid-cols-4 gap-2">
          <el-form-item
            :label="$ctx.locale == 'en' ? cp.customPriceType?.enName : cp.customPriceType?.arName"
            v-for="cp of item.customPrices"
            :key="cp"
            class="input-label"
          >
            <money-input
              class="col-span-4"
              :currency="$ctx.currency"
              v-model="cp.priceAmount"
              @dinero-created="(dinero) => (cp.priceDinero = dinero)"
            />
          </el-form-item>
        </div>
      </div>

      <div class="input-section">
        <h1>{{ t('sections.options') }}</h1>

        <div class="input-group">
          <el-form-item prop="isSerialized" class="input-label">
            <el-tooltip
              class="item"
              effect="dark"
              :content="t('tooltips.serialized')"
              placement="bottom"
            >
              <el-checkbox v-model="item.isSerialized" id="is-serialized">
                {{ t('isSerialized') }}
              </el-checkbox>
            </el-tooltip>
          </el-form-item>

          <el-form-item prop="unlimitedStock" class="input-label">
            <el-tooltip
              class="item"
              effect="dark"
              :content="t('tooltips.unlimitedStock')"
              placement="bottom"
            >
              <el-checkbox v-model="item.unlimitedStock" id="unlimited-stock">
                {{ t('unlimitedStock') }}
              </el-checkbox>
            </el-tooltip>
          </el-form-item>

          <el-form-item prop="sellOutOfStock" class="input-label">
            <el-checkbox v-model="item.sellOutOfStock" id="sell-out-of-stock">
              {{ t('sellOutOfStock') }}
            </el-checkbox>
          </el-form-item>

          <el-form-item prop="discountEligible" class="input-label">
            <el-tooltip
              class="item"
              effect="dark"
              :content="t('tooltips.discountEligible')"
              placement="bottom"
            >
              <el-checkbox v-model="item.discountEligible" id="discount-eligible">
                {{ t('discountEligible') }}
              </el-checkbox>
            </el-tooltip>
          </el-form-item>

          <el-form-item prop="disabled" class="input-label">
            <el-checkbox v-model="item.disabled" id="disabled">
              {{ t('disabled') }}
            </el-checkbox>
          </el-form-item>
        </div>
      </div>

      <div class="input-section">
        <h1>{{ t('sections.stockInfo') }}</h1>
        <div class="input-group">
          <el-form-item :label="t('barcode')" prop="barcode" class="input-label">
            <el-tooltip
              class="item"
              effect="dark"
              :content="t('tooltips.barcode')"
              placement="bottom"
            >
              <el-input
                id="barcode"
                v-model="item.barcode"
                :placeholder="t('placeholders.barcode')"
                maxlength="63"
                clearable
                @keypress.enter.prevent=""
              >
              </el-input>
            </el-tooltip>
          </el-form-item>

          <el-form-item :label="t('sku')" prop="sku" class="input-label">
            <el-tooltip class="item" effect="dark" :content="t('tooltips.sku')" placement="bottom">
              <el-input
                id="sku"
                v-model="item.sku"
                :placeholder="t('placeholders.sku')"
                maxlength="63"
                clearable
              >
              </el-input>
            </el-tooltip>
          </el-form-item>
        </div>
      </div>

      <div class="input-section">
        <h1>{{ t('sections.descriptiveInfo') }}</h1>

        <el-form-item :label="t('description')" prop="description" class="input-label">
          <el-input
            id="description"
            v-model="item.description"
            :placeholder="t('placeholders.description')"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 8 }"
            clearable
          >
          </el-input>
        </el-form-item>

        <div class="input-group">
          <el-form-item :label="t('vendor')" prop="vendor.id" class="input-label">
            <el-select v-model="item.vendor.id" filterable clearable class="w-full">
              <el-option
                v-for="vendor in vendors"
                :key="vendor.id"
                :label="vendor.name"
                :value="vendor.id"
              >
                <span :class="{ 'float-right': $ctx.getDir() == 'rtl' }">
                  {{ vendor.name }}
                </span>
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item :label="t('itemBrand')" prop="itemBrand.id" class="input-label">
            <el-select v-model="item.itemBrand.id" filterable clearable class="w-full">
              <el-option
                v-for="brand in brands"
                :key="brand.id"
                :label="brand.name"
                :value="brand.id"
              >
                <span :class="{ 'float-right': $ctx.getDir() == 'rtl' }">
                  {{ brand.name }}
                </span>
              </el-option>
            </el-select>
          </el-form-item>
        </div>

        <el-form-item :label="t('itemCategory')" prop="itemCategory.id" class="input-label">
          <el-select v-model="item.itemCategory.id" filterable clearable class="w-full">
            <el-option
              v-for="category in categories"
              :key="category.id"
              :label="category.name"
              :value="category.id"
            >
              <span :class="{ 'float-right': $ctx.getDir() == 'rtl' }">
                {{ category.name }}
              </span>
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item :label="t('itemTags')" class="input-label">
          <el-select
            v-model="selectedTags"
            filterable
            clearable
            multiple
            class="w-full"
            @change="updateSelectedTags"
          >
            <el-option v-for="tag in tags" :key="tag.id" :label="tag.name" :value="tag.id">
              <span :class="{ 'float-right': $ctx.getDir() == 'rtl' }">
                {{ tag.name }}
              </span>
            </el-option>
          </el-select>
        </el-form-item>
      </div>

      <div class="input-section">
        <div class="flex flex-col md:flex-row justify-end">
          <div class="md:w-3/12 m-2">
            <horizontal-button
              :title="$t('actions.saveChanges')"
              isSuccess
              faIcon="save"
              @click.prevent="validateSubmit('itemForm')"
            />
          </div>

          <div class="md:w-3/12 m-2" v-if="enableClearForm">
            <horizontal-button
              :title="$t('actions.clearButton')"
              isDanger
              faIcon="trash"
              @click.prevent="clearForm('itemForm')"
            />
          </div>
        </div>
      </div>
    </el-form>

    <!-- item info -->
    <div class="flex flex-col items-center border" v-if="item.id">
      <div class="input-section">
        <h1>{{ t('sections.generalInfo') }}</h1>

        <div class="input-label">
          <el-timeline>
            <el-timeline-item key="createdAt" :timestamp="item?.formatCreatedAt()">
              {{ $t('models.common.createdAt') }}
            </el-timeline-item>
            <el-timeline-item key="updatedAt" :timestamp="item?.formatUpdatedAt()">
              {{ $t('models.common.updatedAt') }}
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>

      <div class="input-section">
        <h1>{{ t('sections.stockInfo') }}</h1>

        <el-table :data="item.itemStocks" style="width: 100%">
          <el-table-column type="expand" v-if="item.isSerialized">
            <template #default="scope">
              {{ t('serialNumbers') }}
              <li v-for="(serial, i) in scope.row.itemSerials" :key="i">
                <el-tag :key="i" type="info">{{ serial.number }}</el-tag>
              </li>
            </template>
          </el-table-column>
          <el-table-column
            :label="t('location')"
            :prop="$ctx.getDir() === 'rtl' ? 'location.arName' : 'location.enName'"
          >
          </el-table-column>
          <el-table-column :label="t('stockQuantity')" prop="quantity"> </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

// models
import Item, { itemI18nMessages, ItemCustomPrice } from '@/models/inventory/Item'
import Tag from '@/models/inventory/Tag'

// components
import HorizontalButton from '@/components/HorizontalButton.vue'
import AlertBadge from '@/components/AlertBadge.vue'
import { ElForm } from 'element-plus'
import MoneyInput from '@/components/form/MoneyInput.vue'

import Category from '@/models/inventory/Category'
import Brand from '@/models/inventory/Brand'
import Vendor from '@/models/vendor/Vendor'

import { useI18n } from 'vue-i18n'
import CustomPriceType from '@/models/inventory/CustomPrice'

interface Option {
  name: string
  values: string[]
}

export default defineComponent({
  name: 'simple-item-form',

  setup() {
    const messages = {
      en: {
        ...itemI18nMessages.en,

        sections: {
          itemInfo: 'Item Info',
          pricing: 'Pricing',
          options: 'Item options',
          stockInfo: 'Stock Info',
          descriptiveInfo: 'Descriptive Info',
          generalInfo: 'Item Info'
        },

        placeholders: {
          item: 'Example: backpack',
          description: 'Example: backpack with smooth fabric and life warranty',
          price: 'Example: 15000',
          cost: 'Example: 5000',
          barcode: 'Example: 1339620142715',
          sku: 'Example: 2392',
          dragDrop: 'Drop file here or click to upload',
          fileTypes: 'jpg/png files with a size less than 500kb'
        },

        select: {
          skipVendor: 'Skip vendor',
          skipBrand: 'Skip Brand',
          skipCategory: 'Skip Category'
        },

        tooltips: {
          cost: 'Cost is used in accounting',
          serialized: 'This feature is used to track items individually',
          unlimitedStock: 'Services have unlimited stock',
          discountEligible: 'Sales reps can apply discount at checkout',
          barcode: 'Must be unique',
          sku: 'Must be unique, used for internal tracking purposes',
          tags: 'To select more than one tag, click Ctrl'
        },

        createItem: 'Save Item',
        location: 'Location',
        serialNumbers: 'Serial Numbers',
        stockQuantity: 'Stock Quanity',
        customPrices: 'Custom Prices',
        newCustomPrice: 'New Custom Price',
        customPriceName: 'Price Name'
      },

      ar: {
        ...itemI18nMessages.ar,

        sections: {
          itemInfo: 'معلومات المنتج',
          pricing: 'التسعيير',
          options: 'اعدادات المنتج',
          stockInfo: 'بيانات التخزين',
          descriptiveInfo: 'البيانات الوصفية',
          generalInfo: 'معلومات المنتج'
        },

        placeholders: {
          item: 'مثلا: حقيبة ظهر',
          description: 'مثلا: حقيبة ظهر بخام ناعم',
          price: 'مثلا: 15000',
          cost: 'مثلا: 5000',
          barcode: 'مثلا: 1339620142715',
          sku: 'مثلا: 2392',
          dragDrop: 'اسحب ملف الصورة هنا أو اضغط لاختيار ملف',
          fileTypes: 'الملفات المدعومة: jpeg png'
        },

        select: {
          skipVendor: 'تخطي الموزع',
          skipBrand: 'تخطي العلامة التجارية',
          skipCategory: 'تخطي المجموعة'
        },

        tooltips: {
          cost: 'يستخدم في حسابات الارباح وغيرها من التقارير',
          serialized: 'يستخدم الترقيم في متابعة المنتجات بشكل فردي',
          unlimitedStock: 'مثلا: الخدمات لاتخضع للمخزون',
          discountEligible: 'يمكن لمندوب المبيعات ان يقوم بتخفيض المنتجات عند البيع',
          barcode: 'يجب ان يكون فريدا',
          sku: 'يجب ان يكون فريدا - يستخدم كرقم تخزين داخلي',
          tags: 'اضغط على ctrl لاختيار اكثر  من وسم'
        },

        createItem: 'حفظ المنتج',
        location: 'الموقع',
        serialNumbers: 'Serial Numbers',
        stockQuantity: 'الكمية',
        customPrices: 'الاسعار الاخرى',
        newCustomPrice: 'سعر جديد',
        customPriceName: 'عنوان السعر'
      }
    }

    const { t } = useI18n({ messages, useScope: 'global' })

    const rules = {
      name: [
        { required: true, message: t('validation.required') },
        { max: 255, message: t('validation.maxLength', { len: 255 }) }
      ],
      listPriceAmount: [{ required: true, message: t('validation.required') }],
      salePriceAmount: [{ required: true, message: t('validation.required') }],
      costAmount: [{ required: true, message: t('validation.required') }],
      barcode: [{ max: 63, message: t('validation.maxLength', { len: 63 }) }],
      sku: [{ max: 63, message: t('validation.maxLength', { len: 63 }) }]
    }

    return { t, rules, ItemCustomPrice }
  },

  components: {
    HorizontalButton,
    AlertBadge,
    MoneyInput
  },
  props: {
    initItem: {
      type: Item,
      required: true
    },
    successMsg: {
      type: String,
      required: true
    },
    submitAction: {
      type: Function,
      required: true
    },
    enableClearForm: {
      type: Boolean,
      default: false
    },
    clearFormOnSubmit: {
      type: Boolean,
      default: false
    }
  },
  // I have the choice to use v-model but would make this code
  // incredibly complicated!
  // I chose to alias/copy props, even though it might be against vue style guide
  // the parent hence passes an initial copy of the item and variants and DOES NOT
  // expect those inital copies to be synchronized!
  data() {
    return {
      item: this.initItem,
      tags: new Array<Tag>(),
      selectedTags: new Array<string | undefined>(),
      categories: new Array<Category>(),
      brands: new Array<Brand>(),
      vendors: new Array<Vendor>(),
      customPriceTypes: new Array<CustomPriceType>(),

      error: { title: '', body: '' }
    }
  },
  async beforeMount() {
    this.selectedTags = this.item.itemTags.map((tag) => tag.id)

    // get categories, brands, vendors, tags for the dropdown menu
    try {
      const customPriceTypesResp = await this.$http.get<CustomPriceType[]>(CustomPriceType.ENDPOINT)
      this.customPriceTypes = customPriceTypesResp.map((cat) => CustomPriceType.from(cat))

      const currentItemPrices = this.item.customPrices || []
      const newItemPrices = [] as Array<ItemCustomPrice>
      this.customPriceTypes.forEach((cp) => {
        const t = currentItemPrices.filter((i) => i.id == cp.id)
        if (t.length) {
          newItemPrices.push({
            id: cp.id,
            priceAmount: t[0].priceAmount,
            priceName: cp.enName,
            customPriceType: cp
          } as ItemCustomPrice)
        } else {
          newItemPrices.push({
            id: cp.id,
            priceAmount: 0,
            priceName: cp.enName,
            customPriceType: cp
          } as ItemCustomPrice)
        }
      })

      this.item.customPrices = newItemPrices

      // let url = this.$http.BASE_URL + `/inventory/categories`;
      const catResp = await this.$http.get<Category[]>(Category.ENDPOINT)
      this.categories = catResp.map((cat) => Category.from(cat))

      const tagResp = await this.$http.get<Tag[]>(Tag.ENDPOINT)
      this.tags = tagResp.map((tag) => Tag.from(tag))

      const brandResp = await this.$http.get<Brand[]>(Brand.ENDPOINT)
      this.brands = brandResp.map((brand) => Brand.from(brand))

      const vendorResp = await this.$http.get<Vendor[]>(Vendor.ENDPOINT)
      this.vendors = vendorResp.map((vendor) => Vendor.from(vendor))
    } catch (error) {
      this.error.title = error?.title
      this.error.body = error?.body
    }
  },
  methods: {
    updateSalePrice(e: any) {
      if (this.item.salePriceAmount) {
        return
      }
      /* @ts-ignore */
      this.$refs.salePriceAmountRef.updateValue(this.item.listPriceAmount)
    },
    addCustomPrice() {
      if (!this.item.customPrices) {
        this.item.customPrices = []
      }
      this.item.customPrices.push(new ItemCustomPrice())
    },
    removeCustomPrice(cp: ItemCustomPrice) {
      cp.priceAmount = 0
    },
    updateSelectedTags() {
      this.item.itemTags = this.selectedTags.map((tagID) => Tag.from({ id: tagID }))
    },

    handleImage(target: HTMLInputElement) {
      // won't work!!!!! need to change this to a put request
      if (target.files?.length) {
        this.item.imageURL = target.files[0].path
        this.item.imageType = target.files[0].type
      }
    },

    clearForm(formName: string) {
      const form = this.$refs[formName] as typeof ElForm

      this.selectedTags = new Array<string | undefined>()
      this.item = new Item()
      this.$globalEmitter.emit('money-input-reset')
      form.resetFields()
    },

    async validateSubmit(formName: string) {
      // attach the price and cost values
      this.item.listPriceAmount = this.item.listPriceDinero?.getAmount()
      this.item.listPriceCurrency = this.item.listPriceDinero?.getCurrency()
      this.item.listPricePrecision = this.item.listPriceDinero?.getPrecision()

      this.item.salePriceAmount = this.item.salePriceDinero?.getAmount()
      this.item.salePriceCurrency = this.item.salePriceDinero?.getCurrency()
      this.item.salePricePrecision = this.item.salePriceDinero?.getPrecision()

      this.item.wholesalePriceAmount = this.item.wholesalePriceDinero?.getAmount()
      this.item.wholesalePriceCurrency = this.item.wholesalePriceDinero?.getCurrency()
      this.item.wholesalePricePrecision = this.item.wholesalePriceDinero?.getPrecision()

      this.item.costAmount = this.item.costDinero?.getAmount()
      this.item.costCurrency = this.item.costDinero?.getCurrency()
      this.item.costPrecision = this.item.costDinero?.getPrecision()

      const form = this.$refs[formName] as typeof ElForm
      form?.validate(async (valid: boolean) => {
        if (!valid) {
          document.getElementById('main')?.scrollTo({ top: 0 })
          return false
        }

        const resp = await this.submitAction(this.item)

        if (resp) {
          this.error.title = resp?.title
          this.error.body = resp?.body
          document.getElementById('main')?.scrollTo({ top: 0 })
          return false
        } else {
          if (this.clearFormOnSubmit) {
            this.clearForm(formName)
          }

          this.$alertModal.showSuccess({ title: this.successMsg, body: '' })
          this.$router.back();
        }
        return true
      })
    }
  }
})
</script>

<style scoped>
h1 {
  @apply text-lg py-3 font-bold;
}

h2 {
  @apply py-3 font-bold;
}

h3 {
  @apply py-3 font-bold;
}

.input-group {
  @apply flex flex-col justify-between w-full;
}

.input-group-row {
  @apply flex justify-between w-full;
}

.input-group-row > label {
  @apply w-6/12;
}

.input-section {
  @apply rounded w-full justify-center;
}

@screen lg {
  .input-section {
    @apply w-7/12;
  }
}

@screen md {
  .input-section {
    @apply flex-row;
  }

  .input-group {
    @apply flex-row;
  }

  .input-group > .input-label {
    @apply w-6/12;
  }
}

.remove-button {
  @apply text-lg;
  color: var(--danger-text-color);
  align-self: center;
  justify-self: center;
  cursor: pointer;
  margin: 0.5rem;
}

.remove-button:hover {
  color: var(--danger-hover-bg-color);
  align-self: center;
  justify-self: center;
}

hr.solid {
  border-top: 1px solid var(--tertiary-bg-color);
}

.hide {
  display: none;
}

.input:hover + .hide {
  display: flex;
  justify-content: center;
}

.danger {
  color: var(--danger-text-color);
  background: var(--danger-bg-color);
}

.success {
  color: var(--success-text-color);
  background: var(--success-bg-color);
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  text-align: center;
}

tr:nth-child(even) {
  background-color: #f3f3f3;
}
</style>
