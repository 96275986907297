<template>
  <div class="m-3" style="height: 90vh">
    <div class="grid grid-cols-12 items-center content-center gap-2">
      <el-select class="input-label col-span-12 md:col-span-2" v-model="selectedValue">
        <el-option key="quickView" :label="$t('views.common.listView.quickView')" value="quickView">
        </el-option>
        <el-option key="allItems" :label="$t('views.common.listView.allItems')" value="allItems">
        </el-option>
        <el-option
          key="deletedItems"
          :label="$t('views.common.listView.deletedItems')"
          value="deletedItems"
        >
        </el-option>
        <el-option
          key="export"
          :label="$t('views.common.listView.selectedRows.export')"
          value="export"
        >
        </el-option>
        <el-option
          v-if="selectedValue != 'deletedItems'"
          key="delete"
          :label="$t('views.common.listView.selectedRows.delete')"
          value="delete"
        >
        </el-option>
        <el-option
          v-if="selectedValue == 'deletedItems'"
          key="restore"
          :label="$t('views.common.listView.selectedRows.restore')"
          value="restore"
        >
        </el-option>
      </el-select>

      <el-input
        :placeholder="$t('views.common.listView.searchRows')"
        class="w-full col-span-12 md:col-span-8"
        :prefix-icon="Search"
        v-model="searchTerm"
      />

      <div class="w-full col-span-12 md:col-span-2">
        <template v-if="$can('create', '/inventory/items')">
          <horizontal-button
            :title="$t('views.inventory.items.newSimpleItem')"
            @click.prevent="$router.push($Route.INVENTORY_ITEMS_NEW_SIMPLE_ITEM)"
          />
        </template>
      </div>
    </div>

    <ag-grid-vue
      v-if="rowModelType"
      class="ag-theme-balham mt-4 md:mt-0"
      :columnDefs="columnDefs"
      :rowData="rowData"
      rowSelection="multiple"
      :enable-rtl="$ctx.getDir() === 'rtl'"
      style="height: 85vh"
      :pagination="selectedValue == 'allItems'"
      :paginationAutoPageSize="selectedValue == 'allItems'"
      :defaultColDef="defaultColDef"
      @cell-double-clicked="onCellDoubleClicked"
      :onGridReady="onGridReady"
      :rowBuffer="rowBuffer"
      :rowModelType="rowModelType"
      :cacheBlockSize="cacheBlockSize"
      :cacheOverflowSize="cacheOverflowSize"
      :maxConcurrentDatasourceRequests="maxConcurrentDatasourceRequests"
      :infiniteInitialRowCount="infiniteInitialRowCount"
      :maxBlocksInCache="maxBlocksInCache"
    >
    </ag-grid-vue>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

// components
import { AgGridVue } from 'ag-grid-vue3'
import {
  ColDef,
  GridApi,
  GridOptions,
  IGetRowsParams,
  InfiniteRowModelModule,
  ModuleRegistry,
  ValueFormatterParams,
  ValueGetterParams
} from 'ag-grid-community'
import Item, { ItemPaginated, itemI18nMessages } from '@/models/inventory/Item'
// import ItemTag from "@/models/inventory/Tag";
import HorizontalButton from '@/components/HorizontalButton.vue'

import { formatDinero } from '@/utils/money'
import { useI18n } from 'vue-i18n'
import { ProcessCellForExportParams } from 'ag-grid-community'
import { Search } from '@element-plus/icons-vue'
import { ref } from 'vue'
import { onBeforeMount } from 'vue'
ModuleRegistry.registerModules([InfiniteRowModelModule])

export default defineComponent({
  name: 'items',

  components: {
    AgGridVue,
    HorizontalButton
  },

  setup() {
    const messages = {
      en: {
        ...itemI18nMessages.en,
        itemPriceUpdated: 'Item price has been updated',
        unlimited: 'Unlimited'
      },
      ar: {
        ...itemI18nMessages.ar,
        itemPriceUpdated: 'تم تحديث سعر المنتج بنجاح',
        unlimited: 'غير محدود'
      }
    }

    const { t } = useI18n({
      messages
    })

    return {
      t,
      Search
    }
  },

  data() {
    const columnDefs: ColDef[] = []
    const rowData: Item[] = []
    const gridOptions: GridOptions = {}
    const defaultColDef: ColDef = {}

    const rowBuffer = ref<any>(null)
    const rowSelection = ref<any>(null)
    const rowModelType = ref<any>(null)
    const cacheBlockSize = ref<any>(null)
    const cacheOverflowSize = ref<any>(null)
    const maxConcurrentDatasourceRequests = ref<any>(null)
    const infiniteInitialRowCount = ref<any>(null)
    const maxBlocksInCache = ref<any>(null)

    onBeforeMount(() => {
      rowBuffer.value = 0
      rowSelection.value = 'multiple'
      rowModelType.value = 'infinite'
      cacheBlockSize.value = 100
      cacheOverflowSize.value = 100
      maxConcurrentDatasourceRequests.value = 2
      infiniteInitialRowCount.value = 0
      maxBlocksInCache.value = 10
    })

    return {
      columnDefs,
      rowData,
      gridOptions,
      defaultColDef,

      searchTerm: '',
      selectedValue: 'quickView',
      gridApi: undefined as GridApi | undefined,

      rowBuffer,
      rowSelection,
      rowModelType,
      cacheBlockSize,
      cacheOverflowSize,
      maxConcurrentDatasourceRequests,
      infiniteInitialRowCount,
      maxBlocksInCache
    }
  },

  beforeMount() {
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
      minWidth: 100,
      cellStyle: { textAlign: 'center' }
    }

    this.columnDefs = [
      {
        field: '-',
        hide: true,
        lockVisible: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          newRowsAction: 'keep'
        }
      },
      {
        headerName: this.t('name'),
        field: 'name',
        checkboxSelection: true,
        autoHeight: true,
        cellStyle: { textAlign: 'start' },
        headerCheckboxSelection: true,
        minWidth: 300
      },
      {
        headerName: this.t('listPriceAmount'),
        field: 'listPriceAmount',
        type: 'numericColumn',
        filter: 'agNumberColumnFilter',
        valueFormatter: ({ data }) => {
          const item = data as Item
          if (!item) return ''
          return item.listPriceDinero ? formatDinero(item.listPriceDinero) : ''
        },
        cellStyle: { textAlign: 'center' },
        minWidth: 200
      },
      {
        headerName: this.t('salePriceAmount'),
        field: 'salePriceAmount',
        type: 'numericColumn',
        filter: 'agNumberColumnFilter',
        valueFormatter: ({ data }) => {
          const item = data as Item
          if (!item) return ''
          return item.salePriceDinero ? formatDinero(item.salePriceDinero) : ''
        },
        cellStyle: { textAlign: 'center' },
        minWidth: 200
      },
      // {
      //   headerName: this.t('wholesalePriceAmount'),
      //   field: 'wholesalePriceAmount',
      //   type: 'numericColumn',
      //   filter: 'agNumberColumnFilter',
      //   valueFormatter: ({ data }) => {
      //     const item = data as Item
      //     return item.wholesalePriceDinero ? formatDinero(item.wholesalePriceDinero) : ''
      //   },
      //   cellStyle: { textAlign: 'center' },
      //   minWidth: 200,
      // },
      {
        headerName: this.t('currentLocationStockQuantity'),
        field: 'currentLocationStockQuantity',
        type: 'numericColumn',
        minWidth: 125,
        filter: 'agNumberColumnFilter',
        cellStyle: ({ value }) => {
          const base = { textAlign: 'center', backgroundColor: 'transparent' }

          if (value < 0) {
            base['backgroundColor'] = 'var(--danger-bg-color)'
          } else if (value < 10) {
            base['backgroundColor'] = 'var(--warning-bg-color)'
          }

          if (value == this.t('unlimited')) {
            base['backgroundColor'] = '#91bee7'
          }

          return base
        },
        valueGetter: (params) => {
          const item = params.node?.data as Item
          if (!item) return ''

          if (item.unlimitedStock) {
            return this.t('unlimited')
          }

          return item.currentLocationStockQuantity
        }
      },
      {
        headerName: this.t('vendor'),
        field: 'vendor.name',
        minWidth: 150
      },
      {
        headerName: this.t('itemBrand'),
        field: 'itemBrand.name',
        minWidth: 150
      },
      {
        headerName: this.t('itemCategory'),
        field: 'itemCategory.name',
        minWidth: 150
      },
      {
        headerName: this.t('itemTags'),
        valueGetter(params: ValueGetterParams) {
          const item = params.data as Item
          if (!item) return ''
          const tags = item.itemTags
          if (!tags) return ''
          return tags.map((tag) => tag.name).join(', ')
        },
        minWidth: 150
      },
      {
        headerName: this.t('isSerialized'),
        valueFormatter: ({ data }) => {
          const item = data as Item
          if (!item) return ''
          return item.isSerialized ? '✔' : '✖'
        },
        minWidth: 150
      },
      {
        headerName: this.t('discountEligible'),
        valueFormatter: ({ data }) => {
          const item = data as Item
          if (!item) return ''
          return item.discountEligible ? '✔' : '✖'
        },
        minWidth: 200
      },
      {
        headerName: this.t('sellOutOfStock'),
        valueFormatter: ({ data }) => {
          const item = data as Item
          if (!item) return ''
          return item.sellOutOfStock ? '✔' : '✖'
        },
        minWidth: 200
      },
      {
        headerName: this.t('disabled'),
        valueFormatter: ({ data }) => {
          const item = data as Item
          if (!item) return ''
          return item.disabled ? '✔' : '✖'
        },
        minWidth: 150
      }
    ]
  },

  mounted() {},

  watch: {
    searchTerm() {
      this.filterItems()
    },
    async selectedValue(newValue, oldValue) {
      if (oldValue == 'delete' || oldValue == 'export' || oldValue == 'restore') {
        return
      }

      if (
        this.selectedValue === 'delete' ||
        this.selectedValue === 'export' ||
        this.selectedValue === 'restore'
      ) {
        const selected = this.gridApi?.getSelectedRows()

        if (selected?.length) {
          if (this.selectedValue === 'delete') {
            // send request to archive
            await this.deleteItems(selected)
            await this.updateRowData()
          } else if (this.selectedValue === 'restore') {
            // send request to archive
            await this.restoreItems(selected)
            await this.updateRowData(oldValue == 'deletedItems')
          } else {
            this.gridApi?.exportDataAsCsv({ onlySelected: true })
            this.$alertModal.showSuccess({
              title: this.$t('views.common.listView.selectedRows.exportSuccess')
            })
          }

          // deselect
          selected.length = 0
        }
        this.selectedValue = oldValue
      } else if (this.selectedValue == 'allItems') {
        this.rowModelType = null
        this.defaultColDef.sortable = true
        this.defaultColDef.filter = true
        setTimeout(() => {
          this.rowModelType = 'clientSide'
        }, 100)
      } else if (this.selectedValue == 'quickView') {
        this.rowModelType = null
        this.defaultColDef.sortable = false
        this.defaultColDef.filter = false
        setTimeout(() => {
          this.rowModelType = 'infinite'
        }, 100)
      } else if (this.selectedValue == 'deletedItems') {
        this.rowModelType = null
        this.defaultColDef.sortable = true
        this.defaultColDef.filter = true
        setTimeout(() => {
          this.rowModelType = 'clientSide'
        }, 100)
      }
    }
  },

  methods: {
    async updateRowData(softDeleted: boolean = false) {
      this.gridApi?.showLoadingOverlay()

      try {
        const data = await this.$http.get<Item[]>(Item.ENDPOINT)
        if (softDeleted) {
          this.rowData = data
            .map((item) => Item.from(item))
            .filter((item) => item.deletedAt != null)
        } else {
          this.rowData = data
            .map((item) => Item.from(item))
            .filter((item) => item.deletedAt == null)
        }
        for (const row of this.rowData) {
          if (row.itemStocks && row.itemStocks.length) {
            row.currentLocationStockQuantity = row.itemStocks[0].quantity
          }
        }

        this.gridApi?.setGridOption('rowData', this.rowData)
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        })
      }

      this.gridApi?.hideOverlay()
    },

    getDatasource() {
      const dataSource = {
        rowCount: undefined,
        getRows: async (params: IGetRowsParams) => {
          this.gridApi?.showLoadingOverlay()

          let url = `${Item.ENDPOINT}?startRow=${params.startRow}&endRow=${params.endRow}`
          if (params.sortModel.length) {
            url += `&colId=${params.sortModel[0].colId}&sort=${params.sortModel[0].sort}`
          }

          if (Object.keys(params.filterModel).length) {
            url += `&query=${params.filterModel['-'].filter}`
          }

          const res = await this.$http.get<ItemPaginated>(url)
          this.rowData = res.data.map((item) => Item.from(item))
          for (const row of this.rowData) {
            if (row.itemStocks && row.itemStocks.length) {
              row.currentLocationStockQuantity = row.itemStocks[0].quantity
            }
          }

          this.gridApi?.hideOverlay()

          const lastRow = res.total
          // call the success callback
          params.successCallback(this.rowData, lastRow)
        }
      }
      return dataSource
    },

    async onGridReady(params: any) {
      this.gridApi = params.api
      this.gridApi?.sizeColumnsToFit()

      if (this.selectedValue == 'quickView') {
        this.gridApi?.setGridOption('datasource', this.getDatasource())
        this.gridApi?.setGridOption('serverSideSortOnServer', true)
      } else if (this.selectedValue == 'allItems') {
        this.updateRowData()
      } else if (this.selectedValue == 'deletedItems') {
        await this.updateRowData(true)
      }
    },

    async deleteItems(items: Item[]) {
      if (confirm(this.$t('views.common.listView.selectedRows.deleteConfirm'))) {
        try {
          await this.$http.delete(Item.ENDPOINT, { data: items })
          this.$alertModal.showSuccess({
            title: this.$t('views.common.listView.selectedRows.deleteSuccess')
          })
        } catch (error) {
          this.$alertModal.showDanger({
            title: error.title,
            body: error.body
          })
        }
      }
    },

    async restoreItems(items: Item[]) {
      if (confirm(this.$t('views.common.listView.selectedRows.restoreConfirm'))) {
        try {
          await this.$http.post('/helpers/restore-deleted', { data: items, table: 'items' })
          this.$alertModal.showSuccess({
            title: this.$t('views.common.listView.selectedRows.restoreSuccess')
          })
        } catch (error) {
          this.$alertModal.showDanger({
            title: error.title,
            body: error.body
          })
        }
      }
    },

    processCellForExport(params: ProcessCellForExportParams) {
      if (params.column.getColDef().headerName === 'List Price') {
        return params.node?.data.salePriceDinero
          ? formatDinero(params.node?.data.listPriceDinero)
          : ''
      } else if (params.column.getColDef().headerName === 'Sale Price') {
        return params.node?.data.salePriceDinero
          ? formatDinero(params.node?.data.salePriceDinero)
          : ''
      } else {
        //if the cell doesn't belong to any of the above don't change it
        return params.value
      }
    },

    filterItems() {
      if (this.rowModelType == 'infinite') {
        this.gridApi?.setFilterModel({ '-': { filter: this.searchTerm } })
        return
      }
      this.gridApi?.setQuickFilter(this.searchTerm)
    },

    onCellDoubleClicked(event: any) {
      this.$router.push(this.$Route.INVENTORY_ITEMS_ITEM_VIEW.replace(':id', event.data.id))
    }
  }
})
</script>

<style></style>
