<template>
  <div class="m-3" style="height: 90vh">
    <div class="grid grid-cols-12 items-center content-center gap-2">
      <el-select
        class="input-label col-span-12 md:col-span-3"
        :placeholder="t('select')"
        v-model="location"
        @change="onFilterChange"
      >
        <el-option
          v-for="(loc, i) in locations"
          :key="i"
          :label="$ctx.locale === 'en' ? loc.enName : loc.arName"
          :value="loc.id"
        >
        </el-option>
      </el-select>

      <div class="col-span-12 md:col-span-2">
        <el-date-picker
          style="width: 100%"
          v-model="period"
          type="month"
          :placeholder="t('pickMonth')"
          @change="onFilterChange"
        >
        </el-date-picker>
      </div>
    </div>

    <div class="grid grid-cols-2 gap-2">
      <div class="col-span-12 md:col-span-2 chart-card" style="height: 400px">
        <v-chart :option="option" autoresize />
      </div>
    </div>

    <div v-if="data" class="flex flex-wrap gap-4">
      <el-card style="width: 480px" shadow="never">
        <div class="flex flex-col items-center">
          <div class="text-lg text-gray-500">{{ t('monthlyTotal') }}</div>
          <div class="text-2xl pt-2">{{ formatMoney(data.total, $ctx.currency) }}</div>
        </div>
      </el-card>

      <el-card style="width: 480px" shadow="never">
        <div class="flex flex-col items-center">
          <div class="text-lg text-gray-500">{{ t('dailyAverage') }}</div>
          <div class="text-2xl pt-2">{{ formatMoney(data.average, $ctx.currency) }}</div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onBeforeMount, provide } from 'vue'
import { useI18n } from 'vue-i18n'
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { PieChart, BarChart } from 'echarts/charts'
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
  DataZoomComponent
} from 'echarts/components'
import VChart, { THEME_KEY } from 'vue-echarts'

import { useHTTP } from '@/plugins/http'
import { useAlertModal } from '@/plugins/alert-modal/alert-modal'
import { formatMoney } from '@/utils/money'
import dayjs from 'dayjs'
import Location from '@/models/company/Location'

// i18n
const { t } = useI18n({
  messages: {
    en: {
      pickMonth: 'Pick a month',
      monthlySales: 'Montly Sales',
      monthlyTotal: 'Monthly Total',
      dailyAverage: 'Daily Average'
    },
    ar: {
      pickMonth: 'اختر الشهر',
      monthlySales: 'المبيعات الشهرية',
      monthlyTotal: 'اجمالي المبيعات الشهرية',
      dailyAverage: 'المعدل اليومي للمبيعات'
    }
  }
})

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  BarChart,
  GridComponent,
  ToolboxComponent,
  DataZoomComponent
])

provide(THEME_KEY, 'light')

const location = ref(null)
const locations = ref<Location[]>([])
const period = ref<Date>()
const data = ref<any>(null)

const option = ref({
  title: {
    text: t('monthlySales'),
    left: 'left'
  },
  toolbox: {
    feature: {
      saveAsImage: {}
    }
  },
  tooltip: {
    trigger: 'item',
    formatter: ({ seriesName, name, value }: any) => {
      const salary = value ? formatMoney(value, 'IQD') : ''
      return `${name}</br>${salary}`
    }
  },
  legend: {},
  dataZoom: [
    {
      id: 'dataZoomX',
      type: 'slider',
      xAxisIndex: 0,
      filterMode: 'empty',
      start: 0,
      end: 50,
      handleSize: 0,
    },
    {
      type: 'inside',
      id: 'insideX',
      xAxisIndex: 0,
      start: 0,
      end: 50,
      zoomOnMouseWheel: false,
      moveOnMouseMove: true,
      moveOnMouseWheel: true
    }
  ],
  yAxis: {
    type: 'value',
    boundaryGap: [0, 0.01],
    axisLabel: {
      formatter: (val: any) => val ? formatMoney(val, 'IQD') : ''
    }
  },
  xAxis: {
    type: 'category',
    data: [] as any
  },
  series: [
    {
      type: 'bar',
      data: []
    }
  ]
})

const http = useHTTP()
const alertModal = useAlertModal()

onBeforeMount(async () => {
  period.value = dayjs().toDate()
  await load()
})

// Methods
async function load() {
  const urlParams = new URLSearchParams()
  urlParams.set('location', location.value || '')
  if (period.value) {
    urlParams.set('year', dayjs(period.value).format('YYYY'))
    urlParams.set('month', dayjs(period.value).format('MM'))
  }

  try {
    const res: any = await http.get<any[]>('/reports/sales/monthly-sales?' + urlParams.toString())
    data.value = res
    option.value.series[0].data = res.data
    option.value.xAxis.data = Array.from(Array(res.data.length).keys()).map((i) => i + 1)
  } catch (error) {
    alertModal.showDanger({
      title: error.title,
      body: error.body
    })
  }
}

async function onFilterChange() {
  await load()
}
</script>
