<!-- for some extremely weird reasons, using v-if with the root div below can break the dom
    at unmount, it calls i18n.unmount for some reason and it breaks the code
    we're going to use v-show for now to avoid calling umount
    until a more stabled version of i18n is released
 -->

<template>
  <div
    class="absolute top-0 z-50 w-10/12 slide-bottom flex justify-center"
    id="alert-modal"
    @click.prevent="dismiss($event.target.id)"
    @keypress.enter.prevent="dismissed = true"
    v-show="!dismissed && title"
  >
    <div
      class="p-2 rounded w-5/6 md:4/6"
      :class="{
        normal: isNormal,
        danger: isDanger,
        success: isSuccess,
        warning: isWarning
      }"
    >
      <div class="flex justify-between items-center">
        <h1>{{ title }}</h1>
        <div class="dismiss" @click="dismiss">
          <font-awesome-icon icon="times-circle" />
        </div>
      </div>
      <p style="white-space: pre-line">{{ body }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'alert-modal',
  mounted() {
    this.$globalEmitter.on('show-alert-modal', (event) => {
      this.title = event?.title
      this.body = event?.body || ''
      const level = event?.level
      switch (level) {
        case 'danger':
          this.isDanger = true
          break
        case 'success':
          this.isSuccess = true
          break
        case 'warning':
          this.isWarning = true
          break
        default:
          this.isNormal = true
      }

      this.dismissed = false

      setTimeout(() => {
        this.dismiss()
      }, 5000)
    })
  },

  data() {
    return {
      title: '',
      body: '',
      isDanger: false,
      isSuccess: false,
      isWarning: false,
      isNormal: false,
      dismissed: false,
      overlay: false
    }
  },
  emits: ['dismissed'],
  methods: {
    dismiss() {
      document.querySelector('#alert-modal')?.classList.remove('slide-bottom')
      document.querySelector('#alert-modal')?.classList.add('slide-top')

      setTimeout(() => {
        this.$emit('dismissed')
        this.dismissed = true
        this.title = ''
        this.body = ''
        this.overlay = false
        this.isNormal = false
        this.isDanger = false
        this.isWarning = false
        this.isSuccess = false

        document.querySelector('#alert-modal')?.classList.remove('slide-top')
        document.querySelector('#alert-modal')?.classList.add('slide-bottom')
      }, 1000)
    }
  }
})
</script>

<style scoped>
.normal {
  color: var(--secondary-text-color);
  background: var(--secondary-bg-color);
}

.danger {
  color: var(--danger-text-color);
  background: var(--danger-bg-color);
}

.success {
  color: var(--success-text-color);
  background: var(--success-bg-color);
}

.warning {
  color: var(--warning-text-color);
  background: var(--warning-bg-color);
}

.dismiss {
  @apply text-lg;
  color: var(--danger-text-color);
  align-self: center;
  justify-self: center;
  cursor: pointer;
  margin: 0.5rem;
}

.dismiss:hover {
  color: var(--danger-hover-text-color);
}

.slide-bottom {
  -webkit-animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slide-top {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-1-31 12:28:22
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
}
@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2021-1-31 12:31:53
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-500px);
    transform: translateY(-500px);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-500px);
    transform: translateY(-500px);
  }
}
</style>
