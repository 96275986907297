import UUIDBase from '../UUIDBase'

export const customerI18nMessages = {
  en: {
    name: 'Name',
    firstName: 'Customer Name',
    lastName: 'Customer Last Name',
    businessName: 'Business Name',
    customerDisplayName: 'Customer Full Name',
    email: 'Email',
    phone: 'Phone',
    phone2: 'Phone #2',

    streetAddress: 'Street Address',
    city: 'City',
    postalCode: 'Postal Code',
    country: 'Country',
    customerType: 'Customer Type',
    regular: 'regular',
    wholesale: 'regular',
    dateOfBirth: 'Date of birth',
    selectCustomer: 'Select a customer'
  },
  ar: {
    name: 'اسم الزبون',
    firstName: 'الاسم الاول',
    lastName: 'الاسم الاخير',
    businessName: 'اسم الشركة',
    customerDisplayName: 'اسم الزبون',
    email: 'البريد الالكتروني',
    phone: 'رقم الهاتف',
    phone2: 'رقم الهاتف 2',

    streetAddress: 'عنوان الزبون',
    city: 'المدينة',
    postalCode: 'الرمز البريدي',
    country: 'الدولة',
    customerType: 'نوع العميل',
    regular: 'عام',
    wholesale: 'جملة',
    dateOfBirth: 'تاريخ الميلاد',
    selectCustomer: 'اختر زبون'
  }
}
export interface CustomersPaginated {
  data: Array<Customer>,
  total: number
}

export default class Customer extends UUIDBase {
  static from(json: Record<string, unknown> | Customer) {
    return Object.assign(new Customer(), json)
  }
  static ENDPOINT = '/customer/customers'
  static INVOICES_ENDPOINT = 'accounting/customerinvoices?customer_id='

  businessName = ''
  firstName = ''
  lastName = ''
  email = ''
  phone = ''
  phone2 = ''
  customerType = ''
  streetAddress = ''
  city = ''
  postalCode = ''
  country = ''
  dateOfBirth = null

  get contactDisplayName() {
    return `${this.firstName} ${this.lastName}`
  }
}
