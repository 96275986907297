<template>
  <div class="badge">
    <span class="arrow-up"></span>
    <p>{{ tip }}</p>
  </div>
</template>

<script lang="ts">
// note that the parent element wrappign tooltip must have position: relative
// for this to work!
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ToolTip',
  props: {
    tip: {
      type: String,
      require: true
    }
  }
})
</script>

<style scoped>
.badge {
  @apply flex flex-col absolute justify-center items-center;
  z-index: 1;
}

.badge > p {
  @apply rounded-lg py-1 px-2;
  background: var(--tertiary-bg-color);
  color: var(--tertiary-text-color);
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;

  border-bottom: 7px solid var(--tertiary-bg-color);
}
</style>
