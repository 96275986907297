<template>
  <div class="el-input el-input__wrapper" :class="{ 'is-focus': isFocus }">
    <input
      ref="inputRef"
      class="el-input__inner"
      :maxlength="15"
      :placeholder="placeholder"
      :autofocus="autofocus"
      @input="update"
      @focusin="focusin"
      @focusout="focusout"
      @keypress.space="appendZero"
      @keyup.enter="$emit('keyup-enter')"
      :disabled="disabled"
    />
  </div>
</template>

<script lang="ts">
// prefix-icon="el-icon-money"
import { defineComponent, watch } from 'vue'
import { useCurrencyInput } from 'vue-currency-input'
import Dinero from 'dinero.js'
import { formatDinero } from '@/utils/money'

/*
 * why is max length = 15?
 * javascript uses double precision IEEE floating points
 * length = 15 is the maximum safe length of a number
 * that is still a lot of money btw!
 */

export default defineComponent({
  name: 'money-input',

  setup(props, { emit }) {
    const { formattedValue, inputRef, setValue, numberValue } = useCurrencyInput({
      currency: props.currency,
      precision: props.currency === 'IQD' ? 0 : props.currency === 'USD' ? 2 : undefined,
      locale: 'en-US'
    })

    watch(numberValue, (value) => {
      if (!value && value !== 0) return

      /*
       * why precision: 0 for IQD?
       * Dinero uses  ISO 4217
       * which still has 1 IQD = 1000 fils
       * unfortunately, this isn't the case in Iraq
       * fils are neglected in almost all day to day operations
       * it would be super confusing for people to read 250.000
       * they might understand it as 250K Iraqi Dinars
       * so for the sake of UX, this decision has been made!
       */
      let asDineroVal = Dinero()
      asDineroVal =
        props.currency === 'IQD'
          ? Dinero({
              amount: value,
              currency: 'IQD',
              precision: 0
            })
          : Dinero({
              amount: value * 100,
              currency: 'USD'
            })

      emit('dinero-created', asDineroVal)
      emit('formatted-money-updated', formatDinero(asDineroVal))
    })

    if (props.modelValue !== undefined) {
      watch(
        () => props.modelValue,
        (value) => {
          if (value || value === 0) {
            setValue(value)
          }
        }
      )
    }
    return { formattedValue, inputRef, setValue }
  },

  props: {
    currency: {
      type: String,
      required: true
    },

    modelValue: {
      type: Number,
      required: false,
      default: undefined
    },

    placeholder: {
      type: String,
      required: false,
      default: ''
    },

    autofocus: {
      type: Boolean,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
  },

  emits: [
    'dinero-created',
    'update:modelValue',
    'formatted-money-updated',
    'input',
    'keyup-enter',
    'focusin'
  ],

  data() {
    return {
      moneyAsDinero: Dinero(),
      isFocus: false
    }
  },

  created() {
    this.$globalEmitter.on('money-input-reset', () => {
      this.reset()
    })
  },

  methods: {
    appendZero() {
      if (!this.modelValue) return
      const amount = (this.modelValue || 0) * 1000
      if (this.inputRef) this.inputRef.value = `${amount}`
      this.$emit('update:modelValue', amount)
    },
    updateValue(value: number) {
      const amount = value
      if (this.inputRef) this.inputRef.value = `${amount}`
      this.$emit('update:modelValue', amount)
    },
    focus() {
      if (this.inputRef) {
        this.inputRef.focus()
      }
    },
    update(event: Event) {
      if (!(event instanceof CustomEvent)) return

      const currency = this.currency
      const val = event.detail.number as number

      if (!val && val !== 0) return

      this.$emit('update:modelValue', val)
      this.$emit('input', val)
    },

    reset() {
      if (this.inputRef) this.inputRef.value = ''
      this.$emit('update:modelValue', null)
      this.$emit('input', null)
    },

    focusin() {
      this.isFocus = true
      this.$emit('focusin')
    },

    focusout() {
      this.isFocus = false
    }
  }
})
</script>
