<template>
  <el-tabs v-model="activeTabName" tab-position="left" style="height: 100%" class="demo-tabs">
    <el-tab-pane :label="t('views.settings.tabs.printers')" name="printers">
      <div class="grid grid-cols-4">
        <el-select
          filterable
          class="col-span-4 md:col-span-2 lg:col-span-1"
          v-model="selectedPrinter"
          value-key="id"
        >
          <el-option v-for="option in printers" :key="option" :label="option" :value="option">
            <span :class="{ 'float-right': $ctx.getDir() == 'rtl' }">
              {{ option }}
            </span>
          </el-option>
        </el-select>
      </div>
    </el-tab-pane>

    <el-tab-pane :label="t('views.settings.tabs.receipt')" name="receipt">
      <div>
        <div class="text-sm">Receipts Size</div>
        <div class="grid grid-cols-4">
          <el-select
            filterable
            class="col-span-4 md:col-span-2 lg:col-span-1"
            v-model="selectedReceiptSize"
            value-key="value"
            @change="selectedReceiptSizeChanged"
          >
            <el-option
              v-for="option in printSizes"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            >
              <span :class="{ 'float-right': $ctx.getDir() == 'rtl' }">
                {{ option.label }}
              </span>
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="mt-3">
        <div class="text-sm">Account Statement Size</div>
        <div class="grid grid-cols-4">
          <el-select
            filterable
            class="col-span-4 md:col-span-2 lg:col-span-1"
            v-model="selectedAccountStatementSize"
            value-key="value"
            @change="selectedAccountStatementSizeChanged"
          >
            <el-option
              v-for="option in printSizes"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            >
              <span :class="{ 'float-right': $ctx.getDir() == 'rtl' }">
                {{ option.label }}
              </span>
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="mt-3">
        <div class="text-sm">Transaction/Expense Size</div>
        <div class="grid grid-cols-4">
          <el-select
            filterable
            class="col-span-4 md:col-span-2 lg:col-span-1"
            v-model="selectedTransactionExpenseSize"
            value-key="value"
            @change="selectedTransactionExpenseSizeChanged"
          >
            <el-option
              v-for="option in printSizes"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            >
              <span :class="{ 'float-right': $ctx.getDir() == 'rtl' }">
                {{ option.label }}
              </span>
            </el-option>
          </el-select>
        </div>
      </div>
    </el-tab-pane>

    <el-tab-pane :label="t('views.settings.tabs.currencies')" name="currencies">
      <div class="input-label flex gap-2 w-1/2 items-center">
        <label for="input" class="text-base w-1/2">{{ t('USDToIQD') }}</label>
        <el-input type="number" v-model.number="USDtoIQDRate" />
      </div>

      <div class="input-label flex gap-2 w-1/2 items-center">
        <label for="input" class="text-base w-1/2">{{ t('IQDToUSD') }}</label>
        <el-input type="number" v-model.number="IQDtoUSDRate" />
      </div>
    </el-tab-pane>

    <el-tab-pane :label="t('views.settings.tabs.language')" name="language">
      <div>
        <div class="text-sm">{{ t('systemLanguage') }}</div>
        <div class="grid grid-cols-4">
          <el-select v-model="currentLocale" class="col-span-4 md:col-span-2 lg:col-span-1">
            <el-option value="en" label="English"><span>English</span></el-option>
            <el-option value="ar" label="العربية"><span>العربية</span></el-option>
          </el-select>
        </div>
      </div>
      <div class="mt-3">
        <div class="text-sm">{{ t('receiptsLanguage') }}</div>
        <div class="grid grid-cols-4">
          <el-select
            v-model="receiptLanguage"
            @change="receiptLanguageChanged"
            class="col-span-4 md:col-span-2 lg:col-span-1"
          >
            <el-option value="en" label="English"><span>English</span></el-option>
            <el-option value="ar" label="العربية"><span>العربية</span></el-option>
          </el-select>
        </div>
      </div>
    </el-tab-pane>

    <el-tab-pane :label="t('views.settings.tabs.license')" name="license">
      <License v-if="activeTabName == 'license'"></License>
    </el-tab-pane>

    <el-tab-pane :label="t('hwid')" name="abc">
      <div class="input-label flex gap-2 w-1/2 items-center">
        <label for="input" class="text-base w-1/2">{{ t('hwid') }}</label>
        <el-input v-model="hwid" />
        <el-button @click="setMachineGUID">update</el-button>
      </div>
      <div class="text-small">
        {{t('currentMachine') }}: {{ $ctx.locale === 'ar' ? $ctx.currentMachine.arName : $ctx.currentMachine.enName}}
      </div>
    </el-tab-pane>
  </el-tabs>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import Location from '@/models/company/Location'
import { isElectron } from '@/utils/env'
import License from '@/views/license/License.vue'
import HorizontalButton from '@/components/HorizontalButton.vue'
import { useAuth } from '@/plugins/auth'

export default defineComponent({
  components: {
    License
  },
  setup() {
    const selectedPrinter = ref('')
    const selectedReceiptSize = ref('')
    const selectedAccountStatementSize = ref('')
    const selectedTransactionExpenseSize = ref('')
    const activeTabName = ref('printers')

    const { locale, t } = useI18n({
      messages: {
        en: {
          switchLang: 'Switch language',
          switchLocation: 'Switch Location',
          switchPrinter: 'Switch Printer',
          confirmMsg: 'Are you sure? This will reload the app and close all open tabs',
          USDToIQD: 'Set USD To IQD Rate',
          IQDToUSD: 'Set IQD to USD Rate',
          systemLanguage: 'System Language',
          receiptsLanguage: 'Receipts language',
          hwid: 'Hardware ID',
          update: "Update",
          currentMachine: "Current Machine"
        },

        ar: {
          switchLang: 'اختر اللغة',
          switchLocation: 'غير الموقع',
          switchPrinter: 'اختر طابعة',
          confirmMsg: 'يرجى التأكيد. ستقوم هذه العملية بغلق جميع النوافذ واعادة تحميل النظام',
          USDToIQD: 'سعر الصرف من الدولار الى الدينار',
          IQDToUSD: 'سعر الصرف من الدينار الى الدولار',
          systemLanguage: 'لغة النظام',
          receiptsLanguage: 'لغة الايصالات',
          hwid: 'معرف الجهاز',
          update: "تحديث",
          currentMachine: "الجهاز الحالي"
        }
      }
    })

    const currentLocale = ref(locale.value)
    const locations = [
      {
        id: '7929d71b-833f-447b-8042-d3cf137612e9',
        name: 'Al-Mansour Store',
        type: 'store'
      },
      {
        id: '87f44a21-0b6d-4150-b82f-af458d2c2db1',
        name: 'Al-Zayona Store',
        type: 'store'
      },
      {
        id: 'fe462e64-d8ba-43ef-9507-99ec3c2da5e4',
        name: 'Karada Warehouse',
        type: 'warehouse'
      }
    ].map((loc) => Location.from(loc))

    return {
      t,
      activeTabName,
      currentLocale,
      locations,
      selectedPrinter,
      selectedReceiptSize,
      selectedAccountStatementSize,
      selectedTransactionExpenseSize
    }
  },

  async beforeMount() {
    // if (isElectron()) {
    //   try {
    //     const { ipcRenderer } = await import('electron')
    //     ipcRenderer.on('printers-list', (_e, args) => {
    //       this.printers = args
    //     })
    //     ipcRenderer.send('get-printers')
    //   } catch (error) {
    //     // do nothing
    //   }
    // }

    this.selectedReceiptSize = this.$ctx.getReceiptSize()
    this.selectedAccountStatementSize = this.$ctx.getAccountStatementSize()
    this.selectedTransactionExpenseSize = this.$ctx.getTransactionExpenseSize()
  },

  data() {
    const tabsPosition = this.$ctx.locale === 'en' ? 'left' : 'right'
    return {
      tabsPosition,
      currentLocation: this.$ctx.currentLocation,
      currentPrinter: this.$ctx.currentPrinter,
      printers: [],
      USDtoIQDRate: this.$ctx.exchangeRates.IQD,
      IQDtoUSDRate: this.$ctx.exchangeRates.USD,
      receiptLanguage: this.$ctx.receiptLanguage,
      printSizes: [
        { label: 'Large Print (A4 Paper)', value: 'a4' },
        { label: 'Small Print (Receipt Paper)', value: 'receipt' }
      ],
      hwid: this.$ctx.machineGUID
    }
  },

  watch: {
    currentLocale() {
      if (confirm(this.t('confirmMsg'))) {
        this.$ctx.setLocale(this.currentLocale)
      }
    },

    currentLocation() {
      this.$ctx.setLocation(this.currentLocation as Location)
    },

    currentPrinter() {
      this.$ctx.setPrinter(this.currentPrinter)
    },

    USDtoIQDRate() {
      this.$ctx.setExchangeRates({
        IQD: this.USDtoIQDRate,
        USD: this.IQDtoUSDRate
      })
    },

    IQDtoUSDRate() {
      this.$ctx.setExchangeRates({
        IQD: this.USDtoIQDRate,
        USD: this.IQDtoUSDRate
      })
    },
  },

  methods: {
    selectedReceiptSizeChanged(newValue: string) {
      this.$ctx.setReceiptSize(newValue)
    },
    selectedAccountStatementSizeChanged(newValue: string) {
      this.$ctx.setAccountStatementSize(newValue)
    }, 
    selectedTransactionExpenseSizeChanged(newValue: string) {
      this.$ctx.setTransactionExpenseSize(newValue)
    },
    receiptLanguageChanged(newValue: string) {
      this.$ctx.setReceiptLanguage(newValue)
    },

    async setMachineGUID() {
      if (confirm("This operation will log you out and refresh the system, are you sure?")) {
        this.$ctx.setMachineGUID(this.hwid)
        const {userLogout} = useAuth()
        await userLogout()
        this.$router.replace('/login')
      }
    }
  }
})
</script>

<style scoped>
.el-tabs--right .el-tabs__content,
.el-tabs--left .el-tabs__content {
  height: 100%;
}

.el-tabs__header {
  width: 15%;
}

.el-tabs--left .el-tabs__item.is-left {
  text-align: left !important;
}
</style>
<style>
.is-active {
  background-color: var(--primary-bg-color);
  color: white !important;
}

.el-tabs__nav {
  width: 200px;
}

.demo-tabs > .el-tabs__content {
  padding: 32px;
  font-size: 32px;
  font-weight: 600;
}
</style>
