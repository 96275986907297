<template>
  <div class="chart-card">
    <div class="chart-title">
      <h3>{{ chartTitle }}</h3>
    </div>
    <apexchart :series="chartData" type="heatmap" height="600" :options="chartOptions"></apexchart>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { pdfCustomIcon } from './PdfCustomIcon'

export default defineComponent({
  setup(props) {
    const chartOptions = {
      chart: {
        zoom: {
          enabled: false
        },
        toolbar: {
          tools: {
            customIcons: [pdfCustomIcon]
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      colors: ['#0077B6'],
      xaxis: {
        categories: props.xAxis
      }
    }

    return { chartOptions }
  },

  props: {
    chartData: {
      required: true,
      type: Object as () => ApexAxisChartSeries
    },
    chartTitle: String,
    xAxis: {
      type: Object as () => string[]
    }
  }
})
</script>

<style scoped>
.chart-card {
  width: 100%;
  background-color: white;
  padding: 1rem;
  border-radius: 5px;
  @apply mt-5;
}

.chart-title {
  text-align: center;
}
</style>
