<template>
  <div v-if="loading" class="flex items-center justify-center h-full">
    <loading-spinner diameter="64px" />
  </div>

  <alert-badge
    v-else-if="error.title"
    isDanger
    :title="error.title"
    :body="error.body"
    @dismissed="
      () => {
        error.title = '';
        error.body = '';
      }
    "
  />

  <div v-else>
    <div class="flex flex-row">
      <div class="flex flex-col">
        <action-button :title="t('print')" faIcon="print" @click="print" />
      </div>

      <TransactionReceiptA4
        v-if="$ctx.getReceiptSize() == 'a4'"
        :order="order"
        :transactions="transactions"
        :barcodeText="barcodeText"
      ></TransactionReceiptA4>

      <TransactionReceiptSmall
        v-if="$ctx.getReceiptSize() == 'receipt'"
        :order="order"
        :transactions="transactions"
        :barcodeText="barcodeText"
      ></TransactionReceiptSmall>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { i18nOrderMessages, Order } from "@/models/sales/Order";
import { useI18n } from "vue-i18n";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import AlertBadge from "@/components/AlertBadge.vue";
import { Transaction } from "@/models/accounting/Transaction";
import ActionButton from "@/components/ActionButton.vue";
import transaction from "../../accounting/transactions/Transaction.vue";
import TransactionReceiptA4 from "./TransactionReceiptA4.vue";
import TransactionReceiptSmall from "./TransactionReceiptSmall.vue";

export default defineComponent({
  computed: {
    transaction() {
      return transaction;
    }
  },
  components: {
    TransactionReceiptA4,
    TransactionReceiptSmall,
    LoadingSpinner,
    AlertBadge,
    ActionButton
  },

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          ...i18nOrderMessages.en,
          print: "Print Receipt"
        },
        ar: {
          ...i18nOrderMessages.ar,
          print: "اطبع الفاتورة"
        }
      }
    });

    return { t };
  },
  data() {
    return {
      order: new Order(),
      transactions: new Array<Transaction>(),
      loading: true,
      barcodeText: "",
      error: {
        title: "",
        body: ""
      }
    };
  },
  async mounted() {
    if (this.$route.params.transactionID) {
      // get transaction
      try {
        const transactionIDs = (this.$route.params
          .transactionID as string).split("&");

        const transactions: Transaction[] = [];

        const http = this.$http;

        for (const tID of transactionIDs) {
          const resp = await http.get<Transaction>(
            `${Transaction.ENDPOINT}/${tID}`
          );

          transactions.push(Transaction.from(resp));
        }

        this.transactions = transactions;

        // get order
        if (this.transactions[0].referenceableType == "sales.Order") {
          const orderResp = await this.$http.get<Order>(
            `${Order.ENDPOINT}/${this.transactions[0].referenceableID}`
          );
          this.order = Order.from(orderResp);
        }
      } catch (error) {
        this.error.title = error.title;
        this.error.body = error.body;
      } finally {
        this.loading = false;
      }
    }
  },
  methods: {
    print() {
      window.print();
    }
  }
});
</script>
<style>
</style>
