<template>
  <div v-if="loading" class="flex items-center justify-center h-full">
    <loading-spinner diameter="64px" />
  </div>

  <alert-badge
    v-else-if="error.title"
    isDanger
    :title="error.title"
    :body="error.body"
    @dismissed="
      () => {
        error.title = ''
        error.body = ''
      }
    "
  />

  <div v-else>
    <div class="flex flex-row">
      <div class="flex flex-col">
        <action-button :title="t('print')" faIcon="print" @click="print" />
      </div>

      <div
        class="flex flex-col items-center mx-4 mt-3 px-2 relative"
        id="a4-receipt"
        style="direction: ltr"
      >
        <img class="watermark" src="@/assets/images/watermark.png" alt="" />
        <div class="flex flex-col items-center w-full" style="">
          <!-- header info -->
          <div id="a4-receipt-header" class="flex flex-col w-full">
            <div class="grid grid-cols-2 mt-6 justify-between w-full">
              <div class="mx-8 mt-4">
                <img src="@/assets/images/logo.png" width="250" alt="" />
              </div>
              <div></div>
            </div>

            <div class="flex w-full justify-center items-center text-xl font-bold mt-2">
              <span>Eye Examination Report - </span>
              <span class="mx-1">تقرير فحص البصر</span>
            </div>

            <div class="flex flex-col w-full items-center mt-5">
              <hr class="border-2 w-11/12" style="border-color: #585954" />
              <hr class="border-2 w-7/12 mt-0.5" style="border-color: #f7e2b6" />
            </div>
          </div>

          <div id="a4-receipt-body" class="w-full">
            <div class="flex items-center justify-between mt-4 flex-wrap gap-1 px-8">
              <!-- customer name -->
              <div class="flex items-center">
                <div class="font-bold">Name</div>
                <div
                  class="bg-gray-200 rounded py-1 px-2 mx-2 font-bold capitalize examination-input"
                >
                  {{ examination?.customer?.firstName ?? '' }}
                  {{ examination?.customer?.lastName ?? '' }}
                </div>
              </div>

              <!-- exam date -->
              <div class="flex items-center">
                <div class="font-bold">Exam Date</div>
                <div
                  class="bg-gray-200 rounded py-1 px-2 mx-2 font-bold capitalize examination-input"
                >
                  {{ `${formatPostgresDate(String(examination?.createdAt), 'DD-MM-YYYY')}` }}
                </div>
              </div>

              <!-- exam no -->
              <div class="flex items-center">
                <div class="font-bold">Exam No.</div>
                <div
                  class="bg-gray-200 rounded py-1 px-2 mx-2 font-bold capitalize examination-input"
                >
                  {{ examination?.externalID ?? '' }}
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-col w-full items-center mt-5">
            <hr class="border-1 border-dashed w-11/12" style="border-color: #585954" />
          </div>

          <div class="flex items-center justify-between mt-4 flex-wrap w-full px-8">
            <div class="flex flex-col">
              <div class="font-bold">VA Without Glasses</div>
              <div class="flex items-center mt-2">
                <!-- OD -->
                <div class="flex items-center">
                  <div class="font-bold">OD</div>
                  <div
                    class="bg-gray-200 rounded py-1 px-2 mx-3 font-bold capitalize examination-input"
                  >
                    {{ examination?.vaWithoutGlassesOD ?? '' }}
                  </div>
                </div>

                <!-- OS -->
                <div class="flex items-center">
                  <div class="font-bold">OS</div>
                  <div
                    class="bg-gray-200 rounded py-1 px-2 mx-3 font-bold capitalize examination-input"
                  >
                    {{ examination?.vaWithoutGlassesOS ?? '' }}
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-col">
              <div class="font-bold">VA With Glasses</div>
              <div class="flex items-center mt-2">
                <!-- OD -->
                <div class="flex items-center">
                  <div class="font-bold">OD</div>
                  <div
                    class="bg-gray-200 rounded py-1 px-2 mx-3 font-bold capitalize examination-input"
                  >
                    {{ examination?.vaWithGlassesOD ?? '' }}
                  </div>
                </div>

                <!-- OS -->
                <div class="flex items-center">
                  <div class="font-bold">OS</div>
                  <div
                    class="bg-gray-200 rounded py-1 px-2 mx-3 font-bold capitalize examination-input"
                  >
                    {{ examination?.vaWithGlassesOS ?? '' }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex items-center justify-between mt-4 flex-wrap w-full px-8">
            <div class="flex flex-col">
              <div class="font-bold">I.O.P</div>
              <div class="flex items-center mt-2">
                <!-- OD -->
                <div class="flex items-center">
                  <div class="font-bold">OD</div>
                  <div
                    class="bg-gray-200 rounded py-1 px-2 mx-3 font-bold capitalize examination-input"
                  >
                    {{ renderExaminationNumber(examination?.iopOD, true, true) ?? '' }}
                  </div>
                </div>

                <!-- OS -->
                <div class="flex items-center">
                  <div class="font-bold">OS</div>
                  <div
                    class="bg-gray-200 rounded py-1 px-2 mx-3 font-bold capitalize examination-input"
                  >
                    {{ renderExaminationNumber(examination?.iopOS, true, true) ?? '' }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-col w-full items-center mt-5">
            <hr class="border-1 border-dashed w-11/12" style="border-color: #585954" />
          </div>

          <div class="examination-grid mt-4 w-full px-8">
            <!-- Distance Glasses Headers -->
            <div class="font-bold">Distance Glasses</div>
            <div class="font-bold"></div>
            <div class="font-bold"></div>
            <div class="font-bold mx-2">SPH</div>
            <div class="font-bold mx-2">CYL</div>
            <div class="font-bold mx-2">AXIS</div>
            <div class="font-bold mx-2">IPD</div>

            <!-- Distance Glasses OD -->
            <div class="font-bold"></div>
            <div class="font-bold"></div>
            <div class="font-bold">OD</div>
            <div class="mx-2">
              <div
                class="font-bold bg-gray-200 rounded py-1 px-2 font-bold capitalize examination-input-2"
              >
                {{ renderExaminationNumber(examination?.distanceODSPH) }}
              </div>
            </div>
            <div class="mx-2">
              <div
                class="font-bold bg-gray-200 rounded py-1 px-2 font-bold capitalize examination-input-2"
              >
                {{ renderExaminationNumber(examination?.distanceODCYL) }}
              </div>
            </div>
            <div class="mx-2">
              <div
                class="font-bold bg-gray-200 rounded py-1 px-2 font-bold capitalize examination-input-2"
              >
                {{ renderExaminationNumber(examination?.distanceODAXIS, true, true) }}
              </div>
            </div>
            <div class="mx-2">
              <div
                class="font-bold bg-gray-200 rounded py-1 px-2 font-bold capitalize examination-input-2"
              >
                {{ renderExaminationNumber(examination?.distanceODIPD, true, true) }}
              </div>
            </div>

            <!-- Distance Glasses OS -->
            <div class="font-bold"></div>
            <div class="font-bold"></div>
            <div class="font-bold">OS</div>
            <div class="mx-2">
              <div
                class="font-bold bg-gray-200 rounded py-1 px-2 font-bold capitalize examination-input-2"
              >
                {{ renderExaminationNumber(examination?.distanceOSSPH) }}
              </div>
            </div>
            <div class="mx-2">
              <div
                class="font-bold bg-gray-200 rounded py-1 px-2 font-bold capitalize examination-input-2"
              >
                {{ renderExaminationNumber(examination?.distanceOSCYL) }}
              </div>
            </div>
            <div class="mx-2">
              <div
                class="font-bold bg-gray-200 rounded py-1 px-2 font-bold capitalize examination-input-2"
              >
                {{ renderExaminationNumber(examination?.distanceOSAXIS, true, true) }}
              </div>
            </div>
            <div class="mx-2"></div>
          </div>

          <div class="examination-grid mt-8 w-full px-8">
            <!-- Reading Glasses Headers -->
            <div class="font-bold">Reading Glasses</div>
            <div class="font-bold"></div>
            <div class="font-bold"></div>
            <div class="font-bold mx-2">SPH</div>
            <div class="font-bold mx-2">CYL</div>
            <div class="font-bold mx-2">AXIS</div>
            <div class="font-bold mx-2">IPD</div>

            <!-- Reading Glasses OD -->
            <div class="font-bold"></div>
            <div class="font-bold">
              <span v-if="examination?.readingAdd">Add</span>
            </div>
            <div class="font-bold">OD</div>
            <div class="mx-2">
              <div
                class="font-bold bg-gray-200 rounded py-1 px-2 font-bold capitalize examination-input-2"
              >
                {{ renderExaminationNumber(examination?.readingODSPH) }}
              </div>
            </div>
            <div class="mx-2">
              <div
                class="font-bold bg-gray-200 rounded py-1 px-2 font-bold capitalize examination-input-2"
              >
                {{ renderExaminationNumber(examination?.readingODCYL) }}
              </div>
            </div>
            <div class="mx-2">
              <div
                class="font-bold bg-gray-200 rounded py-1 px-2 font-bold capitalize examination-input-2"
              >
                {{ renderExaminationNumber(examination?.readingODAXIS, true, true) }}
              </div>
            </div>
            <div class="mx-2">
              <div
                class="font-bold bg-gray-200 rounded py-1 px-2 font-bold capitalize examination-input-2"
              >
                {{ renderExaminationNumber(examination?.readingODIPD, true, true) }}
              </div>
            </div>

            <!-- Reading Glasses OS -->
            <div class="font-bold"></div>
            <div class="font-bold">
              <span v-if="examination?.readingAdd">Add</span>
            </div>
            <div class="font-bold">OS</div>
            <div class="mx-2">
              <div
                class="font-bold bg-gray-200 rounded py-1 px-2 font-bold capitalize examination-input-2"
              >
                {{ renderExaminationNumber(examination?.readingOSSPH) }}
              </div>
            </div>
            <div class="mx-2">
              <div
                class="font-bold bg-gray-200 rounded py-1 px-2 font-bold capitalize examination-input-2"
              >
                {{ renderExaminationNumber(examination?.readingOSCYL) }}
              </div>
            </div>
            <div class="mx-2">
              <div
                class="font-bold bg-gray-200 rounded py-1 px-2 font-bold capitalize examination-input-2"
              >
                {{ renderExaminationNumber(examination?.readingOSAXIS, true, true) }}
              </div>
            </div>
            <div class="mx-2"></div>
          </div>

          <div class="examination-grid mt-8 w-full px-8">
            <!-- Contact Lenses Headers -->
            <div class="font-bold">Contact Lenses</div>
            <div class="font-bold"></div>
            <div class="font-bold"></div>
            <div class="font-bold mx-2">SPH</div>
            <div class="font-bold mx-2">CYL</div>
            <div class="font-bold mx-2">AXIS</div>
            <div class="font-bold mx-2"></div>

            <!-- Contact Lenses OD -->
            <div class="font-bold"></div>
            <div class="font-bold"></div>
            <div class="font-bold">OD</div>
            <div class="mx-2">
              <div
                class="font-bold bg-gray-200 rounded py-1 px-2 font-bold capitalize examination-input-2"
              >
                {{ renderExaminationNumber(examination?.contactODSPH) }}
              </div>
            </div>
            <div class="mx-2">
              <div
                class="font-bold bg-gray-200 rounded py-1 px-2 font-bold capitalize examination-input-2"
              >
                {{ renderExaminationNumber(examination?.contactODCYL) }}
              </div>
            </div>
            <div class="mx-2">
              <div
                class="font-bold bg-gray-200 rounded py-1 px-2 font-bold capitalize examination-input-2"
              >
                {{ renderExaminationNumber(examination?.contactODAXIS, true, true) }}
              </div>
            </div>
            <div class="mx-2"></div>

            <!-- Contact Lenses OS -->
            <div class="font-bold"></div>
            <div class="font-bold"></div>
            <div class="font-bold">OS</div>
            <div class="mx-2">
              <div
                class="font-bold bg-gray-200 rounded py-1 px-2 font-bold capitalize examination-input-2"
              >
                {{ renderExaminationNumber(examination?.contactOSSPH) }}
              </div>
            </div>
            <div class="mx-2">
              <div
                class="font-bold bg-gray-200 rounded py-1 px-2 font-bold capitalize examination-input-2"
              >
                {{ renderExaminationNumber(examination?.contactOSCYL) }}
              </div>
            </div>
            <div class="mx-2">
              <div
                class="font-bold bg-gray-200 rounded py-1 px-2 font-bold capitalize examination-input-2"
              >
                {{ renderExaminationNumber(examination?.contactOSAXIS, true, true) }}
              </div>
            </div>
            <div class="mx-2"></div>
          </div>

          <div class="examination-grid mt-8 w-full px-8">
            <!-- Notes Headers -->
            <div class="font-bold"></div>
            <div class="font-bold"></div>
            <div class="font-bold"></div>
            <div class="font-bold mx-2">Notes</div>
            <div class="font-bold mx-2"></div>
            <div class="font-bold mx-2"></div>
            <div class="font-bold mx-2"></div>

            <div class="font-bold"></div>
            <div class="font-bold"></div>
            <div class="font-bold"></div>
            <div class="font-bold col-span-4 mx-2">
              <pre
                class="font-bold bg-gray-200 rounded py-1 px-2 w-full custom-font"
                style="min-height: 60px; direction: ltr; text-align: left"
                >{{ examination?.notes }}</pre
              >
            </div>
          </div>

          <div class="flex flex-col justify-center items-center self-center w-full mt-3">
            <div
              id="a4-receipt-footer"
              class="flex flex-col items-center justify-between pt-2 mt-2 mb-4 w-full"
            >
              <!-- hr -->
              <div class="flex flex-col w-full items-center mt-5">
                <hr class="border-1 border-dashed w-11/12" style="border-color: #585954" />
              </div>

              <div class="flex items-center justify-start mt-4 flex-wrap w-full px-8">
                <!-- Optometrist -->
                <div class="flex items-center">
                  <div class="font-bold">Optometrist</div>
                  <div
                    class="bg-gray-200 rounded py-1 px-2 mx-2 font-bold capitalize examination-input"
                  >
                    {{ examination?.examinator?.firstName ?? '' }}
                    {{ examination?.examinator?.lastName ?? '' }}
                  </div>
                </div>
              </div>

              <div class="w-full mt-4">
                <div class="flex flex-col w-full items-center">
                  <hr class="border-1 border-solid w-11/12" style="border-color: #585954" />
                </div>
                <div class="flex w-full mt-3">
                  <div class="mx-8 pb-2 text-sm self-start">
                    <p>info@almaaedy.iq</p>
                    <p>www.almaaedy.com.iq</p>
                    <p>facebook.com/SamiAlMaaedyOptical</p>
                  </div>
                  <div class="mx-8 pb-2 text-sm ml-auto">
                    <p style="font-size: 12px">مركزنا الوحيد: العراق - بغداد - شارع 62</p>
                    <p>07707106888 - 07806470404</p>
                  </div>
                </div>
              </div>
              <!-- <div
                class="flex items-center justify-between pt-2 px-4 mt-2 mb-4"
                style="background: #efefef; bottom: 0px; width: 95%"
              >
                <p><img src="@/assets/images/print_logo.png" width="80" /></p>
                <p class="thecky-site">thecky.com</p>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import AlertBadge from '@/components/AlertBadge.vue'
import { examinationI18nMessages } from '@/models/customer/Examination'
import Examination from '@/models/customer/Examination'
import { parseFormat, formatPostgresDate } from '@/utils/date'
import ActionButton from '@/components/ActionButton.vue'

export default defineComponent({
  components: {
    LoadingSpinner,
    AlertBadge,
    ActionButton
  },

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          ...examinationI18nMessages.en,
          print: 'Print'
        },
        ar: {
          ...examinationI18nMessages.ar,
          print: 'اطبع'
        }
      }
    })

    function renderExaminationNumber(
      value: number | undefined,
      withoutMinus = false,
      withoutPrecision = false
    ): string {
      if (value == undefined) {
        return ''
      }

      if (withoutMinus && withoutPrecision) {
        return `${value}`
      }

      return value > 0 ? `+${value.toFixed(2)}` : `${value.toFixed(2)}`
    }

    return { t, parseFormat, formatPostgresDate, renderExaminationNumber }
  },
  data() {
    return {
      examination: null as Examination | null,
      loading: true,
      error: {
        title: '',
        body: ''
      }
    }
  },
  async beforeMount() {
    if (this.$route.params.examination_id) {
      // get order
      try {
        const resp = await this.$http.get<Examination>(
          `${Examination.ENDPOINT}/${this.$route.params.examination_id}`
        )
        this.examination = Examination.from(resp)
      } catch (error) {
        this.error.title = error.title
        this.error.body = error.body
      } finally {
        this.loading = false
      }
    }
  },
  created() {
    // Add print CSS when component is created
    this.addPrintCSS()
  },
  beforeUnmount() {
    // Remove print CSS when component is about to be destroyed
    this.removePrintCSS()
  },
  methods: {
    print() {
      window.print()
    },
    addPrintCSS() {
      // Create a style element with a unique id
      const style = document.createElement('style')
      style.setAttribute('id', 'print-css')
      style.textContent = `
      @media print {
        @page {
          size: A4;
          margin: 0;
        }
        #a4-receipt-footer {
          bottom: 0;
          position: absolute;
        }
      }`
      // Append the style to the document head
      document.head.appendChild(style)
    },
    removePrintCSS() {
      // Find and remove the style element
      const style = document.querySelector('#print-css')
      if (style && style.parentNode) {
        style.parentNode.removeChild(style)
      }
    }
  }
})
</script>
<style>
.thecky-site {
  color: #000;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 3px;
}

#a4-receipt {
  width: 210mm;
  height: 297mm;
  overflow: scroll;
  background-color: #fff;
}

@media print {
  body * {
    visibility: hidden;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  #a4-receipt,
  #a4-receipt * {
    visibility: visible;
  }

  #a4-receipt {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: visible;
    margin: 0cm;
  }

  #a4-receipt-header {
    top: 0;
  }

  #a4-receipt-body {
    position: relative;
  }

  p,
  div {
    page-break-inside: avoid;
  }
}

.watermark {
  position: absolute;
  right: -85px;
  top: -108px;
  width: 340px;
}

.examination-input {
  min-width: 100px;
  min-height: 32px;
}

.examination-grid {
  display: grid;
  grid-template-columns: 180px 40px 30px 100px 100px 100px 100px;
  row-gap: 8px;
}

.examination-input-2 {
  min-width: 60px;
  min-height: 32px;
}

.font-bold {
  font-weight: 500 !important;
}
</style>
