<template>
  <el-form
    @submit.prevent="validateSubmit('brandForm')"
    novalidate
    :model="brand"
    :rules="rules"
    label-position="top"
    ref="brandForm"
  >
    <input-section>
      <div class="flex flex-col md:flex-row justify-end">
        <div class="md:w-4/12 m-2">
          <horizontal-button
            :title="$t('actions.saveChanges')"
            isSuccess
            faIcon="save"
            @click.prevent="validateSubmit('brandForm')"
          />
        </div>

        <div class="md:w-4/12 m-2" v-if="enableClearForm">
          <horizontal-button
            :title="$t('actions.clearButton')"
            isDanger
            faIcon="trash"
            @click.prevent="clearForm('brandForm')"
          />
        </div>
      </div>
    </input-section>

    <!-- @submit errors -->
    <alert-badge
      isDanger
      :title="error.title"
      :body="error.body"
      @dismissed="
        () => {
          error.title = ''
          error.body = ''
        }
      "
    />

    <input-section>
      <h1>{{ t('brandInfo') }}</h1>

      <el-form-item :label="t('name')" prop="name" class="input-label">
        <el-input
          id="name"
          v-model="brand.name"
          :placeholder="t('placeholder')"
          maxlength="255"
          show-word-limit
          clearable
          required
        >
        </el-input>
      </el-form-item>
    </input-section>
  </el-form>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

// components
import AlertBadge from '@/components/AlertBadge.vue'
import HorizontalButton from '@/components/HorizontalButton.vue'
import InputSection from '@/components/form/InputSection.vue'

// models
import Brand, { brandI18nMessages } from '@/models/inventory/Brand'
import { useI18n } from 'vue-i18n'
import { ElForm } from 'element-plus'

export default defineComponent({
  name: 'brand-form',
  components: {
    AlertBadge,
    HorizontalButton,
    InputSection
  },
  props: {
    initBrand: {
      type: Brand,
      required: true
    },
    submitAction: {
      type: Function,
      required: true
    },
    successMsg: {
      type: String,
      required: true
    },
    enableClearForm: {
      type: Boolean,
      required: false
    },
    clearFormOnSubmit: {
      type: Boolean,
      default: false
    }
  },

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          ...brandI18nMessages.en,
          brandInfo: 'Brand Info',
          placeholder: 'Example: Adidas'
        },
        ar: {
          ...brandI18nMessages.ar,
          brandInfo: 'بيانات العلامة التجارية',
          placeholder: 'مثلا: اديداس'
        }
      },
      useScope: 'global'
    })

    return { t }
  },

  data() {
    return {
      brand: this.initBrand,
      error: { title: '', body: '' },
      rules: {
        name: [
          {
            required: true,
            message: this.$t('validation.required'),
            trigger: 'blur'
          },
          {
            max: 255,
            message: this.$t('validation.maxLength', { len: 255 }),
            trigger: 'blur'
          }
        ]
      }
    }
  },

  methods: {
    clearForm(formName: string) {
      const form = this.$refs[formName] as typeof ElForm
      form.resetFields()
    },

    async validateSubmit(formName: string) {
      const form = this.$refs[formName] as typeof ElForm
      form?.validate(async (valid: boolean) => {
        if (!valid) {
          document.getElementById('main')?.scrollTo({ top: 0 })
          return false
        }

        const resp = await this.submitAction(this.brand)

        if (resp) {
          this.error.title = resp?.title
          this.error.body = resp?.body
          document.getElementById('main')?.scrollTo({ top: 0 })
          return false
        } else {
          if (this.clearFormOnSubmit) {
            this.clearForm(formName)
          }

          this.$alertModal.showSuccess({ title: this.successMsg, body: '' })
        }
        return true
      })
    }
  }
})
</script>

<style scoped>
form > h1 {
  @apply text-lg py-3 font-bold;
}

form > h2 {
  @apply py-3 font-bold;
}

formt > h3 {
  @apply py-3 font-bold;
}

form {
  @apply flex flex-col items-center;
}
</style>
