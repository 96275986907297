// import { Currency } from "dinero.js";

import Dinero, { Currency, Dinero as DineroType } from 'dinero.js'
import Customer from '../customer/Customer'
import UUIDBase from '../UUIDBase'
import { Transaction } from '../accounting/Transaction'

export class CustomerInvoicePayment extends UUIDBase {
  static ENDPOINT = '/accounting/customerinvoices'

  static from(json: Record<string, unknown> | CustomerInvoicePayment) {
    const ci = Object.assign(new CustomerInvoicePayment(), json)
    ci.totalDueDinero = Dinero({
      amount: ci.totalDueAmount,
      currency: ci.currency as Currency,
      precision: ci.precision
    })

    ci.paidBeforeChangeDinero = Dinero({
      amount: ci.paidBeforeChangeAmount,
      currency: ci.currency as Currency,
      precision: ci.precision
    })

    ci.changeDueDinero = Dinero({
      amount: ci.changeDueAmount,
      currency: ci.currency as Currency,
      precision: ci.precision
    })

    ci.totalPaidDinero = Dinero({
      amount: ci.totalPaidAmount,
      currency: ci.currency as Currency,
      precision: ci.precision
    })

    return ci
  }
  customerInvoiceID?: string
  externalID = ''
  currency = ''
  precision = 0

  totalDueAmount?: number
  totalDueDinero?: DineroType

  paidBeforeChangeAmount?: number
  paidBeforeChangeDinero?: DineroType

  changeDueAmount?: number
  changeDueDinero?: DineroType

  totalPaidAmount?: number
  totalPaidDinero?: DineroType

  paymentMethod?: string

  referenceableID?: string

  // before change
  reqOnlyTotalCashPaidAmount = 0
  reqOnlyTotalEpaymentAmount = 0

  reqOnlyCashMultiCurrencyBreakdown = [{}]
  reqOnlyEPaymentMultiCurrencyBreakdown = [{}]

  notes = ''
}

export interface CustomerInvoicePaginated {
  data: Array<CustomerInvoice>,
  total: number
}

export class CustomerInvoice {
  static ENDPOINT = '/accounting/customerinvoices'

  static from(json: Record<string, unknown> | CustomerInvoice) {
    const i = Object.assign(new CustomerInvoice(), json)
    if (i.customer) i.customer = Customer.from(i.customer)
    if (i.customerInvoicePayments)
      i.customerInvoicePayments = i.customerInvoicePayments.map((customerInvoicePayment) => {
        return CustomerInvoicePayment.from(customerInvoicePayment)
      })

    if (i.transactions)
      i.transactions = i.transactions.map((transaction) => {
        return Transaction.from(transaction)
      })

    i.totalDiscountDinero = Dinero({
      amount: i.totalDiscountAmount || 0,
      currency: i.currency as Currency,
      precision: i.precision
    })

    i.totalBeforeDiscountsDinero = Dinero({
      amount: i.totalBeforeDiscounts,
      currency: i.currency as Currency,
      precision: i.precision
    })

    i.totalDueDinero = Dinero({
      amount: i.totalDueAmount,
      currency: i.currency as Currency,
      precision: i.precision
    })

    i.totalPaidDinero = Dinero({
      amount: i.totalPaidAmount,
      currency: i.currency as Currency,
      precision: i.precision
    })

    i.totalBalanceDinero = Dinero({
      amount: i.totalBalanceAmount,
      currency: i.currency as Currency,
      precision: i.precision
    })

    return i
  }
  id?: string
  referenceableID?: string
  externalID?: string

  customerID?: string
  customer?: Customer

  cashierName?: string
  pouchID?: string

  billDueDate?: Date

  currency = ''
  precision = 0

  totalBeforeDiscounts?: number
  totalBeforeDiscountsDinero?: DineroType

  totalDiscountAmount?: number
  totalDiscountDinero?: DineroType

  totalDueAmount?: number
  totalDueDinero?: DineroType

  totalBalanceAmount?: number
  totalBalanceDinero?: DineroType

  totalPaidAmount?: number
  totalPaidDinero?: DineroType

  paymentType?: string
  customerInvoicePayments = Array<CustomerInvoicePayment>()
  transactions = Array<Transaction>()

  reqOnlyTotalCashPaidAmount = 0
  reqOnlyTotalEpaymentAmount = 0

  reqOnlyCashMultiCurrencyBreakdown = [{}]
  reqOnlyEPaymentMultiCurrencyBreakdown = [{}]
  notes = ''
}
