<template>
  <div class="flex flex-col items-center px-3" v-if="order.id && !order.empty()">
    <div class="w-full">
      <div class="flex justify-between w-full p-3 card rounded">
        <p>{{ t('customer') }}</p>
        <p class="flex justify-end custom-bold">
          {{ order?.invoice?.customer?.contactDisplayName ?? t('direct') }}
        </p>
      </div>

      <div class="card rounded p-3 mt-4">
        <div class="flex justify-between p-3 w-full bg-blue-100 rounded">
          <p>{{ t('orderNumber') }}</p>
          <p class="flex justify-end custom-bold">
            {{ order?.externalID }}
          </p>
        </div>
        <div
          v-if="order?.invoice.pouchID"
          class="flex justify-between p-3 mt-1 w-full bg-gray-100 rounded"
        >
          <p>{{ t('pouchID') }}</p>
          <p class="flex justify-end custom-bold">
            {{ order?.invoice.pouchID }}
          </p>
        </div>
        <div class="flex justify-between p-3 w-full">
          <p>{{ t('models.common.date') }}</p>
          <p class="flex justify-end custom-bold">
            {{ formatPostgresDate(order?.createdAt, 'DD/MM/YYYY hh:mm A') }}
          </p>
        </div>
        <div class="flex justify-between p-3 w-full">
          <p>{{ t('user') }}</p>
          <p class="flex justify-end custom-bold">
            {{ order?.user.fullName }}
          </p>
        </div>
        <div class="flex justify-between w-full p-3">
          <p>{{ t('location') }}</p>
          <p class="flex justify-end custom-bold">
            {{ $ctx.locale === 'en' ? order?.location?.enName : order?.location?.arName }}
          </p>
        </div>
        <div class="flex justify-between w-full p-3">
          <p>{{ t('register') }}</p>
          <p class="flex justify-end custom-bold">
            {{ $ctx.locale === 'en' ? order?.register?.enName : order?.register?.arName }}
          </p>
        </div>
        <div class="flex justify-between w-full p-3">
          <p>{{ t('orderNotes') }}</p>
          <p class="flex justify-end custom-bold">
            {{ order?.invoice.notes }}
          </p>
        </div>
      </div>

      <div class="card rounded p-3 mt-4">
        <div class="flex justify-between w-full p-3">
          <p>{{ t('paymentType') }}</p>
          <p class="flex justify-end custom-bold">
            {{ t(`${getOrderType(order)}`) }}
          </p>
        </div>

        <div class="flex justify-between w-full p-3">
          <p>{{ t('totalBeforeDiscounts') }}</p>
          <p class="flex justify-end custom-bold">
            {{ formatDinero(order.invoice?.totalBeforeDiscountsDinero) }}
          </p>
        </div>

        <div class="flex justify-between w-full p-3">
          <p>{{ t('discount') }}</p>
          <p class="flex justify-end custom-bold">
            {{ formatDinero(order.invoice?.totalDiscountDinero) }}
          </p>
        </div>

        <div class="flex justify-between w-full p-3">
          <p>{{ t('total') }}</p>
          <p class="flex justify-end custom-bold">
            {{ formatDinero(order.invoice?.totalDueDinero) }}
          </p>
        </div>

        <div
          v-if="!order.paymentComplete"
          class="flex justify-between w-full p-3 bg-green-100 rounded"
        >
          <p>{{ t('paymentsMade') }}</p>
          <p class="flex justify-end custom-bold">
            {{ formatDinero(order.invoice?.totalPaidDinero) }}
          </p>
        </div>
        <div
          v-if="!order.paymentComplete"
          class="flex justify-between w-full p-3 bg-red-100 rounded mt-2"
        >
          <p>{{ t('moneyOwed') }}</p>
          <p class="flex justify-end custom-bold">
            {{ formatDinero(order.invoice?.totalBalanceDinero) }}
          </p>
        </div>
      </div>
    </div>

    <div
      class="card rounded w-full p-3 mt-4"
      v-if="showPrintReceipt || !order.paymentComplete || !order.isDelivered"
    >
      <template v-if="!order.paymentComplete">
        <div class="flex flex-col items-center w-12/12 gap-1 text-black">
          <horizontal-button
            faIcon="money-bill-wave"
            :title="t('makePayment')"
            @click="handleMakePayment"
          />
        </div>
      </template>

      <!-- <div class="mt-1 flex gap-2">
        <div class="flex flex-col items-center w-6/12 gap-1 text-black">
          <horizontal-button isWarning faIcon="edit" :title="t('editOrder')" @click="editOrder" />
        </div>
        <div class="flex flex-col items-center w-6/12 gap-1 text-black">
          <horizontal-button
            isDanger
            faIcon="trash"
            :title="t('deleteOrder')"
            @click="deleteOrder"
          />
        </div>
      </div> -->

      <template v-if="!order.isDelivered">
        <div class="flex flex-col items-center w-12/12 gap-1 text-black mt-1">
          <horizontal-button
            faIcon="check"
            :title="t('markAsDelivered')"
            @click="handleMarkAsDelivered"
          />
        </div>
      </template>

      <template v-if="showPrintReceipt">
        <div class="flex flex-col items-center w-12/12 gap-1 text-black mt-1">
          <horizontal-button
            faIcon="print"
            :title="t('printOrder')"
            @click="$router.push($Route.SALES_ORDERS_ORDER_RECEIPT.replace(':id', order.id))"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { formatDinero, formatMoney } from '@/utils/money'
import { useI18n } from 'vue-i18n'
import { i18nOrderMessages, Order } from '@/models/sales/Order'
import { formatPostgresDate } from '@/utils/date'
import { ElMessage, ElMessageBox } from 'element-plus'

import HorizontalButton from '@/components/HorizontalButton.vue'

export default defineComponent({
  name: 'order-info',
  setup() {
    const { t } = useI18n({
      useScope: 'global',
      messages: {
        en: {
          ...i18nOrderMessages.en,
          print: 'Print Receipt',
          makePayment: 'Make a payment',
          paymentsMade: 'Total paid amount',
          moneyOwed: 'Money Owed',
          direct: 'Direct',
          editOrder: 'Edit Order',
          deleteOrder: 'Delete Order',
          deleteOrderConfirm: 'Are you sure?',
          canotEditDirectOrder: "You can't edit Direct order!",
          discount: 'Discount',
          orderNotes: 'Order Notes'
        },
        ar: {
          ...i18nOrderMessages.ar,
          print: 'اطبع الفاتورة',
          makePayment: 'تسديد مبلغ',
          paymentsMade: 'المبلغ المدفوع',
          moneyOwed: 'المبلغ المطلوب',
          direct: 'مباشر',
          editOrder: 'تعديل الطلب',
          deleteOrder: 'مسح الطلب',
          deleteOrderConfirm: 'هل انت متأكد؟',
          canotEditDirectOrder: 'لا يمكن تعديل الطلب المباشر!',
          discount: 'الخصم',
          orderNotes: 'ملاحظات الطلب'
        }
      }
    })

    return { t, formatPostgresDate }
  },
  props: {
    order: {
      type: Order,
      required: true
    },
    showPrintReceipt: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  components: {
    HorizontalButton
  },

  methods: {
    formatDinero,
    formatMoney,
    handleMarkAsDelivered() {
      ElMessageBox.confirm(this.t('doYouWantMarkAsDelivered'), 'Warning', {
        confirmButtonText: this.t('Yes'),
        cancelButtonText: this.t('Cancel'),
        type: 'warning'
      })
        .then(() => {
          this.$http
            .put<Order>(
              `${Order.ENDPOINT}/markOrderAsDelivered/${this.$route.params.id}`,
              this.order
            )
            .then((resp) => {
              /* eslint-disable vue/no-mutating-props */
              this.order.isDelivered = true
              ElMessage({
                type: 'success',
                message: this.t('orderMarkedAsDelivered')
              })
            })
            .catch(() => {
              ElMessage({
                type: 'error',
                message: 'error'
              })
            })
        })
        .catch(() => {
          console.log('cancelled')
        })
    },
    handleMakePayment() {
      this.$router.push(
        this.$Route.SALES_ORDERS_ORDER_PAYMENT.replace(':id', this.order.invoiceID as string)
      )
    },
    getOrderType(order: Order) {
      let orderType = ''
      if (order.orderItems.filter((oi) => oi.quantity > oi.returnedQuantity).length < 1) {
        return 'typeReturn'
      }

      switch (order.invoice.paymentType ?? '') {
        case 'direct':
          orderType = 'typedirect'
          break
        case 'credit_sale':
          orderType = 'typecredit'
          break
        case 'layaway':
          orderType = 'typelayaway'
          break
      }

      return orderType
    },
    editOrder() {
      if (
        this.order.invoice.paymentType == 'credit_sale' ||
        this.order.invoice.paymentType == 'layaway'
      ) {
        this.$router.push(
          this.$Route.SALES_ORDERS_ORDER_EDIT.replace(':id', this.order.id as string)
        )
      } else {
        this.$alertModal.showDanger({
          title: this.t('canotEditDirectOrder')
        })
      }
    },
    async deleteOrder() {
      if (confirm(this.$t('deleteOrderConfirm'))) {
        try {
          await this.$http.delete(Order.ENDPOINT, { data: [this.order] })
          this.$alertModal.showSuccess({
            title: this.$t('views.common.listView.selectedRows.deleteSuccess')
          })
          this.$router.replace(this.$Route.SALES_ORDERS)
        } catch (error) {
          this.$alertModal.showDanger({
            title: error.title,
            body: error.body
          })
        }
      }
    }
  }
})
</script>

<style>
.custom-bold {
  font-weight: bolder;
}
</style>
