<template>
  <div class="m-3" style="height: 90vh">
    <div class="grid grid-cols-12 items-center content-center gap-2">
      <el-select
        class="input-label col-span-12 md:col-span-3"
        v-model="location"
        @change="onFilterChange"
        :placeholder="t('select')"
      >
        <el-option
          v-for="(loc, i) in locations"
          :key="i"
          :label="$ctx.locale === 'en' ? loc.enName : loc.arName"
          :value="loc.id"
        >
        </el-option>
      </el-select>

      <el-date-picker
        class="col-span-12 md:col-span-1"
        v-model="period"
        type="daterange"
        range-separator="to"
        :start-placeholder="t('startDate')"
        :end-placeholder="t('endDate')"
        @change="onFilterChange"
      >
      </el-date-picker>
    </div>

    <div class="grid grid-cols-2 gap-2">
      <div class="col-span-12 md:col-span-2 chart-card" style="height: 400px">
        <v-chart :option="option" autoresize />
      </div>
    </div>

    <ag-grid-vue
      class="ag-theme-alpine mt-4 md:mt-0"
      :columnDefs="columnDefs"
      :rowData="rowData"
      rowSelection="multiple"
      :enable-rtl="$ctx.getDir() === 'rtl'"
      style="height: 85vh"
      :pagination="true"
      :paginationAutoPageSize="true"
      :defaultColDef="defaultColDef"
      :onGridReady="onGridReady"
    >
    </ag-grid-vue>
  </div>
</template>

<script setup lang="ts">
import { ref, onBeforeMount, provide } from 'vue'
import { useI18n } from 'vue-i18n'
import { ColDef, GridApi } from 'ag-grid-community'
import { AgGridVue } from 'ag-grid-vue3'
import { useRoute, useRouter } from 'vue-router'
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { PieChart, BarChart } from 'echarts/charts'
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
  DataZoomComponent
} from 'echarts/components'
import VChart, { THEME_KEY } from 'vue-echarts'

import { useHTTP } from '@/plugins/http'
import { useAlertModal } from '@/plugins/alert-modal/alert-modal'
import { formatMoney } from '@/utils/money'
import dayjs from 'dayjs'
import Location from '@/models/company/Location'

// i18n
const { t } = useI18n({
  messages: {
    en: {
      name: 'Name',
      total: 'Total',
      topSoldItems: 'Top Sold Items'
    },
    ar: {
      name: 'أسم المنتج',
      total: 'الاجمالي',
      topSoldItems: 'أعلى المنتجات مبيعا'
    }
  }
})

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  BarChart,
  GridComponent,
  ToolboxComponent,
  DataZoomComponent
])

provide(THEME_KEY, 'light')

const location = ref(null)
const locations = ref<Location[]>([])
const period = ref<Date[]>([]) // the default period is from the first day of the month to the current day

const option = ref({
  title: {
    text: t('topSoldItems'),
    left: 'left'
  },
  toolbox: {
    feature: {
      saveAsImage: {}
    }
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  legend: {},
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true
  },
  dataZoom: [
    {
      id: 'dataZoomY',
      type: 'slider',
      yAxisIndex: 0,
      filterMode: 'empty',
      startValue: 0,
      endValue: 4,
      zoomLock: true,
      handleSize: 0,
      showDetail: false,
    },
    {
      type: 'inside',
      id: 'insideY',
      yAxisIndex: 0,
      startValue: 0,
      endValue: 4,
      zoomOnMouseWheel: false,
      moveOnMouseMove: true,
      moveOnMouseWheel: true
    }
  ],
  xAxis: {
    type: 'value',
    boundaryGap: [0, 0.01]
  },
  yAxis: {
    type: 'category',
    inverse: true,
    data: []
  },
  series: [
    {
      type: 'bar',
      data: []
    }
  ]
})

// Data
const columnDefs = ref<ColDef[]>([])
const rowData = ref<any[]>([])
const defaultColDef = ref<ColDef>({})
const searchTerm = ref('')
const selectedValue = ref('')
const gridApi = ref<GridApi | undefined>(undefined)

const http = useHTTP()
const route = useRoute()
const router = useRouter()
const alertModal = useAlertModal()

onBeforeMount(async () => {
  defaultColDef.value = {
    sortable: true,
    filter: true,
    resizable: true,
    minWidth: 150
  }

  columnDefs.value = [
    {
      headerName: t('name'),
      field: 'name',
      checkboxSelection: true,
      headerCheckboxSelection: true
    },
    {
      headerName: t('total'),
      field: 'total',
      cellStyle: { direction: 'ltr !important', textAlign: 'center' }
    }
  ]
})

// Methods
async function updateRowData() {
  gridApi.value?.showLoadingOverlay()

  const urlParams = new URLSearchParams()
  urlParams.set('location', location.value || '')
  if (period.value && period.value.length == 2) {
    urlParams.set('from_date', dayjs(period.value[0]).format('YYYY-MM-DD'))
    urlParams.set('to_date', dayjs(period.value[1]).format('YYYY-MM-DD'))
  }

  try {
    const data: any = await http.get<any[]>('/reports/sales/top-sold-items?' + urlParams.toString())
    rowData.value = data

    option.value.series[0].data = data.slice().map((i: any) => i.total)
    option.value.yAxis.data = data.slice().map((i: any) => String(i.name).slice(0, 15))
  } catch (error) {
    alertModal.showDanger({
      title: error.title,
      body: error.body
    })
  }

  gridApi.value?.hideOverlay()
}

async function onGridReady(params: any) {
  gridApi.value = params.api
  gridApi.value?.sizeColumnsToFit()
  await updateRowData()
}

async function onFilterChange() {
  await updateRowData()
}
</script>
