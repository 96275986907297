<template>
  <div v-if="loading" class="flex h-full justify-center items-center">
    <loading-spinner diameter="64px" />
  </div>

  <alert-badge
    v-else-if="error.title && !hasLoaded"
    isDanger
    :title="error.title"
    :body="error.body"
    @dismissed="
      () => {
        error.title = ''
        error.body = ''
      }
    "
  />

  <div v-else>
    <div class="w-3/6 md:w-1/3 bg-white p-5 mx-2 mt-2">
      <alert-badge
        isDanger
        :title="error.title"
        :body="error.body"
        @dismissed="
        () => {
          error.title = ''
          error.body = ''
        }
      "
      />

      <div v-if="vendorBillPayment">
        <PaymentInput
          label="cashPaid"
          :totalDue="vendorBillPayment.totalDueAmount"
          v-model:changeDue="vendorBillPayment.changeDueAmount"
          v-model:paidBeforeChange="vendorBillPayment.paidBeforeChangeAmount"
          v-model:totalPaid="vendorBillPayment.paymentAmount"
          :currency="vendorBillPayment.currency"
          :precision="vendorBillPayment.precision"
          :enable-epayments="false"
        />
      </div>


      <div class="input-section w-full">
        <div class="mb-2">{{ t('paidFrom') }}</div>
        <el-select
          id="credit-account"
          v-model="vendorBillPayment.payFromAccountID"
          :trigger-on-focus="false"
          value-key="name"
          class="w-full"
          filterable
          remote
          :remote-method="searchAccounts"
        >
          <el-option
            :key="registerAccount.id"
            :label="$ctx.locale === 'ar' ? registerAccount.arName : registerAccount.enName"
            :value="registerAccount.id"
          >
            <div class="w-full bg-green-100">
              {{ $ctx.locale === 'ar' ? registerAccount.arName : registerAccount.enName }}
            </div>
          </el-option>

          <el-option
            :key="safeAccount.id"
            :label="$ctx.locale === 'ar' ? safeAccount.arName : safeAccount.enName"
            :value="safeAccount.id"
          >
            <div class="w-full bg-green-100">
              {{ $ctx.locale === 'ar' ? safeAccount.arName : safeAccount.enName }}
            </div>
          </el-option>

          <el-option
            v-for="option in accounts"
            :key="option.id"
            :label="$ctx.locale === 'ar' ? option.arName : option.enName"
            :value="option.id"
          ></el-option>
        </el-select>
      </div>

      <div class="flex justify-between items-center mb-2 mt-2">
        <horizontal-button :title="t('placePay')" isSuccess @click="openCashierPinDialog" />
      </div>
    </div>
  </div>
  <el-dialog v-model="cashierPinDialogOpen" width="30%">
    <div>
      <!-- Enter the pin -->
      <div class="my-2">
        <p>{{ t('enterCashierPin') }}</p>
      </div>

      <!-- password input up to 6 chars -->
      <div class="my-2">
        <el-input
          id="password"
          v-model="cashierPin"
          type="password"
          required
          show-password
          autofocus
          dir="ltr"
          :maxlength="4"
          :minlength="4"
          ref="cashierPinInput"
          @keyup.enter="submitPayment"
        />
      </div>

      <!-- finish payment -->
      <div class="flex items-center justify-between mb-2">
        <horizontal-button
          class="text-lg complete-payment"
          :title="t('placePay')"
          @click="submitPayment"
          :rounded="false"
        />
      </div>
    </div>
  </el-dialog>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, ref } from 'vue'

import LoadingSpinner from '@/components/LoadingSpinner.vue'
import AlertBadge from '@/components/AlertBadge.vue'
import HorizontalButton from '@/components/HorizontalButton.vue'
import PaymentInput from '@/views/sales/components/PaymentInput.vue'

import { useI18n } from 'vue-i18n'
import { useHTTP } from '@/plugins/http'
import { useAlertModal } from '@/plugins/alert-modal/alert-modal'
import { useRouter } from 'vue-router'
import VendorBill, { VendorBillPayment } from '@/models/vendor/VendorBill'
import { Account } from '@/models/accounting/Account'
import { useContext } from '@/plugins/context'
import MoneyInput from '@/components/form/MoneyInput.vue'
import { nextTick } from 'vue'

export default defineComponent({
  name: 'vendor-bill-payment',

  components: {
    MoneyInput,
    LoadingSpinner,
    AlertBadge,
    HorizontalButton,
    PaymentInput
  },

  data() {
    const accounts: Account[] = []

    return {
      accounts
    }
  },

  setup() {
    const http = useHTTP()
    const alertModal = useAlertModal()
    const router = useRouter()
    const params = router.currentRoute.value.params
    const loading = ref(false)
    const hasLoaded = ref(false)
    let vendorBill: VendorBill
    const vendorBillPayment = ref(new VendorBillPayment())
    const ctx = useContext()
    const error = ref({
      title: '',
      body: ''
    })

    const cashierPinDialogOpen = ref(false)
    const cashierPin = ref('')
    const cashierPinInput = ref()

    const { t } = useI18n({
      messages: {
        en: {
          successMsg: 'Payment made successfully',
          errInvalidAmount: 'Invalid amount. Must be bigger > 0',
          errPaymentIsComplete: 'Payments are already complete, nothing to pay',
          placePay: 'Place payment',
          paidFrom: 'Paid from',
          errYouMustSelectAnAccount: 'You must select an account to pay from',
          enterCashierPin: 'Enter cashier pin',
          errCashierPinRequired: 'Cashier pin is required'
        },
        ar: {
          successMsg: 'تم الدفع بنجاح',
          errInvalidAmount: 'يجب ان يكون المبلغ المدفوع اكبر من 0',
          errPaymentIsComplete: 'تم دفع المبلغ كاملا',
          placePay: 'اتمام الدفع',
          paidFrom: 'حساب الدفع',
          errYouMustSelectAnAccount: 'يجب اختيار حساب للدفع',
          enterCashierPin: 'ادخل رمز الكاشير الخاص بك',
          errCashierPinRequired: 'يرجى ادخال رمز الكاشير لاتمام البيع'
        }
      }
    })


    const registerAccount: Account = ref(new Account())
    const safeAccount: Account = ref(new Account())

    onBeforeMount(async () => {
      try {
        loading.value = true
        const vendorBillResponse = await http.get<VendorBill>(`${VendorBill.ENDPOINT}/${params.id}`)
        console.log(vendorBillResponse)

        vendorBill = VendorBill.from(vendorBillResponse)

        vendorBillPayment.value.totalDueAmount = vendorBill.totalBalanceAmount || 0
        vendorBillPayment.value.currency = vendorBill.currency || ''
        vendorBillPayment.value.precision = vendorBill.precision || 0
        vendorBillPayment.value.vendorBillID = vendorBill.id

        const paymentRemaining = vendorBill.totalBalanceAmount
        if (paymentRemaining === 0) {
          alertModal.showDanger({
            title: t('errPaymentIsComplete')
          })

          await router.back()
        }

        registerAccount.value = Account.from(await http.get<Account>(`${Account.ENDPOINT}/${ctx.currentRegister.accountID}`))
        safeAccount.value = Account.from(await http.get<Account>(`${Account.ENDPOINT}/${ctx.currentLocation.safeAccountID}`))
      } catch (err) {
        console.log(err)
        error.value.title = err.title
        error.value.body = err.body
        loading.value = false
        hasLoaded.value = true
      } finally {
        loading.value = false
        hasLoaded.value = true
      }
    })

    async function submitPayment() {
      if (cashierPin.value === '') {
        cashierPinDialogOpen.value = false
        error.value.title = t('errCashierPinRequired')
        return
      }

      vendorBillPayment.value.paymentAmount = vendorBillPayment.value.paidBeforeChangeAmount

      if (!vendorBillPayment.value.paymentAmount) {
        error.value.title = t('validation.inputErrors')
        error.value.body = t('errInvalidAmount')
        cashierPinDialogOpen.value = false
        return
      }
      if (!vendorBillPayment.value.payFromAccountID) {
        error.value.title = t('errYouMustSelectAnAccount')
        cashierPinDialogOpen.value = false
        return
      }

      try {
        await http.post<VendorBillPayment>(`${VendorBillPayment.ENDPOINT}`, vendorBillPayment.value, { headers: { pin: cashierPin.value } })
        await router.back();
        alertModal.showSuccess({
          title: t('successMsg')
        })
      } catch (err) {
        error.value.title = err.title
        error.value.body = err.body
        cashierPinDialogOpen.value = false
      }
    }

    function openCashierPinDialog() {
      cashierPinDialogOpen.value = true
      nextTick(() => {
        setTimeout(() => {
          cashierPinInput.value.focus()
        }, 0)
      })
    }

    return {
      t,
      vendorBillPayment,
      submitPayment,
      error,
      loading,
      hasLoaded,
      registerAccount,
      safeAccount,
      cashierPinDialogOpen,
      cashierPin,
      cashierPinInput,
      openCashierPinDialog
    }
  },

  methods: {
    async searchAccounts(query: string) {
      try {
        const url = `${Account.ENDPOINT}?q=${query}`
        this.accounts = await this.$http.get<Account[]>(url)
      } catch (error) {
        this.$alertModal.showDanger({ title: error.title })
        this.accounts = []
      }
    }
  }
})
</script>

<style></style>
