<template>
  <div class="navbar">
    <img src="@/assets/logo.png" />
    <ul>
      <li v-for="(item, i) in navItems" :key="i" id="item.route">
        <router-link :to="item.route">
          <div
            class="navitem"
            :class="{
              'selected-navitem-bg-color': isSelectedNavItem(item.route)
            }"
          >
            <div class="navitem-icon">
              <font-awesome-icon v-if="!item.customIcon" :icon="item.icon"> </font-awesome-icon>
              <span v-if="item.customIcon" style="width: 24px; height: 16px; position: relative">
                <i :class="item.icon" style="font-size: 26px; position: absolute; top: -5px" />
              </span>
            </div>

            <!--hide the title on mobile--->
            <div class="hidden-navitem-title">
              {{ item.title }}
            </div>
          </div>
        </router-link>
      </li>
    </ul>

    <div class="navitem">
      <a @click.prevent="signout" href="javascript:void(0);" class="flex items-center w-full">
        <div class="navitem-icon">
          <font-awesome-icon icon="sign-out-alt"> </font-awesome-icon>
        </div>

        <!--hide the title on mobile--->
        <div class="hidden-navitem-title">
          {{ t('signOut') }}
        </div>
      </a>
    </div>
  </div>
</template>

<script lang="ts">
// libraries
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { useAuth } from '@/plugins/auth'
import { useLoadingModal } from '@/plugins/loading-modal/loading-modal'
import { useAlertModal } from '@/plugins/alert-modal/alert-modal'
import { useRouter } from 'vue-router'
import { NavItem } from './NavItem'

export default defineComponent({
  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          signOut: 'Sign Out'
        },
        ar: {
          signOut: 'تسجيل الخروج'
        }
      },
      useScope: 'global'
    })

    const navItems: NavItem[] = [
      {
        route: '/',
        icon: 'home',
        title: t('views.home.home'),
        customIcon: false
      },
      {
        route: '/sales',
        icon: 'dollar-sign',
        title: t('views.sales.sales'),
        customIcon: false
      },
      {
        route: '/inventory',
        icon: 'warehouse',
        title: t('views.inventory.inventory'),
        customIcon: false
      },
      {
        route: '/customers',
        icon: 'users',
        title: t('views.customers.customers'),
        customIcon: false
      },
      {
        route: '/examinations',
        icon: 'icon-examination',
        title: t('views.examinations.examinations'),
        customIcon: true
      },
      {
        route: '/vendors',
        icon: 'users',
        title: t('views.vendors.vendors'),
        customIcon: false
      },
      {
        route: '/hr',
        icon: 'address-book',
        title: t('views.hr.hr'),
        customIcon: false
      },
      {
        route: '/accounting',
        icon: 'calculator',
        title: t('views.accounting.accounting'),
        customIcon: false
      },
      // {
      //   route: "/reports",
      //   icon: "flag",
      //   title: t("views.reports.reports"),
      //   customIcon: false,
      // },
      {
        route: '/settings',
        icon: 'cogs',
        title: t('views.settings.settings'),
        customIcon: false
      }
    ]

    const router = useRouter()

    const isSelectedNavItem = function (parentRoute: string) {
      const currentRoute = router.currentRoute.value.fullPath

      return parentRoute === '/'
        ? currentRoute === parentRoute
        : currentRoute.startsWith(parentRoute)
    }

    const signout = async () => {
      const { show, hide } = useLoadingModal()
      const { showDanger } = useAlertModal()
      try {
        const { userLogout } = useAuth()
        show()
        router.replace('/login')
        await userLogout()
      } catch (error) {
        showDanger({
          title: error.title,
          body: error.body
        })
      } finally {
        hide()
      }
    }

    return {
      navItems,
      isSelectedNavItem,
      t,
      signout
    }
  }
})
</script>

<style scoped>
.navbar {
  background: var(--secondary-bg-color);
  @apply min-h-screen;
  @apply top-0;
  @apply sticky;
  @apply shadow-xl;
}

.navitem {
  @apply flex items-center cursor-pointer transition duration-100 ease-in-out transform;
}

.navitem:hover {
  @apply -translate-y-1;
  background: var(--primary-hover-bg-color);
  color: var(--primary-hover-text-color);
}

/* Documentaion reference https://tailwindcss.com/docs/functions-and-directives#apply */
@screen md {
  .navitem {
    @apply justify-start;
  }
}

.selected-navitem-bg-color {
  background: var(--primary-bg-color);
  color: var(--primary-text-color);
  @extend .primary;
}

.navitem-icon {
  @apply grid m-4 w-6 justify-center;
}

/*
By default, Tailwind uses a mobile-first breakpoint system. So on mobile, we would like to hide the navitem's title. Then we override that in the next block @screen md to display the title on the medium breakpoint onwards.
*/
.hidden-navitem-title {
  @apply hidden;
}

@screen md {
  .hidden-navitem-title {
    @apply contents;
  }
}
</style>
