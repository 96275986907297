<template>
  <div class="m-3" style="height: 90vh">
    <div class="flex flex-col items-center justify-between gap-2 my-6">
      <div class="w-full grid grid-cols-12 items-center content-center gap-2">
        <el-select class="input-label col-span-12 md:col-span-2" v-model="selectedValue" @change="applyToSelected">
          <el-option
            key="export"
            :label="$t('views.common.listView.selectedRows.export')"
            value="export"
          >
          </el-option>
        </el-select>

        <el-input
          :placeholder="$t('views.common.listView.searchRows')"
          class="w-full col-span-12 md:col-span-10"
          :prefix-icon="Search"
          v-model="searchTerm"
          :onchange="filterItems()"
        />
      </div>
      <div v-if="rowData" class="w-full flex justify-between items-center bg-white px-4 py-6">
        <h1 class="text-2xl capitalize" v-if="rowData.customer">
          {{ rowData.customer.contactDisplayName }}
        </h1>
        <h1 class="text-2xl" v-if="rowData.totalBalanceAmount">
          {{ t('balance') + ': ' + getDineroValue(rowData.totalBalanceAmount) }}
        </h1>
      </div>
    </div>

    <ag-grid-vue
      class="ag-theme-alpine mt-4 md:mt-0"
      :columnDefs="columnDefs"
      :rowData="rowData.invoices"
      rowSelection="multiple"
      :enable-rtl="$ctx.getDir() === 'rtl'"
      @cell-double-clicked="onCellDoubleClicked"
      style="height: 85vh"
      :pagination="true"
      :paginationAutoPageSize="true"
      :defaultColDef="defaultColDef"
      :onGridReady="onGridReady"
    >
    </ag-grid-vue>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

// components
import { AgGridVue } from 'ag-grid-vue3'
import { ColDef, GridApi, GridOptions } from 'ag-grid-community'
import actionDropdown from '@/views/accounting/customers/CustomerInvoiceMakePayment.vue'

import { i18nOrderMessages, OrderPayment } from '@/models/sales/Order'
import { useI18n } from 'vue-i18n'
import { formatDinero } from '@/utils/money'
import Dinero, { Currency } from 'dinero.js'
import { useRouter } from 'vue-router'
import dayjs from 'dayjs'
import { CustomerInvoice } from '@/models/sales/CustomerInvoice'
import { Search } from '@element-plus/icons-vue'
import Customer from '@/models/customer/Customer'
import { nilUUID } from '@/models/UUIDBase'
import { parseFormat } from '@/utils/date'

interface CustomerBalanceResponse {
  totalBalanceAmount?: number
  currency?: string
  precision?: number
  invoices?: CustomerInvoice[],
  customer?: Customer,
}

export default defineComponent({
  name: 'customers',

  setup() {
    const { t } = useI18n({
      messages: {
        ...i18nOrderMessages,
        en: {
          invoiceNo: 'Invoice #',
          invoiceDate: 'Invoice Date',
          dueDate: 'Due Date',
          type: 'Type',
          amountPaid: 'Amount Paid',
          balance: 'Balance',
          action: 'Action',
          total: 'Total',
          newCustomer: 'New Customer',
          cashCustomer: "Cash Customer",
        },
        ar: {
          invoiceNo: 'رقم الفاتورة',
          invoiceDate: 'تاريخ الفاتورة',
          dueDate: 'تاريخ الاستحقاق',
          type: 'نوع الفاتورة',
          amountPaid: 'المبلغ المدفوع',
          balance: 'الرصيد',
          action: 'العمليات',
          total: 'المجموع',
          newCustomer: 'زبون جديد',
          cashCustomer: "زبون",
        }
      },
      useScope: 'global'
    })

    return {
      t,
      Search
    }
  },

  data() {
    const columnDefs: ColDef[] = []
    const rowData: CustomerBalanceResponse = {}
    const gridOptions: GridOptions = {}
    const defaultColDef: ColDef = {}
    const router = useRouter()
    return {
      columnDefs,
      rowData,
      gridOptions,
      defaultColDef,
      router,
      searchTerm: '',
      selectedValue: '',
      gridApi: undefined as GridApi | undefined
    }
  },

  components: {
    AgGridVue
    // HorizontalButton
  },

  beforeMount() {
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
      minWidth: 150
    }

    this.columnDefs = [
      {
        headerName: this.t('invoiceNo'),
        field: 'externalID',
        cellStyle: { textAlign: 'center' },
        checkboxSelection: true,
        headerCheckboxSelection: true
      },
      {
        headerName: this.t('invoiceDate'),
        field: 'createdAt',
        valueFormatter: (params) => {
          return parseFormat(params.value)
        },
        cellStyle: { textAlign: 'center' }
      },
      {
        headerName: this.t('dueDate'),
        field: 'billDueDate',
        valueFormatter: (params) => {
          return parseFormat(params.value)
        },
        cellStyle: { textAlign: 'center' }
      },
      {
        headerName: this.t('type'),
        field: 'paymentType',
        cellStyle: { textAlign: 'center' }
      },
      {
        headerName: this.t('amountPaid'),
        field: 'amountPaid',
        valueFormatter: ({ data }) => {
          const invoice = data as CustomerInvoice
          return this.getDineroValue(invoice.totalPaidAmount || 0)
        },
        cellStyle: { textAlign: 'center' }
      },
      {
        headerName: this.t('balance'),

        valueFormatter: ({ data }) => {
          const customerInvoices = data as CustomerBalanceResponse
          return this.getDineroValue(customerInvoices.totalBalanceAmount || 0)
        },
        cellStyle: { textAlign: 'center' }
      },
      {
        headerName: this.t('action'),
        field: 'paymentType',
        cellRenderer: actionDropdown
      }
    ]
  },

  mounted() {
    this.gridApi?.sizeColumnsToFit()
  },

  methods: {
    async updateRowData() {
      try {
        this.gridApi?.showLoadingOverlay()
        const data = await this.$http.get<CustomerBalanceResponse>(
          `${CustomerInvoice.ENDPOINT}?customer_id=${this.router.currentRoute.params.id}`
        )
        data.invoices = data.invoices?.map((ci) => CustomerInvoice.from(ci))
        data.customer = Customer.from(data.customer || {})
        this.rowData = data
        if (this.rowData.customer?.id == nilUUID) {
          this.rowData.customer.firstName = this.t('cashCustomer')
        }
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        })
      }

      this.gridApi?.hideOverlay()
    },

    async onGridReady(params: any) {
      this.gridApi = params.api
      await this.updateRowData()
    },

    async applyToSelected() {
      if (this.selectedValue === 'export') {
        const selected = this.gridApi?.getSelectedRows()

        if (selected?.length) {
          this.gridApi?.exportDataAsCsv({ onlySelected: true })
          this.$alertModal.showSuccess({
            title: this.$t('views.common.listView.selectedRows.exportSuccess')
          })

          // deselect
          selected.length = 0
        }
      }

      this.selectedValue = ''
    },

    calculateAmountPaid(orderPayments: OrderPayment[]) {
      return orderPayments.reduce((acc, orderPayment) => acc + orderPayment.totalPaidAmount, 0)
    },

    filterItems() {
      this.gridApi?.setQuickFilter(this.searchTerm)
    },

    getDineroValue(amount: number): string {
      const dinero = Dinero({
        amount: amount,
        currency: this.rowData.currency as Currency || this.$ctx.currency,
        precision: this.rowData.precision
      })

      return formatDinero(dinero)
    },

    onCellDoubleClicked(event: any) {
      this.$router.push(
        this.$Route.SALES_ORDERS_ORDER.replace(':id', event.data.referenceableID)
      )
    }
  }
})
</script>

<style></style>
