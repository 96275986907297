import UUIDBase from '../UUIDBase'
import { useI18nInstance, localizeFieldName } from '@/plugins/i18n'

const { t } = useI18nInstance().global

export const brandI18nMessages = {
  en: {
    name: 'Brand Name'
  },
  ar: {
    name: 'اسم العلامة التجارية'
  }
}

export default class Brand extends UUIDBase {
  static ENDPOINT = '/inventory/itembrands'

  name = ''

  static from(json: Record<string, unknown> | Brand) {
    return Object.assign(new Brand(), json)
  }

  validateName() {
    if (this.name.length === 0) {
      return t('validation.required', {
        field: localizeFieldName('name', brandI18nMessages)
      })
    } else if (this.name.length > 255) {
      return t('validation.maxLength', {
        field: localizeFieldName('name', brandI18nMessages)
      })
    }
  }
}
