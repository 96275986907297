<template>
  <div class="m-3" style="height: 90vh">
    <div class="grid grid-cols-12 items-center content-center gap-2">
      <el-select
        class="input-label col-span-12 md:col-span-2"
        v-model="selectedValue"
        @change="applyToSelected"
      >
        <template v-if="$can('read', '/accounting/transactions')">
          <el-option
            key="export"
            :label="$t('views.common.listView.selectedRows.export')"
            value="export"
          >
          </el-option>
        </template>
      </el-select>

      <el-input
        :placeholder="$t('views.common.listView.searchRows')"
        class="w-full col-span-12 md:col-span-8"
        :prefix-icon="Search"
        v-model="searchTerm"
      />

      <div class="w-full col-span-12 md:col-span-2">
        <template v-if="$can('create', '/accounting/transactions')">
          <horizontal-button
            :title="$t('views.accounting.company.newExpense')"
            @click.prevent="$router.push($Route.ACCOUNTING_COMPANY_NEW_EXPENSE)"
          />
        </template>
      </div>
    </div>

    <ag-grid-vue
      class="ag-theme-alpine mt-4 md:mt-0"
      :columnDefs="columnDefs"
      :rowData="rowData"
      rowSelection="multiple"
      :enable-rtl="$ctx.getDir() === 'rtl'"
      style="height: 85vh"
      :pagination="true"
      :paginationAutoPageSize="true"
      :defaultColDef="defaultColDef"
      @cell-double-clicked="onCellDoubleClicked"
      :onGridReady="onGridReady"
    >
    </ag-grid-vue>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

// components
import { AgGridVue } from 'ag-grid-vue3'
import { ColDef, GridApi, GridOptions } from 'ag-grid-community'
import HorizontalButton from '@/components/HorizontalButton.vue'

import { useI18n } from 'vue-i18n'
import { formatPostgresDate, parseFormat } from '@/utils/date'
import { formatMoney } from '@/utils/money'
import UUIDBase from '@/models/UUIDBase'
import { Search } from '@element-plus/icons-vue'
import { Transaction } from '@/models/accounting/Transaction'

export const expenseI18nMessages = {
  en: {
    date: 'Date',
    expenseDate: 'Expense Date',
    payee: 'Payee',
    expenseType: 'Expense Type',
    notes: 'Notes',
    amount: 'Amount',
    employee: 'Employee',
    paidFrom: "Paid from"
  },
  ar: {
    date: 'التاريخ',
    expenseDate: 'تاريج الصرف',
    payee: 'المستفيد',
    expenseType: 'نوع الصرف',
    notes: 'الملاحظات',
    amount: 'المبلغ',
    employee: 'الموظف',
    paidFrom: "حساب الدفع"
  }
}
export class Expense extends UUIDBase {
  expenseDate?: string; // not null

  amount: number = 0; // not null, gte 0
  currency: string = ""; // required, size 7, not null
  precision: number = 0; // not null

  // Only for request
  payFromAccountID: string = ""; // UUID
  payToAccountID: string = ""; // UUID

  transactionID?: string; // UUID, not null
  transaction?: Transaction; // not null

  notes: string = "";
}

export default defineComponent({
  name: 'company-expenses',

  setup() {
    const { t } = useI18n({
      messages: {
        en: { ...expenseI18nMessages.en },
        ar: { ...expenseI18nMessages.ar }
      },
      unescape: 'global'
    })

    return {
      t,
      Search
    }
  },

  data() {
    const columnDefs: ColDef[] = []
    const rowData: Expense[] = []
    const gridOptions: GridOptions = {}
    const defaultColDef: ColDef = {}

    return {
      columnDefs,
      rowData,
      gridOptions,
      defaultColDef,

      searchTerm: '',
      selectedValue: '',
      gridApi: undefined as GridApi | undefined
    }
  },

  components: {
    AgGridVue,
    HorizontalButton
  },

  beforeMount() {
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
      minWidth: 150
    }

    this.columnDefs = [
      {
        headerName: this.t('date'),
        field: 'expenseDate',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        cellRenderer: (params) => {
          const expense = params.node?.data as Expense
          return `<div dir="ltr">${formatPostgresDate(expense.expenseDate || "")}</div>`
        },
      },
      {
        headerName: this.t('amount'),
        field: 'amount',
        cellStyle: { direction: 'ltr' },
        valueFormatter: (params) => {
          const expense = params.node?.data as Expense
          return formatMoney(expense.amount || 0, expense.currency || "")
        }
      },
      {
        headerName: this.t('payee'),
        field: this.$ctx.locale === "en" ? 'transaction.debitedAccount.enName' : "transaction.debitedAccount.arName"
      },
      {
        headerName: this.t('paidFrom'),
        field: this.$ctx.locale === "en" ? 'transaction.creditedAccount.enName' : "transaction.creditedAccount.arName"
      },
      {
        headerName: this.t('employee'),
        field: 'transaction.user.fullName'
      },
      {
        headerName: this.t('notes'),
        field: 'notes'
      }
    ]
  },

  mounted() {
    this.gridApi?.sizeColumnsToFit()
  
  },

  watch: {
    searchTerm() {
      this.filterItems()
    }
  },

  methods: {
    async updateRowData() {
      this.gridApi?.showLoadingOverlay()

      try {
        const data = await this.$http.get<Expense[]>("/accounting/company");
        this.rowData = data.map(e => {
          if (e.transaction) {
            e.transaction = Transaction.from(e.transaction)
          }
          return e;
        });
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        })
      }

      this.gridApi?.hideOverlay()
    },
    
    async onGridReady(params: any) {
      this.gridApi = params.api
      await this.updateRowData()
    },

    async applyToSelected() {
      if (this.selectedValue === 'export') {
        const selected = this.gridApi?.getSelectedRows()

        if (selected?.length) {
          this.gridApi?.exportDataAsCsv({
            onlySelected: true
          })
          this.$alertModal.showSuccess({
            title: this.$t('views.common.listView.selectedRows.exportSuccess')
          })

          // deselect
          selected.length = 0
        }
      }

      this.selectedValue = ''
    },

    filterItems() {
      this.gridApi?.setQuickFilter(this.searchTerm)
    },

    onCellDoubleClicked(event: any) {
      this.$router.push(
        this.$Route.ACCOUNTING_TRANSACTIONS_TRANSACTION.replace(':id', event.data.transactionID)
      )
    }
  }
})
</script>

<style></style>
