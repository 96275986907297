import UUIDBase from '../UUIDBase'

export default class ItemSerial extends UUIDBase {
  static ENDPOINT = ''
  ItemStockID = ''

  constructor(number: string) {
    super()
    this.number = number
  }

  number = ''
  Used?: Date
  Missing?: Date
}
