<template>
  <div v-if="loading" class="flex h-full justify-center items-center">
    <loading-spinner diameter="64px" />
  </div>

  <alert-badge
    v-else-if="error.title"
    isDanger
    :title="error.title"
    :body="error.body"
    @dismissed="
      () => {
        error.title = ''
        error.body = ''
      }
    "
  />

  <div v-else>
    <account-form
      :initAccount="initAccount"
      :successMsg="$t('actions.saveChangesSuccess')"
      :submitAction="update"
    />

    <!--    <el-divider content-position="center" v-if="postings.length">{{-->
    <!--      t("associatedPostings")-->
    <!--    }}</el-divider>-->

    <!-- fetch transactions of the account -->
    <!--    <div class="flex flex-col items-center">-->
    <!--      <div class="w-full md:w-8/12 mt-8">-->
    <!--        <el-table-->
    <!--          :data="postings"-->
    <!--          style="width: 100%"-->
    <!--          :row-class-name="colorRows"-->
    <!--          max-height="700"-->
    <!--        >-->
    <!--          <el-table-column prop="createdAt" :label="$t('models.common.date')">-->
    <!--            <template #default="scope">-->
    <!--              <i class="el-icon-time"></i>-->
    <!--              <span class="mx-2" dir="ltr">{{-->
    <!--                parseFormat(scope.row.createdAt)-->
    <!--              }}</span>-->
    <!--            </template>-->
    <!--          </el-table-column>-->

    <!--          <el-table-column prop="type" :label="t('postingType')" align="center">-->
    <!--            <template #default="scope">-->
    <!--              <i v-if="scope.row.type === 'credit'" class="el-icon-remove"></i>-->
    <!--              <i v-else class="el-icon-circle-plus"></i>-->
    <!--              <span class="mx-2">{{ t(scope.row.type) }}</span>-->
    <!--            </template>-->
    <!--          </el-table-column>-->

    <!--          <el-table-column-->
    <!--            prop="amountDinero"-->
    <!--            :label="t('amount')"-->
    <!--            align="center"-->
    <!--          >-->
    <!--            <template #default="scope" dir="ltr">-->
    <!--              {{ formatDinero(scope.row.amountDinero) }}-->
    <!--            </template>-->
    <!--          </el-table-column>-->

    <!--          <el-table-column-->
    <!--            prop="transactionID"-->
    <!--            :label="t('transactionID')"-->
    <!--            align="center"-->
    <!--          >-->
    <!--            <template #default="scope">-->
    <!--              <el-link-->
    <!--                type="primary"-->
    <!--                @click.prevent="-->
    <!--                  $goTo($Route.ACCOUNTING_TRANSACTIONS_TRANSACTION, {-->
    <!--                    transactionID: scope.row.transactionID-->
    <!--                  })-->
    <!--                "-->
    <!--                >{{ scope.row.transactionID }}</el-link-->
    <!--              >-->
    <!--            </template>-->
    <!--          </el-table-column>-->
    <!--        </el-table>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, ref } from 'vue'

import { useHTTP } from '@/plugins/http'

import { Account } from '@/models/accounting/Account'
import { Posting, transactionI18nMessages } from '@/models/accounting/Transaction'

import { parseFormat } from '@/utils/date'
import { formatDinero } from '@/utils/money'

// components
import AlertBadge from '@/components/AlertBadge.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import AccountForm from './AccountForm.vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'account',
  components: {
    AlertBadge,
    LoadingSpinner,
    AccountForm
  },
  setup() {
    const initAccount = ref(new Account())
    const http = useHTTP()
    const loading = ref(true)
    const error = ref({ title: '', body: '' })
    const route = useRoute()

    const { t } = useI18n({
      messages: transactionI18nMessages,
      unescape: 'global'
    })

    onBeforeMount(async () => {
      const accountURl = `${Account.ENDPOINT}/${route.params.id}`

      try {
        const resp = await http.get<Account>(accountURl)
        initAccount.value = Account.from(resp)
      } catch (errorResp) {
        error.value.title = errorResp.title
        error.value.body = errorResp.body
      }

      loading.value = false
    })

    const update = async (account: Account) => {
      try {
        const url = `${Account.ENDPOINT}/${account.id}`
        const resp = await http.put<Account>(url, account)
        initAccount.value = Account.from(resp)
      } catch (error) {
        return error
      }
    }

    return {
      initAccount,
      error,
      loading,
      update,
      t
    }
  },

  methods: {
    parseFormat,
    formatDinero,
    colorRows(params: { row: Posting }) {
      if (params.row.type === 'credit') {
        return 'bg-danger'
      }

      return 'bg-success'
    }
  }
})
</script>
