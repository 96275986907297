<template>
  <div class="m-3" style="height: 90vh">
    <div class="grid grid-cols-12 items-center content-center gap-2">
      <el-select
        class="input-label col-span-12 md:col-span-2"
        v-model="selectedValue"
        @change="applyToSelected"
      >
        <template v-if="$can('read', '/accounting/transactions')">
          <el-option
            key="export"
            :label="$t('views.common.listView.selectedRows.export')"
            value="export"
          >
          </el-option>
        </template>
      </el-select>

      <el-input
        :placeholder="$t('views.common.listView.searchRows')"
        class="w-full col-span-12 md:col-span-8"
        :prefix-icon="Search"
        v-model="searchTerm"
      />

      <div class="w-full col-span-12 md:col-span-2">
        <template v-if="$can('create', '/accounting/transactions')">
          <horizontal-button
            :title="$t('views.accounting.transactions.newTransaction')"
            @click.prevent="handleRegisterClick"
          />
        </template>
      </div>
    </div>

    <ag-grid-vue
      class="ag-theme-alpine mt-4 md:mt-0"
      :columnDefs="columnDefs"
      :rowData="rowData"
      rowSelection="multiple"
      :enable-rtl="$ctx.getDir() === 'rtl'"
      style="height: 85vh"
      :pagination="true"
      :paginationAutoPageSize="true"
      :defaultColDef="defaultColDef"
      :onGridReady="onGridReady"
      @cell-double-clicked="handleRegisterClick"
    >
    </ag-grid-vue>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

// components
import { AgGridVue } from 'ag-grid-vue3'
import { CellClickedEvent, ColDef, GridApi, GridOptions } from 'ag-grid-community'
import HorizontalButton from '@/components/HorizontalButton.vue'
import ActionButton from '@/components/ActionButton.vue'

import { Register, RegisterStatus } from "@/models/company/Register";
import { useI18n } from 'vue-i18n'
import { formatDinero } from '@/utils/money'
import { Search } from '@element-plus/icons-vue'

export const registerI18nMessages = {
  en: {
    no: 'Register Name',
    status: 'Status',
    openingFloating: 'Opening Floating',
    closingFloating: 'Closing Floating',
    totalCashIn: 'Total Cash In',
    totalCashOut: 'Total Cash Out',
    balance: 'Balance',
    moneyDropped: 'Money Dropped',
    openRegister: 'Open Register',
    closeRegister: 'Close Register',
    action: "Action",
    open: "open",
    close: "closed"
  },
  ar: {
    no: 'اسم المسجلة',
    status: 'الحالة',
    openingFloating: 'المبلغ الدوار عند الفتح',
    closingFloating: 'المبلغ الدوار عند الإغلاق',
    totalCashIn: 'النقد الداخل',
    totalCashOut: 'النقد الخارج',
    balance: 'الرصيد',
    moneyDropped: 'النقد المودّع',
    action: "فتح/اغلاق",
    open: "مفتوحة",
    close: "مغلقة",
    openRegister: 'أفتح الصندوق',
    closeRegister: 'أغلق الصندوق',
  }
}

export default defineComponent({
  name: 'registers',

  setup() {
    const { t } = useI18n({
      messages: registerI18nMessages,
      unescape: 'global'
    })

    return {
      t,
      Search
    }
  },

  data() {
    const columnDefs: ColDef[] = []
    const rowData: RegisterStatus[] = []
    const gridOptions: GridOptions = {}
    const defaultColDef: ColDef = {}
    const storeRegisters: Register[] = []

    return {
      columnDefs,
      rowData,
      gridOptions,
      defaultColDef,
      storeRegisters,

      searchTerm: '',
      selectedValue: '',
      gridApi: undefined as GridApi | undefined
    }
  },

  components: {
    AgGridVue,
    HorizontalButton,
    ActionButton
  },

  beforeMount() {
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
      minWidth: 150
    }

    this.columnDefs = [
      {
        headerName: this.t("no"),
        field: this.$ctx.locale === "ar" ? 'register.arName' : 'register.enName',
        checkboxSelection: true,
        headerCheckboxSelection: true,
      },
      {
        headerName: this.t('status'),
        field: 'status',
        cellStyle: ({ value }) => {
          const base = { textAlign: 'center', backgroundColor: 'transparent' }

          if (value == 'open') {
            base['backgroundColor'] = 'var(--success-bg-color)'
          } else if (value == 'close') {
            base['backgroundColor'] = 'var(--danger-bg-color)'
          }

          return base
        },
        valueFormatter: params => {
          const status = params.value;

          return status === 'open' ? this.t('open') : this.t('close');
        }
      },
      {
        headerName: this.t('openingFloating'),
        field: 'openingFloating',
        valueFormatter: (params) => {
          const register = params.node?.data as RegisterStatus
          return formatDinero(register.openingFloatingDinero)
        }
      },
      {
        headerName: this.t('closingFloating'),
        field: 'closingFloating',
        valueFormatter: (params) => {
          const register = params.node?.data as RegisterStatus
          return formatDinero(register.closingFloatingDinero)
        }
      },
      {
        headerName: this.t('totalCashIn'),
        field: 'totalCashIn',
        valueFormatter: (params) => {
          const register = params.node?.data as RegisterStatus
          return formatDinero(register.totalCashInDinero)
        }
      },
      {
        headerName: this.t('totalCashOut'),
        field: 'totalCashOut',
        cellStyle: { direction: 'ltr' },
        valueFormatter: (params) => {
          const register = params.node?.data as RegisterStatus
          return '- ' + formatDinero(register.totalCashOutDinero)
        }
      },
      {
        headerName: this.t('balance'),
        field: 'balance',
        valueFormatter: (params) => {
          const register = params.node?.data as RegisterStatus
          return formatDinero(register.balanceDinero)
        }
      },
      {
        headerName: this.t('action'),
        // valueFormatter: (params) => {
        //   const register = params.node?.data as RegisterStatus
        //   return register.status === 'open' ? this.t('closeRegister') : this.t('openRegister')
        // },
        cellRenderer: (params) => {
          const register = params.node?.data as RegisterStatus

          return `<div class="h-full w-full">
                    <div class="bg-gray-200 rounded-xl cursor-pointer text-center hover:bg-gray-400 justify-center" @click.prevent="handleRegisterClick">
                    ${register.status === 'open' ? this.t('closeRegister') : this.t('openRegister')}
                     </div>
                  </div>`;
        },
        onCellClicked: (params: CellClickedEvent) => {
          const register = params.node?.data as RegisterStatus
          if (register.status === 'close')
            this.$router.push(this.$Route.SALES_REGISTERS_OPEN_REGISTER)
          else this.$router.push(this.$Route.SALES_REGISTERS_CLOSE_REGISTER)
        }
      }
    ]
  },

  async mounted() {
    this.gridApi?.sizeColumnsToFit()

    try {
      const registers = await this.$http.get<Register[]>(Register.ENDPOINT)
      this.storeRegisters = registers.map(r => Register.from(r))
    } catch (error) {
      this.$alertModal.showDanger({
        title: error.title,
        body: error.body
      })
    }
  },

  watch: {
    searchTerm() {
      this.filterItems()
    }
  },

  methods: {
    async updateRowData() {
      this.gridApi?.showLoadingOverlay()
      try {
        const registerRes = await this.$http.get<RegisterStatus>(`/company/register-status`)
        this.rowData = registerRes.map(r => RegisterStatus.from(r))
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        })
      }

      this.gridApi?.hideOverlay()
    },

    async onGridReady(params: any) {
      this.gridApi = params.api
      await this.updateRowData()
    },

    async applyToSelected() {
      if (this.selectedValue === 'export') {
        const selected = this.gridApi?.getSelectedRows()

        if (selected?.length) {
          this.gridApi?.exportDataAsCsv({
            onlySelected: true
          })
          this.$alertModal.showSuccess({
            title: this.$t('views.common.listView.selectedRows.exportSuccess')
          })

          // deselect
          selected.length = 0
        }
      }

      this.selectedValue = ''
    },

    filterItems() {
      this.gridApi?.setQuickFilter(this.searchTerm)
    },

    handleRegisterClick(event) {
      this.$router.push(
        this.$Route.ACCOUNTING_REGISTERS_REGISTER.replace(':id', event.data.registerID!)
      )
    }
  }
})
</script>

<style></style>
