<template>
  <div v-if="loading" class="flex h-full justify-center items-center">
    <loading-spinner diameter="64px" />
  </div>

  <alert-badge
    v-else-if="error.title"
    isDanger
    :title="error.title"
    :body="error.body"
    @dismissed="
      () => {
        error.title = ''
        error.body = ''
      }
    "
  />

  <custom-price-form
    v-else
    :initCustomPrice="initCustomPrice"
    :successMsg="$t('actions.saveChangesSuccess')"
    :submitAction="update"
  />
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, ref } from 'vue'

import { useHTTP } from '@/plugins/http'

import CustomPrice from '@/models/inventory/CustomPrice'

// components
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import CustomPriceForm from '@/views/inventory/customPrices/CustomPriceForm.vue'
import AlertBadge from '@/components/AlertBadge.vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'customPrice',
  components: {
    LoadingSpinner,
    CustomPriceForm,
    AlertBadge
  },

  setup() {
    const initCustomPrice = ref(new CustomPrice())
    const http = useHTTP()
    const loading = ref(true)
    const error = ref({ title: '', body: '' })

    const route = useRoute()

    onBeforeMount(async () => {
      const url = `${CustomPrice.ENDPOINT}/${route.params.id}`

      try {
        const resp = await http.get<CustomPrice>(url)
        initCustomPrice.value = CustomPrice.from(resp)
      } catch (errorResp) {
        error.value.title = errorResp.title
        error.value.body = errorResp.body
      }

      loading.value = false
    })

    const update = async (customPrice: CustomPrice) => {
      try {
        const url = `${CustomPrice.ENDPOINT}/${customPrice.id}`
        const resp = await http.put<CustomPrice>(url, customPrice)
        initCustomPrice.value = CustomPrice.from(resp)
      } catch (error) {
        return error
      }
    }

    return {
      initCustomPrice,
      loading,
      update,
      error
    }
  }
})
</script>
