<template>
  <div v-if="loading" class="flex h-full justify-center items-center">
    <loading-spinner diameter="64px" />
  </div>

  <alert-badge
    v-else-if="error.title"
    isDanger
    :title="error.title"
    :body="error.body"
    @dismissed="
      () => {
        error.title = ''
        error.body = ''
      }
    "
  />

  <div v-else class="flex flex-col items-center">
    <div class="w-full md:w-8/12 mt-8">
      <el-table
        stripe
        :data="[
          { label: t('id'), value: it.externalID },
          { label: t('user'), value: it.user.fullName },
          {
            label: t('fromLocation'),
            value: $ctx.locale === 'en' ? it.fromLocation.enName : it.fromLocation.arName
          },
          {
            label: t('toLocation'),
            value: $ctx.locale === 'en' ? it.toLocation.enName : it.toLocation.arName
          },
          { label: t('status'), value: it.status },
          { label: t('receivedAt'), value: parseFormat(it.receivedAt) },
          { label: t('transferType'), value: it.transferType },
          { label: t('transferOutType'), value: it.transferOutType },
          { label: t('reason'), value: it.reason }
        ]"
      >
        <el-table-column prop="label" :label="t('label')"></el-table-column>
        <el-table-column prop="value" :label="t('value')"></el-table-column>
      </el-table>
    </div>

    <div class="w-full md:w-8/12 mt-8">
      <el-table stripe :data="it.inventoryTransferItems">
        <el-table-column prop="item.name" :label="t('itemName')"> </el-table-column>
        <el-table-column prop="quantity" :label="t('quantity')" align="center" width="150">
        </el-table-column>
        <el-table-column
          prop="InventoryTransferItemSerials"
          :label="t('serialNumbers')"
          width="200"
          align="center"
        >
          <template #default="scope">
            <li v-for="(serial, i) in scope.row.inventoryTransferItemSerials" :key="i">
              <el-tag :key="i" type="info">{{ serial.itemSerial.number }}</el-tag>
            </li>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, ref } from 'vue'

import { useHTTP } from '@/plugins/http'

// components
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import AlertBadge from '@/components/AlertBadge.vue'
import InventoryTransfer, {
  inventoryTransferI18nMessages
} from '@/models/inventory/InventoryTransfer'
import { useI18n } from 'vue-i18n'

import { parseFormat } from '@/utils/date'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'inventory-receiving',
  components: {
    LoadingSpinner,
    AlertBadge
  },
  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          ...inventoryTransferI18nMessages.en,
          generalInfoSection: 'General Info',
          label: 'Key',
          value: 'Value'
        },
        ar: {
          ...inventoryTransferI18nMessages.ar,
          generalInfoSection: 'المعلومات العامة',
          label: 'الحقل',
          value: 'القيمة'
        }
      }
    })

    const it = ref(new InventoryTransfer(''))
    const http = useHTTP()
    const loading = ref(true)
    const error = ref({ title: '', body: '' })
    const route = useRoute()

    onBeforeMount(async () => {
      const url = `${InventoryTransfer.ENDPOINT}/${route.params.id}`

      try {
        const resp = await http.get<InventoryTransfer>(url)
        it.value = InventoryTransfer.from(resp)
      } catch (errorResp) {
        error.value.title = errorResp.title
        error.value.body = errorResp.body
      }

      loading.value = false
    })

    return {
      it,
      loading,
      error,
      t
    }
  },

  methods: {
    parseFormat
  }
})
</script>

<style scoped>
/* table */
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
}
td,
th {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 4px;
}
tr:nth-child(odd) {
  background-color: #ebebeb;
}
</style>
