<template>
  <div v-if="loading" class="flex items-center justify-center h-full">
    <loading-spinner diameter="64px" />
  </div>

  <alert-badge
    v-else-if="error.title"
    isDanger
    :title="error.title"
    :body="error.body"
    @dismissed="
      () => {
        error.title = '';
        error.body = '';
      }
    "
  />

  <div v-else>
    <div class="flex flex-row">
      <div class="flex flex-col">
        <action-button :title="t('print')" faIcon="print" @click="print" />
      </div>

      <ReturnReceiptA4
        v-if="$ctx.getReceiptSize() == 'a4'"
        :order="order"
        :orderChange="orderChange"
        :transactions="transactions"
        :barcodeText="barcodeText"
        :receiptID="receiptID"
      ></ReturnReceiptA4>

      <ReturnReceiptSmall
        v-if="$ctx.getReceiptSize() == 'receipt'"
        :order="order"
        :orderChange="orderChange"
        :transactions="transactions"
        :barcodeText="barcodeText"
        :receiptID="receiptID"
      ></ReturnReceiptSmall>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { i18nOrderMessages, Order, OrderChange } from "@/models/sales/Order";
import { useI18n } from "vue-i18n";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import AlertBadge from "@/components/AlertBadge.vue";
import { Transaction } from "@/models/accounting/Transaction";
import ActionButton from "@/components/ActionButton.vue";
import ReturnReceiptA4 from "./ReturnReceiptA4.vue";
import ReturnReceiptSmall from "./ReturnReceiptSmall.vue";

export default defineComponent({
  components: {
    ReturnReceiptA4,
    ReturnReceiptSmall,
    LoadingSpinner,
    AlertBadge,
    ActionButton
  },

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          ...i18nOrderMessages.en,
          print: "Print Receipt"
        },
        ar: {
          ...i18nOrderMessages.ar,
          print: "اطبع الفاتورة",
          receipt: {
            returnReceipt: "إيصال الاسترجاع",
            type: "نوع الطلب",
            direct: "مباشر",
            credit: "آجل",
            layaway: "عربون",
            customer: "الزبون",
            cashCustomer: "زبون",
            location: "المتجر",
            orderNo: "رقم الطلب",
            cashier: "موظف الكاشير",
            dateAndTime: "التاريخ والوقت",
            orderNotes: "ملاحظات الطلب",
            itemName: "اسم المنتج",
            qty: "الكمية",
            price: "السعر",
            finalPrice: "السعر النهائي",
            totalBeforeDiscounts: "المبلغ الكلي قبل التخفيض",
            totalDiscount: "المبلغ الكلي للتخفيض",
            totalDue: "الاجمالي",
            totalItemsSold: "عدد المنتجات المباعة",
            paymentsInfo: "تفاصيل المدفوعات",
            paymentID: "رقم الحركة",
            paymentMethod: "طريقة الدفع",
            cash: "كاش",
            changeDue: "المتبقي",
            totalPaid: "المبلغ المدفوع الكلي",
            balance: "الرصيد",
            paymentNotes: "ملاحظات الحركة"
          }
        }
      }
    });

    return { t };
  },
  data() {
    return {
      order: new Order(),
      orderChange: null as OrderChange | null,
      transactions: [] as Array<Transaction>,
      loading: true,
      barcodeText: "",
      receiptID: "",
      error: {
        title: "",
        body: ""
      }
    };
  },
  async mounted() {
    try {
      const orderRes = await this.$http.get<Order>(
        `${Order.ENDPOINT}/${this.$route.params.id}`
      );
      this.order = Order.from(orderRes);
    } catch (error) {
      this.error.title = error.title;
      this.error.body = error.body;
    } finally {
      this.loading = false;

      // find orderChange
      this.order.orderChanges.forEach(oc => {
        if (oc.id == this.$route.params.orderChangeID) {
          this.orderChange = oc;
        }
      });
      if (this.orderChange) {
        this.transactions = this.order.invoice.transactions.filter(
          t => t.isReceipt && t.id == this.orderChange?.paidBackByCashTransactionID || t.isReceipt && t.id == this.orderChange?.paidBackByEpaymentTransactionID
        );

        if (this.transactions.length) {
          // this.receiptID = this.transactions[0].externalID!;
          this.receiptID = this.orderChange.externalID
        }

        this.barcodeText = `${this.orderChange.externalID!.slice(
          0,
          5
        )}-${this.orderChange.externalID!.slice(5)}`;
      }
    }
  },
  methods: {
    print() {
      window.print();
    }
  }
});
</script>
<style></style>
