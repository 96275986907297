import { isElectron } from '@/utils/env'

/**
 * safeSend is needed because importing from electron in a non-electron env will crash the system
 */
// async function safeSend(channel: string, ...args: unknown[]): Promise<void> {
//   if (isElectron()) {
//     try {
//       const { ipcRenderer } = await import('electron')
//       ipcRenderer.send(channel, ...args)
//     } catch (error) {
//       // do nothing
//     }
//   }
// }

/**
 * each function below checks if the env == electron, this is intentional
 * since I don't know yet if we should log if env != electron!
 */

export function logError(msg: string) {
  // if (isElectron()) {
  //   safeSend('log-error', msg)
  // } else {
  console.error(msg)
  // }
}

export function logWarn(msg: string) {
  // if (isElectron()) {
  //   safeSend('log-warn', msg)
  // } else {
  console.warn(msg)
  // }
}

export function logInfo(msg: string) {
  // if (isElectron()) {
  //   safeSend('log-info', msg)
  // } else {
  console.info(msg)
  // }
}

export function logVerbose(msg: string) {
  // if (isElectron()) {
  //   safeSend('log-verbose', msg)
  // } else {
  console.log(msg)
  // }
}

export function logDebug(msg: string) {
  // if (isElectron()) {
  //   safeSend('log-debug', msg)
  // } else {
  console.debug(msg)
  // }
}

export function logSilly(msg: string) {
  // if (isElectron()) {
  //   safeSend('log-silly', msg)
  // } else {
  console.log(msg)
  // }
}
